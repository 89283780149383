import request from 'services/request';

export async function tradeAgreementsMassiveUpload({ cancelToken, data }) {
  let uri = `/Agreements/Api/v1/SellOut/MassLoadExcel`;
  return request(uri, {
    method: 'POST',
    cancelToken,
    data,
  });
}
