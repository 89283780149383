import request from '../../../services/request';
const URL_SALESCHALLENGE = '/Sales/Api/v1/Challenge/';
const SALESCHALLENGE_GRAPHS = `${URL_SALESCHALLENGE}Graphs/`;

export async function getSalesGraph(params, cancelToken) {
  return request(`${SALESCHALLENGE_GRAPHS}Family/`, {
    method: 'GET',
    params,
    cancelToken,
  });
}

export async function getSalesGoals(params, cancelToken) {
  return request(`${URL_SALESCHALLENGE}SalesGoals/`, {
    method: 'GET',
    params,
    cancelToken,
  });
}
