import React, { useCallback, useEffect, useState } from 'react';
import { Form, Row, Col } from 'antd';
import Clients from 'RootModule/catalogs/Clients';
import BusinessLine from 'RootModule/catalogs/BusinessLine';
import SubRegion from 'RootModule/catalogs/SubRegion';
import Pog from 'RootModule/catalogs/Pog';
import BSwitch from 'Domains/TradeAgreements/common/BSwitch';
import { FormTitleDivider, ActionButtons } from 'common';
import SignersList from 'Domains/TradeAgreements/components/SignersList';
import BLoading from 'common/components/BLoading';
import {
  getSignersRequest,
  getReportRequest,
} from 'Domains/TradeAgreements/redux/actions/generateReport';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { openNotification } from 'common/misc/openNotification';

const layoutClient = {
  xs: 24,
  sm: 24,
  md: 8,
  lg: 8,
  xl: 6,
};
function GenerateFileForm(props) {
  const {
    year,
    territoryId,
    clientInfo,
    getSignersRequest: getSigners,
    getReportRequest: getReport,
    signers,
    isLoadingSigners,
    isLoading,
  } = props;
  const [isMultiple, setIsMultiple] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();

  useEffect(() => {
    getSigners({
      params: {
        clientId: clientInfo?.key,
      },
    });
  }, [getSigners, clientInfo]);

  const handleOnFormValuesChange = useCallback(({ changedValues, allValues }) => {
    //coment Tommy
    // const currentChange = Object.keys(changedValues)[0];
    // const queryValues = form.getFieldsValue();
  }, []);

  const hasTerritoryId = useCallback(() => {
    return territoryId ? { territoryId } : {};
  }, [territoryId]);

  const handleOnCancel = useCallback(() => {
    history.push({
      pathname: '/trade-agreements',
      state: { fromGenerateFile: true },
    });
  }, [history]);

  const onSuccessReport = useCallback(() => {
    openNotification(
      'success',
      'La descarga del archivo de acuerdo comercial se realizó correctamente.'
    );
  }, []);

  const handleOnSubmit = (values) => {
    getReport({
      params: {
        clientId: clientInfo.key,
        lineId: values?.lineId,
        subRegionId: values?.subRegionId,
        pogTypeId: values?.pogTypeId,
        clientIds: values.clientId ? values.clientId : [],
        name: `${clientInfo.value}_${year}`,
      },
      onSuccess: onSuccessReport,
    });
  };

  const handleSwitch = useCallback((checked) => {
    setIsMultiple(!checked);
    // comment tommy
    // const formValues = form.getFieldsValue();
    // form.setFieldsValue({});
  }, []);

  const setAutomaticallyAnOption = useCallback(
    (options, optionKey = 1, filterName = 'none') => {
      if (options?.length > 0) {
        const optionIndex = options.findIndex((option) => option?.key === optionKey);

        form.setFieldsValue({
          [filterName]: options[optionIndex]?.key ?? undefined,
        });
      }
    },
    [form]
  );

  return (
    <Form
      form={form}
      name="create-trade-agreement"
      onFinish={handleOnSubmit}
      onValuesChange={(changedValues, allValues) =>
        handleOnFormValuesChange({ changedValues, allValues })
      }
    >
      <FormTitleDivider title="Información de cliente" />

      <Row>
        <Col>
          <span>¿El archivo se genera para más de un cliente?</span>
          <BSwitch
            isRequired
            checkedChildren="No"
            unCheckedChildren="Sí"
            defaultChecked
            onChange={(checked) => handleSwitch(checked)}
          />
        </Col>
      </Row>
      <Row>
        {isMultiple && (
          <Col {...layoutClient}>
            <Clients
              isRequired
              mode={'multiple'}
              sourceId="TradeReport"
              requestParams={{
                year,
                clientId: clientInfo?.key,
                ...hasTerritoryId(),
              }}
            />
          </Col>
        )}

        <Col {...layoutClient}>
          <BusinessLine
            isRequired
            onSuccess={(options) => {
              signers && setAutomaticallyAnOption(options, signers?.lineId, 'lineId');
            }}
          />
        </Col>
        <Col {...layoutClient}>
          <SubRegion
            isRequired
            onSuccess={(options) =>
              signers && setAutomaticallyAnOption(options, signers?.subregionId, 'subRegionId')
            }
          />
        </Col>
        <Col {...layoutClient}>
          <Pog
            isRequired
            onSuccess={(options) =>
              signers && setAutomaticallyAnOption(options, signers?.pogId, 'pogTypeId')
            }
          />
        </Col>
      </Row>

      <FormTitleDivider title="Información de firmantes cliente" />

      {isLoadingSigners && (
        <BLoading
          size="small"
          style={{
            textAlign: 'center',
            fontSize: '2rem',
            margin: '0 auto',
            width: '100%',
            color: 'var(--main-theme-color)',
          }}
        />
      )}

      {!isLoadingSigners && (
        <SignersList title="Información del cliente" dataSource={signers ? signers?.clients : []} />
      )}

      <FormTitleDivider title="Información de firmantes BASF" />
      {isLoadingSigners && (
        <BLoading
          size="small"
          style={{
            textAlign: 'center',
            fontSize: '2rem',
            margin: '0 auto',
            width: '100%',
            color: 'var(--main-theme-color)',
          }}
        />
      )}
      {!isLoadingSigners && (
        <SignersList title="Información del cliente" dataSource={signers ? signers?.basfs : []} />
      )}

      <ActionButtons
        onCancel={handleOnCancel}
        isLoading={isLoading}
        disabled={
          form.isFieldsTouched(true) &&
          form.getFieldsError().filter(({ errors }) => errors.length).length > 0
        }
      />
    </Form>
  );
}
function mapStateToProps(state) {
  return {
    signers: state?.TRADE_AGREEMENTS?.generateReport?.signers?.data ?? false,
    isLoadingSigners: state?.TRADE_AGREEMENTS?.generateReport?.signers?.loading ?? false,
    isLoading: state?.TRADE_AGREEMENTS?.generateReport?.getReport?.isLoading ?? false,
    year: state?.TRADE_AGREEMENTS?.query?.year ?? '',
  };
}

export default connect(mapStateToProps, { getSignersRequest, getReportRequest })(GenerateFileForm);
