import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  approveTradeAgreementRequest,
  approveTradeAgreementRequestByKAM,
  cleanClientOnChangeTerritory,
  rejectTradeAgreementRequest,
  rejectTradeAgreementRequestByKAM,
  tradeCommentsRequest,
} from '../redux/actions';
import { ReviewActions, roleIdEnum, salesChallengeStatus } from 'common';
import { aproveClientModal, rejectClientModal } from '../utils/aproveRejectModals';

const ReviewActionsContainer = (props) => {
  const {
    query,
    isNational,
    statusClientId,
    getDaysLeft,
    approveTradeAgreementRequest: approveTradeAgreement,
    rejectTradeAgreementRequest: rejectTradeAgreement,
    approveTradeAgreementRequestByKAM: approveTradeAgreementKAM,
    rejectTradeAgreementRequestByKAM: rejectTradeAgreementKAM,
    tradeCommentsRequest: getHistory,
    cleanClientOnChangeTerritory: cleanClientOnTerritory,
  } = props;
  const [flag, setFlag] = useState(null);
  const { clientId, territoryId, year } = query;
  const localData = JSON.parse(localStorage.getItem('user'));
  const { role } = localData;
  let params = { clientId, year, territoryId };
  if (isNational) {
    params = { clientId, territoryId, year };
  }
  // Fn to aprove trade based on role
  const onValidate = () => {
    if (role.roleId !== roleIdEnum.KAM) {
      approveTradeAgreement({ data: params });
    } else {
      approveTradeAgreementKAM({ data: params });
    }
  };
  // Fn to reject trade based on role
  const onReject = (comment) => {
    params = { ...params, comment };
    if (role.roleId !== roleIdEnum.KAM) {
      rejectTradeAgreement({ data: params });
      getHistory({ params: { year, clientId, territoryId } });
    } else {
      rejectTradeAgreementKAM({ data: params });
      getHistory({ params: { year, clientId, territoryId } });
    }
  };
  // Effect to clean clientInfo
  useEffect(() => {
    if (flag === territoryId || territoryId === undefined) return;
    setFlag(territoryId);
    cleanClientOnTerritory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cleanClientOnTerritory, territoryId]);
  // Fn to show buttons based on roles
  const showButtons = () => {
    let status = false;
    let roleId = role.roleId;
    // True if year exist n if year is equal to year from state daysLeft
    let validDate = year && getDaysLeft?.year === parseInt(year, 10);
    if (validDate) {
      if ((isNational && territoryId) || (!isNational && clientId)) {
        if (roleId === roleIdEnum.KAM) {
          if (statusClientId === salesChallengeStatus.ON_SECOND_VALIDATION) {
            status = true;
          }
        } else if (roleId === roleIdEnum.SALES_MANAGER || roleId === roleIdEnum.ADMIN_SELL_OUT) {
          if (statusClientId === salesChallengeStatus.ON_VALIDATION) {
            status = true;
          }
        } else if (roleId === roleIdEnum.ADMINISTRATOR) {
          if (
            statusClientId === salesChallengeStatus.ON_VALIDATION ||
            statusClientId === salesChallengeStatus.ON_SECOND_VALIDATION
          ) {
            status = true;
          }
        }
      }
    }

    return status;
  };

  if (showButtons()) {
    return (
      // Component that shows "Aprobar", "Rechazar" buttons n corresponding modals
      <ReviewActions
        className={'sales-challenge'}
        templateLiteralsAprove={aproveClientModal}
        templateLiteralsReject={rejectClientModal}
        onApprove={() => onValidate()}
        onRejected={(comment) => onReject(comment)}
        actions={['approve', 'rejected']}
        showLabels={true}
      />
    );
  } else {
    return null;
  }
};

ReviewActionsContainer.propTypes = {
  /*Name of the the step . Will be used for info request */
  scope: PropTypes.oneOf(['Subregion', 'Territory', 'Zone', 'General']),
  /* Status challenge */
  statusId: PropTypes.number,
  /*Name of the the validation . Will be used for validation request */
  name: PropTypes.oneOf(['ValidateTerritory', 'ValidateZone', 'ValidateClient']),
};

ReviewActionsContainer.defaultProps = {
  statusId: 0,
};

function mapStateToProps(state) {
  return {
    query: state?.TRADE_AGREEMENTS_SELLOUT?.query,
    statusClientId: state.TRADE_AGREEMENTS_SELLOUT?.infoForAgreement?.clientInfo?.statusId,
    isNational: state?.TRADE_AGREEMENTS_SELLOUT?.infoForAgreement?.clientInfo?.isNational,
    getDaysLeft: state?.TRADE_AGREEMENTS_SELLOUT?.registerTrade?.daysLeft?.data?.VALIDITY_PROCESS,
    year: state?.TRADE_AGREEMENTS_SELLOUT?.query?.year ?? null,
    clientId: state?.TRADE_AGREEMENTS_SELLOUT?.query?.clientId,
    territoryId: state?.TRADE_AGREEMENTS_SELLOUT?.query?.territoryId,
  };
}

export default connect(
  mapStateToProps,
  {
    approveTradeAgreementRequest,
    rejectTradeAgreementRequest,
    approveTradeAgreementRequestByKAM,
    rejectTradeAgreementRequestByKAM,
    tradeCommentsRequest,
    cleanClientOnChangeTerritory,
  },
  null
  // {
  //   areStatePropsEqual: (next, prev) => console.log(next, prev),
  // }
)(ReviewActionsContainer);
