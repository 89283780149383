import TradeSellOutMassiveUpload from 'Domains/TradeAgreementsSellOut/massiveUpload/containers/TradeAgreementsMAsiveUpload';
import TradeSellOutGenerate from 'Domains/TradeAgreementsSellOut/pages/TradeAgreementsGenerate/TradeAgreementsGenerate';
import TradeAgreementsSellOut from 'Domains/TradeAgreementsSellOut/pages/TradeAgreementsSellOut/TradeAgreementsSellOut';
import TradeSellOutRegister from 'Domains/TradeAgreementsSellOut/pages/TradeAgreementsSellOutRegister';

export const tradeAgreementstSellOutModule = {
  'trade-agreements-sellout': TradeAgreementsSellOut,
  'trade-agreements-sellout_massive-upload': TradeSellOutMassiveUpload,
  'register_trade-agreements-sellout': TradeSellOutRegister,
  'update_trade-agreements-sellout': TradeSellOutRegister,
  'adjusted_trade-agreements-sellout': TradeSellOutRegister,
  'generate_trade-agreements-sellout': TradeSellOutGenerate,
};
