import { takeLatest, all, call, put, cancelled } from 'redux-saga/effects';

import {
  ACCOUNT_STATUS_DYNAMIC_ELEMENTS_REQUEST_CONSULT_TABLE,
  accountStatusDynamicElementsSetConsultTable,
  accountStatusDynamicElementsClearConsultTable,
} from '../actions';

import instance from 'services/request';

import { responseOK } from 'Domains/SalesChallengeRefactor/utils';
import { AccountStatusConsult } from '../../services';
import { checkIfNoElements } from '../../utils';
import { messageError } from 'utils/messages';

function* workAccountStatusDynamicElementsRequestConsultTable(action) {
  const { params, history } = action.payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  let mustRedirectToHome = false;
  try {
    const response = yield call(AccountStatusConsult, {
      cancelToken: source.token,
      params,
    });
    if (responseOK(response)) {
      mustRedirectToHome = checkIfNoElements(params, response.data);

      yield put(accountStatusDynamicElementsSetConsultTable(response.data));
    } else {
      yield put(accountStatusDynamicElementsClearConsultTable());
    }
  } catch (error) {
    yield accountStatusDynamicElementsClearConsultTable();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield accountStatusDynamicElementsClearConsultTable();
      source.cancel('cancelled Request');
    }
    if (mustRedirectToHome) {
      yield call(
        setTimeout,
        () => {
          messageError('No hay registros de elementos dinámicos en el sistema');
        },
        100
      );
      yield call(history.push, '/account-status');
    }
  }
}

export function* watchAccountStatusDynamicElementsRequestConsultTable() {
  yield all([
    takeLatest(
      ACCOUNT_STATUS_DYNAMIC_ELEMENTS_REQUEST_CONSULT_TABLE,
      workAccountStatusDynamicElementsRequestConsultTable
    ),
  ]);
}
