import request from 'services/request';
//import { paramsSerializer } from 'RootModule/services/catalogs';
const ADD_VALIDATOR_API = '/Sales/Api/v1/Validators/Save';

export async function addSalesChallengeValidator(params, cancelToken) {
  return request(`${ADD_VALIDATOR_API}`, {
    method: 'POST',
    data: params,
    cancelToken,
  });
}
