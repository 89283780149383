export const policyFormatter = (bussinesRules) => {
  let PolicyTypeId = bussinesRules;
  let Portafolio = '';
  if (bussinesRules.includes('_')) {
    let array = bussinesRules.split('_');
    PolicyTypeId = array[0];
    Portafolio = array[1];
  } else {
    Portafolio = undefined;
  }
  return { PolicyTypeId, Portafolio };
};
