export const useValidatorsReduceList = (list) => {
  return list.reduce((lista, actual) => {
    if (!lista.find((item) => item.userId === actual.userId)) {
      lista.push({ ...actual, zones: [actual.zone] });
      return lista;
    } else {
      const element = lista[lista.findIndex((item) => item.userId === actual.userId)];
      element.zones.push(actual.zone);
      return lista;
    }
  }, []);
};
