/**sagas */
import { takeLatest, put, cancelled, call } from 'redux-saga/effects';
import {
  GET_PROCESS_DATES_REQUEST,
  getProcessDatesFail,
  getProcessDatesSuccess,
  REGISTER_PROCESS_DATES_REQUEST,
  registerProcessDatesSuccess,
  registerProcessDatesFail,
} from 'Catalog/redux/actions/catalogs';
import instance from 'services/request';
import { getProcessDates, registerProcessDates } from 'Catalog/services/apiCatalogs';
import { responseOK } from 'utils';
import { orderArrayObjects } from 'Domains/SalesChallengeRefactor/utils';

function* getProcessDatesWorker(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getProcessDates, {
      cancelToken: source.token,
    });

    if (responseOK(response)) {
      yield put(getProcessDatesSuccess(response.data.sort(orderArrayObjects('stepId'))));
    } else {
      yield put(getProcessDatesFail());
    }
  } catch (e) {
    yield put(getProcessDatesFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* maskAllAsReadWatcher() {
  yield takeLatest(GET_PROCESS_DATES_REQUEST, getProcessDatesWorker);
}

function* registerProcessDatesWorker(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;

  try {
    const { params } = payload;
    const response = yield call(registerProcessDates, {
      cancelToken: source.token,
      params,
    });

    if (responseOK(response)) {
      yield put(registerProcessDatesSuccess(response.data.sort(orderArrayObjects('stepId'))));
    } else {
      yield put(registerProcessDatesFail());
    }
  } catch (e) {
    yield put(registerProcessDatesFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* registerProcessDatesWatcher() {
  yield takeLatest(REGISTER_PROCESS_DATES_REQUEST, registerProcessDatesWorker);
}
