import { takeLatest, put, call, cancelled } from 'redux-saga/effects';
import { INFO_REQUEST, infoFail, infoSuccess } from '../actions/info';
import instance from '../../../../services/request';
import { getInfo } from '../../services/info';
import { responseOK } from '../../utils';

function* workerInfoRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;

    const { params, name, onSuccess } = payload;

    const response = yield call(getInfo, name, params, source.token);

    if (responseOK(response)) {
      if (onSuccess) {
        yield onSuccess(response.data);
      }

      yield put(infoSuccess(response.data));
    } else {
      yield put(infoFail());
    }
  } catch (e) {
    yield put(infoFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* watchIndicatorRequest() {
  yield takeLatest(INFO_REQUEST, workerInfoRequest);
}
