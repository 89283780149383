export const handleOnOkAddProductModal = (
  form,
  current,
  setAddOkIsDisabled,
  filtersNameEnum,
  setDisabledFilters,
  setAllowRequests,
  familyReq,
  requestParamsSubscription,
  setShowModal,
  saveQuery,
  onChangeActiveComponent,
  cleanSelectedProducts,
  setWasSelected,
  objectKeys,
  values,
  hasTerritoryId,
  clientId,
  addProduct
) => {
  let products = [];
  let aux = [];

  const onSuccess = () => {
    setAddOkIsDisabled(false);
    form.setFieldsValue({
      [filtersNameEnum.FAMILY]: undefined,
      productId: undefined,
    });

    setDisabledFilters({
      zone: false,
      client: false,
      family: false,
      product: true,
    });

    setAllowRequests({
      zone: false,
      client: false,
      family: true,
      product: false,
    });

    familyReq({
      requestParams: { ...requestParamsSubscription?.['family'], ...hasTerritoryId() },
      sourceId: 'Agreements-SellOut',
    });

    setShowModal(false);
    setAddOkIsDisabled(true);
    current.resetFields();
    current.setFieldsValue({ products: [0] });

    saveQuery({ ...form.getFieldsValue() });
    onChangeActiveComponent('trade-agreements-sellout');
    cleanSelectedProducts();
    setWasSelected(false);
  };

  if (objectKeys.length > 0) {
    products = values.products;

    if (products.length > 0) {
      products.filter((product, index) => {
        if (product[`product${index}`]) {
          aux = [...aux, product[`product${index}`]];
        }
        return null;
      });

      let params = {
        ...hasTerritoryId(),
        clientId: clientId,
        products: aux,
      };
      addProduct({ params, onSuccess, sourceId: 'Agreements-SellOut' });
    }
  }
};
