import { takeEvery, all, call, put, cancelled } from 'redux-saga/effects';

import {
  SALES_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
  SALES_MASSIVE_UPLOAD_REQUEST_VERIFY,
  SALES_MASSIVE_UPLOAD_REQUEST_UPLOAD,
  SalesMassiveUploadSetAllowUpload,
  SalesMassiveUploadClearAllowUpload,
  SalesMassiveUploadSetVerify,
  SalesMassiveUploadClearVerify,
  SalesMassiveUploadClearUpload,
} from '../actions';

import instance from 'services/request';
import { messageSuccess } from 'utils/messages';

import { responseOK } from 'Domains/SalesChallengeRefactor/utils';

import { SalesMassiveUpload } from '../../services';
import {
  fileTo64String,
  validateTypeAndSize,
  summaryModal,
  summaryEndModal,
} from 'common/MassiveUploads/utils';
import { messageError } from 'utils/messages';

function* workSalesMassiveUploadSetAllowUpload(action) {
  try {
    yield SalesMassiveUploadSetAllowUpload(action.payload);
  } catch (error) {
    yield SalesMassiveUploadClearAllowUpload();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield SalesMassiveUploadClearAllowUpload();
    }
  }
}

function* workSalesMassiveUploadRequestVerify({ payload }) {
  const { file, setFileString } = payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const isValidFile = validateTypeAndSize(file);
    if (!isValidFile) {
      messageError(
        'El formato del archivo de carga masiva de ventas debe ser .xlsx y tener un tamaño máximo de 20 MB.'
      );

      return;
    }

    const file64Str = yield call(fileTo64String, file);
    yield call(setFileString, file64Str);
    const response = yield call(SalesMassiveUpload, {
      cancelToken: source.token,
      data: {
        content: file64Str,
        overwrite: false,
        save: false,
      },
    });
    if (responseOK(response)) {
      let { data, message, allCorrectMessage } = response;
      let allCorrect = data.correct > 0 && data.totalErrors === 0;

      if (allCorrect) {
        data.allCorrectMessage = allCorrectMessage;
      }

      yield put(SalesMassiveUploadSetVerify(data));
      yield call(summaryModal, message, false);
    } else {
      yield put(SalesMassiveUploadClearVerify());
    }
  } catch (error) {
    yield SalesMassiveUploadClearVerify();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield SalesMassiveUploadClearVerify();
      source.cancel('cancelled Request');
    }
  }
}

function* workSalesMassiveUploadRequestUpload({ payload }) {
  const { history, fileString, overwrite } = payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(SalesMassiveUpload, {
      cancelToken: source.token,
      data: {
        content: fileString,
        save: true,
        overwrite,
      },
    });
    if (responseOK(response)) {
      yield call(summaryEndModal, response.data, history, `/historic-information/sales`);
      yield call(messageSuccess, response.message);
      //yield call(history.push, '/catalog/process-dates');
    } else {
      yield put(SalesMassiveUploadClearUpload());
    }
  } catch (error) {
    yield SalesMassiveUploadClearUpload();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield SalesMassiveUploadClearUpload();
      source.cancel('cancelled Request');
    }
  }
}

export function* watchSalesMassiveUploadSetAllowUpload() {
  yield all([
    takeEvery(SALES_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD, workSalesMassiveUploadSetAllowUpload),
  ]);
}

export function* watchSalesMassiveUploadRequestVerify() {
  yield all([takeEvery(SALES_MASSIVE_UPLOAD_REQUEST_VERIFY, workSalesMassiveUploadRequestVerify)]);
}

export function* watchSalesMassiveUploadRequestUpload() {
  yield all([takeEvery(SALES_MASSIVE_UPLOAD_REQUEST_UPLOAD, workSalesMassiveUploadRequestUpload)]);
}
