/* eslint-disable no-empty-function */
import React, { forwardRef, useCallback } from 'react';
import { Modal, Input } from 'antd';
import { IconsMap } from '../../../../Layout/utils';
import PropTypes from 'prop-types';
import 'common/components/DeleteProductModal/DeleteProductModal.css';
const { TextArea } = Input;
const ExclamationIcon = IconsMap.ExclamationCircleOutlined;
const DeleteProductModal = forwardRef((props, ref) => {
  const {
    onOk,
    onCancel,
    visible,
    label,
    commentText,
    setCommentText,

    okIsLoading,
    okIsDisabled,
  } = props;

  const handleOk = useCallback(() => {
    onOk(commentText);
    setCommentText('');
  }, [onOk, commentText, setCommentText]);
  const handleCancel = useCallback(() => {
    onCancel();
    setCommentText('');
  }, [onCancel, setCommentText]);
  //rechazar el acuerdo comercial para el cliente
  return (
    <Modal
      className="modal-add-product"
      onOk={handleOk}
      onCancel={handleCancel}
      visible={visible}
      okButtonProps={{ loading: okIsLoading, disabled: okIsDisabled }}
      width={600}
      destroyOnClose={true}
    >
      <div className="header-modal">
        <ExclamationIcon style={{ fontSize: '1.5rem', alignSelf: 'center' }} />
        <p>Rechazar {label}</p>
      </div>

      <div className="body-modal" style={{ marginBottom: '1rem' }}>
        <p>¿Está seguro que desea rechazar el {label} para el cliente?</p>
        <TextArea
          rows={2}
          placeholder="Comentario"
          maxLength={300}
          value={commentText}
          onChange={(ev) => setCommentText(ev.target.value)}
        />
      </div>
    </Modal>
  );
});

DeleteProductModal.displayName = 'DeleteProductModal';

DeleteProductModal.propTypes = {
  label: PropTypes.oneOf(['acuerdo comercial', 'desafío de ventas']),
  onCancel: PropTypes.func,
};

DeleteProductModal.defaultProps = {
  label: 'acuerdo comercial',
  onCancel: () => {},
};

export default DeleteProductModal;
