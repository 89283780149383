export const MSG_022 = (entity) => {
  return `No hay registros de ${entity} en el sistema`;
};

export const MSG_100 = (year, client) => {
  return `"¿Esta seguro que desea enviar el archivo de acuerdo comercial correspondiente al año ${year} para el cliente ${client}?`;
};

export const MSG_035 = (entity) => {
  let existEntity = entity ? entity : ' desafío de ventas';
  return `El volumen para cada mes del ${existEntity} debe ser una cantidad múltiplo del MOQ registrado para el producto. Revise la información registrada.`;
};
export const MSG_003 = 'Los campos de volúmenes son obligatorios para los 12 meses.';

export const MSG_036 = (info) => {
  const { zone, client, product } = info;
  return `El registro del desafío de ventas para la zona ${zone}, cliente ${client} y producto ${product} se realizó de forma exitosa.`;
};

export const MSG_019_020 = (entity, necessity) => {
  return `Es necesario registrar la información correspondiente a ${necessity} en el sistema, antes de realizar el registro de ${entity}. Realice el registro de precios para continuar con la operación.`;
};
