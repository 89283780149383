import { combineReducers } from 'redux';
import downloadSalesChallengeReducer from './downloadSalesChallengeReport';
import downloadTradeAgreementsReducer from './downloadTradeAgreements';
import filters from './filters';
import validationFieldsReducer from './validationFields';
import addSalesChallengeValidatorReducer from './addSalesChallengeValidator';
import deleteSalesChallengeValidatorReducer from './deleteSalesChallengeValidator';
import validatorList from './validatorList';
import deleteTradeAgreementValidator from './deleteTradeAgreementValidator';
import addTradeAgreementValidator from './addTradeAgreementValidator';
import daysLeft from './daysLeft';
import salesChallengeValidatorConfirm from './salesChallengeValidatorConfirm';
import salesChallengeTeritoryValidator from './salesChallengeTerritoryValidator';
import addTradeAgreementsSellOutValidator from './addTradeAgreementSellOutValidator';
import downloadTradeAgreementsSellOut from './downloadTradeAgreementsSellOut';
import deleteTradeAgreementSellOutValidator from './deleteTradeAgreementSellOutValidator';

export default combineReducers({
  downloadSalesChallengeReducer,
  downloadTradeAgreementsReducer,
  downloadTradeAgreementsSellOut,
  addSalesChallengeValidatorReducer,
  addTradeAgreementValidator,
  addTradeAgreementsSellOutValidator,
  deleteSalesChallengeValidatorReducer,
  deleteTradeAgreementValidator,
  deleteTradeAgreementSellOutValidator,
  validatorList,
  daysLeft,
  validationFieldsReducer,
  filters,
  salesChallengeValidatorConfirm,
  salesChallengeTeritoryValidator,
});
