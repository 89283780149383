/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState, useRef, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { Row, Col, Form } from 'antd';
import { connect } from 'react-redux';
import { Years, Clients, Territory, Family, Products } from 'RootModule/catalogs/';
import { ResetButton } from 'common/components';

import ToggleModalAddProduct from 'common/components/BToggleModalControl';
import AddProductModal from 'common/components/AddProductModal';

import { openNotification, salesChallengeStatus } from 'common';
import { familyRequest } from 'RootModule/redux/actions/catalogs';

import { MinusOutlined } from '@ant-design/icons';
import { userIs } from 'utils';
import {
  addProductRequest,
  cleanRegisterTrade,
  cleanTable as cleanTableRequest,
  cleanTopFilters,
  cleanViewsRequest,
  deleteProductModalOpen,
  deleteProductRequest,
  getProductsRequest,
  productSelectedClean,
  productsSelected,
  queryClean,
  querySave,
  validateTableRequest,
  validProductsForDeletingSuccess,
} from '../../redux/actions';
import RemainingDays from '../common/RemainingDays';
import { MSG_003 } from '../../utils/messages';
import { handleFilters } from 'Domains/TradeAgreementsSellOut/utils/handleFilters';
import { handleOnOkAddProductModal } from 'Domains/TradeAgreementsSellOut/utils/handleOnOkAddProductModal';
import { setAnOption } from 'Domains/TradeAgreementsSellOut/utils/setAutomaticallyAnOption';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import SegmentBusinessRules from 'RootModule/catalogs/SegmentBusinessRules';
import { setAnOptionGroup } from 'Domains/TradeAgreementsSellOut/utils/setAutomaticallyAnOptionGroup';

const layoutFilters = {
  xs: 24,
  sm: 12,
  md: 6,
  lg: 4,
  xlg: 5,
};
const filtersNameEnum = {
  YEARS: 'years',
  ZONE: 'zone',
  CLIENT: 'client',
  FAMILY: 'family',
  PRODUCT: 'product',
};

const productsHasAgreementVolume = (el) => {
  if (el) {
    return {
      className: el.hasMontlyVolume ? 'element-registered' : 'not-special',
    };
  }
};

const TopFiltersToRegister = (props) => {
  const {
    onChangeActiveComponent,
    querySave: saveQuery,
    cleanRegisterTrade: cleanRegister,
    validateTableRequest: validateTableMandatory,
    cleanTableRequest: cleanTable,
    cleanViewsRequest: cleanViews,
    daysLeft,
    territoryId,
    getProductsRequest: getProductInfo,
    agreementType,
    isAddingProduct,
    productsSelected: selectedProduct,
    productSelectedClean: cleanSelectedProducts,
    cleanAddProducts: cleanProducts,
    addProductRequest: addProduct,
    deleteProductModalOpen: openModalDeleteProduct,
    clientId,
    productId,
    familyRequest: familyReq,
    validityProcessYear,
    consultClientKey,
    tableIsValid,
    keyClient,
    family,
    mustResetFilters,
    validProductsForDeleting,
    totalVolumeOfProduct,
    clientStatusId,
    validProductsForDeletingSuccess: setValidProductsForDeleting,
    persistentLine,
  } = props;

  // const [localClient, setLocalClient] = usePersistData('localClient', undefined);
  const ref = useRef();
  const history = useHistory();
  let days = 0;
  const [form] = Form.useForm();
  const [wasSelected, setWasSelected] = useState(false);
  const [addOkIsDisabled, setAddOkIsDisabled] = useState(true);
  const [isNational, setIsNational] = useState(false);
  const location = useLocation();

  const [disabledFilters, setDisabledFilters] = useState({
    lineId: true,
    territoryId: true,
    clientId: true,
    businessRule: true,
    family: true,
    productId: true,
  });
  const [allowRequest, setAllowRequests] = useState({
    lineId: false,
    territoryId: null,
    clientId: true,
    businessRule: false,
    family: false,
    productId: false,
  });
  const [requestParamsSubscription, setRequestParamsSubscription] = useState({
    lineId: {},
    territoryId: {},
    clientId: {},
    businessRule: {},
    family: { owner: true },
    productId: { owner: true },
  });

  // Effect to reload or redirect based in products for deleting
  useEffect(() => {
    if (mustResetFilters === false) return;
    setValidProductsForDeleting(0);
    if (validProductsForDeleting === 1) {
      history.push('/trade-agreements-sellout');
    }
    afterDeleting();
  }, [mustResetFilters]);

  // useEffect(() => {
  //   if (consultClientKey && territoryId && days > 0)
  //     setLocalClient({
  //       consultClientKey,
  //       territoryId,
  //       days,
  //     });
  // }, []);

  useEffect(() => {
    const cleanonRouteChange = () => {
      localStorage.removeItem('localClient');
    };

    return () => {
      cleanonRouteChange();
    };
  }, [location]);

  // Memorize if user can delete product
  const canDeleteProduct = useMemo(() => {
    let userIsValid = userIs('Administrador', 'Representante de Ventas');
    let processIsValid =
      clientStatusId === salesChallengeStatus.ON_PROCESS ||
      clientStatusId === salesChallengeStatus.REJECTED;

    return (
      productId &&
      userIsValid &&
      totalVolumeOfProduct !== null &&
      totalVolumeOfProduct >= 0 &&
      processIsValid
    );
  }, [productId, totalVolumeOfProduct, clientStatusId]);
  // Effect to clean table
  useEffect(() => {
    cleanTable();
  }, [cleanTable]);

  const hasTerritoryId = useCallback(() => {
    return territoryId ? { territoryId } : {};
  }, [territoryId]);

  const hasLineId = useCallback(() => {
    return persistentLine ? { lineId: persistentLine } : {};
  }, [persistentLine]);
  // Fn to handle filters change
  const handleOnFormValuesChange = ({ changedValues }) => {
    // Validates if table is ready to send request
    if (typeof tableIsValid.state === 'boolean' && !tableIsValid.state && !tableIsValid.wasSend) {
      setTimeout(() => {
        validateTableMandatory({ state: false, wasSend: true });
      }, 600);
      setTimeout(() => {
        openNotification('error', MSG_003);
      }, 1000);
    }

    const currentChange = Object.keys(changedValues)[0];
    const formValues = form.getFieldsValue();
    // Switch to set form values, wich filters will be disabled n wich ara allowed to make request based in currentChange
    handleFilters(
      currentChange,
      form,
      setDisabledFilters,
      setRequestParamsSubscription,
      requestParamsSubscription,
      setAllowRequests,
      formValues,
      hasTerritoryId,
      getProductInfo
    );
    const queryValues = form.getFieldsValue();
    // Sets query state with payload
    saveQuery({ ...queryValues, state: 'changed' });
    //Sets active component
    onChangeActiveComponent('trade-agreements-sellout');
  };

  const commonmFiltersProps = {
    sourceId: 'Agreements-SellOut',
  };
  // Fn to reset register filters
  const handleResetPage = useCallback(() => {
    cleanRegister();
    cleanTable();
    cleanViews();
    form.setFieldsValue({
      family: undefined,
      productId: undefined,
      businessRule: '3',
    });
  }, [cleanRegister, cleanTable, cleanViews, form]);

  const handleDaysLeft = useCallback(() => {
    if (agreementType && daysLeft) {
      switch (agreementType) {
        case 'GENERATE_TRADE_AGREEMENT':
          days = daysLeft?.GENERATE_TRADE_AGREEMENT?.daysLeft;
          return days;
        case 'TRADE_AGREEMENT_ADJUST':
          days = daysLeft?.TRADE_AGREEMENT_ADJUST?.daysLeft;
          return days;
        default:
          return '?';
      }
    }
  }, [agreementType, daysLeft]);

  const onModalOk = useCallback(
    (setShowModal) => {
      const { current } = ref;
      const values = current.getFieldsValue();
      const objectKeys = Object.keys(values);
      // Handles modal when trying to add product
      handleOnOkAddProductModal(
        form,
        current,
        setAddOkIsDisabled,
        filtersNameEnum,
        setDisabledFilters,
        setAllowRequests,
        familyReq,
        requestParamsSubscription,
        setShowModal,
        saveQuery,
        onChangeActiveComponent,
        cleanSelectedProducts,
        setWasSelected,
        objectKeys,
        values,
        hasTerritoryId,
        clientId,
        addProduct
      );
    },
    [
      cleanSelectedProducts,
      clientId,
      form,
      onChangeActiveComponent,
      saveQuery,
      addProduct,
      familyReq,
      hasTerritoryId,
      requestParamsSubscription,
    ]
  );

  const onModalCancel = useCallback(() => {
    const { current } = ref;
    current.resetFields();
    setAddOkIsDisabled(true);
    cleanSelectedProducts();
    setWasSelected(false);
    current.setFieldsValue({ products: [0] });
  }, [cleanSelectedProducts]);

  const productsRequestParams = useMemo(() => {
    return {
      ...requestParamsSubscription?.['productId'],
      ...hasTerritoryId(),
    };
  }, [family]);

  const onOneElementIsValid = () => {
    setAddOkIsDisabled(false);
  };

  useEffect(() => {
    form.setFieldsValue({ year: validityProcessYear });
  }, [form, validityProcessYear]);

  const setAutomaticallyAnOption = (options, optionKey = 1, filterName = 'none') => {
    setAnOption(
      options,
      optionKey,
      filterName,
      form,
      keyClient,
      clientId,
      handleOnFormValuesChange
    );
  };

  const setAutomaticallyAnOptionGroup = (options, optionKey = 1, filterName = 'none') => {
    setAnOptionGroup(
      options,
      optionKey,
      filterName,
      form,
      keyClient,
      clientId,
      handleOnFormValuesChange
    );
  };
  // Fn to reaload after deleting product
  const afterDeleting = () => {
    handleOnFormValuesChange({ changedValues: { clientId: form.getFieldsValue().clientId } });
  };

  // Send selected territory in consult trade agreement for admin territoryId
  // For sales representatives sen nill in territoryId

  if (daysLeft && validityProcessYear) {
    return (
      <>
        <Row justify="end">
          <Col {...{ md: 5, sm: 24, lg: 4 }}>
            <div style={{ marginRight: '-80px' }}>
              <RemainingDays days={handleDaysLeft()} />
            </div>
          </Col>
        </Row>

        <Form
          style={{ marginTop: '-2.7rem' }}
          name="validate_other"
          onValuesChange={(changedValues, allValues) =>
            handleOnFormValuesChange({ changedValues, allValues })
          }
          form={form}
          initialValues={{
            territoryId: territoryId,
          }}
          layout="horizontal"
        >
          <Row gutter={{ xs: 8, sm: 8, md: 10, lg: 12 }}>
            <Col {...layoutFilters}>
              <Years {...commonmFiltersProps} askFromService={false} disabled />
            </Col>
            <Col {...layoutFilters}>
              <Clients
                {...commonmFiltersProps}
                allowClear={false}
                askFromService={allowRequest?.['clientId']}
                requestParams={{
                  year: validityProcessYear,
                  owner: true,
                  ...hasTerritoryId(),
                  ...hasLineId(),
                }}
                onSuccess={(options) => {
                  setIsNational(
                    !keyClient
                      ? false
                      : options.find((option) => option.key === keyClient)?.isNational
                  );
                  setAutomaticallyAnOption(options, consultClientKey, 'clientId');
                }}
                disabled={true}
              />
            </Col>
            {isNational && (
              <Col {...layoutFilters}>
                <Territory
                  {...commonmFiltersProps}
                  allowClear={false}
                  askFromService={allowRequest?.['territoryId']}
                  requestParams={requestParamsSubscription?.['territoryId']}
                  disabled={true}
                />
              </Col>
            )}

            <Col {...layoutFilters}>
              <SegmentBusinessRules
                {...commonmFiltersProps}
                allowClear={false}
                askFromService={allowRequest?.['businessRule']}
                requestParams={requestParamsSubscription?.['businessRule']}
                disabled={disabledFilters['businessRule']}
                onSuccess={(options) => setAutomaticallyAnOptionGroup(options, '3', 'businessRule')}
              />
            </Col>
            <Col {...layoutFilters}>
              <Family
                {...commonmFiltersProps}
                allowClear={false}
                askFromService={allowRequest?.['family']}
                requestParams={{
                  ...requestParamsSubscription?.['family'],
                  ...hasTerritoryId(),
                }}
                disabled={disabledFilters['family']}
              />
            </Col>
            <Col {...layoutFilters}>
              <Products
                {...commonmFiltersProps}
                allowClear={false}
                askFromService={allowRequest?.['productId']}
                requestParams={productsRequestParams}
                disabled={disabledFilters['productId']}
                filterOption={(input, product) => {
                  let option = product.key.toLowerCase();
                  input = input.toLowerCase();
                  return option.indexOf(input) >= 0;
                }}
                optionKey="value"
                valueKey="key"
                showHasYearVolume={true}
                hasVolumeParams={{
                  clientId: consultClientKey ?? keyClient,
                  year: validityProcessYear,
                  ...hasTerritoryId(),
                }}
                ruleToClassName={productsHasAgreementVolume}
              />
            </Col>
            <Col {...{ md: 2, sm: 4, lg: 1 }}>
              <div style={{ marginTop: '36px', bottom: 0 }}>
                <ResetButton title="Limpiar filtros" onClick={handleResetPage} />
              </div>
            </Col>
            <Col style={{ marginTop: '36px' }}>
              {clientId ? (
                <ToggleModalAddProduct
                  tooltipText="Agregar otro producto"
                  conditionalClose
                  onOk={onModalOk}
                  onCancel={onModalCancel}
                >
                  <AddProductModal
                    onOneElementIsValid={onOneElementIsValid}
                    okIsDisabled={addOkIsDisabled}
                    okIsLoading={isAddingProduct}
                    selectedProduct={selectedProduct}
                    ref={ref}
                    wasSelected={wasSelected}
                    setShowAddAnotherOption={setWasSelected}
                    cleanProducts={cleanProducts}
                    sourceId={'Products'}
                    territoryId={territoryId}
                    hasTerritoryId={hasTerritoryId}
                    clientId={clientId}
                  />
                </ToggleModalAddProduct>
              ) : null}
            </Col>
            <Col style={{ marginTop: '36px' }}>
              {canDeleteProduct && (
                <ToggleModalAddProduct
                  tooltipText="Eliminar producto"
                  conditionalClose
                  iconButton={<MinusOutlined />}
                  onOpen={() => {
                    openModalDeleteProduct(productId);
                  }}
                />
              )}
            </Col>
          </Row>
        </Form>
      </>
    );
  } else {
    return null;
  }
};

function mapStateToProps(state) {
  return {
    validityProcessYear:
      state?.TRADE_AGREEMENTS_SELLOUT?.registerTrade?.daysLeft?.data?.VALIDITY_PROCESS?.year ??
      false,
    daysLeft: state?.TRADE_AGREEMENTS_SELLOUT?.registerTrade?.daysLeft?.data ?? false,
    territoryId:
      state?.TRADE_AGREEMENTS_SELLOUT?.infoForAgreement?.territoryInfo?.territoryId ?? null,
    consultClientKey:
      state?.TRADE_AGREEMENTS_SELLOUT?.infoForAgreement?.clientInfo?.key ?? undefined,
    consultClientIsNational:
      state?.TRADE_AGREEMENTS_SELLOUT?.infoForAgreement?.clientInfo?.isNational ?? undefined,
    agreementType: state?.TRADE_AGREEMENTS_SELLOUT?.infoForAgreement?.agreementType ?? false,
    clientId: state?.TRADE_AGREEMENTS_SELLOUT?.query?.clientId ?? false,
    productId: state?.TRADE_AGREEMENTS_SELLOUT?.query?.productId ?? false,
    isAddingProduct: state?.TRADE_AGREEMENTS_SELLOUT?.addProduct?.newProduct?.isPosting ?? false,
    tableIsValid: state?.TRADE_AGREEMENTS_SELLOUT?.table?.tableIsValid,
    keyClient: state?.TRADE_AGREEMENTS_SELLOUT?.clientKey?.clientKey ?? undefined,
    validProductsForDeleting: state?.TRADE_AGREEMENTS_SELLOUT?.validProductsForDeleting?.total ?? 0,
    family: state?.TRADE_AGREEMENTS_SELLOUT?.query?.family,
    PolicyTypeId: state?.TRADE_AGREEMENTS_SELLOUT?.query?.PolicyTypeId ?? null,
    mustResetFilters: state?.TRADE_AGREEMENTS_SELLOUT?.deleteProduct?.mustResetFilters ?? false,
    totalVolumeOfProduct: state?.TRADE_AGREEMENTS_SELLOUT?.table?.data?.find(
      (row) => row.currentPeriod === true
    ).total,
    clientStatusId: state?.TRADE_AGREEMENTS_SELLOUT?.infoForAgreement?.clientInfo?.statusId ?? 1,
    persistentLine: state?.TRADE_AGREEMENTS_SELLOUT?.persistentValues?.line ?? null,
  };
}

export default connect(mapStateToProps, {
  querySave,
  cleanRegisterTrade,
  cleanTopFilters,
  queryClean,
  getProductsRequest,
  cleanViewsRequest,
  cleanTableRequest,
  productSelectedClean,
  productsSelected,
  addProductRequest,
  deleteProductRequest,
  familyRequest,
  validateTableRequest,
  deleteProductModalOpen,
  validProductsForDeletingSuccess,
})(TopFiltersToRegister);
