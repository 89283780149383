import { takeLatest, put, cancelled, call } from 'redux-saga/effects';
import instance from 'services/request';
import { responseOK } from 'Domains/SalesChallengeRefactor/utils';
import { othersSectionActions } from '../../actions';
import { requestSectionData } from '../../../services';

const {
  getOthersClients,
  getOthersSubRegions,
  othersClientsFail,
  othersSubRegionsFail,
  cleanOthersClients,
  cleanOthersSubRegions,
  OTHERS_CLIENTS_REQUEST,
  GET_OTHERS_CLIENTS,
  OTHERS_CLIENTS_FAIL,
  CLEAN_OTHERS_CLIENTS,
  OTHERS_SUBREGIONS_REQUEST,
  GET_OTHERS_SUBREGIONS,
  OTHERS_SUBREGIONS_FAIL,
  CLEAN_OTHERS_SUBREGIONS,
} = othersSectionActions;

function* reqOthersClients(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  try {
    const response = yield call(requestSectionData, { cancelToken: source.token, payload });
    if (responseOK(response)) {
      yield put(getOthersClients(response.data ?? []));
    } else {
      yield put(othersClientsFail('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(othersClientsFail(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* SagaRequestOthersClients() {
  yield takeLatest(OTHERS_CLIENTS_REQUEST, reqOthersClients);
}

export function* SagaGetOthersClients() {
  yield takeLatest(GET_OTHERS_CLIENTS, getOthersClients);
}

export function* SagaOthersClientsFail() {
  yield takeLatest(OTHERS_CLIENTS_FAIL, othersClientsFail);
}

export function* SagaOthersClientsClean() {
  yield takeLatest(CLEAN_OTHERS_CLIENTS, cleanOthersClients);
}

function* reqOthersSubRegions(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  try {
    const response = yield call(requestSectionData, { cancelToken: source.token, payload });
    if (responseOK(response)) {
      yield put(getOthersSubRegions(response.data ?? []));
    } else {
      yield put(othersSubRegionsFail('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(othersSubRegionsFail(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* SagaRequestShopSubRegions() {
  yield takeLatest(OTHERS_SUBREGIONS_REQUEST, reqOthersSubRegions);
}

export function* SagaGetShopSR() {
  yield takeLatest(GET_OTHERS_SUBREGIONS, getOthersSubRegions);
}

export function* SagaShopSectionSRFail() {
  yield takeLatest(OTHERS_SUBREGIONS_FAIL, othersSubRegionsFail);
}

export function* SagaShopSRClean() {
  yield takeLatest(CLEAN_OTHERS_SUBREGIONS, cleanOthersSubRegions);
}
