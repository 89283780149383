import request from 'services/request';

const AGREEMENTS_API = '/Agreements/Api/v1/';
const AGREEMENTS_REPORT = `${AGREEMENTS_API}SellOut/Reports/`;

export async function getReport({ sourceId, params, cancelToken }) {
  // ${sourceId}
  return request(`${AGREEMENTS_REPORT}Report?`, {
    method: 'GET',
    responseType: 'blob',
    params,
    cancelToken,
    handlerEnabled: false,
  });
}

export async function getCatalogReportByName({ reportName = '', params, cancelToken }) {
  return request(`${AGREEMENTS_REPORT}Catalog/${reportName}`, {
    method: 'GET',
    params,
    cancelToken,
  });
}
export async function getGenerateFile({ data, cancelToken }) {
  const customBaseURL = `${process.env.REACT_APP_API_REPORTS}Reports/Api/v1/Agreements`;

  return request(``, {
    method: 'POST',
    data,
    customBaseURL,
    cancelToken,
  });
}

export async function getClientsApprovedList({ clientId, cancelToken }) {
  return request(`${AGREEMENTS_REPORT}Catalog/Clients/${clientId}`, {
    method: 'GET',
    cancelToken,
  });
}

export function getMassiveUpload({ sourceId, params, cancelToken }) {
  // ${sourceId}
  return request(`${AGREEMENTS_REPORT}ReportMassive`, {
    method: 'GET',
    responseType: 'blob',
    params,
    cancelToken,
    handlerEnabled: false,
  });
}
