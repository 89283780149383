import ErrorColumnTitle from 'common/components/ErrorColumnTitle';
import React from 'react';

const messageFaseOne = (
  <>
    <p>
      Registros para los cuales se omitió ingresar campos obligatorios o se ingresó un tipo de dato
      que no corresponde al solicitado en el campo.
    </p>
  </>
);
const messageFaseTwo = (
  <>
    <p>
      Registros para los cuales se ingresó información de elementos con estatus &quot; Inactivo
      &quot;, que no están registrados en el sistema, que no están relacionados entre si o se
      registró el valor de volumen mensual que no es múltiplo del MOQ del producto.
    </p>
  </>
);
const messageFaseThree = (
  <>
    <p>Registros que se encuentran repetidos dentro del archivo de carga masiva.</p>
  </>
);
const errorMessages = [
  {
    fase: 'Fase 1',
    message: messageFaseOne,
  },
  {
    fase: 'Fase 2',
    message: messageFaseTwo,
  },
  {
    fase: 'Fase 3',
    message: messageFaseThree,
  },
];

const tableColumns = [
  {
    key: 'errorPhase',
    title: (
      <>
        <ErrorColumnTitle errorMessages={errorMessages} />
      </>
    ),
    dataIndex: 'phase',
    className: 'title-masive',
  },
  {
    title: 'Línea de negocio',
    dataIndex: 'bussinesLine',
    key: 'bussinesLine',
  },
  {
    title: 'Subregión',
    dataIndex: 'subRegion',
    key: 'subRegion',
  },
  {
    title: 'Territorio',
    dataIndex: 'territory',
    key: 'territory',
  },

  {
    title: 'Representante clave de la cuenta',
    dataIndex: 'clientManager',
    key: 'clientManager',
  },
  {
    title: 'Sold to',
    dataIndex: 'soldTo',
    key: 'soldTo',
  },
  {
    title: 'Nombre del cliente',
    dataIndex: 'clientName',
    key: 'clientName',
  },
  {
    title: 'Número de producto',
    dataIndex: 'productNumber',
    key: 'productNumber',
  },
  {
    title: 'Nombre del producto',
    dataIndex: 'productName',
    key: 'productName',
  },
];
export default tableColumns;
