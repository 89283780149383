export const setFormFieldValue = (form, options, filterName, optionKey, handleOnValuesChange) => {
  if (options?.length > 0) {
    const optionIndex = options.findIndex((option) => option?.key === optionKey);
    const selectedOption = options[optionIndex] ?? options[0];

    form.setFieldsValue({
      [filterName]: selectedOption?.key ?? undefined,
    });

    const allValues = form.getFieldsValue();
    const changedValues = { [filterName]: selectedOption?.key };

    handleOnValuesChange({ changedValues, allValues });
  }
};
