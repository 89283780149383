import React from 'react';
import { connect } from 'react-redux';
import DeleteProductModal from 'common/components/DeleteProductModal';

import {
  deleteProductModalClose,
  deleteProductRequest,
} from 'Domains/TradeAgreements/redux/actions/deleteProduct';

const DeleteTradeAgreementProductModal = (props) => {
  const {
    showModal,
    productIdToDelete,
    validityProcessYear,
    territoryId,
    clientId,
    deleteProductModalClose: closeModal,
    deleteProductRequest: deleteProduct,
  } = props;
  const handleDeleteProduct = () => {
    const params = {
      year: validityProcessYear,
      territoryId,
      clientId,
      productId: productIdToDelete,
    };
    deleteProduct({ params });
    closeModal();
  };
  return (
    <DeleteProductModal
      onOk={handleDeleteProduct}
      onCancel={closeModal}
      visible={showModal}
      okIsDisabled={false}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    showModal: state.TRADE_AGREEMENTS.deleteProduct.showModal,
    productIdToDelete: state.TRADE_AGREEMENTS.deleteProduct.productIdToDelete,
    clientId: state?.TRADE_AGREEMENTS?.query?.clientId ?? false,
    // productId: state?.TRADE_AGREEMENTS?.query?.productId ?? false,
    validityProcessYear:
      state?.TRADE_AGREEMENTS?.registerTrade?.daysLeft?.data?.VALIDITY_PROCESS?.year ?? false,
    territoryId: state?.TRADE_AGREEMENTS?.infoForAgreement?.territoryInfo?.territoryId ?? null,
  };
};
const ConnectedDeleteTradeAgreementProductModal = connect(mapStateToProps, {
  deleteProductRequest,
  deleteProductModalClose,
})(DeleteTradeAgreementProductModal);

export default ConnectedDeleteTradeAgreementProductModal;
