import React, { forwardRef, useState } from 'react';
import { Form, Select, Divider, Input, Modal, Button } from 'antd';
import { PlusOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { setRuleRequired } from 'utils/validations';
import PropTypes from 'prop-types';
import { getPopupContainer as helperGetPopupContainer } from 'Domains/SalesChallengeRefactor/helpers/helperForms';
import { MdModeEdit } from 'react-icons/md';
import { FaTrash } from 'react-icons/fa';

const { Option } = Select;

const BSelect = forwardRef((props, ref) => {
  //Props
  const {
    form,
    initialValue,
    name,
    isLoading,
    isRequired,
    label,
    labelKey,
    optionKey,
    options,
    placeholder,
    valueKey,
    getPopupContainer,
    filterOption,
    allowClear,
    disabled,
    optionsProps,
    selectAll,
    postOneSegmentation: postSegment,
    deleteSegment,
    putSegment,
    getSegments,
    onSuccess,
    ...other
  } = props;
  const [modalData, setmodalData] = useState({ visible: false, name: '' });
  const [modalName, setmodalName] = useState('');
  const requiredRule = isRequired ? [setRuleRequired(label)] : [];

  const createOptionsList = () => {
    let optionsList = [...options];
    if (selectAll) {
      optionsList = [
        { [valueKey]: 'ALL', [optionKey]: 'ALL', [labelKey]: 'Todos' },
        ...optionsList,
      ];
    }
    if (typeof options !== 'undefined' && options.length > 0) {
      return optionsList.map((option) => {
        return (
          <Option
            key={option[optionKey]}
            value={option[valueKey]}
            {...optionsProps[option[valueKey]]}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                paddingLeft: '5px',
                paddingRight: '5px',
              }}
            >
              <div style={{ display: 'inline-block' }}>{option[labelKey]}</div>
              <div style={{ display: 'inline-block' }}>
                <MdModeEdit
                  size={22}
                  onClick={() => modalPUT(option)}
                  style={{ flex: 'none', cursor: 'pointer', marginRight: '15px' }}
                />
                {option.inUse === false ? (
                  <FaTrash size={20} onClick={() => deleteItem(option)} />
                ) : (
                  <FaTrash size={20} style={{ color: 'lightgray' }} />
                )}
              </div>
            </div>
          </Option>
        );
      });
    }
  };

  const loadingSelect = {
    hasFeedback: true,
    validateStatus: 'validating',
  };

  const loading = isLoading ? loadingSelect : null;
  const initial = initialValue === null ? null : { initialValue: initialValue };
  const parentValue = props.value ? { value: props.value } : {};

  //NUEVO CODIGO
  const [state, setState] = useState({
    items: ['jack', 'lucy'],
    name: '',
  });
  const onNameChange = (event) => {
    setState({
      items: [...state.items],
      name: event.target.value,
    });
  };
  //Modal PUT segmentación
  const modalPUT = (item) => {
    setmodalData({ visible: true, name: item.value, item: item });
    setmodalName(item.value);
  };
  //Agregar Segmentación
  const addItem = () => {
    postSegment(state.name);
    form.setFieldsValue({ segmentationId: undefined });
    setTimeout(() => {
      getSegments({ onSuccess });
    }, 300);
    setState({
      items: [...state.items],
      name: '',
    });
  };
  //Eliminar Segmentación
  const deleteItem = (item) => {
    Modal.confirm({
      title: 'Eliminar segmentación',
      content: `¿Está seguro que desea eliminar la segmentación?`,
      onOk() {
        deleteSegment(item.key);
        form.resetFields(['segmentationId']);
        setTimeout(() => {
          getSegments({ onSuccess });
        }, 300);
      },
    });
  };
  //Modificar Segmentación
  const putItem = () => {
    const { item } = modalData;
    putSegment({
      id: item.key,
      value: modalName,
    });
    form.resetFields(['segmentationId']);
    setTimeout(() => {
      getSegments({ onSuccess });
    }, 300);
    setmodalData({ visible: false, name: '', item: '' });
  };

  return options ? (
    <>
      <Form.Item
        label={label}
        {...loading}
        name={name}
        rules={[...requiredRule]}
        {...initial}
        {...other}
      >
        <Select
          //loading={isLoading}
          // style={{ minWidth: '180px' }}
          showSearch
          data-testid="idtest"
          id={name}
          ref={ref}
          {...other}
          {...parentValue}
          size={'middle'}
          getPopupContainer={getPopupContainer}
          placeholder={placeholder}
          allowClear={allowClear}
          disabled={disabled || isLoading}
          filterOption={filterOption}
          dropdownRender={(menu) => (
            <div>
              <Divider style={{ margin: '4px 0' }} />
              <div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
                <Input style={{ flex: 'auto' }} value={state.name} onChange={onNameChange} />
                <Button
                  type="primary"
                  // style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
                  onClick={addItem}
                  disabled={!state?.name}
                >
                  <PlusOutlined /> Agregar Nuevo
                </Button>
              </div>
              {menu}
            </div>
          )}
        >
          {createOptionsList()}
        </Select>
      </Form.Item>
      <Modal
        title={
          <div style={{ fontSize: '16px', color: '#00499a' }}>
            <InfoCircleTwoTone style={{ fontSize: '22px' }} />
            {`    Editar Segmentación "${modalData.name}"`}
          </div>
        }
        visible={modalData.visible}
        okButtonProps={{ disabled: !modalName }}
        onOk={putItem}
        onCancel={() => setmodalData({ visible: false, name: '' })}
      >
        <>
          <Form.Item label="Segmentación:">
            <Input
              style={{ flex: 'auto' }}
              onChange={(value) => setmodalName(value.target.value)}
              value={modalName}
            />
          </Form.Item>
        </>
      </Modal>
    </>
  ) : null;
});

BSelect.propTypes = {
  allowClear: PropTypes.bool,
  //filterOption: PropTypes.func,
  getPopupContainer: PropTypes.func,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLoading: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  labelKey: PropTypes.string,
  name: PropTypes.string,
  optionKey: PropTypes.string.isRequired,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  valueKey: PropTypes.string,
  disabled: PropTypes.bool,
  optionsProps: PropTypes.object,
};

BSelect.defaultProps = {
  allowClear: true,
  initialValue: null,
  isLoading: false,
  isRequired: false,
  label: 'Label',
  labelKey: 'value',
  name: 'select',
  optionKey: 'key',
  options: [],
  placeholder: 'Seleccione una opción',
  valueKey: 'key',
  disabled: false,
  optionsProps: {},
  getPopupContainer: helperGetPopupContainer,
};

BSelect.displayName = 'BSelect';

export default BSelect;
