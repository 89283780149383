import { takeLatest, put, call, cancelled } from 'redux-saga/effects';
import instance from 'services/request';
import {
  DOWNLOAD_TRADE_AGREEMENTS_SELL_OUT_REPORT_REQUEST,
  downloadTradeAgreementsSellOutReportSuccess,
  downloadTradeAgreementsSellOutReportFail,
} from '../actions/downloadTradeAgreementSellOut';
import { getTradeAgreementsSellOutValidatorsReport } from '../../services/reports';
import { openNotification } from 'common/misc/openNotification';

function* workDownloadTradeAgreementsSellOutReportRequest(action) {
  const { payload } = action;
  const { onSuccess, fileConfig } = payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  let date = new Date().toISOString().split('T')[0];
  try {
    const response = yield call(getTradeAgreementsSellOutValidatorsReport, source.token);
    if (response.Blob === null) {
      openNotification(
        'error',
        response.message
          ? response.message
          : 'El sistema no pudo realizar la operación, inténtelo de nuevo mas tarde'
      );
    }
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(response);
    link.download = `REPORTE VALIDADORES ACUERDO_SELL_OUT_${date}.${
      fileConfig ? fileConfig.extension : 'xlsx'
    }`;
    link.click();
    onSuccess();
    yield put(downloadTradeAgreementsSellOutReportSuccess(response));
  } catch (error) {
    console.error('[Redux-Saga-Basf]', error);
    yield put(downloadTradeAgreementsSellOutReportFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* watchSellOutReportRequest() {
  yield takeLatest(
    DOWNLOAD_TRADE_AGREEMENTS_SELL_OUT_REPORT_REQUEST,
    workDownloadTradeAgreementsSellOutReportRequest
  );
}
