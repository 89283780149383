import { takeEvery, put, call, all, cancelled } from 'redux-saga/effects';
import {
  READ_ONE_USER_REQUEST,
  readOneUserSuccess,
  readOneUserClean,
  PUT_ONE_USER_REQUEST,
  putOneUserClean,
  TOGGLE_ONE_USER_REQUEST,
  POST_ONE_USER_REQUEST,
  setIsPosting,
} from '../actions';
import { getOneUser, putOneUser, updateOneUserStatus, postOneUser } from '../../services/users';
import instance from 'services/request';
import { responseOK } from 'Domains/SalesChallengeRefactor/utils';
import { openNotification } from 'common';

function* workReadUser(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { userId } = payload;
    const response = yield call(getOneUser, {
      userId,
    });
    if (responseOK(response)) {
      yield put(readOneUserSuccess(response.data));
    } else {
      yield put(readOneUserClean());
    }
  } catch (error) {
    yield put(readOneUserClean());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

function* workPutOneUserRequest(action) {
  try {
    const { payload } = action;
    const { values, userId, history } = payload;
    const response = yield call(putOneUser, {
      data: { ...values },
      userId: userId,
    });
    if (responseOK(response)) {
      //yield put(putOneUserSuccess(response.data));
      yield openNotification('success', response.message);
      setTimeout(() => {
        if (history) history.push('/users');
      }, 300);
    } else {
      yield put(putOneUserClean());
    }
  } catch (error) {
    yield put(putOneUserClean());
    console.error('[BASF[REDUX-SAGA]', error);
  }
}

function* workToggleUser(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { enabled, userId } = payload;

    const response = yield call(updateOneUserStatus, {
      enabled,
      userId: userId,
    });
    if (responseOK(response)) {
      openNotification('success', response.message);
    } else {
      yield put(putOneUserClean());
    }
  } catch (error) {
    yield put(putOneUserClean());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

function* workPostOneUserRequest(action) {
  try {
    const { payload } = action;
    const { values, history } = payload;

    const response = yield call(postOneUser, {
      data: { ...values },
    });
    if (responseOK(response)) {
      yield put(setIsPosting(false));
      yield openNotification('success', response.message);
      setTimeout(() => {
        if (history) history.push('/users');
      }, 300);
    } else {
      yield put(setIsPosting(false));
    }
  } catch (error) {
    yield put(putOneUserClean());
    console.error('[BASF[REDUX-SAGA]', error);
  }
}

export function* watchCreateOneCaseRequest() {
  yield all([takeEvery(READ_ONE_USER_REQUEST, workReadUser)]);
}

export function* watchPutOneUserRequest() {
  yield all([takeEvery(PUT_ONE_USER_REQUEST, workPutOneUserRequest)]);
}

export function* watchToggleUser() {
  yield all([takeEvery(TOGGLE_ONE_USER_REQUEST, workToggleUser)]);
}

export function* watchPostOneUserRequest() {
  yield all([takeEvery(POST_ONE_USER_REQUEST, workPostOneUserRequest)]);
}
