import { takeLatest, put, call, cancelled } from 'redux-saga/effects';
import instance from '../../../../services/request';
import {
  GET_MASSIVE_UPLOAD_REQUEST,
  getMassiveUploadSuccess,
  getMAsiveUploadFail,
} from '../actions/getMassiveUpload';
import { GET_REPORT_REQUEST, getReportFail, getReportSuccess } from '../actions/generateReport';
import { getMassiveUpload, getGenerateFile } from '../../services/reports';
import { openNotification } from 'common/misc/openNotification';

function* workMassiveUploadRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;

    const { sourceId, onSuccess, params, fileConfig } = payload;
    let requestConfig;
    let service;
    let response;

    if (sourceId === 'Massive Upload') {
      response = yield getGenerateFile({ data: params, cancelToken: source.token });
    } else {
      service = getMassiveUpload;
      requestConfig = { sourceId, params, cancelToken: source.token };
      response = yield call(service, requestConfig);
    }

    if (response) {
      if (response.Data === null) {
        openNotification(
          'error',
          response.Message
            ? response.Message
            : 'El sistema no pudo realizar la operación, inténtelo de nuevo mas tarde'
        );
      }

      if (typeof response !== 'undefined') {
        if (onSuccess) onSuccess();
        yield put(getMassiveUploadSuccess());
        yield put(getReportSuccess());
        let blob = '';
        if (sourceId === 'Massive Upload') {
          if (response) {
            blob = response.Data;
            const link = document.createElement('a');
            link.href = `data:application/pdf;base64,${blob}`;
            link.download = `${params.name}.${fileConfig ? fileConfig.extension : 'xlsx'}`;
            link.click();
          }
        } else {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(response);
          link.download = `${params.name}.${fileConfig ? fileConfig.extension : 'xlsx'}`;
          link.click();
        }

        return response;
      }
    } else {
      yield put(getMAsiveUploadFail());
    }
  } catch (error) {
    yield put(getMAsiveUploadFail());
    yield put(getReportFail());
    console.error('[Redux-Saga-Basf prueba]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* watchReportRequest() {
  yield takeLatest(GET_MASSIVE_UPLOAD_REQUEST, workMassiveUploadRequest);
}

export function* watchReportsGetRequest() {
  yield takeLatest(GET_REPORT_REQUEST, workMassiveUploadRequest);
}
