import { takeLatest, put, call, select } from 'redux-saga/effects';
import instance from 'services/request';
import {
  ADD_SALES_CHALLENGE_VALIDATOR_REQUEST,
  addSalesChallengeValidatorSuccess,
  addSalesChallengeValidatorFail,
} from '../actions/addSalesChallengeValidator';
import { validatorsListSalesChallengeRequest } from '../actions/validatorList';
import { addSalesChallengeValidator } from '../../services/addSalesChallengeValidator';
import { salesChallengeValidatorsCatalogRequest } from 'RootModule/redux/actions/salesChallengeValidators';
//import { zonesRequest } from 'RootModule/redux/actions/catalogs';
//import { tradeAgreementsValidatorsCatalogRequest } from 'RootModule/redux/actions/tradeAgreementsValidators';
import { openNotification } from 'common/misc/openNotification';
import { responseOK } from 'utils';

function* workAddSalesChallengeValidator(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const query = yield select((state) => state?.VALIDATIONS?.filters?.query);
  const { territoryId } = query;
  const paramsToGetValidators = { Territories: territoryId };
  const paramsToGetCatalog = { Territories: territoryId };
  try {
    const params = payload;
    const response = yield call(addSalesChallengeValidator, params, source.token);
    /* const requestParams = { territories: territoryId };
    let sourceId = 'Validators'; */
    if (responseOK(response)) {
      yield put(addSalesChallengeValidatorSuccess());
      openNotification(
        'success',
        response.message
          ? response.message
          : 'El registro del usuario validador se realizó correctamente.'
      );
      //obtener validadores
      yield put(salesChallengeValidatorsCatalogRequest({ params: paramsToGetCatalog }));
      yield put(validatorsListSalesChallengeRequest({ params: paramsToGetValidators }));
      //yield put(zonesRequest({ requestParams, sourceId }));
    } else {
      openNotification(
        'error',
        response.message ? response.message : 'No se pudo realizar la operación'
      );
    }
  } catch (error) {
    yield put(addSalesChallengeValidatorFail());
    console.error(error);
  }
}

export function* watchAddSalesChallengeValidator() {
  yield takeLatest(ADD_SALES_CHALLENGE_VALIDATOR_REQUEST, workAddSalesChallengeValidator);
}
