import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { salesChallengeValidatorsCatalogRequest } from 'RootModule/redux/actions/salesChallengeValidators';
import { addSalesChallengeValidatorRequest } from '../redux-saga/actions/addSalesChallengeValidator';
import { deleteSalesChallengeValidatorRequest } from '../redux-saga/actions/deleteSalesChallengeValidator';
import { validatorsListSalesChallengeRequest } from '../redux-saga/actions/validatorList';
import { ValidatorsListView } from './ValidatorsListView';
import { ValidatorsScopeWrapper } from './ValidatorSScopeWrapper';
import { DeleteValidatorModal } from './DeleteValidatorModal';
import { daysLeftRequest } from '../redux-saga/actions/daysLeft';
import { useValidatorsReduceList } from '../hooks/useValidatorsReduceList';
import { useValidatorsParams } from '../hooks/useValidatorsParams';

export const SalesChallengeList = () => {
  const dispatch = useDispatch();
  const options = useSelector(
    (state) => state?.APP?.salesChallengevalidatorsCatalog?.salesChallengeValidators?.data ?? []
  );
  const territoryId = useSelector((state) => state?.VALIDATIONS?.filters?.query?.territoryId ?? []);
  const isLoading = useSelector(
    (state) =>
      state?.VALIDATIONS?.addSalesChallengeValidatorReducer?.loading ||
      state?.VALIDATIONS?.deleteSalesChallengeValidatorReducer?.loading ||
      false
  );
  const daysLeft = useSelector((state) => state?.VALIDATIONS?.daysLeft?.total ?? 0);
  const list = useSelector(
    (state) => state?.VALIDATIONS?.validatorList?.salesChallenge?.list || []
  );

  const selected = useRef('');
  const [dropdownLength, setDropdownLength] = useState(0);
  const [selectedOption, setSelectedOption] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [deletionModal, setDeletionModal] = useState({ visible: false, user: {} });
  const [validatorsToDisable, setValidatorsToDisable] = useState([]);
  const canModify = useMemo(
    () => territoryId.length > 0 && !isLoading && daysLeft > 0,
    [territoryId, isLoading, daysLeft]
  );

  const { getAddSalesChallengeAddParams, getDeleteSalesChallengeParams } = useValidatorsParams();
  const reducedList = useValidatorsReduceList(list);

  const modalOptions = deletionModal.user?.territories;
  const moduleTitle = 'Desafío de ventas';

  useEffect(() => {
    if (territoryId.length === 0) return;
    let params = {
      Territories: territoryId,
    };
    dispatch(salesChallengeValidatorsCatalogRequest({ params }));
    dispatch(validatorsListSalesChallengeRequest({ params }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [territoryId]);

  useEffect(() => {
    dispatch(daysLeftRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelect = (value) => {
    selected.current = value.map((item) => JSON.parse(item).userId);
    setDropdownLength(value.length);
    setSelectedOption(value);
  };

  const handleAddSalesChallengeValidator = (id) => {
    let params = getAddSalesChallengeAddParams({ id, territoryId });
    dispatch(addSalesChallengeValidatorRequest(params));
  };

  const hasAprovedSalesChallenges = (item) => {
    return item.territories.some(({ hasApprovedChallenge }) => hasApprovedChallenge === false);
  };

  const handleDeleteSalesChallengeValidator = () => {
    let params = getDeleteSalesChallengeParams({ validatorsToDisable, selectedUser });
    dispatch(deleteSalesChallengeValidatorRequest(params));
  };

  const handleSelectedValidatorToDisable = (item) => {
    const { territories } = item;
    setValidatorsToDisable(territories.map((territory) => territory.territoryId));
  };

  const validatorScopeWrapper = (item) => <ValidatorsScopeWrapper data={item.territories} />;
  const delValidatorModal = () => (
    <DeleteValidatorModal
      deletionModal={deletionModal}
      validatorsToDisable={validatorsToDisable}
      moduleTitle={moduleTitle}
      onCancel={() => setDeletionModal({ visible: false, user: {} })}
      onOk={() => {
        handleDeleteSalesChallengeValidator();
        setDeletionModal({ visible: false, user: {} });
      }}
      onChange={(value) => setValidatorsToDisable(value)}
      modalOptions={modalOptions?.map((item) => ({ id: item.territoryId, name: item.name }))}
    />
  );

  return (
    <ValidatorsListView
      moduleTitle={moduleTitle}
      canModify={canModify}
      handleSelect={handleSelect}
      selectedOption={selectedOption}
      options={options}
      dropdownLength={dropdownLength}
      handleAddValidator={handleAddSalesChallengeValidator}
      handleSelectedValidatorToDisable={handleSelectedValidatorToDisable}
      selected={selected}
      setSelectedOption={setSelectedOption}
      reducedList={reducedList}
      hasAproved={hasAprovedSalesChallenges}
      setSelectedUser={setSelectedUser}
      validatorScopeWrapper={validatorScopeWrapper}
      setDeletionModal={setDeletionModal}
      delValidatorModal={delValidatorModal}
    />
  );
};
