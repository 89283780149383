import useGetPermissions from './useGetPermissions';
import { capitalizeString } from '../utils';
import { tradeAgreementsFilters } from '../models/enumFilters';

function useGetFilters() {
  const userCurrent = useGetPermissions('trade-agreements', 'consult');
  if (userCurrent && userCurrent.permissions) {
    let findFilters = userCurrent.permissions;
    let filtersArray = [];
    if (findFilters) {
      let filters = findFilters.find((p) => p.uri === 'topFilters');
      if (filters && filters?.components?.length > 0) {
        let filtersAllowed = filters.components.map((f) => f.uri);
        let excludeFilters = ['typeValue'];
        filtersAllowed.map((filter) => {
          if (!excludeFilters.includes(filter)) {
            let nameComponent = capitalizeString(filter);
            filtersArray = [
              ...filtersArray,
              {
                key: filter,
                component: nameComponent,
                enum: tradeAgreementsFilters[filter],
              },
            ];
          }
          return null;
        });
        //console.info('useGetFilters: filtros permitidos [', filters.components.length, ']');
      } else {
        console.warn('useGetFilters: No tiene ningún filtro permitido ');
      }
    } else {
      console.warn('useGetFilters: No tiene el permiso topFilters ,agregalo ');
    }
    // filtersArray[6].component = 'SegmentBusinessRules';
    return filtersArray;
  }
}

export default useGetFilters;
