import React from 'react';
import EditableCell from 'Domains/TradeAgreements/common/EditableTable/EditableCell';
import { formatPriceWithDecimals } from 'Domains/SalesChallengeRefactor/utils';

let rowsWithFormat = [2, 5, 8];
let rowsWithFormatAgreements = [2, 3];

function isAllvalues(values) {
  let mes = ['ene', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'];

  let count = 0;

  mes.map((d) => {
    if (values[d] !== '' && values[d] !== null) {
      count++;
    }
    return count;
  });

  if (count === 12) {
    return true;
  }
}

const renderInputCell = (record, key) => {
  if (record.isEditable) {
    let isIncompleteData = isAllvalues(record);

    return {
      props: {
        className: 'cell-editable ',
      },
      children: (
        <EditableCell
          isIncompleteData={isIncompleteData}
          cellId={key}
          isEditable={record?.isEditable}
          value={record?.[key]}
          onValueChange={record?.onValueChange}
          moq={record?.moq}
          pageType={record?.pageType}
        />
      ),
    };
  } else {
    if (typeof record[key] === 'string' || record.rowKey === 7) {
      return record.rowKey === 7 ? formatPriceWithDecimals(record[key], true) : record[key];
    } else {
      if (
        record.pageType === 'TradeAgreements' &&
        rowsWithFormatAgreements.includes(record.rowKey)
      ) {
        return formatPriceWithDecimals(record[key]);
      }

      if (rowsWithFormat.includes(record.rowKey)) {
        return formatPriceWithDecimals(record[key]);
      } else {
        if (record.rowKey === 6) {
          return formatPriceWithDecimals(record[key], true);
        }
      }
    }
  }
};

const renderTotalCell = (text, record) => {
  if (typeof text === 'string') {
    return text;
  } else {
    if (record.rowKey === 7) {
      return formatPriceWithDecimals(text, true);
    } else {
      if (
        record.pageType === 'TradeAgreements' &&
        rowsWithFormatAgreements.includes(record.rowKey)
      ) {
        return formatPriceWithDecimals(text);
      }

      if (rowsWithFormat.includes(record.rowKey)) {
        return formatPriceWithDecimals(text);
      } else {
        return formatPriceWithDecimals(text, true);
      }
    }
  }
};

const renderTitles = (record, key) => {
  if (record.currentPeriod) {
    return {
      props: {
        className: 'cell-current-period current',
      },
      children: record[key],
    };
  } else {
    return record[key];
  }
};

export const modelTable = [
  {
    title: '',
    dataIndex: 'title',
    className: 'cell-title',
    key: 'title',
    render: (_text, record) => renderTitles(record, 'title'),
  },
  {
    title: 'Ene',
    dataIndex: 'ene',
    key: 'ene',
    className: 'cell-month',

    render: (_text, record) => renderInputCell(record, 'ene'),
  },
  {
    title: 'Feb',
    dataIndex: 'feb',
    key: 'feb',
    className: 'cell-month',

    render: (_text, record) => renderInputCell(record, 'feb'),
  },
  {
    title: 'Mar',
    dataIndex: 'mar',
    key: 'mar',
    className: 'cell-month',
    render: (_text, record) => renderInputCell(record, 'mar'),
  },
  {
    title: 'Abr',
    dataIndex: 'mar',
    key: 'apr',
    className: 'cell-month',
    render: (_text, record) => renderInputCell(record, 'apr'),
  },
  {
    title: 'May',
    dataIndex: 'may',
    key: 'may',
    className: 'cell-month',
    render: (_text, record) => renderInputCell(record, 'may'),
  },
  {
    title: 'Jun',
    dataIndex: 'jun',
    className: 'cell-month',
    key: 'jun',
    render: (_text, record) => renderInputCell(record, 'jun'),
  },
  {
    title: 'Jul',
    dataIndex: 'jul',
    key: 'jul',
    className: 'cell-month',
    render: (_text, record) => renderInputCell(record, 'jul'),
  },
  {
    title: 'Ago',
    dataIndex: 'ago',
    key: 'ago',
    className: 'cell-month',
    render: (_text, record) => renderInputCell(record, 'ago'),
  },
  {
    title: 'Sep',
    dataIndex: 'sep',
    key: 'sep',
    className: 'cell-month',
    render: (_text, record) => renderInputCell(record, 'sep'),
  },
  {
    title: 'Oct',
    dataIndex: 'oct',
    key: 'oct',
    className: 'cell-month',
    render: (_text, record) => renderInputCell(record, 'oct'),
  },
  {
    title: 'Nov',
    dataIndex: 'nov',
    key: 'nov',
    className: 'cell-month',
    render: (_text, record) => renderInputCell(record, 'nov'),
  },
  {
    title: 'Dic',
    dataIndex: 'dic',
    key: 'dic',
    className: 'cell-month',
    render: (_text, record) => renderInputCell(record, 'dic'),
  },
  {
    title: 'Total',
    dataIndex: 'total',
    className: 'cell-total',
    key: 'total',
    render: (text, record) => renderTotalCell(text, record),
  },
];
