import { takeLatest, cancelled, put, call } from 'redux-saga/effects';
import { responseOK } from 'Domains/SalesChallengeRefactor/utils';
import instance from 'services/request';
import { addProduct } from 'Domains/SalesChallengeRefactor/services/challenge';
import { addProduct as addProductAgreements } from '../../services/registerTrade';
import { openNotification } from 'common/misc/openNotification';
import { ADD_PRODUCT_REQUEST, addProductFail, addProductSuccess } from '../actions';

function* workAddProductRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { params, onSuccess, sourceId } = payload;
    let service;
    switch (sourceId) {
      case 'Agreements-SellOut':
        service = addProductAgreements;
        break;
      case 'Sales':
      default:
        service = addProduct;
        break;
    }
    const response = yield call(service, { params, cancelToken: source.token });

    if (responseOK(response)) {
      setTimeout(() => {
        openNotification('success', response.message);
      }, 500);
      if (onSuccess) {
        onSuccess(response.data);
      }
      yield put(addProductSuccess(response.data));
    } else {
      yield put(addProductFail());
    }
  } catch (error) {
    yield put(addProductFail());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchAddProductRequest() {
  yield takeLatest(ADD_PRODUCT_REQUEST, workAddProductRequest);
}
