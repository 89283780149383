import React, { useState, useEffect, useRef } from 'react';
import { Col, Row } from 'antd';
import './style.css';
import PropTypes from 'prop-types';
import { formatNumber } from 'utils';

function OnlyBattery(props) {
  const { value, style, type, showValue } = props;
  const timer1 = useRef();
  const timer2 = useRef();
  const timer3 = useRef();
  const timer4 = useRef();
  const [bar1, setBar1] = useState('#D4D4D4');
  const [bar2, setBar2] = useState('#D4D4D4');
  const [bar3, setBar3] = useState('#D4D4D4');
  const [bar4, setBar4] = useState('#D4D4D4');
  let initialColor = '#D4D4D4';

  const [barsToPaint, setBarsToPaint] = useState({
    bar1: false,
    bar2: false,
    bar3: false,
    bar4: false,
  });
  const [barColor, setBarColor] = useState('');

  useEffect(() => {
    const sempahore = Object.freeze({
      RED: '#e6001d',
      YELLOW: '#ff9600',
      GREEN: '#00c14f',
      BLUE: '#0078b3',
    });
    const { RED, YELLOW, BLUE, GREEN } = sempahore;
    if (typeof value === 'number' && value !== 0) {
      setBarColor(initialColor);

      if (value >= 0.1 && value <= 24.99) {
        setBarsToPaint((previous) => setBarsToPaint({ ...previous, bar1: true }));
      } else if (value >= 25 && value <= 49.99) {
        setBarsToPaint((previous) => setBarsToPaint({ ...previous, bar1: true, bar2: true }));
      } else if (value >= 50 && value <= 74.99) {
        setBarsToPaint((previous) =>
          setBarsToPaint({ ...previous, bar1: true, bar2: true, bar3: true })
        );
      } else if (value >= 75) {
        setBarsToPaint({ bar1: true, bar2: true, bar3: true, bar4: true });
      }

      if (value <= 79.99) {
        //initialColor = RED;
        setBarColor(RED);
      } else if (value >= 80 && value <= 94.99) {
        //initialColor = YELLOW;
        setBarColor(YELLOW);
      } else if (value >= 95 && type !== 'agreements') {
        //initialColor = GREEN;
        setBarColor(GREEN);
      } else if (value >= 95 && value <= 100 && type === 'agreements') {
        setBarColor(GREEN);
      } else if (value > 100 && type === 'agreements') {
        //initialColor = BLUE;
        setBarColor(BLUE);
      } else {
        setBarColor('');
      }
    }
    //setBarColor(initialColor);

    return () => {
      setBarsToPaint({
        bar1: false,
        bar2: false,
        bar3: false,
        bar4: false,
      });
    };
  }, [value, type, setBarsToPaint, initialColor]);

  useEffect(() => {
    timer1.current = setTimeout(() => {
      if (barsToPaint.bar1) setBar1(barColor);
    }, 200);

    return () => {
      clearTimeout(timer1.current);
      setBar1(initialColor);
    };
  }, [barColor, barsToPaint, initialColor]);

  useEffect(() => {
    timer2.current = setTimeout(() => {
      if (barsToPaint.bar2) setBar2(barColor);
    }, 300);
    return () => {
      clearTimeout(timer2.current);
      setBar2(initialColor);
    };
  }, [barColor, barsToPaint, initialColor]);

  useEffect(() => {
    timer3.current = setTimeout(() => {
      if (barsToPaint.bar3) setBar3(barColor);
    }, 400);
    return () => {
      clearTimeout(timer3.current);
      setBar3(initialColor);
    };
  }, [barColor, barsToPaint, initialColor]);

  useEffect(() => {
    timer4.current = setTimeout(() => {
      if (barsToPaint.bar4) setBar4(barColor);
    }, 500);
    return () => {
      clearTimeout(timer4.current);
      setBar4(initialColor);
    };
  }, [barColor, barsToPaint, initialColor]);

  const handleShowValue = () => {
    if (showValue) {
      if (value && value !== undefined && value !== null) {
        return `${formatNumber(value)}%`;
      } else {
        return '0%';
      }
    } else {
      return '';
    }
  };

  return (
    <div style={style}>
      <Row className="battery-info-container text-center">
        <Col span="24" style={{ marginTop: '-12px' }}>
          <h4 style={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '.5rem' }}>
            {handleShowValue()}
          </h4>
          <svg
            style={{ display: 'inline-block', verticalAlign: 'middle' }}
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            width="70px"
            viewBox="0 0 508 508"
            xmlSpace="preserve"
            className="battery-indicator"
          >
            <path
              fill="#D4D4D4"
              d="M422.7,106.35H21.2c-11.7,0-21.2,9.5-21.2,21.2v252.9c0,11.7,9.5,21.2,21.2,21.2h401.5 c11.7,0,21.2-9.5,21.2-21.2v-252.9C443.9,115.85,434.4,106.35,422.7,106.35z"
            />
            <rect x="45.3" y="151.65" fill="#FFFFFF" width="353.3" height="204.8" />
            <rect className="bar" x="68" y="174.25" fill={bar1} width="58.4" height="159.5" />
            <rect className="bar" x="151.2" y="174.25" fill={bar2} width="58.4" height="159.5" />
            <rect className="bar" x="234.4" y="174.25" fill={bar3} width="58.4" height="159.5" />
            <rect className="bar" x="317.6" y="174.25" fill={bar4} width="58.4" height="159.5" />
            <path
              fill="#D4D4D4"
              d="M496.7,159.45H444v189.2h52.7c6.3,0,11.3-5.1,11.3-11.3v-166.6C508,164.55,502.9,159.45,496.7,159.45 z"
            />
          </svg>
        </Col>
      </Row>
    </div>
  );
}

OnlyBattery.propTypes = {
  style: PropTypes.object,
  type: PropTypes.oneOf(['challenge', 'agreements']),
  showValue: PropTypes.bool,
};

OnlyBattery.defaultProps = {
  style: {
    marginTop: '27px',
    minHeight: '81px',
    width: '100%',
  },
  type: 'agreements',
  showValue: true,
};

export default OnlyBattery;
