import { takeEvery, put, call, all, cancelled } from 'redux-saga/effects';
import {
  PUT_ONE_UPDATE_PRICES_REQUEST,
  PUT_ONE_STATUS_REQUEST,
  GET_UPDATE_PRICES_REQUEST,
  putOneUpdatePricesClean,
  getUpdatePricesSuccess,
  getUpdatePricesClean,
  putOneStatusClean,
} from '../actions';
import {
  putOneUpdatePrices,
  getUpdatePricesRequest,
  updateOneExchangeRateStatus,
} from '../../services/updatePrices';
import { responseOK } from 'Domains/SalesChallengeRefactor/utils';
import instance from 'services/request';
import { openNotification } from 'common';

function* workPutOneUpdatePricesRequest(action) {
  try {
    const { payload } = action;
    const { values, history } = payload;

    const { ap, list, net, brute, suggested, taxes, priceId } = values;
    const response = yield call(putOneUpdatePrices, {
      data: {
        priceId,
        ap,
        list,
        net,
        brute,
        suggested,
        taxes,
      },
    });
    if (responseOK(response)) {
      yield openNotification('success', response.message);
      setTimeout(() => {
        if (history) history.push('/catalog/prices');
      }, 300);
    } else {
      console.error('[BASF[REDUX-SAGA]##');
    }
  } catch (error) {
    yield put(putOneUpdatePricesClean());

    console.error('[BASF[REDUX-SAGA]', error);
  }
}

function* workGetUpdatePricesRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { priceId } = payload;

    const response = yield call(getUpdatePricesRequest, {
      priceId,
    });
    if (responseOK(response)) {
      yield put(getUpdatePricesSuccess(response.data));

      if (response.data === true) {
        yield openNotification('error', response.message);
      }
    } else {
      yield put(getUpdatePricesClean());
    }
  } catch (error) {
    yield put(getUpdatePricesClean());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

function* workToggleExchange(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { enabled, priceId, history, setStatusSwitch } = payload;

    const response = yield call(updateOneExchangeRateStatus, {
      enabled,
      priceId: priceId,
    });
    if (responseOK(response)) {
      openNotification('success', response.message);
      if (response.data === true) {
        setStatusSwitch({
          disabled: false,
          checked: !enabled,
        });
      }
      if (!enabled) {
        setTimeout(() => {
          if (history) history.push('/catalog/prices');
        }, 300);
      }
    } else {
      yield put(putOneStatusClean());
    }
  } catch (error) {
    yield put(putOneStatusClean());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchPutOneUpdatePricesRequest() {
  yield all([takeEvery(PUT_ONE_UPDATE_PRICES_REQUEST, workPutOneUpdatePricesRequest)]);
}
export function* watchCreateOneCaseRequest() {
  yield all([takeEvery(GET_UPDATE_PRICES_REQUEST, workGetUpdatePricesRequest)]);
}
export function* watchToggleUser() {
  yield all([takeEvery(PUT_ONE_STATUS_REQUEST, workToggleExchange)]);
}
