import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BSelect } from 'common/components';
import { filtersEnum } from 'RootModule/models/enums/filtersEnum';
import { businessLineRequest2 } from 'RootModule/redux/actions/catalogs';

function BusinessLine2(props) {
  const {
    businessLines2,
    businessLineRequest2: getBusinessLines2,
    requestParams,
    sourceId,
    name,
    label,
    askFromService,
    onOptionsChange,
    onSuccess,
    onChange,
    ...other
  } = props;
  useEffect(() => {
    if (askFromService) {
      getBusinessLines2({ requestParams, sourceId, onSuccess });
    }
    // eslint-disable-next-line
  }, [getBusinessLines2, requestParams, askFromService, sourceId]);

  useEffect(() => {
    if (onOptionsChange) {
      onOptionsChange(businessLines2.data ?? []);
    }
  }, [businessLines2, onOptionsChange]);

  const handleOnChange = (value) => {
    if (onChange) onChange(value, { options: businessLines2?.data, name: props.name });
  };

  return (
    <BSelect
      isLoading={businessLines2.isLoading}
      disabled={businessLines2.isLoading}
      name={name}
      options={businessLines2.data}
      label={label}
      getPopupContainer={(trigger) => trigger.parentNode}
      onChange={handleOnChange}
      {...other}
    />
  );
}

BusinessLine2.propTypes = {
  initialValue: PropTypes.any,
  businessLineRequest2: PropTypes.func,
  businessLines2: PropTypes.object,
  requestParams: PropTypes.object,
  sourceId: PropTypes.oneOf(['Agreements', 'Agreements-SellOut', 'Sales', 'Catalogs', 'soldTo']),
  askFromService: PropTypes.bool,
  onOptionsChange: PropTypes.func,
};

BusinessLine2.defaultProps = {
  initialValue: undefined,
  businessLineRequest2: null,
  businessLines2: { data: [], isLoading: false },
  label: filtersEnum.businessLine.label,
  name: filtersEnum.businessLine.name,
  requestParams: {},
  sourceId: 'Sales',
  askFromService: true,
  onOptionsChange: null,
};

function mapStateToProps(state) {
  return {
    businessLines2: state.APP?.catalogs?.businessLines2,
  };
}

export default connect(mapStateToProps, { businessLineRequest2 })(BusinessLine2);
