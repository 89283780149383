import request from 'services/request';

export async function addTradeAgreementSellOutValidator(params, cancelToken) {
  // TODO: Change url
  return request('/Agreements/Api/v1/SellOut/Validators/Save', {
    method: 'POST',
    cancelToken,
    data: params,
  });
}
