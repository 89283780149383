import request from '../../../services/request';
const URL_SALESCHALLENGE = '/Sales/Api/v1/Challenge/';
const SALESCHALLENGE_INDICATOR = `${URL_SALESCHALLENGE}Indicators/`;

export async function getIndicator(indicatorName, params, cancelToken) {
  return request(`${SALESCHALLENGE_INDICATOR}${indicatorName}`, {
    method: 'GET',
    params,
    cancelToken,
  });
}

export async function getClientSummary({ params, cancelToken }) {
  return request(`${SALESCHALLENGE_INDICATOR}ClientSummary`, {
    method: 'GET',
    cancelToken,
    params,
  });
}

export async function getKAMIndicators(indicatorName, params, cancelToken) {
  return request(`${SALESCHALLENGE_INDICATOR}KAM/`, {
    method: 'GET',
    cancelToken,
    params,
  });
}
