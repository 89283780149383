import React, { useEffect, useState } from 'react';
import { Row, Col, Select, Form } from 'antd';

import { getPopupContainer } from 'Domains/SalesChallengeRefactor/helpers/helperForms';
import { SubRegion, Segmentation } from 'RootModule/catalogs';
import ClientB from './Client/Client';
import { BInput } from 'common/components';

const Client = ({ getStaticFiels, form, getOneClient, getArrayPOG, getArrayPresence }) => {
  const [valuePOG, setValuePOG] = useState();
  const [valueSales, setValueSales] = useState();
  const [valuePresence, setValuePresence] = useState();
  const [valueTerritory, setValueTerritory] = useState();

  useEffect(() => {
    form.setFieldsValue({
      subregionId: parseInt(getStaticFiels?.subReg),
      segmentationId: parseInt(getStaticFiels?.seg),
      clientLineId: parseInt(getStaticFiels?.line),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getStaticFiels]);

  useEffect(() => {
    if (getArrayPOG && getStaticFiels) {
      const resultPOG = getArrayPOG.filter((pog) => pog.key === getStaticFiels?.periodPOG);
      const resultSales = getArrayPOG.filter((pog) => pog.key === getStaticFiels?.periodSales);

      setValuePOG(resultPOG[0].value);
      setValueSales(resultSales[0].value);
      setValueTerritory(getStaticFiels?.territory.join(' , '));
    }
  }, [getArrayPOG, getStaticFiels]);

  useEffect(() => {
    if (getArrayPresence && getStaticFiels) {
      const resulPresence = getArrayPresence.filter(
        (presence) => presence.key === parseInt(getStaticFiels?.presc)
      );

      setValuePresence(resulPresence[0].value);
    }
  }, [getArrayPresence, getStaticFiels]);

  return (
    <div>
      <ClientB getOneClient={getOneClient} />

      <Row>
        <Col span={6}>
          <SubRegion label="*Subregión" name="subregionId" disabled={true} />
        </Col>
        <Col span={6}>
          <Form.Item name="placementId" label="*Presencia:" style={{ height: 20 }}>
            <Select
              getPopupContainer={getPopupContainer}
              showSearch
              style={{ width: '100%' }}
              disabled={true}
              defaultValue={valuePresence}
              placeholder={valuePresence}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item name="territoryId" label="*Territorio:" style={{ height: 20 }}>
            <Select
              getPopupContainer={getPopupContainer}
              showSearch
              style={{ width: '100%' }}
              disabled={true}
              defaultValue={valueTerritory}
              placeholder={valueTerritory}
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Segmentation label="*Segmentación" name="segmentationId" disabled={true} />
        </Col>

        <Col span={6}>
          <Form.Item
            name="periodoVentasId"
            label="*Periodo de incentivo de ventas:"
            style={{ height: 20 }}
          >
            <Select
              getPopupContainer={getPopupContainer}
              showSearch
              style={{ width: '100%' }}
              placeholder={valueSales}
              disabled={true}
              defaultValue={valueSales}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="periodoPOGId"
            label="*Periodo de incentivo de POG:"
            style={{ height: 20 }}
          >
            <Select
              getPopupContainer={getPopupContainer}
              showSearch
              style={{ width: '100%' }}
              disabled={true}
              defaultValue={valuePOG}
              placeholder={valuePOG}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <BInput
            placeholder={getStaticFiels?.currency}
            disabled={true}
            defaultValue={getStaticFiels?.currency}
            maxLength={10}
            name="tradeAgreementCurrency"
            label="*Moneda Acuerdo Comercial:"
          />
        </Col>
      </Row>
    </div>
  );
};

export default Client;
