import { takeEvery, all, call, put, cancelled } from 'redux-saga/effects';

import {
  EXCHANGE_RATE_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
  EXCHANGE_RATE_MASSIVE_UPLOAD_REQUEST_VERIFY,
  EXCHANGE_RATE_MASSIVE_UPLOAD_REQUEST_UPLOAD,
  exchangeRateMassiveUploadSetAllowUpload,
  exchangeRateMassiveUploadClearAllowUpload,
  exchangeRateMassiveUploadSetVerify,
  exchangeRateMassiveUploadClearVerify,
  exchangeRateMassiveUploadClearUpload,
} from '../actions';

import instance from 'services/request';

import { responseOK } from 'Domains/SalesChallengeRefactor/utils';

import { ExchageRatesMassiveUpload } from '../../services';
import {
  fileTo64String,
  validateTypeAndSize,
  summaryModal,
  summaryEndModal,
} from 'common/MassiveUploads/utils';
import { messageError } from 'utils/messages';

function* workExchangeRateMassiveUploadSetAllowUpload(action) {
  try {
    yield exchangeRateMassiveUploadSetAllowUpload(action.payload);
  } catch (error) {
    yield exchangeRateMassiveUploadClearAllowUpload();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield exchangeRateMassiveUploadClearAllowUpload();
    }
  }
}

function* workExchangeRateMassiveUploadRequestVerify({ payload }) {
  const { file, setFileString } = payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const isValidFile = validateTypeAndSize(file);
    if (!isValidFile) {
      messageError(
        'El formato del archivo de carga masiva de Tipos de Cambio debe ser .xlsx y tener un tamaño máximo de 20 MB.'
      );

      return;
    }

    const file64Str = yield call(fileTo64String, file);
    yield call(setFileString, file64Str);
    const response = yield call(ExchageRatesMassiveUpload, {
      cancelToken: source.token,
      data: {
        content: file64Str,
        overwrite: false,
        save: false,
      },
    });
    if (responseOK(response)) {
      let { data, message, allCorrectMessage } = response;
      let allCorrect = data.correct > 0 && data.totalErrors === 0;

      if (allCorrect) {
        data.allCorrectMessage = allCorrectMessage;
      }

      yield put(exchangeRateMassiveUploadSetVerify(data));
      yield call(summaryModal, message);
    } else {
      yield put(exchangeRateMassiveUploadClearVerify());
    }
  } catch (error) {
    yield exchangeRateMassiveUploadClearVerify();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield exchangeRateMassiveUploadClearVerify();
      source.cancel('cancelled Request');
    }
  }
}

function* workExchangeRateMassiveUploadRequestUpload({ payload }) {
  const { history, fileString, overwrite } = payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(ExchageRatesMassiveUpload, {
      cancelToken: source.token,
      data: {
        content: fileString,
        save: true,
        overwrite,
      },
    });
    if (responseOK(response)) {
      yield call(summaryEndModal, response.data, history, '/catalog/exchange-rate');
    } else {
      yield put(exchangeRateMassiveUploadClearUpload());
    }
  } catch (error) {
    yield exchangeRateMassiveUploadClearUpload();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield exchangeRateMassiveUploadClearUpload();
      source.cancel('cancelled Request');
    }
  }
}

export function* watchExchangeRateMassiveUploadSetAllowUpload() {
  yield all([
    takeEvery(
      EXCHANGE_RATE_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
      workExchangeRateMassiveUploadSetAllowUpload
    ),
  ]);
}

export function* watchExchangeRateMassiveUploadRequestVerify() {
  yield all([
    takeEvery(
      EXCHANGE_RATE_MASSIVE_UPLOAD_REQUEST_VERIFY,
      workExchangeRateMassiveUploadRequestVerify
    ),
  ]);
}

export function* watchExchangeRateMassiveUploadRequestUpload() {
  yield all([
    takeEvery(
      EXCHANGE_RATE_MASSIVE_UPLOAD_REQUEST_UPLOAD,
      workExchangeRateMassiveUploadRequestUpload
    ),
  ]);
}
