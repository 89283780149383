import { takeLatest, put, call, cancelled, select } from 'redux-saga/effects';
import {
  REGIONS_REQUEST,
  regionSuccess,
  regionFail,
  SEGMENTS_REQUEST,
  segmentsSuccess,
  segmentsFail,
  BUSINESSLINE_REQUEST,
  businessLineSuccess,
  businessLineFail,
  SUBREGIONS_REQUEST,
  subregionsSuccess,
  subregionsFail,
  TERRITORY_REQUEST,
  territoryFail,
  territorySuccess,
  ZONES_REQUEST,
  zonesSuccess,
  zonesFail,
  CLIENTS_REQUEST,
  clientsSuccess,
  clientsFail,
  PRODUCTS_REQUEST,
  productsSuccess,
  productsFail,
  FAMILY_REQUEST,
  familySuccess,
  familyFail,
  BUSINESSRULES_REQUEST,
  businessRulesSuccess,
  businessRulesFail,
  YEARS_REQUEST,
  yearsSuccess,
  yearsFail,
} from '../actions/filters';
import { CLIENT_SELECTED, saveClient, TERRITORY_SELECTED } from '../actions/infoForAgreement';
import { getFromStore } from './selectors';
import instance from '../../../../services/request';
import {
  getSalesChallengeCatalogByName,
  getBusinessStructureLines,
  getBusinessStructureSubregions,
  getFixedCatalogsSegmentation,
  getAgreementCatalogByCatalogName,
  getSalesChallengeCatalogLines,
  getAgreementByName,
} from '../../services/catalogs';
import { responseOK } from '../../utils';

function* workRegionsRequest(_action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getSalesChallengeCatalogByName, {
      cancelToken: source.token,
      name: 'Regions',
    });

    if (responseOK(response)) {
      yield put(regionSuccess(response.data));
    } else {
      yield put(regionFail());
    }
  } catch (e) {
    yield put(regionFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

function* workSegmentsRequest(_action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getFixedCatalogsSegmentation, source.token);
    if (responseOK(response)) {
      yield put(segmentsSuccess(response.data));
    } else {
      yield put(segmentsFail());
    }
  } catch (e) {
    yield put(regionFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request ');
    }
  }
}

function* workSubregionsRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const { sourceId, requestParams } = payload;
  let service;
  let name;
  switch (sourceId) {
    case 'Catalogs':
      service = getBusinessStructureSubregions;
      break;

    case 'Sales':
      name = 'Subregions';
      service = getSalesChallengeCatalogByName;
      break;

    default:
      service = getBusinessStructureSubregions;
      break;
  }
  try {
    const response = yield call(service, {
      cancelToken: source.token,
      name: name,
      params: requestParams,
    });
    if (responseOK(response)) {
      yield put(subregionsSuccess(response.data));
    } else {
      yield put(subregionsFail());
    }
  } catch (e) {
    yield put(subregionsFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request ');
    }
  }
}

function* workBusinesslineRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const { sourceId, requestParams } = payload;
  let service;
  switch (sourceId) {
    case 'Catalogs':
      service = getBusinessStructureLines;
      break;

    case 'Sales':
      service = getSalesChallengeCatalogLines;
      break;

    default:
      service = getBusinessStructureLines;
      break;
  }

  try {
    const response = yield call(service, { cancelToken: source.token, params: requestParams });
    if (responseOK(response)) {
      yield put(businessLineSuccess(response.data));
    } else {
      yield put(businessLineFail());
    }
  } catch (error) {
    console.error(error);
    yield put(businessLineFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request ');
    }
  }
}

function* workTerritoryRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const { requestParams } = payload;
  try {
    const response = yield call(getSalesChallengeCatalogByName, {
      cancelToken: source.token,
      name: 'Territories',
      params: requestParams,
    });
    if (responseOK(response)) {
      yield put(territorySuccess(response.data));
    } else {
      yield put(territoryFail());
    }
  } catch (error) {
    console.error(error);
    yield put(territoryFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request ');
    }
  }
}

function* workZonesRequest() {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getBusinessStructureLines, { cancelToken: source.token });
    if (responseOK(response)) {
      yield put(zonesSuccess(response.data));
    } else {
      yield put(zonesFail());
    }
  } catch (error) {
    console.error(error);
    yield put(zonesFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request ');
    }
  }
}

function* workClientsRequest(action) {
  const { payload } = action;
  const { requestParams } = payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getAgreementCatalogByCatalogName, {
      cancelToken: source.token,
      name: 'Clients',
      params: requestParams,
    });

    if (responseOK(response)) {
      yield put(clientsSuccess(response.data));
    } else {
      yield put(clientsFail());
    }
  } catch (error) {
    console.error(error);
    yield put(clientsFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request ');
    }
  }
}

function* workProductsRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const { requestParams } = payload;
  try {
    const response = yield call(getAgreementCatalogByCatalogName, {
      cancelToken: source.token,
      name: 'Products',
      params: requestParams,
    });
    if (responseOK(response)) {
      yield put(productsSuccess(response.data));
    } else {
      yield put(productsFail());
    }
  } catch (error) {
    console.error(error);
    yield put(productsFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request ');
    }
  }
}

function* workFamilyRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const { requestParams } = payload;
  try {
    const response = yield call(getAgreementCatalogByCatalogName, {
      cancelToken: source.token,
      name: 'Families',
      params: requestParams,
    });
    if (responseOK(response)) {
      yield put(familySuccess(response.data));
    } else {
      yield put(familyFail());
    }
  } catch (error) {
    console.error(error);
    yield put(familyFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request ');
    }
  }
}

function* workBusinessrulesRequest() {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getBusinessStructureLines, { cancelToken: source.token });
    if (responseOK(response)) {
      yield put(businessRulesSuccess(response.data));
    } else {
      yield put(businessRulesFail());
    }
  } catch (error) {
    console.error(error);
    yield put(businessRulesFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request ');
    }
  }
}

function* workYearsRequest() {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getAgreementByName, {
      cancelToken: source.token,
      name: 'Years',
    });
    if (responseOK(response)) {
      yield put(yearsSuccess(response.data));
    } else {
      yield put(yearsFail());
    }
  } catch (error) {
    console.error(error);
    yield put(yearsFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request ');
    }
  }
}

function* workerClientSelected(action) {
  const currentClients = yield select((state) => getFromStore(state, 'APP', 'catalogs', 'clients'));

  try {
    const { payload } = action;
    const { clientId } = payload;
    if (currentClients) {
      const getClientInfo = currentClients.data.find((client) => client.key === clientId);
      if (getClientInfo && Object.keys(getClientInfo).length > 0) {
        yield put(saveClient(getClientInfo));
      } else {
        console.warn('Por algún motivo extraño no encontramos el cliente');
      }
    } else {
      console.warn("'No viene de la consulta de desafío");
    }
  } catch (error) {
    console.error(error);
  }
}

function* workerTerritorySelected(action) {
  const currentTerritory = yield select((state) =>
    getFromStore(state, 'TRADE_AGREEMENTS', 'filters', 'territory')
  );

  try {
    const { payload } = action;
    const { territoryId } = payload;
    if (currentTerritory) {
      if (currentTerritory?.data) {
        const getTerritoryInfo = currentTerritory?.data.find(
          (territory) => territory.key === territoryId
        );

        if (getTerritoryInfo && Object.keys(getTerritoryInfo).length > 0) {
          yield put(saveClient(getTerritoryInfo));
        } else {
          console.warn('Por algún motivo extraño no encontramos el territorio');
        }
      } else {
        console.warn('No viene de la consulta de desafío');
      }
    } else {
      console.warn('No viene de la consulta de desafío');
    }
  } catch (error) {
    console.error(error);
  }
}

export function* watchFamilyRequest() {
  yield takeLatest(FAMILY_REQUEST, workFamilyRequest);
}

export function* watchProductsRequest() {
  yield takeLatest(PRODUCTS_REQUEST, workProductsRequest);
}

export function* watchClientsRequest() {
  yield takeLatest(CLIENTS_REQUEST, workClientsRequest);
}

export function* watchZonesRequest() {
  yield takeLatest(ZONES_REQUEST, workZonesRequest);
}

export function* watchTerritoryRequest() {
  yield takeLatest(TERRITORY_REQUEST, workTerritoryRequest);
}

export function* watchBusinessrulesRequest() {
  yield takeLatest(BUSINESSRULES_REQUEST, workBusinessrulesRequest);
}

export function* watchRegionRequest() {
  yield takeLatest(REGIONS_REQUEST, workRegionsRequest);
}

export function* watchSegmentsRequest() {
  yield takeLatest(SEGMENTS_REQUEST, workSegmentsRequest);
}

export function* watchSubregionsRequest() {
  yield takeLatest(SUBREGIONS_REQUEST, workSubregionsRequest);
}

export function* businesslineRequest() {
  yield takeLatest(BUSINESSLINE_REQUEST, workBusinesslineRequest);
}

export function* watchYearsRequest() {
  yield takeLatest(YEARS_REQUEST, workYearsRequest);
}

export function* watchClientSelected() {
  yield takeLatest(CLIENT_SELECTED, workerClientSelected);
}

export function* watchTerritorySelected() {
  yield takeLatest(TERRITORY_SELECTED, workerTerritorySelected);
}
