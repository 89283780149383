import {
  TRADE_AGREEMENTS_SELLOUT_VALIDATORS_CATALOG_REQUEST,
  TRADE_AGREEMENTS_SELLOUT_VALIDATORS_CATALOG_SUCCESS,
  TRADE_AGREEMENTS_SELLOUT_VALIDATORS_CATALOG_FAIL,
} from '../actions/tradeAgreementsSellOutValidators';

let initialState = {};
const tradeAgreementsSellOutValidatorsCatalog = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case TRADE_AGREEMENTS_SELLOUT_VALIDATORS_CATALOG_REQUEST:
      return {
        ...state,
        tradeAgreementsSellOutValidators: {
          isLoading: true,
          data: [],
        },
      };

    case TRADE_AGREEMENTS_SELLOUT_VALIDATORS_CATALOG_SUCCESS:
      return {
        ...state,
        tradeAgreementsSellOutValidators: {
          isLoading: false,
          data: payload,
        },
      };

    case TRADE_AGREEMENTS_SELLOUT_VALIDATORS_CATALOG_FAIL:
      return {
        ...state,
        tradeAgreementsSellOutValidators: {
          isLoading: false,
          data: [],
        },
      };
    default:
      return state;
  }
};

export default tradeAgreementsSellOutValidatorsCatalog;
