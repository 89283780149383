import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import BSelect from 'common/components/BSelect';
import { connect } from 'react-redux';
import { countriesRequest } from 'RootModule/redux/actions/catalogs';
import { filtersEnum } from 'RootModule/models/enums/filtersEnum';

function Country(props) {
  const {
    country,
    countriesRequest: getCountry,
    askFromService,
    requestParams,
    sourceId,
    onSuccess,
    mode,
    onChange,
    getCurrentOptions,
    selectAll,
    ...other
  } = props;

  useEffect(() => {
    if (askFromService) getCountry({ requestParams, sourceId, onSuccess, mode });
  }, [getCountry, askFromService, requestParams, sourceId, onSuccess, mode]);

  const handleOnChange = (value) => {
    if (onChange) onChange(value, { options: country?.data, name: props.name });
  };

  useEffect(() => {
    if (country.data && getCurrentOptions && !country.isLoading) {
      setTimeout(() => {
        getCurrentOptions(country.data);
      }, 500);
    }
  }, [country.data, getCurrentOptions, country.isLoading]);

  return (
    <BSelect
      disabled={country.isLoading}
      isLoading={country.isLoading}
      selectAll={selectAll}
      options={country.data}
      getPopupContainer={(trigger) => trigger.parentNode}
      mode={mode}
      onChange={handleOnChange}
      {...other}
    />
  );
}

Country.propTypes = {
  askFromService: PropTypes.bool,
  initialValue: PropTypes.any,
  label: PropTypes.string,
  name: PropTypes.string,
  requestParams: PropTypes.object,
  sourceId: PropTypes.oneOf(['Agreements', 'Agreements-SellOut', 'Sales']),
  country: PropTypes.object,
  countriesRequest: PropTypes.func,
};

Country.defaultProps = {
  askFromService: true,
  initialValue: undefined,
  label: filtersEnum.country.label,
  name: filtersEnum.country.name,
  requestParams: {},
  sourceId: 'Sales',
  country: { data: [], isLoading: false },
  countriesRequest: null,
  selectAll: false,
};

function mapStateToProps(state) {
  return {
    country: state.APP?.catalogs?.countries,
  };
}

export default connect(mapStateToProps, { countriesRequest })(Country);
