/* eslint-disable react/no-unescaped-entities */
import React, { useContext, useEffect } from 'react';
import Image from 'common/home-background.jpg';
import { appContext, layoutContext } from 'context';
import Styles from './dashboard.module.css';

function Dashboard() {
  const { userInfo } = useContext(appContext);
  const { onChangeActiveComponent } = useContext(layoutContext);

  /* Se vacia el componente activo para el contexto de layout */
  useEffect(() => {
    onChangeActiveComponent(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={Styles[`container-title-dashboard`]}>
        <h1>Bienvenido "{userInfo.name}"</h1>
      </div>
      <div className={Styles[`img-wrapper`]}>
        <img src={Image} alt="" />
      </div>
    </>
  );
}
export default Dashboard;
