import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { validatorsListTradeAgreementRequest } from '../redux-saga/actions/validatorList';
import { tradeAgreementsValidatorsCatalogRequest } from 'RootModule/redux/actions/tradeAgreementsValidators';
import { daysLeftRequest } from '../redux-saga/actions/daysLeft';
import { addTradeAgreementValidatorRequest } from '../redux-saga/actions/addTradeAgreementValidator';
import { deleteTradeAgreementValidatorRequest } from '../redux-saga/actions/deleteTradeAgreementValidator';
import { ValidatorsListView } from './ValidatorsListView';
import { ValidatorsScopeWrapper } from './ValidatorSScopeWrapper';
import { DeleteValidatorModal } from './DeleteValidatorModal';
import { useValidatorsReduceList } from '../hooks/useValidatorsReduceList';
import { useValidatorsParams } from '../hooks/useValidatorsParams';

export const SellInList = () => {
  const dispatch = useDispatch();
  const territoryId = useSelector((state) => state?.VALIDATIONS?.filters?.query?.territoryId ?? []);
  const clientId = useSelector((state) => state?.VALIDATIONS?.filters?.query?.clientId ?? []);
  const daysLeft = useSelector((state) => state?.VALIDATIONS?.daysLeft?.total ?? 0);
  const agreementValidatorListLoading = useSelector(
    (state) => state?.VALIDATIONS?.addTradeAgreementValidator?.loading || false
  );
  const list = useSelector(
    (state) => state?.VALIDATIONS?.validatorList?.tradeAgreement?.list || []
  );
  const options = useSelector(
    (state) => state?.APP?.tradeAgreementsValidatorsCatalog?.tradeAgreementsValidators?.data ?? []
  );

  const [selectedUser, setSelectedUser] = useState('');
  const [selectedOption, setSelectedOption] = useState([]);
  const [validatorsToDisable, setValidatorsToDisable] = useState([]);
  const [deletionModal, setDeletionModal] = useState({ visible: false, user: {} });
  const [dropdownLength, setDropdownLength] = useState(0);
  const selected = useRef('');
  const { getAddTradeAgreementsParams, getDeleteTradeAgreementsParams } = useValidatorsParams();
  const reducedList = useValidatorsReduceList(list);

  const canModifyTradeAgreement = typeof territoryId === 'number' && clientId.length > 0;
  const canModify = canModifyTradeAgreement && !agreementValidatorListLoading && daysLeft > 0;
  const modalOptions = deletionModal.user?.clients;
  const moduleTitle = 'Acuerdo Comercial Sell In';

  const callTradeAgreementList = () => {
    const params = { Territories: [territoryId], clients: clientId };
    dispatch(validatorsListTradeAgreementRequest({ params }));
  };

  useEffect(() => {
    const canMod = !Array.isArray(territoryId) && clientId.length > 0;
    if (canMod) callTradeAgreementList();
    if (typeof territoryId === 'number') {
      let params = {
        Clients: clientId,
        Territories: [territoryId],
      };
      dispatch(tradeAgreementsValidatorsCatalogRequest({ params }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [territoryId, clientId]);

  useEffect(() => {
    dispatch(daysLeftRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddTradeAgreementValidator = (id) => {
    const params = getAddTradeAgreementsParams({ id, clientId, territoryId });
    dispatch(addTradeAgreementValidatorRequest({ params }));
  };
  const handleDeleteTradeAgreementValidator = () => {
    const params = getDeleteTradeAgreementsParams({
      validatorsToDisable,
      selectedUser,
      territoryId,
    });
    dispatch(deleteTradeAgreementValidatorRequest({ params }));
  };

  const handleSelectedValidatorToDisable = (item) => {
    const { clients } = item;
    setValidatorsToDisable(clients.map((client) => client.clientId));
  };

  const validatorScopeWrapper = (item) => <ValidatorsScopeWrapper data={item.clients} />;

  const handleSelect = (value) => {
    selected.current = value.map((item) => JSON.parse(item).userId);
    setDropdownLength(value.length);
    setSelectedOption(value);
  };

  const hasAprovedSalesChallenges = (item) => {
    return item.territories.some(({ hasApprovedChallenge }) => hasApprovedChallenge === false);
  };

  const delValidatorModal = () => (
    <DeleteValidatorModal
      deletionModal={deletionModal}
      validatorsToDisable={validatorsToDisable}
      moduleTitle={moduleTitle}
      onCancel={() => setDeletionModal({ visible: false, user: {} })}
      onOk={() => {
        handleDeleteTradeAgreementValidator();
        setDeletionModal({ visible: false, user: {} });
      }}
      onChange={(value) => setValidatorsToDisable(value)}
      modalOptions={modalOptions?.map((item) => ({ id: item.clientId, name: item.name }))}
    />
  );

  return (
    <ValidatorsListView
      moduleTitle={moduleTitle}
      canModify={canModify}
      handleSelect={handleSelect}
      selectedOption={selectedOption}
      options={options}
      dropdownLength={dropdownLength}
      handleSelectedValidatorToDisable={handleSelectedValidatorToDisable}
      handleAddValidator={handleAddTradeAgreementValidator}
      validatorScopeWrapper={validatorScopeWrapper}
      selected={selected}
      setSelectedOption={setSelectedOption}
      reducedList={reducedList}
      hasAproved={hasAprovedSalesChallenges}
      setSelectedUser={setSelectedUser}
      setDeletionModal={setDeletionModal}
      delValidatorModal={delValidatorModal}
    />
  );
};
