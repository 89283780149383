import React, { useCallback, useState } from 'react';
import { Button, Modal, Spin } from 'antd';
import { connect } from 'react-redux';
import { getMassiveUploadRequest, reportRequest } from '../../redux/actions';
import { openNotification } from 'common';
import { LoadingOutlined } from '@ant-design/icons';
import DownloadButton from '../common/DownloadButton';
import { setReportParams } from '../../utils/setReportParams';
import { setMasiveUploadParams } from '../../utils/setMasiveUploadParams';

const CustomSpin = () => (
  <Spin
    indicator={
      <LoadingOutlined
        className="custom-spin"
        type="message"
        style={{
          fontSize: '16px',
          marginRight: '5px',
          display: 'inline-block',
        }}
        theme="outlined"
      />
    }
  />
);

const DownloadReport = (props) => {
  const {
    getMassiveUploadRequest: generateMassiveUpload,
    reportRequest: generateReport,
    current,
    reportIsDownloading,
    reportMassiveUploadIsLoading,
    query,
  } = props;

  const [isVisibleModal, setIsVisibleModal] = useState(false);
  // Fn to generate report
  const handleGenerateReport = useCallback(() => {
    const { params, reportName } = setReportParams(query, current, '');
    generateReport({
      sourceId: reportName,
      params,
      onSuccess: () => {
        openNotification('success', 'El reporte de acuerdo comercial se generó exitosamente');
        setIsVisibleModal(false);
      },
    });
  }, [query, current, generateReport]);
  // Fn to generate massive upload
  const handleGenerateMassiveUpload = useCallback(() => {
    const { massiveUploadName, params } = setMasiveUploadParams(query, current);

    generateMassiveUpload({
      sourceId: massiveUploadName,
      params,
      onSuccess: () => {
        openNotification('success', 'El reporte de acuerdo comercial se generó exitosamente');
        setIsVisibleModal(false);
      },
    });
  }, [query, current, generateMassiveUpload]);

  if (query && query.year && Object.keys(query).length > 1) {
    return (
      <>
        <Button
          onClick={() => {
            setIsVisibleModal(true);
          }}
          style={{ background: '#00499A', color: 'white' }}
        >
          Exportar
        </Button>

        <Modal
          visible={isVisibleModal}
          className={'download__modal'}
          title={null}
          closable={!reportIsDownloading && !reportMassiveUploadIsLoading}
          onCancel={() => {
            setIsVisibleModal(false);
          }}
          footer={null}
        >
          <h4> Descargar Acuerdo comercial </h4>
          <p>¿En qué formato desea descargar la información del acuerdo comercial?</p>

          <footer className="modal__footer">
            <DownloadButton
              onClick={handleGenerateReport}
              className={'report-btn'}
              size="large"
              label={<>{reportIsDownloading && <CustomSpin />} Reporte</>}
              loading={reportIsDownloading}
            />

            <DownloadButton
              onClick={handleGenerateMassiveUpload}
              size="large"
              label={<> {reportMassiveUploadIsLoading && <CustomSpin />} Carga masiva</>}
              loading={reportMassiveUploadIsLoading}
            />
          </footer>
        </Modal>
      </>
    );
  } else {
    return null;
  }
};

function mapStateToProps(state) {
  return {
    current: state?.TRADE_AGREEMENTS_SELLOUT?.views?.current,
    reportIsDownloading: state?.TRADE_AGREEMENTS_SELLOUT?.report?.isDownloading ?? false,
    reportMassiveUploadIsLoading:
      state?.TRADE_AGREEMENTS_SELLOUT?.generateReport?.getReport?.isLoading ?? false,
    query: state?.TRADE_AGREEMENTS_SELLOUT?.query,
  };
}

export default connect(mapStateToProps, {
  reportRequest,
  getMassiveUploadRequest,
})(DownloadReport);
