import { takeLatest, all, call, put, cancelled } from 'redux-saga/effects';
import { responseOK } from 'Domains/SalesChallengeRefactor/utils';
import { DrawerAddServices } from '../../../services';
import { DrawerAddActions } from '../../actions';
import instance from 'services/request';
import { messageError } from 'utils/messages';

//Actions
const { POST_IMAGE_LINE_REQUEST, POSTImageLineClean, POSTImageLineSet } = DrawerAddActions;
//Services
const { POSTImageLine } = DrawerAddServices;

// POST IMAGE LINE
function* workPOSTImageLine(action) {
  const { imageInfo, dataEndpoint, okPost } = action.payload;
  const { CancelToken } = instance;

  const source = CancelToken.source();
  try {
    const response = yield call(POSTImageLine, {
      cancelToken: source.token,
      data: imageInfo,
      endpoint: dataEndpoint.endpoint,
    });
    if (responseOK(response)) {
      yield put(POSTImageLineSet(response.data));
      okPost();
    } else {
      yield call(messageError, response.message);

      yield put(POSTImageLineClean());
    }
  } catch (error) {
    yield put(POSTImageLineClean());
    console.error('SAGAS ERROR', error);
  } finally {
    if (yield cancelled()) {
      yield put(POSTImageLineClean());
      source.cancel('cancelled Request');
    }
  }
}

export function* watchPOSTImageLine() {
  yield all([takeLatest(POST_IMAGE_LINE_REQUEST, workPOSTImageLine)]);
}
