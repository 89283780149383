import request from '../../../services/request';
const URL_API = '/Sales/Api/v1';
const AGREEMENTS_API = '/Agreements/Api/v1/SellIn/';
const URL_CATALOGS = '/Catalogs/Api/v1';
const AGREEMENTS_CATALOGS = `${AGREEMENTS_API}Catalogs/`;
const AGREEMENTS_OF_PRODUCTS = `${AGREEMENTS_API}`;

export async function getSalesChallengeCatalogByName({ cancelToken, name, params }) {
  return request(`${URL_API}/Challenge/Catalogs/${name}`, {
    method: 'GET',
    cancelToken,
    params,
  });
}

export async function getSalesChallengeCatalogLines({ cancelToken, params }) {
  return request(`${URL_API}/Challenge/Catalogs/BusinessLines`, {
    method: 'GET',
    cancelToken,
    params,
  });
}

//Controlador BusinessStructure
export async function getBusinessStructureLines({ cancelToken }) {
  return request(`${URL_CATALOGS}/BusinessStructure/Lines`, {
    method: 'GET',
    cancelToken,
  });
}

export async function getBusinessStructureSubregions({ cancelToken, params }) {
  return request(`${URL_CATALOGS}/BusinessStructure/Subregions`, {
    method: 'GET',
    cancelToken,
    params: params ?? {},
  });
}

//Controlador FixedCatalogs
export async function getFixedCatalogsSegmentation({ cancelToken }) {
  return request(`${URL_CATALOGS}/FixedCatalogs/Segmentation`, {
    method: 'GET',
    cancelToken,
  });
}
export async function getFixedCatalogsBusienssLines({ cancelToken, params }) {
  return request(`${URL_CATALOGS}/FixedCatalogs/BusinessLines`, {
    method: 'GET',
    cancelToken,
    params,
  });
}

//Controlador Aggreements
export async function getAgreementCatalogByCatalogName({ cancelToken, name, params }) {
  return request(`${AGREEMENTS_CATALOGS}${name}`, {
    method: 'GET',
    cancelToken,
    params,
  });
}
export async function getAgreementProductById({ cancelToken, params }) {
  return request(`${AGREEMENTS_OF_PRODUCTS}`, {
    method: 'GET',
    cancelToken,
    params,
  });
}

//MicroServicio Agreements Controlador Agreements

export async function getAgreementByName({ cancelToken, name }) {
  return request(`${AGREEMENTS_API}${name}`, {
    method: 'GET',
    cancelToken,
  });
}

export async function getDaysLeft({ params, cancelToken }) {
  return request(`${URL_CATALOGS}/ProcessDates/DaysLeft/`, {
    method: 'GET',
    params,
    cancelToken,
  });
}

export async function getProductsToAdd({ params, cancelToken }) {
  return request(`${AGREEMENTS_API}Products/`, {
    method: 'GET',
    params,
    cancelToken,
  });
}
