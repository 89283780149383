import { formatToPrice } from '../../../utils';

export const quartersInfo = [
  {
    title: 'Q1',
    dataIndex: 'q1',
    key: 'q1',
    render: (text) => formatToPrice(text),
    align: 'center',
  },
  {
    title: 'Q2',
    dataIndex: 'q2',
    key: 'q2',
    render: (text) => formatToPrice(text),
    align: 'center',
  },
  {
    title: 'Q3',
    dataIndex: 'q3',
    key: 'q3',
    render: (text) => formatToPrice(text),
    align: 'center',
  },
  {
    title: 'Q4',
    dataIndex: 'q4',
    key: 'q4',
    render: (text) => formatToPrice(text),
    align: 'center',
  },
];
