import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { Col, Form, Row } from 'antd';
import { FaUpload } from 'react-icons/fa';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { processStepIdEnum, ResetButton } from 'common';
import ReviewActionsContainer from './ReviewActionsContainer';
import ActionsGroup from './ActionsGroup/ActionsGroup';
import RejectTradeAgreement from './RejectTradeAgreement';
import SecondLevelValidation from './SecondLevelValidation';

import usePersistData from 'hooks/usePersistData';
import useWasRendered from 'hooks/useWasRendered';
import useGetFilters from 'hooks/useGetFilters';

import { setSubscriptionValue4Register } from 'Domains/SalesChallengeRefactor/redux/actions/subscriptions';

import {
  cleanInfo,
  cleanTopFilters,
  clientSelected,
  getDaysLeftRequest,
  queryClean,
  querySave,
  setCurrentView,
  setPersistentLine,
  setPersistentTerritory,
  territorySelected,
  validProductsForDeletingSuccess,
} from '../redux/actions';
import { ErrorBoundary } from 'Domains/TradeAgreements/common';
import useFiltersGroup from '../hooks/useFiltersGroup';

const layoutFilters = {
  xs: 24,
  sm: 12,
  md: 6,
  lg: 4,
  xlg: 5,
};

const {
  GENERATE_TRADE_AGREEMENT,
  VALIDITY_PROCESS,
  UPLOAD_TRADE_AGREEMENT,
  TRADE_AGREEMENT_ADJUST,
} = processStepIdEnum;

const TopTradeAgreementsFiltersGroup = (props) => {
  const {
    queryClean: cleanQuery,
    querySave: saveQuery,
    cleanTopFilters: cleanFilters,
    setCurrentView: setView,
    onChangeActiveComponent,
    cleanInfo: infoClean,
    clientSelected: setClientSelected,
    territorySelected: setTerritorySelected,
    getDaysLeftRequest: getDaysLeft,
    setSubscriptionValue4Register: setSubRegister,
    setPersistentTerritory: updatePersistentTerritory,
    setPersistentLine: updatePersistentLine,
    mustResetContent,
    validProductsForDeleting,
    validProductsForDeletingSuccess: setValidProductsForDeleting,
  } = props;

  const [form] = Form.useForm();
  const [queryLocal] = usePersistData('query');
  const {
    initialAllowRequest,
    initialDisabledFilters,
    initialRequestParamsSubscription,
    orchestrateFiltersChange,
    orchestrateFiltersReset,
  } = useFiltersGroup();
  const { wasRendered, setWasRendered } = useWasRendered();
  const [disabledFilters, setDisabledFilters] = useState({});
  const [visibleMassiveUpload, setVisibleMassiveUpload] = useState(true);
  const [allowRequest, setAllowRequests] = useState();
  const [requestParamsSubscription, setRequestParamsSubscription] = useState({});

  /**sí la HU lo demanda quizás sea buena idea subirlo un nivel */
  const getFilters = useGetFilters();
  const loadComponent = (componentName) => {
    return React.lazy(() => import(`RootModule/catalogs/${componentName}`));
  };

  const cleanAllFilters = useCallback(() => {
    cleanFilters();
  }, [cleanFilters]);

  useEffect(() => {
    if (initialAllowRequest && initialDisabledFilters && initialRequestParamsSubscription) {
      setDisabledFilters(initialDisabledFilters);
      setAllowRequests(initialAllowRequest);
      setRequestParamsSubscription(initialRequestParamsSubscription);
      setWasRendered(true);
    }
  }, [
    initialAllowRequest,
    initialDisabledFilters,
    initialRequestParamsSubscription,
    setWasRendered,
    wasRendered,
  ]);

  useEffect(() => {
    if (!wasRendered.current) {
      if (queryLocal && Object.keys(queryLocal).length > 1) {
        //
      } else {
        saveQuery({ state: 'idle', showBruteValue: true });
      }

      cleanAllFilters();
      setWasRendered();
      getDaysLeft({ params: { processId: UPLOAD_TRADE_AGREEMENT } }); //6 días restantes
      getDaysLeft({ params: { processId: VALIDITY_PROCESS } }); //fecha de proceso
      getDaysLeft({ params: { processId: GENERATE_TRADE_AGREEMENT } });
      getDaysLeft({ params: { processId: TRADE_AGREEMENT_ADJUST } });
    }
  }, [saveQuery, wasRendered, setWasRendered, cleanAllFilters, getDaysLeft, queryLocal]);

  const handleOnFormValuesChange = useCallback(
    ({ changedValues }) => {
      const currentChange = Object.keys(changedValues)[0];
      onChangeActiveComponent('trade-agreements-sellout');
      /*  
     Get to know what fields of this fileds are rendered as it may vary from user to user
    1. year 
    2. regionId
    3. lineId
    4. subRegionId 
    5. territoryId
    6. clientId
    7. businessRule 
    8. family
    9.productId
    **/

      //TODO: Check that we dont try to update fields that ate not render
      //const renderedFields = Object.keys(form.getFieldsValue());
      const formValues = form.getFieldsValue();
      if (currentChange === 'territoryId') {
        //save territoryId to redux so you can make the call on screens that don't have filter

        updatePersistentTerritory(changedValues.territoryId);
      }
      if (currentChange === 'lineId') {
        //save lineId to redux so you can make the call on screens that don't have filter

        updatePersistentLine(changedValues.lineId);
      }
      orchestrateFiltersChange({
        changedValues,
        currentChange,
        form,
        formValues,
        requestParamsSubscription,
        setAllowRequests,
        setClientSelected,
        setDisabledFilters,
        setRequestParamsSubscription,
        setTerritorySelected,
      });
      if (currentChange === 'businessRule') {
        setView('clientId');
      } else {
        setView(currentChange);
      }

      if (formValues && Object.keys(formValues).length !== 0) {
        saveQuery({ ...formValues, state: 'changed' });
      } else if (queryLocal && Object.keys(queryLocal).length > 1) {
        saveQuery({ ...queryLocal, state: 'changed' });
      }
      if (formValues.year < new Date().getFullYear()) {
        setVisibleMassiveUpload(false);
      } else {
        setVisibleMassiveUpload(true);
      }
    },

    [
      form,
      onChangeActiveComponent,
      orchestrateFiltersChange,
      queryLocal,
      requestParamsSubscription,
      saveQuery,
      setClientSelected,
      setTerritorySelected,
      setView,
      updatePersistentLine,
      updatePersistentTerritory,
    ]
  );

  const setAutomaticallyFirstOption = useCallback(
    (options, filterName = 'none') => {
      if (options?.length > 0) {
        form.setFieldsValue({
          [filterName]: options[0]?.key,
        });
      }
    },
    [form]
  );

  // TODO: Change key prop to value once SegmentBusinessRules is implemented
  const setAutomaticallyAnOption = useCallback(
    (options, optionKey = 1, filterName = 'none') => {
      if (options?.length > 0) {
        const optionIndex = options.findIndex((option) => {
          return option?.value === optionKey;
        });
        form.setFieldsValue({
          [filterName]: options[optionIndex]?.value ?? undefined,
        });
        const allValues = form.getFieldsValue();
        const changedValues = { [filterName]: options[optionIndex]?.value };

        handleOnFormValuesChange({ changedValues, allValues });
      }
    },
    [form, handleOnFormValuesChange]
  );

  const generateFilters = useCallback(() => {
    const handleOnYearChange = (_value, option) => {
      setSubRegister({ year: option.key });
    };

    let specificFilterProps = {};
    specificFilterProps = {
      year: {
        onChange: handleOnYearChange,
        onSuccess: (options) => setAutomaticallyFirstOption(options, 'year'),
      },

      businessRule: {
        onSuccess: (options) => setAutomaticallyAnOption(options, '3', 'businessRule'),
      },
    };

    if (getFilters && getFilters.length > 0) {
      return getFilters.map((filter) => {
        let filterCompName = filter.component;
        if (filter.component === 'BusinessRules') {
          filterCompName = 'SegmentBusinessRules';
        }
        let FilterComponent = loadComponent(filterCompName);
        const filterName = filter?.enum?.name;

        return (
          <Col {...layoutFilters} key={filterName}>
            <FilterComponent
              askFromService={allowRequest?.[filterName]}
              name={filterName}
              disabled={disabledFilters?.[filterName]}
              requestParams={requestParamsSubscription?.[filterName]}
              allowClear={false}
              sourceId="Agreements-SellOut"
              {...specificFilterProps[filterName]}
            />
          </Col>
        );
      });
    }
    return null;
  }, [
    getFilters,
    setSubRegister,
    setAutomaticallyFirstOption,
    setAutomaticallyAnOption,
    allowRequest,
    disabledFilters,
    requestParamsSubscription,
  ]);

  const handleResetButton = useCallback(() => {
    try {
      window.localStorage.removeItem('query');
    } catch (_error) {
      console.error('errror intentando borrar en localStorage');
    }

    orchestrateFiltersReset({
      setDisabledFilters,
      setRequestParamsSubscription,
      setAllowRequests,
    });

    const formValues = form.getFieldsValue();
    cleanQuery({ state: 'idle', year: formValues.year, showBruteValue: true });
    form.resetFields();
    cleanAllFilters();
    infoClean();
  }, [cleanAllFilters, cleanQuery, form, infoClean, orchestrateFiltersReset]);

  useEffect(() => {
    if (mustResetContent && validProductsForDeleting === 1) {
      //reset filters if there is only one product and it gets deleted

      handleResetButton();
      setValidProductsForDeleting(0);
    }
  }, [handleResetButton, mustResetContent, setValidProductsForDeleting, validProductsForDeleting]);

  return (
    <>
      <Form
        name="validate_other"
        onValuesChange={(changedValues, allValues) =>
          handleOnFormValuesChange({ changedValues, allValues })
        }
        form={form}
        layout="horizontal"
      >
        <Row gutter={{ xs: 8, sm: 8, md: 10, lg: 12 }}>
          <Suspense fallback={<div>Loading...</div>}>
            <ErrorBoundary>{generateFilters()}</ErrorBoundary>
          </Suspense>
          <Col style={{ marginTop: '36px' }}>
            <ResetButton
              title="Limpiar filtros"
              onClick={handleResetButton}
              label="Limpiar filtros"
            />
          </Col>
          {visibleMassiveUpload && (
            <Col style={{ marginTop: '32px' }}>
              <Link to={'/trade-agreements-sellout/register/trade-agreements-sellout/'}>
                <span style={{ fontSize: '1.75rem', color: '#00499A' }}>
                  <FaUpload />
                </span>
              </Link>
            </Col>
          )}

          <Col>
            <ReviewActionsContainer />
          </Col>
          <Col style={{ textAlign: 'right', marginTop: '2rem' }}>
            <ActionsGroup />
          </Col>

          <RejectTradeAgreement />
          <SecondLevelValidation />
        </Row>
      </Form>
    </>
  );
};

function mapStateToProps(state) {
  return {
    validityProcess:
      state.TRADE_AGREEMENTS_SELLOUT?.registerTrade?.daysLeft?.data?.VALIDITY_PROCESS,
    mustResetContent: state.TRADE_AGREEMENTS_SELLOUT?.deleteProduct?.mustResetFilters,
    validProductsForDeleting: state?.TRADE_AGREEMENTS_SELLOUT?.validProductsForDeleting?.total ?? 0,
  };
}

export default connect(mapStateToProps, {
  queryClean,
  querySave,
  cleanTopFilters,
  setCurrentView,
  cleanInfo,
  clientSelected,
  territorySelected,
  getDaysLeftRequest,
  setSubscriptionValue4Register,
  setPersistentTerritory,
  setPersistentLine,
  validProductsForDeletingSuccess,
})(TopTradeAgreementsFiltersGroup);

TopTradeAgreementsFiltersGroup.propTypes = {
  clientInfo: PropTypes.object,
};

TopTradeAgreementsFiltersGroup.defaultProps = {
  clientInfo: {},
};
