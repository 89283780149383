import React, { useEffect } from 'react';
import { Col, Row } from 'antd';
import { DaysLeft, OnlyBattery } from 'Domains/SalesChallengeRefactor/components/indicators';
import { connect } from 'react-redux';
import { daysLeftRequest } from 'Domains/SalesChallengeRefactor/redux/actions/indicators';
import { processStepIdEnum } from 'common/models/enums/';
import { ProgressPercentage } from 'common/misc/';
import './style.css';
function IndicatorsSection(props) {
  const { daysLeftRequest: daysLeftReq, indicators, graph, reach, pageType } = props;

  useEffect(() => {
    const { SALES_CHALLENGE_ADJUST, SALES_CHALLENGE_REGISTER } = processStepIdEnum;
    const proccesTypeId =
      pageType === 'adjusted' ? SALES_CHALLENGE_ADJUST : SALES_CHALLENGE_REGISTER;
    daysLeftReq({ params: { processId: proccesTypeId } });
  }, [daysLeftReq, pageType]);

  return (
    <Row>
      <Col {...{ md: 7, sm: 24, xs: 24 }}>
        <p className="text-center">Meta de venta </p>
        <OnlyBattery style={{ marginTop: '-18px' }} value={graph} type="challenge" />
      </Col>
      <Col {...{ md: 11, sm: 24, xs: 24 }}>
        <ProgressPercentage
          title="Avance cliente/producto"
          percentage={reach}
          className="porcetange"
        />
      </Col>
      <Col {...{ md: 6, sm: 24, xs: 24 }}>
        <DaysLeft daysLeft={indicators?.daysLeft?.data?.daysLeft ?? '?'} />
      </Col>
    </Row>
  );
}

function mapStateToProps(state) {
  return {
    indicators: state?.SALES_CHALLENGE?.indicators,
    reach: state?.SALES_CHALLENGE?.challenge?.reach?.data ?? 0,
    zoneId: state?.SALES_CHALLENGE?.query?.zone,
    clientId: state?.SALES_CHALLENGE?.query?.client,
    graph: state?.SALES_CHALLENGE?.graph?.salesGoal?.data ?? 0,
  };
}

export default connect(mapStateToProps, { daysLeftRequest })(IndicatorsSection);
