import { takeLatest, all, call, put, cancelled } from 'redux-saga/effects';
import { notification } from 'antd';
import { openNotification } from 'common';
import instance from 'services/request';

import { responseOK } from 'Domains/SalesChallengeRefactor/utils';
import { DeleteImageServices } from '../../../services';

import { messageError } from 'utils/messages';
import { DeleteImageActions } from '../../actions';

const { DELETE_MANAGE_IMAGES_REQUEST, DeleteManageImagesClean, DeleteManageImagesSet } =
  DeleteImageActions;
const { ManageImagesDelete } = DeleteImageServices;

function* workManageImageDelete(action) {
  const { params, dataEndpoint, okDelete } = action.payload;
  const { CancelToken } = instance;
  const source = CancelToken.source();
  let mustRedirectToHome = false;
  try {
    const response = yield call(ManageImagesDelete, {
      cancelToken: source.token,
      params,
      endpoint: dataEndpoint.endpoint,
    });
    if (responseOK(response)) {
      yield put(DeleteManageImagesSet(response.data));
      okDelete();
    } else {
      yield openNotification('error', response.message);
    }
  } catch (error) {
    yield DeleteManageImagesClean();
    console.error('SAGAS ERROR', error);
  } finally {
    if (yield cancelled()) {
      yield DeleteManageImagesClean();
      source.cancel('cancelled Request');
    }
    if (mustRedirectToHome) {
      yield call(notification.destroy);
      yield call(messageError, 'No hay registros de ######## en el sistema');
    }
  }
}

export function* watchManageImageDelete() {
  yield all([takeLatest(DELETE_MANAGE_IMAGES_REQUEST, workManageImageDelete)]);
}
