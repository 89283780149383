import TradeAgreements from 'Domains/TradeAgreements/pages/TradeAgreements';
import TradeAgreementsRegister from 'Domains/TradeAgreements/pages/TradeAgreementsRegister';
import TradeAgreementsGenerate from 'Domains/TradeAgreements/pages/TradeAgreementsGenerate';
import TradeAgreementsMassiveUpload from 'Domains/TradeAgreements/massiveUpload';

export const tradeAgreementsModule = {
  'trade-agreements': TradeAgreements,
  'trade-agreements_massive-upload': TradeAgreementsMassiveUpload,
  'register_trade-agreements': TradeAgreementsRegister,
  'update_trade-agreements': TradeAgreementsRegister,
  'adjusted_trade-agreements': TradeAgreementsRegister,
  'generate_trade-agreements': TradeAgreementsGenerate,
};
