export const handleResetBtn = (
  form,
  setDisabledFilters,
  setAllowRequests,
  cleanQuery,
  cleanAgreement,
  cleanAllFilters,
  infoClean,
  indicatorsClean,
  setRequestParamsSubscription,
  initialDisabledFilters,
  initialAllowRequest,
  orchestrateFiltersReset
) => {
  form.resetFields();
  setDisabledFilters({
    ...initialDisabledFilters,
  });

  setAllowRequests({
    ...initialAllowRequest,
  });

  orchestrateFiltersReset({
    setDisabledFilters,
    setRequestParamsSubscription,
    setAllowRequests,
    form,
  });

  const formValues = form.getFieldsValue();
  cleanQuery({ state: 'idle', year: formValues.year, showBruteValue: true });
  cleanAgreement();
  cleanAllFilters();
  infoClean();
  indicatorsClean();
};
