import { takeLatest, put, cancelled, call } from 'redux-saga/effects';
import instance from 'services/request';
import { responseOK } from 'Domains/SalesChallengeRefactor/utils';
import { ocupationCreditLines } from '../../actions';
import { requestSectionData } from '../../../services';

const {
  OCL_SUBREGIONS_REQUEST,
  creditLinesSubregionsSet,
  creditLinesSubregionsClean,
  OCL_CLIENTS_REQUEST,
  creditLinesClientsSet,
  creditLinesClientsClean,
} = ocupationCreditLines;

function* reqCreditLinesClientsSectionData(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  try {
    const response = yield call(requestSectionData, { cancelToken: source.token, payload });
    if (responseOK(response)) {
      yield put(creditLinesClientsSet(response.data));
    } else {
      yield put(creditLinesClientsClean('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(creditLinesClientsClean(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

function* reqCreditLinesSubregionsData(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  try {
    const response = yield call(requestSectionData, { cancelToken: source.token, payload });
    if (responseOK(response)) {
      yield put(creditLinesSubregionsSet(response.data ?? []));
    } else {
      yield put(creditLinesSubregionsClean('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(creditLinesSubregionsClean(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* SagaRequestCreditLinesClientsSectionData() {
  yield takeLatest(OCL_CLIENTS_REQUEST, reqCreditLinesClientsSectionData);
}

export function* SagaRequestCreditLinesSubregionsData() {
  yield takeLatest(OCL_SUBREGIONS_REQUEST, reqCreditLinesSubregionsData);
}
