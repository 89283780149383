export const CLEAN_FILTERS = 'APP/CLEAN_FILTERS';

export const REGIONS_REQUEST = 'APP/REGIONS_REQUEST';
export const REGIONS_SUCCESS = 'APP/REGIONS_SUCCESS';
export const REGIONS_FAIL = 'APP/SUBREGIONS_FAIL';

export function regionRequest(payload) {
  return {
    type: REGIONS_REQUEST,
    payload: payload,
  };
}

export function regionSuccess(payload) {
  return {
    type: REGIONS_SUCCESS,
    payload: payload,
  };
}

export function regionFail() {
  return {
    type: REGIONS_FAIL,
  };
}

export const SUBREGIONS_REQUEST = 'APP/SUBREGIONS_REQUEST';
export const SUBREGIONS_SUCCESS = 'APP/SUBREGIONS_SUCCESS';
export const SUBREGIONS_FAIL = 'APP/SUBREGIONS__FAIL';

export function subregionsRequest(payload) {
  return {
    type: SUBREGIONS_REQUEST,
    payload: payload,
  };
}

export function subregionsSuccess(payload) {
  return {
    type: SUBREGIONS_SUCCESS,
    payload: payload,
  };
}

export function subregionsFail(payload) {
  return {
    type: SUBREGIONS_FAIL,
    payload: payload,
  };
}

export const AREAS_REQUEST = 'APP/AREAS_REQUEST';
export const AREAS_SUCCESS = 'APP/AREAS_SUCCESS';
export const AREAS_FAIL = 'APP/AREAS_FAIL';

export function areasRequest(payload) {
  return {
    type: AREAS_REQUEST,
    payload: payload,
  };
}

export function areasSuccess(payload) {
  return {
    type: AREAS_SUCCESS,
    payload: payload,
  };
}

export function areasFail(payload) {
  return {
    type: AREAS_FAIL,
    payload: payload,
  };
}

export const YEARS_REQUEST = 'APP/YEARS_REQUEST';
export const YEARS_SUCCESS = 'APP/YEARS_SUCCESS';
export const YEARS_FAIL = 'APP/YEARS_FAIL';

export function yearsRequest(payload) {
  return {
    type: YEARS_REQUEST,
    payload: payload,
  };
}

export function yearsSuccess(payload) {
  return {
    type: YEARS_SUCCESS,
    payload: payload,
  };
}

export function yearsFail() {
  return {
    type: YEARS_FAIL,
  };
}

// BUSINESS LINE 1
export const BUSINESSLINE_REQUEST = 'APP/BUSINESSLINE_REQUEST';
export const BUSINESSLINE_SUCCESS = 'APP/BUSINESSLINE_SUCCESS';
export const BUSINESSLINE_FAIL = 'APP/BUSINESSLINE_FAIL';

export function businessLineRequest(payload) {
  return {
    type: BUSINESSLINE_REQUEST,
    payload: payload,
  };
}
export function businessLineSuccess(payload) {
  return {
    type: BUSINESSLINE_SUCCESS,
    payload: payload,
  };
}
export function businessLineFail() {
  return {
    type: BUSINESSLINE_FAIL,
  };
}

// BUSINESS LINE 2
export const BUSINESSLINE_REQUEST2 = 'APP/BUSINESSLINE_REQUEST2';
export const BUSINESSLINE_SUCCESS2 = 'APP/BUSINESSLINE_SUCCESS2';
export const BUSINESSLINE_FAIL2 = 'APP/BUSINESSLINE_FAIL2';

export function businessLineRequest2(payload) {
  return {
    type: BUSINESSLINE_REQUEST2,
    payload: payload,
  };
}
export function businessLineSuccess2(payload) {
  return {
    type: BUSINESSLINE_SUCCESS2,
    payload: payload,
  };
}
export function businessLineFail2() {
  return {
    type: BUSINESSLINE_FAIL2,
  };
}

export const SEGMENTS_REQUEST = 'APP/SEGMENTS_REQUEST';
export const SEGMENTS_SUCCESS = 'APP/SEGMENTS_SUCCESS';
export const SEGMENTS_FAIL = 'APP/SEGMENTS_FAIL';

export function segmentsRequest(payload) {
  return {
    type: SEGMENTS_REQUEST,
    payload: payload,
  };
}

export function segmentsSuccess(payload) {
  return {
    type: SEGMENTS_SUCCESS,
    payload: payload,
  };
}

export function segmentsFail() {
  return {
    type: SEGMENTS_FAIL,
  };
}

export const BUSINESSRULES_REQUEST = 'APP/BUSINESSRULES_REQUEST';
export const BUSINESSRULES_SUCCESS = 'APP/BUSINESSRULES_SUCCESS';
export const BUSINESSRULES_FAIL = 'APP/BUSINESSRULES_FAIL';

export function businessRulesRequest(payload) {
  return {
    type: BUSINESSRULES_REQUEST,
    payload: payload,
  };
}

export function businessRulesSuccess(payload) {
  return {
    type: BUSINESSRULES_SUCCESS,
    payload: payload,
  };
}

export function businessRulesFail() {
  return {
    type: BUSINESSRULES_FAIL,
  };
}

export const SEGMENT_BUSINESSRULES_REQUEST = 'APP/SEGMENT_BUSINESSRULES_REQUEST';
export const SEGMENT_BUSINESSRULES_SUCCESS = 'APP/SEGMENT_BUSINESSRULES_SUCCESS';
export const SEGMENT_BUSINESSRULES_FAIL = 'APP/SEGMENT_BUSINESSRULES_FAIL';

export function segmentBusinessRulesRequest(payload) {
  return {
    type: SEGMENT_BUSINESSRULES_REQUEST,
    payload: payload,
  };
}

export function segmentBusinessRulesSuccess(payload) {
  return {
    type: SEGMENT_BUSINESSRULES_SUCCESS,
    payload: payload,
  };
}

export function segmentBusinessRulesFail() {
  return {
    type: SEGMENT_BUSINESSRULES_FAIL,
  };
}

export const TERRITORY_REQUEST = 'APP/TERRITORY_REQUEST';
export const TERRITORY_SUCCESS = 'APP/TERRITORY_SUCCESS';
export const TERRITORY_FAIL = 'APP/TERRITORY_FAIL';

export function territoryRequest(payload) {
  return {
    type: TERRITORY_REQUEST,
    payload: payload,
  };
}

export function territorySuccess(payload) {
  return {
    type: TERRITORY_SUCCESS,
    payload: payload,
  };
}

export function territoryFail() {
  return {
    type: TERRITORY_FAIL,
  };
}

export const ZONES_REQUEST = 'APP/ZONES_REQUEST';
export const ZONES_SUCCESS = 'APP/ZONES_SUCCESS';
export const ZONES_FAIL = 'APP/ZONES_FAIL';

export function zonesRequest(payload) {
  return {
    type: ZONES_REQUEST,
    payload: payload,
  };
}

export function zonesSuccess(payload) {
  return {
    type: ZONES_SUCCESS,
    payload: payload,
  };
}

export function zonesFail() {
  return {
    type: ZONES_FAIL,
  };
}

export const POG_REQUEST = 'APP/POG_REQUEST';
export const POG_SUCCESS = 'APP/POG_SUCCESS';
export const POG_FAIL = 'APP/POF_FAIL';

export function pogRequest(payload) {
  return {
    type: POG_REQUEST,
    payload: payload,
  };
}

export function pogSuccess(payload) {
  return {
    type: POG_SUCCESS,
    payload: payload,
  };
}

export function pogFail() {
  return {
    type: POG_FAIL,
  };
}

export const CLIENTS_REQUEST = 'APP/CLIENTS_REQUEST';
export const CLIENTS_SUCCESS = 'APP/CLIENTS_SUCCESS';
export const CLIENTS_FAIL = 'APP/CLIENTS_FAIL';
export const CLIENTS_UPDATE = 'APP/CLIENTS_UPDATE';

export function updateClients(payload) {
  return {
    type: CLIENTS_UPDATE,
    payload: payload,
  };
}

export function clientsRequest(payload) {
  return {
    type: CLIENTS_REQUEST,
    payload: payload,
  };
}

export function clientsSuccess(payload) {
  return {
    type: CLIENTS_SUCCESS,
    payload: payload,
  };
}

export function clientsFail() {
  return {
    type: CLIENTS_FAIL,
  };
}

export const FAMILY_REQUEST = 'APP/FAMILY_REQUEST';
export const FAMILY_SUCCESS = 'APP/FAMILY_SUCCESS';
export const FAMILY_FAIL = 'APP/FAMILY_FAIL';

export function familyRequest(payload) {
  return {
    type: FAMILY_REQUEST,
    payload: payload,
  };
}

export function familySuccess(payload) {
  return {
    type: FAMILY_SUCCESS,
    payload: payload,
  };
}

export function familyFail() {
  return {
    type: FAMILY_FAIL,
  };
}

export const ROLES__REQUEST = 'APP/ROLES__REQUEST';
export const ROLES__SUCCESS = 'APP/ROLES__SUCCESS';
export const ROLES_FAIL = 'APP/ROLES__FAIL';

export function rolesRequest(payload) {
  return {
    type: ROLES__REQUEST,
    payload: payload,
  };
}

export function rolesSuccess(payload) {
  return {
    type: ROLES__SUCCESS,
    payload: payload,
  };
}

export function rolesFail() {
  return {
    type: ROLES_FAIL,
  };
}

export const PRODUCTS_REQUEST = 'APP/PRODUCTS_REQUEST';
export const PRODUCTS_SUCCESS = 'APP/PRODUCTS_SUCCESS';
export const PRODUCTS_FAIL = 'APP/PRODUCTS_FAIL';
export const PRODUCTS_UPDATE_REQUEST = 'APP/PRODUCT_UPDATE_REQUEST';
export const PRODUCTS_UPDATE_SUCCESS = 'APP/PRODUCT_UPDATE_SUCCESS';

export const PRODUCTS_UPDATE = 'APP/PRODUCTS_UPDATE';

export function updateProducts(payload) {
  return {
    type: PRODUCTS_UPDATE,
    payload: payload,
  };
}

export function updateProductRequest(payload) {
  return {
    type: PRODUCTS_UPDATE_REQUEST,
    payload: payload,
  };
}

export function updateProductUpdate(payload) {
  return {
    type: PRODUCTS_UPDATE_SUCCESS,
    payload,
  };
}

export function productsRequest(payload) {
  return {
    type: PRODUCTS_REQUEST,
    payload: payload,
  };
}

export function productsSuccess(payload) {
  return {
    type: PRODUCTS_SUCCESS,
    payload: payload,
  };
}

export function productsFail() {
  return {
    type: PRODUCTS_FAIL,
  };
}

export const GET_PRODUCTS_ADD_REQUEST = 'GET_PRODUCTS_ADD_REQUEST/APP';
export const GET_PRODUCTS_ADD_SUCCESS = 'GET_PRODUCTS_ADD_SUCCESS/APP';
export const GET_PRODUCTS_ADD_FAIL = 'GET_PRODUCTS_ADD_FAIL/APP';
export const CLEAN_ADD_PRODUCTS = 'CLEAN_ADD_PRODUCTS';

export function cleanAddProducts() {
  return {
    type: CLEAN_ADD_PRODUCTS,
  };
}
export const GET_PRODUCTS_ADD_CLEAN = 'GET_PRODUCTS_ADD_CLEAN/APP';

export function getProductsAddRequest(payload) {
  return {
    type: GET_PRODUCTS_ADD_REQUEST,
    payload: payload,
  };
}

export function getProductsAddSuccess(payload) {
  return {
    type: GET_PRODUCTS_ADD_SUCCESS,
    payload: payload,
  };
}

export function getProductsAddFail(payload) {
  return {
    type: GET_PRODUCTS_ADD_FAIL,
    payload,
  };
}

export function getProductsAddClean() {
  return {
    type: GET_PRODUCTS_ADD_CLEAN,
  };
}

export function cleanTopFilters() {
  return {
    type: CLEAN_FILTERS,
  };
}

export const COUNTRIES_REQUEST = 'APP/COUNTRIES_REQUEST';
export const COUNTRIES_SUCCESS = 'APP/COUNTRIES_SUCCESS';
export const COUNTRIES_FAIL = 'APP/COUNTRIES_FAIL';

export function countriesRequest(payload) {
  return {
    type: COUNTRIES_REQUEST,
    payload: payload,
  };
}

export function countriesSuccess(payload) {
  return {
    type: COUNTRIES_SUCCESS,
    payload: payload,
  };
}

export function countriesFail() {
  return {
    type: COUNTRIES_FAIL,
  };
}

export const CURRENCY_REQUEST = 'APP/CURRENCY_REQUEST';
export const CURRENCY_SUCCESS = 'APP/CURRENCY_SUCCESS';
export const CURRENCY_FAIL = 'APP/CURRENCY_FAIL';

export function currencyRequest(payload) {
  return {
    type: CURRENCY_REQUEST,
    payload: payload,
  };
}

export function currencySuccess(payload) {
  return {
    type: CURRENCY_SUCCESS,
    payload: payload,
  };
}

export function currencyFail() {
  return {
    type: CURRENCY_FAIL,
  };
}

export const COMPANIES_REQUEST = 'APP/COMPANIES_REQUEST';
export const COMPANIES_SUCCESS = 'APP/COMPANIES_SUCCESS';
export const COMPANIES_FAIL = 'APP/COMPANIES_FAIL';

export function companiesRequest(payload) {
  return {
    type: COMPANIES_REQUEST,
    payload: payload,
  };
}

export function companiesSuccess(payload) {
  return {
    type: COMPANIES_SUCCESS,
    payload: payload,
  };
}

export function companiesFail() {
  return {
    type: COMPANIES_FAIL,
  };
}
