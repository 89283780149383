import React from 'react';
import { BCards } from 'common/components';
import PropTypes from 'prop-types';
import { OnlyBattery } from 'Domains/SalesChallengeRefactor/components/indicators';
function BatteryIndicator(props) {
  const { title, value, className, module, isLoading, ...other } = props;

  return (
    <div style={{ marginLeft: '10px', width: '50%' }}>
      <BCards
        title={title}
        loading={isLoading}
        align="center"
        headerType="info"
        className={`bcard-agreements type-value ${className} battery-indicator-div`}
        {...other}
      >
        <div
          style={{
            marginTop: '-20px',
            minHeight: '81px',
            width: '100%',
          }}
        >
          <OnlyBattery value={value} type="challenge" />
        </div>
      </BCards>
    </div>
  );
}

BatteryIndicator.propTypes = {
  module: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.any,
};

BatteryIndicator.defaultProps = {
  module: null,
  title: 'Meta de venta',
  value: undefined,
};

export default BatteryIndicator;
