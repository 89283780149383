import { takeLatest, put, call, cancelled, delay } from 'redux-saga/effects';
import {
  VALIDATE_REQUEST,
  validateSuccess,
  validateFail,
  CHALLENGE_REQUEST,
  challengeSuccess,
  challengeFail,
  REACH_REQUEST,
  reachSuccess,
  reachFail,
  SEND_TO_VALIDATE_REQUEST,
  sendToValidateFail,
  sendToValidateSuccess,
  CHALLENGE_COMMENTS_REQUEST,
  challengeCommentsSuccess,
  challengeCommentsFail,
} from '../actions/challenge';

import {
  getChallenge,
  getChallengeReach,
  sendToValidate,
  validate,
  getChallengeComments,
} from '../../services/challenge';
import { responseOK } from '../../utils';
import { openNotification } from 'common/misc/openNotification';
import { infoRequest } from 'Domains/SalesChallengeRefactor/redux/actions/info';
import {
  indicatorsRequest,
  territoryData,
} from 'Domains/SalesChallengeRefactor/redux/actions/indicators';
import instance from 'services/request';

function* workerValidateRequest(action) {
  //? Request params for success could be obtained from the store
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { name, data, onSuccess, infoParams, scope } = payload;
    const response = yield call(validate, { name, data, cancelToken: source.token });
    //const response = { status: 200, data: true };
    if (responseOK(response)) {
      yield call(openNotification, 'success', response.message);
      yield delay(2000);
      yield put(validateSuccess(response.data));
      //* Start here the orchestrarion of updates. it may vary form scope to scope so you cand add a control strucuture here
      yield put(
        infoRequest({
          name: scope,
          params: { ...infoParams },
        })
      );
      yield put(indicatorsRequest({ name: scope, params: { ...infoParams } }));
      yield put(territoryData({ name: scope, params: { ...infoParams } }));
      if (onSuccess) {
        yield onSuccess(response.data);
      }
    } else {
      yield put(validateFail());
    }
  } catch (e) {
    yield put(validateFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchValidateRequest() {
  yield takeLatest(VALIDATE_REQUEST, workerValidateRequest);
}

function* workerChallengeRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { params, onSuccess } = payload;
    const response = yield call(getChallenge, { params, cancelToken: source.token });

    if (responseOK(response)) {
      if (onSuccess) {
        yield onSuccess(response.data);
      }
      yield put(challengeSuccess(response.data));
    } else {
      yield put(challengeFail());
    }
  } catch (e) {
    yield put(challengeFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchChallengeRequest() {
  yield takeLatest(CHALLENGE_REQUEST, workerChallengeRequest);
}

function* workerReachRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { params, onSuccess } = payload;
    const response = yield call(getChallengeReach, params, source.token);

    if (responseOK(response)) {
      if (onSuccess) {
        yield onSuccess(response.data);
      }
      yield put(reachSuccess(response.data));
    } else {
      yield put(reachFail());
    }
  } catch (e) {
    yield put(reachFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchReachRequest() {
  yield takeLatest(REACH_REQUEST, workerReachRequest);
}

function* workerSendToValidateRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();

  try {
    const { payload } = action;
    const { params, onSuccess } = payload;
    const response = yield call(sendToValidate, { params, cancelToken: source.token });
    if (responseOK(response)) {
      yield call(openNotification, 'success', response.message);
      yield delay(1500);
      if (onSuccess) {
        yield onSuccess(response.data);
      }
      yield put(sendToValidateSuccess(response.data));
    } else {
      yield put(sendToValidateFail());
    }
  } catch (e) {
    console.error(e);
    yield put(sendToValidateFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchSendToValidate() {
  yield takeLatest(SEND_TO_VALIDATE_REQUEST, workerSendToValidateRequest);
}

function* workerChallengeCommentsRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { params, onSuccess } = payload;
    const response = yield call(getChallengeComments, { params, cancelToken: source.token });
    if (responseOK(response)) {
      if (onSuccess) {
        yield onSuccess(response.data);
      }
      yield put(challengeCommentsSuccess(response.data));
    } else {
      yield put(challengeCommentsFail());
    }
  } catch (e) {
    console.error(e);
    yield put(challengeCommentsFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchChallengeComments() {
  yield takeLatest(CHALLENGE_COMMENTS_REQUEST, workerChallengeCommentsRequest);
}
