import { takeLatest, put, cancelled, call } from 'redux-saga/effects';
import instance from 'services/request';
import { responseOK } from 'Domains/SalesChallengeRefactor/utils';
import { registerData } from '../../actions';
import { requestRegisterData } from '../../../services';
import { openNotification } from 'common';

const { REGISTER_REQUEST, registerSet, registerClean } = registerData;

function* reqRegisterData(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const { history, paramsArray } = payload;
  try {
    const response = yield call(requestRegisterData, {
      cancelToken: source.token,
      payload: paramsArray,
    });
    if (responseOK(response)) {
      yield put(registerSet(response.data));

      if (response.data === true) {
        openNotification(
          'success',
          'La administración de secciones de estados de cuenta se realizó correctamente.'
        );
        setTimeout(() => {
          history.push('/account-status/dynamic-items/consult');
        }, 500);
      }
    } else {
      yield put(registerClean('error al cargar la respuesta'));
      openNotification(
        'error',
        response.message ? response.message : 'Error al actualizar los datos'
      );
    }
  } catch (e) {
    yield put(registerClean(e));
    const message = e.message ? e.message : 'Error al actualizar los datos';
    openNotification('error', message);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* SagaRequestRegisterData() {
  yield takeLatest(REGISTER_REQUEST, reqRegisterData);
}
