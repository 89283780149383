import React, { useCallback, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BButton from 'common/components/BButton';
import { setAgreementType, generateTradeAgreementRequest } from '../../redux/actions';
import { roleIdEnum, salesChallengeStatus } from 'common';
import { RoleAccessControl } from 'common/components/accessControllers';
import useUser from 'hooks/useUser';
import usePersistData from 'hooks/usePersistData';
import { openNotification } from 'common/misc/openNotification';
import DownloadReport from '../DownloadReport/DownloadReport';
import { SendToValidate } from '..';
import { actionTradeEnum } from '../../models/enums';
import { fnActionType } from './actionType';
import { fnHandleActionTrade } from '../../utils/handleActionTrade';

const { ON_PROCESS, REJECTED, APPROVED } = salesChallengeStatus;
const { SALES_AGENT } = roleIdEnum;

const ActionsGroup = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    query,
    clientInfo,
    clientId,
    territoryId,
    yearSelected,
    process,
    isGenerating,
    PolicyTypeId,
    statusClientId,
    isNational,
    updatableNational,
    infoData,
    clientCatalogs,
    clientKey,
  } = useSelector(
    (state) => ({
      query: state.TRADE_AGREEMENTS_SELLOUT?.query,
      clientInfo: state?.TRADE_AGREEMENTS_SELLOUT?.infoForAgreement?.clientInfo ?? null,
      clientId: state?.TRADE_AGREEMENTS_SELLOUT?.query?.clientId ?? null,
      territoryId: state?.TRADE_AGREEMENTS_SELLOUT?.query?.territoryId ?? null,
      yearSelected: state?.TRADE_AGREEMENTS_SELLOUT?.query?.year ?? null,
      process: state?.TRADE_AGREEMENTS_SELLOUT?.registerTrade?.daysLeft?.data,
      isGenerating: state?.TRADE_AGREEMENTS_SELLOUT?.agreement?.validateStatus?.isLoading ?? false,
      PolicyTypeId: state.TRADE_AGREEMENTS_SELLOUT?.query?.PolicyTypeId,
      statusClientId:
        state?.TRADE_AGREEMENTS_SELLOUT?.infoForAgreement?.clientInfo?.statusId ?? null,
      isNational: state?.TRADE_AGREEMENTS_SELLOUT?.infoForAgreement?.clientInfo?.isNational,
      updatableNational:
        state?.TRADE_AGREEMENTS_SELLOUT?.views?.indicators?.data?.updatable ?? false,
      infoData: state?.TRADE_AGREEMENTS_SELLOUT?.views?.info?.data,
      clientCatalogs: state?.APP?.catalogs?.clients?.data ?? false,
      clientKey: state?.TRADE_AGREEMENTS_SELLOUT?.clientKey?.clientKey ?? null,
    }),
    shallowEqual
  );
  // Gets user from localstorage
  const user = useUser();
  const existClient = typeof clientId === 'string';
  // eslint-disable-next-line no-unused-vars
  const [queryLocal, setQueryLocal] = usePersistData('query', query);

  // Returns client data which key is equal to clientKey
  const determineClientData = useCallback(() => {
    if (!clientCatalogs || !clientKey) return false;
    return clientCatalogs.find((eachClient) => eachClient.key === clientKey);
  }, [clientCatalogs, clientKey]);
  // Returns boolean based in condition
  const getIsUpdatableNational = () => {
    const currentClient = determineClientData();
    return currentClient?.isNational && currentClient?.updatable;
  };
  const updatableNational2 = getIsUpdatableNational();
  // Effect to set or remove query from localstorage based in query
  useEffect(() => {
    setQueryLocal(query);
  }, [query, setQueryLocal]);
  // Returns obj with label, key and path for action buttons or false
  const actionType = useMemo(() => {
    const clientData2 = determineClientData();
    const actnType = fnActionType(
      clientInfo,
      clientData2,
      yearSelected,
      existClient,
      updatableNational,
      updatableNational2,
      ON_PROCESS,
      REJECTED,
      APPROVED,
      user,
      isNational,
      statusClientId,
      actionTradeEnum,
      territoryId,
      roleIdEnum,
      process
    );

    return actnType ? actnType : false;
  }, [
    determineClientData,
    clientInfo,
    yearSelected,
    existClient,
    updatableNational,
    updatableNational2,
    user,
    isNational,
    statusClientId,
    territoryId,
    process,
  ]);
  // Dispatchs actions on action key
  const handleActionTrade = useCallback(() => {
    let action = actionType;
    fnHandleActionTrade(
      action,
      dispatch,
      setAgreementType,
      history,
      generateTradeAgreementRequest,
      PolicyTypeId,
      clientId,
      territoryId,
      yearSelected
    );
  }, [actionType, dispatch, history, PolicyTypeId, clientId, territoryId, yearSelected]);
  // Success notification when agreement was sent
  const onSuccessValidate = (msg) => {
    openNotification('success', msg);
    setTimeout(() => {
      history.push('/');
    }, 300);
  };
  // Validations to render dowload report button
  const handleShowDownloadReport = useCallback(() => {
    const querySet = Object.keys(query).length > 3;
    const data = infoData && infoData.length > 0;
    const nationalValidation = isNational ? query.territoryId : query.clientId;
    const clientValidations = query.clientId ? nationalValidation : true;
    const commonValidity = clientValidations && querySet && data;

    const isSalesAgent = user?.role?.roleId === SALES_AGENT;
    const allApproved = clientInfo?.allApproved;
    const clientStatus = APPROVED === clientInfo?.statusId;

    if (isSalesAgent) {
      return commonValidity && allApproved && clientStatus;
    }
    return commonValidity;
  }, [query, infoData, clientInfo, user, isNational]);

  return (
    <>
      {(user?.role?.roleId === roleIdEnum.ADMINISTRATOR ||
        user?.role?.roleId === roleIdEnum.SALES_AGENT) && (
        <RoleAccessControl
          moduleKey="trade-agreements-sellout"
          page="register_trade-agreements-sellout"
        >
          <>
            {actionType.label && (
              <BButton
                onClick={handleActionTrade}
                style={{ margin: '0 .5rem' }}
                type="primary"
                loading={isGenerating}
                label={actionType.label ? actionType.label : 'AA'}
              />
            )}

            {existClient && actionType?.key === 2 && (
              <SendToValidate
                pageType="consult"
                onSuccessValidate={onSuccessValidate}
                label="Enviar acuerdo"
              />
            )}
          </>
        </RoleAccessControl>
      )}

      {user?.role?.roleId === roleIdEnum.AREA_ADMINISTRATOR && user?.area === 'Acceso a mercado' && (
        <RoleAccessControl
          moduleKey="trade-agreements-sellout"
          page="generate_trade-agreements-sellout"
        >
          <>
            {actionType && (
              <BButton
                onClick={handleActionTrade}
                style={{ margin: '0 .5rem' }}
                type="primary"
                loading={isGenerating}
                label={actionType.label ? actionType.label : 'AA'}
              />
            )}

            {existClient && actionType && actionType?.key === 2 && (
              <SendToValidate
                pageType="consult"
                onSuccessValidate={onSuccessValidate}
                label="Enviar acuerdo"
              />
            )}
          </>
        </RoleAccessControl>
      )}

      {handleShowDownloadReport() && <DownloadReport />}
    </>
  );
};

export default ActionsGroup;
