import { takeLatest, put, call, select } from 'redux-saga/effects';
import instance from 'services/request';
import {
  ADD_TRADE_AGREEMENT_VALIDATOR_REQUEST,
  addTradeAgreementValidatorSuccess,
  addTradeAgreementValidatorFail,
} from '../actions/addTradeAgreementValidator';
import { tradeAgreementsValidatorsCatalogRequest } from 'RootModule/redux/actions/tradeAgreementsValidators';
import { openNotification } from 'common/misc/openNotification';
import { responseOK } from 'utils';
import { addTradeAgreementValidator } from 'Domains/Validations/services/addTradeAgreementValidator';
import { validatorsListTradeAgreementRequest } from '../actions/validatorList';
import { clientsRequest } from 'RootModule/redux/actions/catalogs';

function* workAddTradeAgreementValidator(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();

  try {
    const { params } = action?.payload;
    const paramsToGetValidators = { Territories: params.territoryId, Clients: params.clientId };

    //const paramsToGetCatalog = { territoryId: params.territoryId };
    const response = yield call(addTradeAgreementValidator, params, source.token);
    const query = yield select((state) => state?.VALIDATIONS?.filters?.query);
    const requestParams = {
      lineId: query.lineId,
      subRegionId: query.subRegionId,
      territoryId: query.territoryId,
    };
    const sourceId = 'Validators';

    if (responseOK(response)) {
      yield put(addTradeAgreementValidatorSuccess());
      openNotification('success', response.message);
      yield put(validatorsListTradeAgreementRequest({ params: paramsToGetValidators }));

      yield put(
        clientsRequest({
          requestParams,
          sourceId,
          mode: 'multiple',
        })
      );
      yield put(tradeAgreementsValidatorsCatalogRequest({ params: paramsToGetValidators }));
    } else {
      yield put(addTradeAgreementValidatorFail());
      openNotification('error', response.message);
    }
  } catch (error) {
    yield put(addTradeAgreementValidatorFail());

    console.error('[BASF[REDUX-SAGA]', error);
  }
}

export function* watchAddTradeAgreementValidator() {
  yield takeLatest(ADD_TRADE_AGREEMENT_VALIDATOR_REQUEST, workAddTradeAgreementValidator);
}
