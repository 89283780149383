import { takeEvery, all, call, put, cancelled } from 'redux-saga/effects';

import {
  CLIENTS_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
  CLIENTS_MASSIVE_UPLOAD_REQUEST_VERIFY,
  CLIENTS_MASSIVE_UPLOAD_REQUEST_UPLOAD,
  clientsMassiveUploadSetAllowUpload,
  clientsMassiveUploadClearAllowUpload,
  clientsMassiveUploadSetVerify,
  clientsMassiveUploadClearVerify,
  clientsMassiveUploadClearUpload,
} from '../actions';

import instance from 'services/request';

import { responseOK } from 'Domains/SalesChallengeRefactor/utils';

import { clientsMassiveUpload } from '../../services';
import {
  fileTo64String,
  validateTypeAndSize,
  summaryModal,
  summaryEndModal,
} from 'common/MassiveUploads/utils';
import { messageError } from 'utils/messages';

function* workClientsMassiveUploadSetAllowUpload(action) {
  try {
    yield clientsMassiveUploadSetAllowUpload(action.payload);
  } catch (error) {
    yield clientsMassiveUploadClearAllowUpload();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield clientsMassiveUploadClearAllowUpload();
    }
  }
}

function* workClientsMassiveUploadRequestVerify({ payload }) {
  const { file, setFileString } = payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const isValidFile = validateTypeAndSize(file);
    if (!isValidFile) {
      messageError(
        'El formato del archivo de carga masiva de clientes debe ser .xlsx y tener un tamaño máximo de 20 MB.'
      );

      return;
    }

    const file64Str = yield call(fileTo64String, file);
    yield call(setFileString, file64Str);
    const response = yield call(clientsMassiveUpload, {
      cancelToken: source.token,
      data: {
        content: file64Str,
        overwrite: false,
        save: false,
      },
    });
    if (responseOK(response)) {
      let { data, message, allCorrectMessage } = response;
      let allCorrect = data.correct > 0 && data.totalErrors === 0;

      if (allCorrect) {
        data.allCorrectMessage = allCorrectMessage;
      }

      yield put(clientsMassiveUploadSetVerify(data));
      yield call(summaryModal, message);
    } else {
      yield put(clientsMassiveUploadClearVerify());
    }
  } catch (error) {
    yield clientsMassiveUploadClearVerify();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield clientsMassiveUploadClearVerify();
      source.cancel('cancelled Request');
    }
  }
}

function* workClientsMassiveUploadRequestUpload({ payload }) {
  const { history, fileString, overwrite } = payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(clientsMassiveUpload, {
      cancelToken: source.token,
      data: {
        content: fileString,
        save: true,
        overwrite,
      },
    });
    if (responseOK(response)) {
      /* yield call(messageSuccess, response.message);
      yield call(history.push, '/catalog/clients');*/
      yield call(summaryEndModal, response.data, history, '/catalog/clients');
    } else {
      yield put(clientsMassiveUploadClearUpload());
    }
  } catch (error) {
    yield clientsMassiveUploadClearUpload();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield clientsMassiveUploadClearUpload();
      source.cancel('cancelled Request');
    }
  }
}

export function* watchClientsMassiveUploadSetAllowUpload() {
  yield all([
    takeEvery(CLIENTS_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD, workClientsMassiveUploadSetAllowUpload),
  ]);
}

export function* watchClientsMassiveUploadRequestVerify() {
  yield all([
    takeEvery(CLIENTS_MASSIVE_UPLOAD_REQUEST_VERIFY, workClientsMassiveUploadRequestVerify),
  ]);
}

export function* watchClientsMassiveUploadRequestUpload() {
  yield all([
    takeEvery(CLIENTS_MASSIVE_UPLOAD_REQUEST_UPLOAD, workClientsMassiveUploadRequestUpload),
  ]);
}
