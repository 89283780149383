/* eslint-disable no-console */
import { takeEvery, put, call, all, cancelled, select } from 'redux-saga/effects';
import {
  PlACEMENTS_REQUEST,
  TYPE_CLIENT_REQUEST,
  BUSINESS_LINES_REQUEST,
  SEGMENTATION_REQUEST,
  COMPANY_REQUEST,
  POGS_REQUEST,
  TERRITORIES_REQUEST,
  CURRENCIES_REQUEST,
  CLIENTID_REQUEST,
  REGISTER_DYNAMIC_ELEMENT_REQUEST,
  VALIDATE_CLIENT_REQUEST,
  INCENTIVE_PERIOD_REQUEST,
  LINES_REQUEST,
  getLinesSuccess,
  getLinesClean,
  getSalesIncentivePeriodSuccess,
  getSalesIncentivePeriodClean,
  getClientIdClean,
  getClientIdSuccess,
  getPlacementsSuccess,
  getPlacementsClean,
  getTypeClientSuccess,
  getTypeClientClean,
  getBusinessLinesSuccess,
  getBusinessLinesClean,
  getSegmentationSuccess,
  getSegmentationClean,
  getCompanyClean,
  getCompanySuccess,
  getTerritoriesSuccess,
  getTerritoriesClean,
  getPogsClean,
  getPogsSuccess,
  getCurrenciesSuccess,
  getCurrenciesClean,
  DynamicElementsClean,
  DynamicElementsSuccess,
  validateClientSuccess,
  validateClientClean,
} from '../actions';
import {
  getOptionsClient,
  getLinesClient,
  RegisterDynamicElements,
  getTerritoriesClient,
  validateClient,
  getClient,
  getIncentivePeriod,
} from '../../services/index';
import instance from 'services/request';
import { responseOK } from 'Domains/SalesChallengeRefactor/utils';
import { openNotification } from 'common';

//get data cliente
function* validateClientRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { soldTo, lineId, errorMessage } = payload;

    const response = yield call(validateClient, {
      soldTo,
      lineId,
    });

    if (responseOK(response)) {
      if (response.data === true) {
        setTimeout(() => {
          errorMessage(response.message);
        }, 1000);
      }
      yield put(validateClientSuccess(response.data));
    } else {
      yield put(validateClientClean(response.data));
    }
  } catch (error) {
    yield put(validateClientClean(error.message));
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchValidateClientRequest() {
  yield all([takeEvery(VALIDATE_CLIENT_REQUEST, validateClientRequest)]);
}
//get clientid
function* workGetClientRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getClient, {
      cancelToken: source.token,
    });

    if (responseOK(response)) {
      yield put(getClientIdSuccess(response.data));
    } else {
      yield put(getClientIdClean());
    }
  } catch (error) {
    yield put(getClientIdClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetClientRequest() {
  yield all([takeEvery(CLIENTID_REQUEST, workGetClientRequest)]);
}

//get placements
function* workGetPlacementsRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getOptionsClient, {
      cancelToken: source.token,
      name: 'Placements',
    });

    if (responseOK(response)) {
      yield put(getPlacementsSuccess(response.data));
    } else {
      yield put(getPlacementsClean());
    }
  } catch (error) {
    yield put(getPlacementsClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetPlacementsRequest() {
  yield all([takeEvery(PlACEMENTS_REQUEST, workGetPlacementsRequest)]);
}

//get tipo

function* workGetTypeClientRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getOptionsClient, {
      cancelToken: source.token,
      name: 'ClientTypes',
    });

    if (responseOK(response)) {
      yield put(getTypeClientSuccess(response.data));
    } else {
      yield put(getTypeClientClean());
    }
  } catch (error) {
    yield put(getTypeClientClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetTypeClientRequest() {
  yield all([takeEvery(TYPE_CLIENT_REQUEST, workGetTypeClientRequest)]);
}
//get lines

function* workGetLinesRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { params, onSuccess } = action.payload;
  const state = yield select();

  try {
    const response = yield call(getLinesClient, {
      cancelToken: source.token,
      params,
    });

    if (responseOK(response)) {
      let newLineBusines = state.REGISTERDELEMENT.DynamicElements.businessLine.concat(
        response.data
      );
      const filteredLines = newLineBusines.reduce((acc, current) => {
        const x = acc.find((item) => item.key === current.key);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      yield put(getLinesSuccess(filteredLines));
      if (onSuccess) {
        onSuccess(response.data);
      }
    } else {
      yield put(getLinesClean());
    }
  } catch (error) {
    yield put(getLinesClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetLinesRequest() {
  yield all([takeEvery(LINES_REQUEST, workGetLinesRequest)]);
}
//get busines lines

function* workGetBussinesLinesRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getOptionsClient, {
      cancelToken: source.token,
      name: 'BusinessLines',
    });

    if (responseOK(response)) {
      yield put(getBusinessLinesSuccess(response.data));
    } else {
      yield put(getBusinessLinesClean());
    }
  } catch (error) {
    yield put(getBusinessLinesClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetBussinesLinesRequest() {
  yield all([takeEvery(BUSINESS_LINES_REQUEST, workGetBussinesLinesRequest)]);
}

//get segmantation

function* workGetSegmentactionRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getOptionsClient, {
      cancelToken: source.token,
      name: 'Segmentation',
    });

    if (responseOK(response)) {
      yield put(getSegmentationSuccess(response.data));
    } else {
      yield put(getSegmentationClean());
    }
  } catch (error) {
    yield put(getSegmentationClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetSegmentationRequest() {
  yield all([takeEvery(SEGMENTATION_REQUEST, workGetSegmentactionRequest)]);
}
//get company

function* workGetCompanyRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getOptionsClient, {
      cancelToken: source.token,
      name: 'Companies',
    });

    if (responseOK(response)) {
      yield put(getCompanySuccess(response.data));
    } else {
      yield put(getCompanyClean());
    }
  } catch (error) {
    yield put(getCompanyClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetCompanyRequest() {
  yield all([takeEvery(COMPANY_REQUEST, workGetCompanyRequest)]);
}
//get Territories

function* workGetTerritoriesRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const { params } = payload;

  try {
    const response = yield call(getTerritoriesClient, {
      cancelToken: source.token,
      params,
    });

    if (responseOK(response)) {
      yield put(getTerritoriesSuccess(response.data));
    } else {
      yield put(getTerritoriesClean());
    }
  } catch (error) {
    yield put(getTerritoriesClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetTerritoriesRequest() {
  yield all([takeEvery(TERRITORIES_REQUEST, workGetTerritoriesRequest)]);
}
//get incentive period

function* workGetSalesIncentivePeriodRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getIncentivePeriod, {
      cancelToken: source.token,
    });

    if (responseOK(response)) {
      yield put(getSalesIncentivePeriodSuccess(response.data));
    } else {
      yield put(getSalesIncentivePeriodClean());
    }
  } catch (error) {
    yield put(getSalesIncentivePeriodClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetIncentivePeriodRequest() {
  yield all([takeEvery(INCENTIVE_PERIOD_REQUEST, workGetSalesIncentivePeriodRequest)]);
}

//get pogs

function* workGetPogsRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getOptionsClient, {
      cancelToken: source.token,
      name: 'Pogs',
    });

    if (responseOK(response)) {
      yield put(getPogsSuccess(response.data));
    } else {
      yield put(getPogsClean());
    }
  } catch (error) {
    yield put(getPogsClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetPogsRequest() {
  yield all([takeEvery(POGS_REQUEST, workGetPogsRequest)]);
}

//get currencies

function* workGetCurrenciesRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getOptionsClient, {
      cancelToken: source.token,
      name: 'Currencies',
    });

    if (responseOK(response)) {
      yield put(getCurrenciesSuccess(response.data));
    } else {
      yield put(getCurrenciesClean());
    }
  } catch (error) {
    yield put(getCurrenciesClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetCurrenciesRequest() {
  yield all([takeEvery(CURRENCIES_REQUEST, workGetCurrenciesRequest)]);
}

//Register clients

function* workRegisterDinamicElementRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const { parameters, history } = payload;
  try {
    const response = yield call(RegisterDynamicElements, {
      cancelToken: source.token,
      data: parameters,
    });

    if (responseOK(response)) {
      if (response?.data === false) {
        yield put(DynamicElementsSuccess(response.data));
        yield openNotification('error', response.message);
      } else {
        yield openNotification('success', response.message);
        yield put(DynamicElementsSuccess(response.data));
        setTimeout(() => {
          history.push('/account-status/dynamic-items/consult');
        }, 1000);
      }
    } else {
      yield put(DynamicElementsClean());
    }
    if (response?.data === false) {
      yield put(DynamicElementsSuccess(response.data));
      yield openNotification('error', response.message);
    }
  } catch (error) {
    yield put(DynamicElementsClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchRegisterClientRequest() {
  yield all([takeEvery(REGISTER_DYNAMIC_ELEMENT_REQUEST, workRegisterDinamicElementRequest)]);
}
