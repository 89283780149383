export const fnHandleActionTrade = (
  action,
  dispatch,
  setAgreementType,
  history,
  generateTradeAgreementRequest,
  policyTypeId,
  clientId,
  territoryId,
  yearSelected
) => {
  if (action) {
    switch (action.key) {
      case 1:
      case 2:
        dispatch(setAgreementType('GENERATE_TRADE_AGREEMENT'));
        history.push(action.path);
        break;
      case 3:
        dispatch(setAgreementType('TRADE_AGREEMENT_ADJUST'));
        history.push(action.path);
        break;
      case 4:
        dispatch(
          generateTradeAgreementRequest({
            policyTypeId: policyTypeId,
            clientId,
            territoryId: territoryId ? territoryId : null,
            year: yearSelected,
          })
        );
        break;
      case 5:
        dispatch(setAgreementType('TRADE_AGREEMENT_GENERATE'));
        history.push(action.path);
        break;
      default:
        break;
    }
  }
};
