export const setFormFieldValueGroup = (
  form,
  options,
  filterName,
  optionKey,
  handleOnValuesChange
) => {
  if (options?.length > 0) {
    const optionIndex = options.findIndex((option) => option?.value === optionKey);
    const selectedOption = options[optionIndex] ?? options[0];

    form.setFieldsValue({
      [filterName]: selectedOption?.value ?? undefined,
    });

    const allValues = form.getFieldsValue();
    const changedValues = { [filterName]: selectedOption?.value };

    handleOnValuesChange({ changedValues, allValues });
  }
};
