import Dashboard from 'Domains/Dashboard';
import DashboardLayout from '../../Dashboards/dashboardLayout';
import { catalogModulesMap } from './catalogModulesMap';
import { accountStatusModules } from './accountStatusModules';
import { historicInformation } from './historicInformation.map';
import ConsultLog from '../../ConsultLog/pages';
import MasiveExchangeRateUpload from '../../MasiveExchangeRateUpload/pages';
import { USERS_MAP } from './usersModules.map';
import Notifications from 'Layout/Components/Notifications/pages';
import Maps from 'Dashboards/Maps/containers/Maps';
import SalesChallengeDashboard from 'Dashboards/SalesChallenge/salesChallenge';
import Comparative from 'Dashboards/Comparative/containers/Comparative';
import { CommercialAgreementComponent } from '../../Dashboards/CommercialAgreement/CommercialAgreementComponent';
import Validations from 'Domains/Validations/containers/Validations';
import { tradeAgreementsModule } from './tradeAgreementsModule';
import { salesChallengeModule } from './salesChallengeModule';
import { tradeAgreementstSellOutModule } from './tradeAgreementsSellOutModule';

const RootModulesMap = {
  dashboard: Dashboard,
  dashboards: DashboardLayout,
  ...USERS_MAP,
  'challenge-dashboard': SalesChallengeDashboard,
  'commercial-agreement-dashboard': CommercialAgreementComponent,
  'map-dashboard': Maps,
  'comparative-dashboard': Comparative,
  validators: Validations,
  binnacle: ConsultLog,
  'upload_exchange-rate': MasiveExchangeRateUpload,
  notifications: Notifications,
  ...salesChallengeModule,
  ...tradeAgreementsModule,
  ...catalogModulesMap,
  ...historicInformation,
  ...accountStatusModules,
  ...tradeAgreementstSellOutModule,
};
export default RootModulesMap;
