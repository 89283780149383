import React from 'react';
import { Row } from 'antd';

import EditableTable from 'Domains/TradeAgreements/common/EditableTable';
import './style.css';

function SecondRowRight({ showBruteValue, currentAgreement, dataSource, ...other }) {
  return (
    <Row className="second-row-agreements">
      <EditableTable
        dataSource={dataSource}
        loading={currentAgreement && currentAgreement?.isLoading}
        {...other}
      />
      {!showBruteValue && (
        <p style={{ color: 'red', textAlign: 'right', width: '100%' }}>
          Los valores netos son exclusivamente para uso interno BASF. No comunicar a cliente. Sólo
          uso interno
        </p>
      )}
    </Row>
  );
}

export default SecondRowRight;
