export const CLIENT_SELECTED = 'CLIENT_SELECTED/SALES-CHALLENGE';
export const TERRITORY_SELECTED = 'CLIENT_SELECTED/SALES-CHALLENGE';
export const CLIENT_COMPLETE = 'CLIENT_COMPLETE';
export const TERRITORY_COMPLETE = 'TERRITORY_COMPLETE';
export function clientSelected(payload) {
  return {
    type: CLIENT_SELECTED,
    payload: payload,
  };
}
export function saveClient(payload) {
  return {
    type: CLIENT_COMPLETE,
    payload,
  };
}

export function territorySelected(payload) {
  return {
    type: TERRITORY_COMPLETE,
    payload: payload,
  };
}
export function saveTerritory(payload) {
  return {
    type: TERRITORY_COMPLETE,
    payload,
  };
}
