import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import { openNotification } from 'common/misc/openNotification';
import { formatPriceWithDecimals, formatNumber } from '../../../utils';

import { whoSaveChallenge } from 'Domains/SalesChallengeRefactor/models/enums';
import { InputNumber } from 'antd';
import { calculateMoq } from 'Domains/SalesChallengeRefactor/helpers/helperEditable';
import getFloatParser from 'utils/getFloatParser';
import { MSG_035 } from 'Domains/TradeAgreementsSellOut/utils/messages';
import { changeCell } from 'Domains/TradeAgreementsSellOut/redux/actions';
import { saveVolume } from 'Domains/SalesChallengeRefactor/redux/actions/table';

function EditableCell(props) {
  const {
    isIncompleteData,
    cellId,
    isEditable,
    value,
    moq,
    saveVolume: saveChange,
    changeCell: saveToTable,
    pageType,
  } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [internalValue, setInernalValue] = useState('');
  const [outWithEnterKey, setOutWithEnterKey] = useState(false);
  const [isMultiple, setIsMultiple] = useState(undefined);

  const [lastInteraction, setLastInteraction] = useState(null);
  //  Effect to handle key 'Tab'
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Tab') {
        setLastInteraction('tab');
      } else {
        setLastInteraction('notab');
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);
  // Set isEditing on condition
  const handleFocus = () => {
    if (lastInteraction === 'tab' && process.env.REACT_APP_ISD) setIsEditing(true);
  };
  // Saves data cell based on pagetype
  const saveData = useCallback(
    (data) => {
      if (pageType === 'TradeAgreements') {
        saveToTable({
          data: { ...data, whoSaveChallenge: whoSaveChallenge.CELL },
        });
      } else {
        saveChange({
          data: { ...data, whoSaveChallenge: whoSaveChallenge.CELL },
        });
      }
    },
    [saveChange, pageType, saveToTable]
  );
  // Fn to calculate Moq
  const validateMultiple = useCallback(
    (v) => {
      return calculateMoq(v, moq);
    },
    [moq]
  );
  // Effect to calculate moq n set internalValue
  useEffect(() => {
    setInernalValue(value);
    let is = validateMultiple(value);
    setIsMultiple(is);
  }, [value, validateMultiple]);
  // Fn that toogles isEditable if true
  const handleOnDoubleClick = () => {
    if (isEditable) {
      setIsEditing(!isEditing);
    }
  };
  //
  const sendMessage = () => {
    let entity = '';
    if (pageType === 'TradeAgreements') {
      entity = 'acuerdo comercial sell out';
    } else {
      entity = 'desafío de ventas';
    }
    openNotification('info', MSG_035(entity));
  };

  const handleOnBlur = () => {
    let isMult = validateMultiple(internalValue);
    if (!isMult && !outWithEnterKey) {
      sendMessage();
    }
    saveData({
      value: internalValue,
      key: cellId,
      isValid: validateMultiple(internalValue),
    });

    setIsEditing(false);
  };

  const handleIputOnChange = useCallback(
    (val) => {
      if (typeof val !== 'undefined') {
        if (typeof val === 'number') {
          let number = parseFloat(val);
          let isMult = validateMultiple(number);
          setIsMultiple(isMult);
          setInernalValue(number);
        } else {
          setInernalValue('');
        }
      }
    },
    [validateMultiple]
  );

  return (
    <div
      role="button"
      tabIndex={0}
      onFocus={handleFocus}
      style={{
        minHeight: '38px',
        minWidth: '100%',
        paddingTop: '7px',
        border:
          (value === '' || value === null) && isIncompleteData === false
            ? '2px solid #c6dff1'
            : null,
        cursor: isEditable ? 'pointer' : 'inherit',
        backgroundColor:
          (value === '' || value === null) && isIncompleteData === false ? 'white' : null,
        textAlign: 'right',
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          if (typeof internalValue === 'number') {
            let isMult = validateMultiple(internalValue);

            if (!isMult) {
              setOutWithEnterKey(true);
              sendMessage();
            }
            saveData({
              value: internalValue,
              key: cellId,
              isValid: validateMultiple(internalValue),
            });
          } else {
            saveData({
              value: '',
              key: cellId,
              isValid: validateMultiple(internalValue),
            });
          }

          setIsEditing(false);
        }
        return null;
      }}
      onDoubleClick={handleOnDoubleClick}
      className={`${isEditable ? 'container-editable ' : ''}`}
    >
      {isEditing ? (
        <InputNumber
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          className={`editable-input ${
            typeof isMultiple !== 'undefined' && internalValue !== '' && !isMultiple
              ? 'input-error'
              : ''
          }`}
          onChange={(val) => handleIputOnChange(val)}
          defaultValue={value !== null ? value : ''}
          onBlur={handleOnBlur}
          precision={2}
          min={0}
          max={9999999999.99}
          parser={getFloatParser({ integerLength: 10, floatLength: 2, replaceParams: [/,/g, ''] })}
          onKeyDown={(e) => {
            if (
              e.keyCode === 69 ||
              e.keyCode === 73 ||
              e.keyCode === 189 ||
              e.keyCode === 40 ||
              e.keyCode === 107 ||
              e.keyCode === 109 ||
              e.keyCode === 187 ||
              e.keyCode === 186
            )
              e.preventDefault();
          }}
        />
      ) : (
        <span style={{ color: !isMultiple ? 'red' : '' }} title={formatNumber(internalValue)}>
          {formatPriceWithDecimals(internalValue, true)}
        </span>
      )}
    </div>
  );
}

export default connect(null, { saveVolume, changeCell })(EditableCell);
