import { useState, useEffect, useRef, useCallback } from 'react';
import useUser from 'hooks/useUser';

const initialState = {
  orchestrateFiltersChange: null,
  orchestrateFiltersReset: null,
  initialDisabledFilters: null,
  initialAllowRequest: null,
  initialRequestParamsSubscription: null,
};
function useFilters(national, topFilter, _query) {
  const topFilterOrigin = topFilter ? topFilter : 'TopFiltersNationalClients';
  const [stateModel, setStateModel] = useState(initialState);
  const wasRendered = useRef(false);
  let user = useUser();

  const importModelFrom = useCallback(
    (modelName) => {
      import(`Domains/TradeAgreements/components/${topFilterOrigin}/${modelName}`).then((file) => {
        setStateModel(file);
      });
    },
    [topFilterOrigin]
  );

  useEffect(() => {
    const getRolFilters = async () => {
      try {
        if (Object.keys(user).length > 0 && user.role) {
          const { roleId } = user?.role;
          switch (roleId) {
            case 1: //admin
              importModelFrom('adminSettings');
              break;
            case 2: //adminArea
              importModelFrom('adminAreaSettings');
              break;
            case 4: //GTV
              importModelFrom('GTVSettings');
              break;
            case 5: //RTV
              importModelFrom('rtevSettings');
              break;
            case 6: //LAP
              importModelFrom('LAPSettings');
              break;
            case 7: //UserConsultant
              importModelFrom('userConsultantSettings');
              break;
            case 8: //kam
              importModelFrom('KAMsettings');
              break;
            case 9: //LSO
              importModelFrom('GTVSettings');
              break;

            default:
              console.warn(
                '[Agroplan-UseFilters] There is not a settings file for this roleId >>',
                roleId
              );
              break;
          }
        }
      } catch (error) {
        if (wasRendered.current) setStateModel(initialState);
      }
    };
    if (!wasRendered.current) getRolFilters();
    return () => {
      wasRendered.current = false;
    };
  }, [user, importModelFrom, national]);
  return stateModel;
}

useFilters.propTypes = {};

useFilters.defaultProps = {};

export default useFilters;
