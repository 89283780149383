import request from 'services/request';
const AGREEMENTS_API = '/Agreements/Api/v1/SellIn/';
const AGREEMENTS_INDICATOR = `${AGREEMENTS_API}Indicators/`;

export async function getIndicator(indicatorName, params, cancelToken) {
  return request(`${AGREEMENTS_INDICATOR}${indicatorName}`, {
    method: 'GET',
    params,
    cancelToken,
  });
}
