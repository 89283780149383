import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { BSelect } from 'common/components';
import { connect } from 'react-redux';
import { yearsRequest } from 'RootModule/redux/actions/catalogs';
import { filtersEnum } from 'RootModule/models/enums/filtersEnum';

function Years(props) {
  const {
    years,
    yearsRequest: getYears,
    askFromService,
    onSetOnlyValue,
    onOptionsChange,
    sourceId,
    onSuccess,
    ...other
  } = props;

  useEffect(() => {
    if (askFromService) getYears({ sourceId, onSuccess });
  }, [getYears, askFromService, sourceId, onSuccess]);

  useEffect(() => {
    if (onOptionsChange) {
      onOptionsChange(years.data ?? []);
    }

    if (onSetOnlyValue && typeof onSetOnlyValue === 'function')
      onSetOnlyValue(years && years?.data?.length > 0 ? years?.data[0]?.value : '');
  }, [years, onSetOnlyValue, onOptionsChange]);

  return (
    <BSelect
      isLoading={years.isLoading}
      options={years && years.data && Object.keys(years.data).length > 0 ? years.data : []}
      getPopupContainer={(trigger) => trigger.parentNode}
      {...other}
    />
  );
}

Years.propTypes = {
  onSetOnlyValue: PropTypes.func,
  yearsRequest: PropTypes.func,
  years: PropTypes.any,
  askFromService: PropTypes.bool,
  onOptionsChange: PropTypes.func,
  sourceId: PropTypes.oneOf(['Agreements', 'Agreements-SellOut', 'DaysLeft', 'Sales', 'Fixed']),
  name: PropTypes.string,
  label: PropTypes.string,
};

Years.defaultProps = {
  initialValue: undefined,
  yearsRequest: null,
  years: { data: [], isLoading: false },
  askFromService: true,
  onSetOnlyValue: () => {
    return;
  },
  onOptionsChange: null,
  sourceId: 'DaysLeft',
  label: filtersEnum.years.label,
  name: filtersEnum.years.name,
};

function mapStateToProps(state) {
  return {
    years: state.APP?.catalogs?.years,
  };
}

export default connect(mapStateToProps, { yearsRequest })(Years);
