import DeleteProductModalSellOut from 'common/components/DeleteProductModal/DeleteProductModalSellOut';
import React from 'react';
import { connect } from 'react-redux';
import { deleteProductModalClose, deleteProductRequest } from '../redux/actions';

const DeleteTradeAgreementSellOutProductModal = (props) => {
  const {
    showModal,
    productIdToDelete,
    validityProcessYear,
    territoryId,
    clientId,
    deleteProductModalClose: closeModal,
    deleteProductRequest: deleteProduct,
  } = props;
  const handleDeleteProduct = () => {
    const params = {
      year: validityProcessYear,
      territoryId,
      clientId,
      productId: productIdToDelete,
    };
    deleteProduct({ params });
    closeModal();
  };
  return (
    <DeleteProductModalSellOut
      onOk={handleDeleteProduct}
      onCancel={closeModal}
      visible={showModal}
      okIsDisabled={false}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    showModal: state.TRADE_AGREEMENTS_SELLOUT.deleteProduct.showModal,
    productIdToDelete: state.TRADE_AGREEMENTS_SELLOUT.deleteProduct.productIdToDelete,
    clientId: state?.TRADE_AGREEMENTS_SELLOUT?.query?.clientId ?? false,
    validityProcessYear:
      state?.TRADE_AGREEMENTS_SELLOUT?.registerTrade?.daysLeft?.data?.VALIDITY_PROCESS?.year ??
      false,
    territoryId:
      state?.TRADE_AGREEMENTS_SELLOUT?.infoForAgreement?.territoryInfo?.territoryId ?? null,
  };
};
const ConnectedDeleteTradeAgreementSellOutProductModal = connect(mapStateToProps, {
  deleteProductRequest,
  deleteProductModalClose,
})(DeleteTradeAgreementSellOutProductModal);

export default ConnectedDeleteTradeAgreementSellOutProductModal;
