import React from 'react';
import BButton from 'common/components/BButton';
import { Modal } from 'antd';
import { connect } from 'react-redux';
import { sendToValidateRequest } from 'Domains/TradeAgreements/redux/actions/registerTrade';
import { RoleAccessControl } from 'Domains/TradeAgreements/common/accessControllers';

import PropTypes from 'prop-types';

function SendToValidate(props) {
  const {
    disabled,
    isSending,
    onSuccessValidate,
    clientIdQuery,
    territoryIdInfo,
    sendToValidateRequest: sendToValidate,
    pageType,
    label,
    year,
  } = props;

  const handleSendToValidate = () => {
    let params = {
      territoryId: territoryIdInfo,
      clientId: clientIdQuery,
      year,
    };

    Modal.confirm({
      title: 'Enviar acuerdo comercial',
      content: '¿Está seguro que desea enviar el acuerdo comercial a validación?',
      okText: 'Aceptar',
      cancelText: 'Cancelar',
      centered: true,
      okButtonProps: { type: 'info', style: { backgroundColor: '#003d81', color: '#ffffff' } },
      cancelButtonProps: {
        type: 'default',
        style: { backgroundColor: '#0078B3', color: '#ffffff' },
      },
      onOk() {
        sendToValidate({ params, onSuccess: onSuccessValidate });
      },
    });
  };
  const blockType = () => {
    return pageType === 'register' ? { block: true } : {};
  };

  return (
    <RoleAccessControl moduleKey="trade-agreements" page="send_to_validate_trade-agreements">
      <BButton
        className={pageType === 'register' ? `button-send transition` : ``}
        type="primary"
        disabled={disabled}
        {...blockType()}
        label={label}
        onClick={handleSendToValidate}
        style={{
          height: pageType === 'register' ? 40 : 'auto',
          marginRight: pageType === 'register' ? 'inherit' : '5px',
        }}
        loading={isSending}
      />
    </RoleAccessControl>
  );
}

function mapStateToProps(state) {
  return {
    isSending: state?.TRADE_AGREEMENTS?.registerTrade?.validation?.sendingToValidation ?? false,
    clientIdQuery: state?.TRADE_AGREEMENTS?.query?.clientId,
    territoryIdInfo: state?.TRADE_AGREEMENTS?.infoForAgreement?.territoryInfo?.territoryId,
    year: state?.TRADE_AGREEMENTS?.query?.year,
  };
}

export default connect(mapStateToProps, { sendToValidateRequest })(SendToValidate);

SendToValidate.propTypes = {
  pageType: PropTypes.oneOf(['consult', 'register']),
  label: PropTypes.string,
};

SendToValidate.defaultProps = {
  pageType: 'register',
  label: 'Enviar',
};
