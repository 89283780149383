import React, { useEffect, useCallback } from 'react';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';
import BTable from '../../../common/BTable';
import OnlyBattery from 'Domains/SalesChallengeRefactor/components/indicators/OnlyBattery';
import BCard from '../../../common/BCards';
import BSwitch from '../../../common/BSwitch';
import { formatToPrice } from '../../../utils';
import { toggleTypeValue } from '../../../redux/actions/query';
import { infoRequest } from 'Domains/TradeAgreements/redux/actions/info';
import useWasRendered from 'hooks/useWasRendered';

import './style.css';
const quartersInfo = [
  {
    title: 'Q1',
    dataIndex: 'q1',
    key: 'q1',
    render: (text) => formatToPrice(text),
    align: 'center',
  },
  {
    title: 'Q2',
    dataIndex: 'q2',
    key: 'q2',
    render: (text) => formatToPrice(text),
    align: 'center',
  },
  {
    title: 'Q3',
    dataIndex: 'q3',
    key: 'q3',
    render: (text) => formatToPrice(text),
    align: 'center',
  },
  {
    title: 'Q4',
    dataIndex: 'q4',
    key: 'q4',
    render: (text) => formatToPrice(text),
    align: 'center',
  },
];

function FirstRowRight(props) {
  const {
    quartySales,
    netQuarters,
    toggleTypeValue: setTypeValue,
    showBruteValue,
    totalSales,
    infoRequest: getInfo,
    productId,
    year,
    clientId,
    territoryId,
    policyTypeId,
    Portafolio,
    reach,
    currentAgreement,
    isSaving,
    bruteQuarters,
  } = props;
  const { wasRendered, setWasRendered } = useWasRendered();

  useEffect(() => {
    if (!wasRendered.current) {
      getInfo({
        params: {
          productId,
          year,
          clientId,
          territoryId,
          policyTypeId,
          Portafolio,
        },
        name: 'Product',
      });
      setWasRendered(true);
    }
  }, [
    setWasRendered,
    wasRendered,
    getInfo,
    productId,
    year,
    clientId,
    territoryId,
    policyTypeId,
    Portafolio,
  ]);

  const handleToggle = (checked) => {
    setTypeValue(checked);
  };

  const handleTotalClient = useCallback(() => {
    if (currentAgreement) {
      if (showBruteValue) {
        return formatToPrice(currentAgreement?.data?.clientBruteAmount);
      } else {
        return formatToPrice(currentAgreement?.data?.clientNetAmount);
      }
    } else {
      return '';
    }
  }, [showBruteValue, currentAgreement]);

  const prepareQuarters = useCallback(() => {
    let aux = {};
    //esto funciona en la consulta
    if (netQuarters && bruteQuarters) {
      if (showBruteValue) {
        if (bruteQuarters.length > 0) {
          bruteQuarters.map((quarty, index) => {
            aux = { ...aux, [`q${index + 1}`]: quarty };
            return null;
          });
        }
      } else {
        if (netQuarters.length > 0) {
          netQuarters.map((quarty, index) => {
            aux = { ...aux, [`q${index + 1}`]: quarty };
            return null;
          });
        }
      }
    }
    //esto funciona en la edición dentro de una celda en la tabla
    // if (quartySales && quartySales.length > 0) {
    //   quartySales.map((quarty, index) => {
    //     aux = { ...aux, [`q${index + 1}`]: quarty };
    //     return null;
    //   });
    // }
    return [aux];
  }, [bruteQuarters, netQuarters, showBruteValue]);

  return (
    <Row className="first-row-agreements">
      <Col {...{ lg: 9, md: 12, sm: 12, xs: 24 }}>
        <BCard
          align="center"
          headerType="info"
          loading={quartySales?.isLoading || isSaving}
          title={showBruteValue ? 'Venta trimestral bruta (USD)' : 'Venta trimestral neta (USD)'}
          className="bquarty-sales"
        >
          <BTable
            bordered={true}
            loading={currentAgreement?.isLoading || isSaving}
            pagination={false}
            noDataFeedback={false}
            columns={quartersInfo}
            dataSource={prepareQuarters()}
          />
        </BCard>
      </Col>
      <Col {...{ lg: 6, md: 12, sm: 12, xs: 24 }}>
        <BCard
          align="center"
          headerType="info"
          loading={totalSales?.isLoading || isSaving}
          title={showBruteValue ? 'Total cliente bruto (USD)' : 'Total cliente neto (USD)'}
          className="card-total-sale"
        >
          <h4 style={{ paddingTop: '15px' }}>{handleTotalClient()}</h4>
        </BCard>
      </Col>
      <Col {...{ lg: 4, md: 12, sm: 12, xs: 24 }}>
        <BCard align="center" headerType="info" title="Tipo de valor" className="card-type-value">
          <span>
            Bruto
            <BSwitch
              onChange={handleToggle}
              style={{ diplay: 'inline-block' }}
              name="typeValue"
            />{' '}
            Neto
          </span>
        </BCard>
      </Col>
      <Col {...{ lg: 5, md: 12, sm: 12, xs: 24 }}>
        <BCard
          align="center"
          headerType="info"
          title="Acuerdo vs Desafío"
          className="card-battery"
          loading={reach?.isLoading || isSaving}
        >
          <OnlyBattery value={reach?.data} style={{ marginTop: reach?.data ? '19px' : '35px' }} />
        </BCard>
      </Col>
    </Row>
  );
}
function mapStateToProps(state) {
  return {
    showBruteValue: state?.TRADE_AGREEMENTS?.query?.showBruteValue,
    quartySales: state?.TRADE_AGREEMENTS?.registerTrade?.currentAgreement?.data?.quarters,
    netQuarters: state?.TRADE_AGREEMENTS?.registerTrade?.currentAgreement?.data?.netQuarters,
    bruteQuarters: state?.TRADE_AGREEMENTS?.registerTrade?.currentAgreement?.data?.bruteQuarters,
    totalSales: state?.TRADE_AGREEMENTS?.registerTrade?.currentAgreement?.data,
    batteryInfo: state?.TRADE_AGREEMENTS?.views?.indicators?.batteryInfo,
    productId: state?.TRADE_AGREEMENTS?.query?.productId ?? null,
    year: state?.TRADE_AGREEMENTS?.query?.year ?? null,
    clientId: state?.TRADE_AGREEMENTS?.query?.clientId ?? null,
    territoryId: state?.TRADE_AGREEMENTS?.infoForAgreement?.territoryInfo?.territoryId ?? null,
    policyTypeId: state?.TRADE_AGREEMENTS?.query?.PolicyTypeId ?? null,
    Portafolio: state?.TRADE_AGREEMENTS?.query?.Portafolio ?? null,
    isSaving: state?.TRADE_AGREEMENTS?.registerTrade?.postSaveAgreement?.isLoading ?? false,
  };
}

export default connect(mapStateToProps, { toggleTypeValue, infoRequest })(FirstRowRight);
