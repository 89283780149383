/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useContext, useEffect, useMemo } from 'react';
import { Row, Col, Form } from 'antd';
import {
  Years,
  Region,
  Clients,
  Territory,
  BusinessLine,
  SubRegion,
  // Zones,
} from 'RootModule/catalogs/';
import { connect } from 'react-redux';

import {
  setValidatorsQuery,
  requestValidatorsDisabledDepending,
} from '../redux-saga/actions/filters';
import { getParentFiltersQuery } from 'Domains/Validations/utils/filters';
import { layoutContext } from 'context';
import {
  validatorsFiltersSalesChallengeOrder,
  validatorsFiltersTradeAgreementOrder,
} from '../consts';

const Filters = ({
  setValidatorsQuery: setQuery,
  requestValidatorsDisabledDepending: getDisabledDepending,
  query,
  validationsFields: validationType,
  defaultYear,
  disabled,
  lineId,
  regionId,
  subRegionId,
  // disabledDepending,
  // depending,
  // clientId,
  // territoryId,
  // zoneId,
}) => {
  const [form] = Form.useForm();
  const { onChangeActiveComponent } = useContext(layoutContext);

  const filtersOrder =
    validationType === 'tradeAgreement' || validationType === 'tradeAgreementSellOut'
      ? validatorsFiltersTradeAgreementOrder
      : validatorsFiltersSalesChallengeOrder;

  const businessLineDisabled = disabled.includes('lineId');
  const subRegionDisabled = disabled.includes('subRegionId');
  const territoryDisabled = disabled.includes('territoryId');
  const zonesDisabled = disabled.includes('zoneId');
  const clientsDisabled = validationType === 'All' ? zonesDisabled : disabled.includes('clientId');
  const sourceId = 'Fixed';

  const cleanDependingFilters = (changedValue, allValues, validatorsOrder) => {
    const valuePosition = Object.keys(validatorsOrder).indexOf(Object.keys(changedValue)[0]);
    const restOfItems = Object.keys(validatorsOrder).slice(valuePosition + 1);

    if (!changedValue.clientId) {
      restOfItems.forEach((item) => {
        allValues[item] = undefined;
      });
    }
    form.setFieldsValue(allValues);
    return allValues;
  };

  const setActivecomponent = () => {
    onChangeActiveComponent('validations');
  };

  const releaseActivecomponent = () => {
    onChangeActiveComponent(undefined);
  };

  const onValuesChangeForm = (changedValue, allValues) => {
    const cleanedAllValues = cleanDependingFilters(changedValue, allValues, filtersOrder);

    setQuery(cleanedAllValues);
    getDisabledDepending(validationType);
    setActivecomponent();
  };
  useEffect(() => {
    form.setFieldsValue({
      year: defaultYear,
    });
  }, [defaultYear]);

  const resetFilters = () => {
    form.resetFields();
    setQuery(form.getFieldsValue());
    getDisabledDepending(validationType);
    releaseActivecomponent();
  };
  //reset filters when validationType changes
  useEffect(() => {
    resetFilters();
  }, [validationType]);
  /// years none

  /// region none

  /// BusinessLine TA none SC regionId=1
  const businessLinesParams = useMemo(
    () => getParentFiltersQuery('lineId', query, validationType),
    [regionId]
  );
  ///Subregions TA regionId=1&lineId=1  SC regionId=1&lineId=1
  const subRegionsParams = useMemo(
    () => getParentFiltersQuery('subRegionId', query, validationType),
    [regionId, lineId]
  );
  //Territories TA lineId=1&subRegionId=1&regionId=1 SC lineId=1&subRegionId=1
  const territoriesParams = useMemo(
    () => getParentFiltersQuery('territoryId', query, validationType),
    [regionId, lineId, subRegionId]
  );
  //Zones TA none SC territoryId=1&year=2024
  // const zonesParams = useMemo(
  //   () => getParentFiltersQuery('zoneId', query, validationType),
  //   [territoryId.length]
  // );
  ///Clients TA owner=true&territoryId=2&year=2024 SC forRegister=false&year=2024&zoneId=1
  const clientsParams = useMemo(
    () => getParentFiltersQuery('clientId', query, validationType),
    [regionId, lineId]
  );

  return (
    <>
      <section className="validations__filters">
        <Row>
          <Suspense fallback={<div>Loading...</div>}>
            <Form
              style={{ flex: 1 }}
              form={form}
              onValuesChange={onValuesChangeForm}
              initialValues={{ year: defaultYear }}
            >
              <Row>
                <Col span={3}>
                  <Years askFromService sourceId={sourceId} name={filtersOrder.year} disabled />
                </Col>
                <Col span={4}>
                  <Region disabled={false} name={filtersOrder.regionId} sourceId={sourceId} />
                </Col>
                <Col span={4}>
                  <BusinessLine
                    disabled={businessLineDisabled}
                    askFromService={!businessLineDisabled}
                    name={filtersOrder.lineId}
                    sourceId={sourceId}
                    requestParams={businessLinesParams}
                  />
                </Col>
                {validationType !== 'salesChallenge' && (
                  <Col span={5}>
                    <Clients
                      disabled={clientsDisabled}
                      askFromService={!clientsDisabled}
                      name={filtersOrder.clientId}
                      sourceId={'Validators'}
                      requestParams={clientsParams}
                      mode="multiple"
                      maxTagCount={1}
                    />
                  </Col>
                )}
                <Col span={4}>
                  <SubRegion
                    disabled={subRegionDisabled}
                    askFromService={!subRegionDisabled}
                    name={filtersOrder.subRegionId}
                    sourceId={sourceId}
                    requestParams={subRegionsParams}
                  />
                </Col>

                <Col span={4}>
                  <Territory
                    disabled={territoryDisabled}
                    askFromService={!territoryDisabled}
                    name={filtersOrder.territoryId}
                    sourceId={'Validators'}
                    requestParams={territoriesParams}
                    {...(validationType === 'salesChallenge' && { mode: 'multiple' })}
                  />
                </Col>
              </Row>
              <Row>
                {/* <Col span={1}>
                  <ResetButton
                    title="Limpiar filtros"
                    onClick={resetFilters}
                    label="Limpiar filtros"
                  />
                </Col> */}
              </Row>
            </Form>
          </Suspense>
        </Row>
      </section>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    query: state?.VALIDATIONS?.filters?.query,
    zoneId: state?.VALIDATIONS?.filters?.query?.zoneId ?? '',
    territoryId: state?.VALIDATIONS?.filters?.query?.territoryId ?? '',
    subRegionId: state?.VALIDATIONS?.filters?.query?.subRegionId ?? '',
    regionId: state?.VALIDATIONS?.filters?.query?.regionId ?? '',
    lineId: state?.VALIDATIONS?.filters?.query?.lineId ?? '',
    clientId: state?.VALIDATIONS?.filters?.query?.clientId ?? '',
    depending: state?.VALIDATIONS?.filters?.disabledDepending?.depending ?? '',
    disabled: state?.VALIDATIONS?.filters?.disabledDepending?.disabled ?? [],
    defaultYear: state?.APP?.catalogs?.years?.data[0]?.value ?? '',
    validationsFields: state?.VALIDATIONS?.validationFieldsReducer?.validationFields ?? '',
  };
};
const FiltersConnected = connect(mapStateToProps, {
  setValidatorsQuery,
  requestValidatorsDisabledDepending,
})(Filters);

export default FiltersConnected;
