/* eslint-disable no-empty-function */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import ValidationHeader from 'Domains/Validations/components/ValidationHeader';
import FiltersGroup from 'Domains/Validations/components/Filters';
import '../css/style.css';
import { connect } from 'react-redux';

import { DynamicModuleLoader } from 'redux-dynamic-modules';
import getRootModule from 'RootModule';
import getValidationsModule from '../getValidationsModule';
import { SalesChallengeList } from '../components/SalesChallengeList';
import { SellInList } from '../components/SellInList';
import { SellOutList } from '../components/SellOutList';

const Validations = (props) => {
  const { clientId, territoryId, validationsFields } = props;

  const canModifyTradeAgreement = typeof territoryId === 'number' && clientId.length > 0;
  const isVisibleTradeAgreement =
    validationsFields === 'tradeAgreement' && canModifyTradeAgreement && territoryId > 0;
  const isVisibleTradeAgreementSellOut =
    validationsFields === 'tradeAgreementSellOut' && canModifyTradeAgreement && territoryId > 0;
  const isVisibleSalesChallenge = validationsFields === 'salesChallenge' && territoryId.length > 0;

  return (
    <>
      <ValidationHeader />
      <FiltersGroup />
      <section className="validators__holder">
        {isVisibleSalesChallenge && <SalesChallengeList />}
        {isVisibleTradeAgreement && <SellInList />}
        {isVisibleTradeAgreementSellOut && <SellOutList />}
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    validationsFields: state?.VALIDATIONS?.validationFieldsReducer?.validationFields ?? '',
    clientId: state?.VALIDATIONS?.filters?.query?.clientId ?? [],
    territoryId: state?.VALIDATIONS?.filters?.query?.territoryId ?? [],
  };
};

const ReduxValidations = connect(mapStateToProps, null)(Validations);

const SagasValidations = () => {
  return (
    <DynamicModuleLoader modules={[getRootModule(), getValidationsModule()]}>
      <ReduxValidations />
    </DynamicModuleLoader>
  );
};

export default SagasValidations;
