import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import BSelect from 'common/components/BSelect';
import { connect } from 'react-redux';
import { areasRequest } from 'RootModule/redux/actions/catalogs';

function Areas(props) {
  const {
    areas,
    areasRequest: getZones,
    askFromService,
    requestParams,
    sourceId,
    onSuccess,
    mode,
    ...other
  } = props;

  useEffect(() => {
    if (askFromService) getZones({ requestParams, sourceId, onSuccess, mode });
  }, [getZones, askFromService, requestParams, sourceId, onSuccess, mode]);

  return (
    <BSelect
      disabled={areas.isLoading}
      isLoading={areas.isLoading}
      options={areas.data}
      getPopupContainer={(trigger) => trigger.parentNode}
      mode={mode}
      {...other}
    />
  );
}

Areas.propTypes = {
  askFromService: PropTypes.bool,
  initialValue: PropTypes.any,
  label: PropTypes.string,
  name: PropTypes.string,
  requestParams: PropTypes.object,
  sourceId: PropTypes.oneOf(['Agreements', 'Agreements-SellOut', 'Sales']),
  areas: PropTypes.object,
  areasRequest: PropTypes.func,
};

Areas.defaultProps = {
  askFromService: true,
  initialValue: undefined,
  label: 'Area',
  name: 'area',
  requestParams: {},
  sourceId: 'Sales',
  areas: { data: [], isLoading: false },
  areasRequest: null,
};

function mapStateToProps(state) {
  return {
    areas: state.APP?.catalogs?.areas,
  };
}

export default connect(mapStateToProps, { areasRequest })(Areas);
