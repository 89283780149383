import { takeEvery, call, put, cancelled } from 'redux-saga/effects';

import {
  PRODUCT_UPDATE_SET_FORM_VALUES,
  PRODUCT_UPDATE_REQUEST_PRODUCT_DATA,
  PRODUCT_UPDATE_REQUEST_STATUS_UPDATE,
  PRODUCT_UPDATE_REQUEST_PRODUCT_UPDATE,
  productUpdateSetFormValues,
  productUpdateClearFormValues,
  productUpdateSetProductData,
  productUpdateClearProductData,
  productUpdateSetStatusUpdate,
  productUpdateClearStatusUpdate,
  productUpdateSetProductUpdate,
  productUpdateClearProductUpdate,
} from '../actions';

import instance from 'services/request';

import { responseOK } from 'Domains/SalesChallengeRefactor/utils';

import { messageSuccess } from 'utils/messages';

import {
  getByProductId,
  updateProductStatus,
  updateProduct,
} from 'Products/ProductUpdate/services';

function* workProductUpdateSetFormValues(action) {
  try {
    yield productUpdateSetFormValues(action.payload);
  } catch (error) {
    yield productUpdateClearFormValues();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield productUpdateClearFormValues();
    }
  }
}

function* workProductUpdateRequestProductData(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getByProductId, {
      cancelToken: source.token,
      id: action.payload,
    });
    if (responseOK(response)) {
      yield put(productUpdateSetProductData(response.data));
    } else {
      yield put(productUpdateClearProductData());
    }
  } catch (error) {
    yield productUpdateClearProductData();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield productUpdateClearProductData();
      source.cancel('cancelled Request');
    }
  }
}

function* workProductUpdateRequestStatusUpdate({ payload }) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { data, history, onSuccess, onLoading } = payload;
    const response = yield call(updateProductStatus, {
      cancelToken: source.token,
      data,
    });
    if (responseOK(response)) {
      yield put(productUpdateSetStatusUpdate(response.data));
      setTimeout(
        () => {
          messageSuccess(response.message);
        },
        data.enabled ? 500 : 1000
      );
      setTimeout(() => {
        if (history && data.enabled === false) {
          history.push('/catalog/products');
        }
      }, 500);
      if (onSuccess) {
        yield onSuccess();
      }
      if (onLoading) {
        yield onLoading();
      }
    } else {
      yield put(productUpdateClearStatusUpdate());
    }
  } catch (error) {
    yield productUpdateClearStatusUpdate();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield productUpdateClearStatusUpdate();
      source.cancel('cancelled Request');
    }
  }
}

function* workProductUpdateRequestProductUpdate({ payload }) {
  const { data, history } = payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(updateProduct, {
      cancelToken: source.token,
      data,
    });
    if (responseOK(response)) {
      messageSuccess(response.message);
      yield put(productUpdateSetProductUpdate(response.data));
      setTimeout(() => {
        if (history) history.push('/catalog/products');
      }, 500);
    } else {
      yield put(productUpdateClearProductUpdate());
    }
  } catch (error) {
    yield productUpdateClearProductUpdate();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield productUpdateClearProductUpdate();
      source.cancel('cancelled Request');
    }
  }
}

export function* watchProductUpdateSetFormValues() {
  yield takeEvery(PRODUCT_UPDATE_SET_FORM_VALUES, workProductUpdateSetFormValues);
}

export function* watchProductUpdateRequestProductData() {
  yield takeEvery(PRODUCT_UPDATE_REQUEST_PRODUCT_DATA, workProductUpdateRequestProductData);
}

export function* watchProductUpdateRequestStatusUpdate() {
  yield takeEvery(PRODUCT_UPDATE_REQUEST_STATUS_UPDATE, workProductUpdateRequestStatusUpdate);
}

export function* watchProductUpdateRequestProductUpdate() {
  yield takeEvery(PRODUCT_UPDATE_REQUEST_PRODUCT_UPDATE, workProductUpdateRequestProductUpdate);
}
