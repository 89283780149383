/* eslint-disable array-callback-return */
import { takeEvery, put, call, all, cancelled } from 'redux-saga/effects';
import { messageError } from 'utils/messages';
import { saveAs } from 'file-saver';

import {
  GET_TypeElement_REQUEST,
  getTypeElementSuccess,
  getTypeElementClean,
  PUT_TypeElement_REQUEST,
  // putTypeElementSuccess,
  putTypeElementClean,
  POST_TypeElement_REQUEST,
  POST_DisplayExcel_REQUEST,
  // postTypeElementSuccess,
  postTypeElementClean,
  postDisplayExcelDataRequest,
  DELETE_TypeElement_REQUEST,
  deleteTypeElementSuccess,
  deleteTypeElementClean,
  GET_EXCEL_REQUEST,
  getExcelSuccess,
  getExcelClean,
  postValidateFilesClean,
} from '../actions';
import instance from 'services/request';
import {
  postTypeElement,
  putTypeElement,
  getTypeElement,
  getExcel,
  deleteTypeElement,
  postValidateFiles,
} from '../../services/MapsModalsServices';
import { responseOK } from 'Domains/SalesChallengeRefactor/utils';

import { openNotification } from 'common';
let zip = require('jszip')();

///GET_Excel
function* workExcelRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { TypeElement, params, element } = payload;
    let response;
    let type = '';
    switch (TypeElement) {
      case 'Territory':
        type = 'Territorio';
        break;
      case 'Zone':
        type = 'Zona';
        break;
      case 'Country':
        type = 'País';
        break;
      case 'Region':
        type = 'Región';
        break;
      case 'BusinessLine':
        type = 'Línea de negocio';
        break;
      case 'Subregion':
        type = 'Subregión';
        break;
      default:
        type = '';
        break;
    }

    if (TypeElement === 'BusinessLine') {
      response = yield call(getExcel, {
        TypeElement: 'Line',
        params,
      });
    } else {
      response = yield call(getExcel, {
        TypeElement,
        params,
      });
    }
    if (responseOK(response)) {
      const { coordinates, names } = response.data;

      for (let index = 0; index < coordinates.length; index++) {
        zip.file(`${type}-${element}-${names[index]}.csv`, coordinates[index], { base64: true });
      }
      zip
        .generateAsync({ type: 'blob' })
        .then(function (content) {
          saveAs(content, `${type}-${element}.zip`);
          openNotification(
            'success',
            'La descarga de las coordenadas geográficas se realizó correctamente.'
          );
        })
        .catch((error) => {
          openNotification(
            'error',
            'El sistema no pudo realizar la operación, inténtelo de nuevo mas tarde'
          );
        });

      zip = require('jszip')();
      /*  } else {
        const linkSource = `${'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64'},${
          coordinates[0]
        }`;
        const downloadLink = document.createElement('a');
        const fileName = `${type}-${element}-${names[0]}.csv`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        openNotification(
          'success',
          'La descarga de las coordenadas geográficas se realizó correctamente.'
        );
      } */

      yield put(getExcelSuccess(response));
    }
  } catch (error) {
    yield put(getExcelClean());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchExcelRequest() {
  yield all([takeEvery(GET_EXCEL_REQUEST, workExcelRequest)]);
}

///POST_TypeElement
function* workPOSTTypeElementRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const {
      fetchBusinessStructure,
      handleCancel,
      TypeElement,
      structureId,
      color,
      csvFile,
      namesCsvFiles,
    } = payload;
    let response;
    if (TypeElement === 'BusinessLine') {
      response = yield call(postTypeElement, {
        TypeElement: 'Line',
        data: {
          structureId,
          color,
          csvFiles: csvFile,
          namesCsvFiles,
        },
      });
    } else {
      response = yield call(postTypeElement, {
        TypeElement,
        data: {
          structureId,
          color,
          csvFiles: csvFile,
          namesCsvFiles,
        },
      });
    }
    if (responseOK(response)) {
      yield openNotification('success', response.message);
      handleCancel();
      setTimeout(() => {
        fetchBusinessStructure();
      }, 300);
    }
  } catch (error) {
    yield openNotification(
      'error',
      'El sistema no pudo realizar la operación, inténtelo de nuevo mas tarde'
    );
    yield put(postTypeElementClean());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchPOSTTypeElementRequest() {
  yield all([takeEvery(POST_TypeElement_REQUEST, workPOSTTypeElementRequest)]);
}

///POST_DisplayExcel
function* workPOSTDisplayExcelRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const { files, saveDisabledButton, structureId, color, csvFile, handleError, namesCsvFiles } =
    payload;
  try {
    // eslint-disable-next-line no-inner-declarations
    function filesToJson(file) {
      return new Promise(function (resolve, reject) {
        let fr = new FileReader();

        fr.onload = function () {
          let coordinates = csvToArray(fr.result);
          return resolve(coordinates);
        };

        fr.onerror = function () {
          reject(fr);
        };

        fr.readAsText(file);
      });
    }

    var csvToArray = function CSVToArray(data) {
      return data
        .slice(data.indexOf('\n') + 1)
        .split('\n')
        .map(function (v) {
          return v.split(',').slice(0, 2);
        });
    };

    let excelCoordinates = [];

    for (let i = 0; i < files.length; i++) {
      const fileCoordinates = yield filesToJson(files[i]);
      excelCoordinates.push(fileCoordinates);
    }

    ///Message Carga archivos vacíos
    const message = excelCoordinates.map((item) => {
      if (item[1] === undefined || item[1][0] === '') {
        return true;
      }
    });

    if (message[0]) {
      saveDisabledButton();
      yield put(
        messageError(
          'Las coordenadas del polígono deben ser validas, registrarse separadas por "," en dos columnas y no se permite realizar la carga de archivos vacíos'
        )
      );
    } else {
      let response;
      response = yield call(postValidateFiles, {
        data: {
          structureId,
          color,
          csvFiles: csvFile,
          namesCsvFiles,
        },
      });

      if (responseOK(response)) {
        yield put(postDisplayExcelDataRequest(response.data.coordinates));
      }
    }
  } catch (error) {
    handleError();
    yield put(postValidateFilesClean());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}
export function* watchPOSTDisplayExcelRequest() {
  yield all([takeEvery(POST_DisplayExcel_REQUEST, workPOSTDisplayExcelRequest)]);
}

///PUT_TypeElement
function* workPUTTypeElementRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const {
      fetchBusinessStructure,
      handleCancel,
      TypeElement,
      structureId,
      color,
      csvFile,
      namesCsvFiles,
    } = payload;

    let response;
    if (TypeElement === 'BusinessLine') {
      response = yield call(putTypeElement, {
        TypeElement: 'Line',
        data: {
          structureId,
          color,
          csvFiles: csvFile,
          namesCsvFiles,
        },
      });
    } else {
      response = yield call(putTypeElement, {
        TypeElement,
        data: {
          structureId,
          color,
          csvFiles: csvFile,
          namesCsvFiles,
        },
      });
    }
    if (responseOK(response)) {
      yield openNotification('success', response.message);
      handleCancel();
      setTimeout(() => {
        fetchBusinessStructure();
      }, 300);
    }
  } catch (error) {
    yield openNotification(
      'error',
      'El sistema no pudo realizar la operación, inténtelo de nuevo mas tarde'
    );
    yield put(putTypeElementClean());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchPUTTypeElementRequest() {
  yield all([takeEvery(PUT_TypeElement_REQUEST, workPUTTypeElementRequest)]);
}
///GET_TypeElement
function* workTypeElementRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { TypeElement, params } = payload;
    let response;
    if (TypeElement === 'BusinessLine') {
      response = yield call(getTypeElement, {
        TypeElement: 'Line',
        params,
      });
    } else {
      response = yield call(getTypeElement, {
        TypeElement,
        params,
      });
    }
    if (responseOK(response)) {
      yield put(getTypeElementSuccess(response));
    }
  } catch (error) {
    yield put(getTypeElementClean());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchTypeElementRequest() {
  yield all([takeEvery(GET_TypeElement_REQUEST, workTypeElementRequest)]);
}

///DELETE_TypeElement
function* workDeleteTypeElementRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { TypeElement, params, handleCancel, fetchBusinessStructure } = payload;
    let response;
    if (TypeElement === 'BusinessLine') {
      response = yield call(deleteTypeElement, {
        TypeElement: 'Line',
        params,
      });
    } else {
      response = yield call(deleteTypeElement, {
        TypeElement,
        params,
      });
    }
    if (responseOK(response)) {
      handleCancel();
      setTimeout(() => {
        fetchBusinessStructure();
      }, 300);
      yield getTypeElementClean();
      yield put(deleteTypeElementSuccess(response));
      yield openNotification('success', response.message);
      yield put(getTypeElementClean());
      setTimeout(() => {
        fetchBusinessStructure();
      }, 300);
    }
  } catch (error) {
    yield put(deleteTypeElementClean());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchDeleteTypeElementRequest() {
  yield all([takeEvery(DELETE_TypeElement_REQUEST, workDeleteTypeElementRequest)]);
}
