import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { BSelect } from 'common/components';
import { connect } from 'react-redux';
import { currencyRequest } from 'RootModule/redux/actions/catalogs';
import { filtersEnum } from 'RootModule/models/enums/filtersEnum';

function Currency(props) {
  const {
    currency,
    currencyRequest: getCurrency,
    askFromService,
    name,
    requestParams,
    sourceId,
    onOptionsChange,
    label,
    onSuccess,
    requestMode,
    onChange,
    ...other
  } = props;
  const workedData = (currency.data ?? []).map((item) => {
    const { id, value } = item;

    return {
      id: id,
      key: id,
      value: value,
    };
  });

  useEffect(() => {
    if (askFromService) {
      getCurrency({ requestParams, sourceId, onSuccess, mode: requestMode });
    }
  }, [getCurrency, askFromService, requestParams, sourceId, onSuccess, requestMode]);

  useEffect(() => {
    if (onOptionsChange) {
      onOptionsChange(currency.data ?? []);
    }
  }, [currency, onOptionsChange]);

  const handleOnChange = (value) => {
    if (onChange) onChange(value, { options: currency?.data, name: props.name });
  };

  return (
    <BSelect
      isLoading={currency.isLoading}
      disabled={currency.isLoading}
      name={name}
      options={workedData}
      label={label}
      getPopupContainer={(trigger) => trigger.parentNode}
      onChange={handleOnChange}
      {...other}
    />
  );
}

Currency.propTypes = {
  currencyRequest: PropTypes.func,
  // currency: PropTypes.object,
  askFromService: PropTypes.bool,
  requestParams: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  sourceId: PropTypes.oneOf([
    'Agreements',
    'Agreements-SellOut',
    'Sales',
    'Catalogs',
    'Subregion',
    '',
    null,
  ]),
};

Currency.defaultProps = {
  currencyRequest: null,
  currency: { data: [], isLoading: false },
  askFromService: true,
  label: filtersEnum.subRegion.label,
  name: filtersEnum.subRegion.name,
  requestParams: {},
  sourceId: '',
};

function mapStateToProps(state) {
  return {
    currency: state.APP?.catalogs?.currency ?? [],
  };
}

export default connect(mapStateToProps, { currencyRequest })(Currency);
