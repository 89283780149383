import {
  REJECT_TRADE_AGREEMENT_REQUEST,
  rejectTradeAgreementSuccess,
  rejectTradeAgreementFail,
} from 'Domains/TradeAgreements/redux/actions/rejectTradeAgreement';
import { takeLatest, cancelled, put, call, select } from 'redux-saga/effects';
import { responseOK } from 'Domains/SalesChallengeRefactor/utils';
import instance from 'services/request';
import { openNotification } from 'common/misc/openNotification';
import { indicatorsRequest } from '../actions/indicators';
import { rejectTradeAgreementByAdmin } from 'Domains/TradeAgreements/services/agreement';

function* workRejectTradeAgreementRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const state = yield select();
  const { territoryId, clientId, year } = state?.TRADE_AGREEMENTS?.query;

  const indicatorsParams = {
    territoryId,
    clientId,
    year,
  };
  try {
    const { payload } = action;
    const { params, afterDeleting } = payload;

    const response = yield call(rejectTradeAgreementByAdmin, { params, cancelToken: source.token });

    if (responseOK(response)) {
      setTimeout(() => {
        openNotification('success', response.message);
      }, 500);

      yield put(indicatorsRequest({ params: indicatorsParams, name: 'client' }));

      yield put(rejectTradeAgreementSuccess(response.data));
    } else {
      yield put(rejectTradeAgreementFail());
    }

    if (afterDeleting) {
      afterDeleting();
    }
  } catch (error) {
    yield put(rejectTradeAgreementFail());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchRejectTradeAgreementRequest() {
  yield takeLatest(REJECT_TRADE_AGREEMENT_REQUEST, workRejectTradeAgreementRequest);
}
