import { takeLatest, takeEvery, put, call, cancelled, select, delay } from 'redux-saga/effects';
import instance from 'services/request';
import {
  GET_DAYS_LEFT_REQUEST,
  getDayLeftFail,
  getDayLeftSuccess,
  GET_PRODUCTS_REQUEST,
  getProductsSuccess,
  getProductsFail,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_PRICES_REQUEST,
  getProductsPricesSuccess,
  getProductsPricesFail,
  GET_AGREEMENT_REQUEST,
  getAgreementFail,
  getAgreementSuccess,
  GET_REACH_REQUEST,
  getReachSuccess,
  getReachFail,
  SEND_TO_VALIDATE_REQUEST,
  sendToValidateSuccess,
  sendToValidateFail,
  areVolumesValids,
  SAVE_AGREEMENT_REQUEST,
  saveAgreenmentFail,
  saveAgreenmentSuccess,
  TRADE_COMMENTS_REQUEST,
  tradeCommentsSuccess,
  tradeCommentsFail,
} from '../actions/registerTrade';
import { saveTable } from '../actions/table';
import { indicatorsSet } from '../actions/indicators';
import {
  getProducts,
  getProductsPrices,
  getAgreement,
  getReach,
  postAgreements,
  sendTovalidate,
  getAgreementsComments,
} from '../../services/registerTrade';
import { getDaysLeft } from '../../services/catalogs';
import { responseOK } from '../../utils';
import { generateRowsSalesChallenge as processDataToTable } from 'Domains/SalesChallengeRefactor/helpers/helperEditable';
import { checkIfVolumesAreValids } from 'Domains/SalesChallengeRefactor/redux/sagas/table';
import { validateTableRequest as validateTableMandatory } from 'Domains/TradeAgreementsSellOut/redux/actions/table';

import {
  UPDATE_TABLE_INPUTS,
  updateTableDisplays,
  setMultipleTableDisplays,
  UPDATE_PRICE,
} from '../actions/tableActions';

function* workGetDaysLeftRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;

    const { params } = payload;

    const response = yield call(getDaysLeft, { params, cancelToken: source.token });

    if (response) {
      if (typeof response !== 'undefined') {
        const { processId } = params;
        let key = '';
        switch (processId) {
          case 7:
            key = 'GENERATE_TRADE_AGREEMENT';
            break;
          case 6:
            key = 'UPLOAD_TRADE_AGREEMENT';
            break;
          case 10:
            key = 'VALIDITY_PROCESS';
            break;
          case 9:
            key = 'TRADE_AGREEMENT_ADJUST';
            break;
          default:
            break;
        }
        yield put(getDayLeftSuccess({ [key]: { ...response.data, processId } }));
      }
    } else {
      yield put(getDayLeftFail());
    }
  } catch (e) {
    console.error('Error', e);
    yield put(getDayLeftFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}
export function* watchGetDaysLeftRequest() {
  yield takeEvery(GET_DAYS_LEFT_REQUEST, workGetDaysLeftRequest);
}

export const getRegisterTrade = (state) => state.TRADE_AGREEMENTS_SELLOUT?.registerTrade;

function* workUpdateTableInputs(action) {
  const { payload } = action;
  const PRICE = payload?.price ?? 0;
  const showBruteValue = payload?.showBruteValue ?? false;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const registerTradeState = yield select(getRegisterTrade);
  // Obtener la suma de los volumenes
  const inputValues = { ...registerTradeState?.table?.inputs };
  const inputValuesArray = Object.values(inputValues);
  const imputValuesSum = inputValuesArray.reduce(
    (accumulator, currentValue) => accumulator + currentValue
  );
  yield put(updateTableDisplays({ key: 'totalVolume', value: imputValuesSum }));
  // Obtener las ventas (precio por volumne)
  const inputKeysArray = Object.keys(inputValues);
  let calculatedValues = {};
  inputKeysArray.forEach((item) => {
    calculatedValues = { ...calculatedValues, [item]: inputValues?.[item] * PRICE };
  });
  //Obtener el precio sumado  de venta bruta
  const calculatedValuesArray = Object.values(calculatedValues);
  const calculatedValuesSum = calculatedValuesArray.reduce(
    (accumulator, currentValue) => accumulator + currentValue
  );
  //Guardar
  yield put(setMultipleTableDisplays({ ...calculatedValues, total: calculatedValuesSum }));
  //Hacer peticion de guardado

  //Reflejar los datos del guardado
  //TODO: Checar que le llegue
  yield put(
    indicatorsSet({
      totalSales: { isLoading: true },
      quartySales: { isLoading: true },
      batteryInfo: { isLoading: true },
    })
  );
  yield delay(2000);

  //Usar el servicio de guardado

  try {
    const response = {
      status: 200,
      data: { semaphore: 0, clientNetAmount: 10, clientBruteAmount: 20 },
    };
    if (response) {
      if (typeof response !== 'undefined') {
        const totalSales = showBruteValue
          ? response?.data?.clientBruteAmount
          : response?.data?.clientNetAmount;
        const quarter = (totalSales / 4).toFixed(2);

        yield put(
          indicatorsSet({
            totalSales: { isLoading: false, data: totalSales },
            quartySales: {
              isLoading: false,
              data: [{ q1: quarter, q2: quarter, q3: quarter, q4: quarter }],
            },
            batteryInfo: { isLoading: false, data: response?.data?.semaphore },
          })
        );
      }
    } else {
      //yield put(getProductsPricesFail());
    }
  } catch (e) {
    //ield put(getProductsPricesFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* updateTableInputs() {
  yield takeLatest(UPDATE_TABLE_INPUTS, workUpdateTableInputs);
}

function* workGetProductsRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;

    const { params } = payload;

    const response = yield call(getProducts, params, source.token);

    if (response) {
      if (typeof response !== 'undefined') {
        const { processId } = params;
        yield put(getProductsSuccess({ ...response.data, processId }));
        //aquí checamos
      }
    } else {
      yield put(getProductsFail());
    }
  } catch (e) {
    yield put(getProductsFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchGetProductsRequest() {
  yield takeEvery(GET_PRODUCTS_REQUEST, workGetProductsRequest);
}

function* workGetProductsPricesRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;

    const { params } = payload;

    const response = yield call(getProductsPrices, params, source.token);

    if (response) {
      if (typeof response !== 'undefined') {
        const { processId } = params;
        yield put(getProductsPricesSuccess({ ...response.data, processId }));
      }
    } else {
      yield put(getProductsPricesFail());
    }
  } catch (e) {
    yield put(getProductsPricesFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchGetProductsPricesRequest() {
  yield takeEvery(GET_PRODUCTS_PRICES_REQUEST, workGetProductsPricesRequest);
}

function* workUpdatePrice(action) {
  const { payload } = action;
  const registerTradeState = yield select(getRegisterTrade);
  const display = registerTradeState?.table?.display ?? false;
  if (display) {
    const newDisplays = {
      ene: display?.ene * payload?.price ?? 0,
      feb: display?.ene * payload?.price ?? 0,
      mar: display?.ene * payload?.price ?? 0,
      total: display?.total * payload?.price ?? 0,
    };
    yield put(setMultipleTableDisplays(newDisplays));
  }
}

export function* watchUpdatePrice() {
  yield takeEvery(UPDATE_PRICE, workUpdatePrice);
}

function* workerGetAgreement(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { params } = payload;
    const response = yield call(getAgreement, { params, cancelToken: source.token });
    if (responseOK(response)) {
      yield put(getAgreementSuccess(response.data));
      const productInfo = yield select(
        (state) => state?.TRADE_AGREEMENTS_SELLOUT?.registerTrade?.productInfo?.data ?? false
      );
      if (productInfo) {
        yield put(getProductsSuccess(productInfo));
      }
      //AQUÍ CHECAR
    } else {
      yield put(getAgreementFail());
    }
  } catch (e) {
    yield put(getAgreementFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchGetAgreementRequest() {
  yield takeEvery(GET_AGREEMENT_REQUEST, workerGetAgreement);
}

function* workerGetReach(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { params } = payload;
    const response = yield call(getReach, { params, cancelToken: source.token });
    if (responseOK(response)) {
      yield put(getReachSuccess(response.data));
      // yield put(saveTable(table));
    } else {
      yield put(getReachFail());
    }
  } catch (e) {
    yield put(getReachFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchGetReachRequest() {
  yield takeEvery(GET_REACH_REQUEST, workerGetReach);
}

function* workSendToValidateRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { params, onSuccess } = payload;
    const response = yield call(sendTovalidate, { params, cancelToken: source.token });
    if (responseOK(response)) {
      yield put(sendToValidateSuccess(response.data));
      if (onSuccess) {
        yield onSuccess(response.message);
      }
    } else {
      yield put(sendToValidateFail());
    }
  } catch (e) {
    yield put(sendToValidateFail());
    console.error('Redux saga error:', e);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchSendToValidateRequest() {
  yield takeEvery(SEND_TO_VALIDATE_REQUEST, workSendToValidateRequest);
}

function* workerSaveAgreementRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { onSuccess } = payload;
    const currentTable = yield select((state) => state?.TRADE_AGREEMENTS_SELLOUT?.table?.data);
    const query = yield select((state) => state?.TRADE_AGREEMENTS_SELLOUT?.query);
    const productInfo = yield select(
      (state) => state?.TRADE_AGREEMENTS_SELLOUT?.registerTrade?.productInfo?.data ?? false
    );
    let territoryId = yield select(
      (state) => state?.TRADE_AGREEMENTS_SELLOUT?.infoForAgreement?.territoryInfo?.territoryId
    );
    if (territoryId === undefined) {
      const localClient = JSON.parse(window.localStorage.getItem('localClient'));
      territoryId = localClient.territoryId;
    }
    const { clientId, productId } = query;
    let rowVolumes = currentTable.find((row) => row.rowKey === 1);

    let totalValids = checkIfVolumesAreValids(rowVolumes, productInfo.moq);
    if (totalValids.length === 12) {
      let dataToSave = {
        clientId,
        territoryId,
        productId,
        products: totalValids,
      };
      const response = yield call(postAgreements, {
        cancelToken: source.token,
        data: dataToSave,
      });

      if (responseOK(response)) {
        yield put(saveAgreenmentSuccess(response.data));
        if (onSuccess) {
          yield onSuccess(response.message);
        }
      } else {
        yield put(saveAgreenmentFail());
      }
    }
  } catch (e) {
    yield put(saveAgreenmentFail());
    console.error('Redux saga error:', e);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

function* workerProductSuccess() {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const moq = yield select(
      (state) => state?.TRADE_AGREEMENTS_SELLOUT?.registerTrade?.productInfo?.data?.moq ?? 0
    );
    const currentAgreement = yield select(
      (state) => state?.TRADE_AGREEMENTS_SELLOUT?.registerTrade?.currentAgreement?.data ?? false
    );
    const year = yield select((state) => state?.TRADE_AGREEMENTS_SELLOUT?.query?.year ?? 2020);

    if (currentAgreement) {
      const { ...others } = currentAgreement;
      let table = yield processDataToTable({
        year,
        data: others,
        isEditable: true,
        moq,
        price: 0,
      });

      let volumes = table.find((row) => row.rowKey === 1);
      let totalValids = checkIfVolumesAreValids(volumes, moq);
      let allValids = totalValids.length === 12;
      yield put(areVolumesValids(allValids));
      yield put(saveTable(table));

      yield put(validateTableMandatory({ state: 'initial', wasSend: false }));
    }
  } catch (error) {
    console.error('[Redux-Saga-Catchh]', error);
    yield put(getAgreementFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

function* workerTradeCommentsRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { params, onSuccess } = payload;
    const response = yield call(getAgreementsComments, { params, cancelToken: source.token });
    if (responseOK(response)) {
      if (onSuccess) {
        yield onSuccess(response.data);
      }
      yield put(tradeCommentsSuccess(response.data));
    } else {
      yield put(tradeCommentsFail());
    }
  } catch (e) {
    console.error(e);
    yield put(tradeCommentsFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchChallengeComments() {
  yield takeLatest(TRADE_COMMENTS_REQUEST, workerTradeCommentsRequest);
}

export function* watchSaveAgreement() {
  yield takeEvery(SAVE_AGREEMENT_REQUEST, workerSaveAgreementRequest);
}

export function* watchProductSucess() {
  yield takeEvery(GET_PRODUCTS_SUCCESS, workerProductSuccess);
}
