import { put, call, takeEvery, all, cancelled, select, takeLatest } from 'redux-saga/effects';

import {
  COMPARATIVE_SET_TYPE_OF_VIEW,
  comparativeSetTypeOfView,
  comparativeClearTypeOfView,
  regionRequest,
  regionClean,
  regionSuccess,
  REGION_REQUEST,
  TYPE_CLIENT_REQUEST,
  typeClientRequest,
  typeClientSuccess,
  typeClientClean,
  SEGMENTATION_REQUEST,
  segmentationRequest,
  segmentationSuccess,
  segmentationClean,
  BUSINESS_LINES_REQUEST,
  businessLineRequest,
  businessLineSuccess,
  businessLineClean,
  SUBREGION_REQUEST,
  subRegionRequest,
  subRegionSuccess,
  subRegionClean,
  TERRITORIES_REQUEST,
  territoryRequest,
  territorySuccess,
  territoryClean,
  ZONES_REQUEST,
  zoneRequest,
  zoneSuccess,
  zoneClean,
  INDICATOR_REQUEST,
  indicatorSuccess,
  indicatorClean,
  TYPEINFO_REQUEST,
  typeInformationRequest,
  typeInformationSuccess,
  typeInformationClean,
  TYPEUNIDAD_REQUEST,
  typeUnidadRequest,
  typeUnidadSuccess,
  typeUnidadClean,
  PERIODO_REQUEST,
  periodoRequest,
  periodoSuccess,
  periodoClean,
  YEAR_REQUEST,
  yearRequest,
  yearSuccess,
  yearClean,
  MONTH_REQUEST,
  monthRequest,
  monthSuccess,
  monthClean,
  CLIENT_REQUEST,
  clientRequest,
  clientSuccess,
  clientClean,
  POLITICSPRODUCT_REQUEST,
  politicsProductRequest,
  politicsProductClean,
  politicsProductSuccess,
  DIPONIBILIDADPRODUCT_REQUEST,
  disponibilidadProductRequest,
  disponibilidadProductClean,
  disponibilidadProductSuccess,
  FAMILYPRODUCT_REQUEST,
  familyProductRequest,
  familyProductSuccess,
  familyProductClean,
  PRODUCT_REQUEST,
  productRequest,
  productSuccess,
  productClean,
  COMPARATIVE_DASHBOARD_REQUEST_MAIN_API,
  comparativeDashboardRequestMainApi,
  comparativeDashboardSetMainApi,
  comparativeDashboardClearMainApi,
  COMPARATIVE_DASHBOARD_REQUEST_CATALOGS_LOAD,
  comparativeDashboardSetCatalogsLoad,
  comparativeDashboardClearCatalogsLoad,
  COMPARATIVE_DASHBOARD_REQUEST_EXCEL,
  comparativeDashboardSetExcel,
  comparativeDashboardClearExcel,
  COMPARATIVE_DASHBOARD_REQUEST_INFORMATION_EXISTANCES,
  comparativeDashboardClearInformationExistances,
} from '../actions';
import instance from 'services/request';
import { responseOK } from 'Domains/SalesChallengeRefactor/utils';
import {
  getFixedCatalogs,
  getCustomFixedCatalogs,
  getTimelapseDetail,
  getComparativeData,
  getExcel,
} from '../../services/';
import { parametersMap, excelParameters } from '../../utils/';

import {
  getClientSoldTo,
  getProductsPolicy,
  getProductsAvailability,
  getProductFamily,
  getProductList,
} from '../../services/productsServices';

import { getDMYDateString } from 'Dashboards/Comparative/components/DownloadPDFButton';
import { message001, messageError } from 'utils/messages';

//SAGAS PRODUCTOS /////////////////////////////////////////////////
//productos
function* workGetProductRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const form = action?.payload?.form;
  let state;

  try {
    const response = yield call(getProductList, {
      cancelToken: source.token,
      params: action?.payload?.params ? action.payload.params : null,
    });

    if (responseOK(response)) {
      const result = response.data ? response.data : [];
      const resultList = result.map((item) => {
        return { key: item.productNumber, value: item.name };
      });
      yield put(productSuccess(resultList));
      if (form) {
        yield call(form.setFieldsValue, { product: resultList.map((item) => item.key) });
      }
    } else {
      yield put(productClean());
    }

    state = yield select();
    if (state.COMPARATIVE.stateClient) yield put(comparativeDashboardRequestMainApi());

    //yield put(comparativeDashboardRequestMainApi());
  } catch (error) {
    yield put(productClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetProductRequest() {
  yield all([takeEvery(PRODUCT_REQUEST, workGetProductRequest)]);
}
//family
function* workGetFamilyProductRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getProductFamily, {
      cancelToken: source.token,
    });

    if (responseOK(response)) {
      const result = response.data ? response.data : [];
      const resultList = result.map((item) => {
        return { key: item, value: item };
      });
      yield put(familyProductSuccess(resultList));
    } else {
      yield put(familyProductClean());
    }
  } catch (error) {
    yield put(familyProductClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetFamilyProductRequest() {
  yield all([takeEvery(FAMILYPRODUCT_REQUEST, workGetFamilyProductRequest)]);
}
//disponibilidad productos
function* workGetDisponibilidadProductRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getProductsAvailability, {
      cancelToken: source.token,
      name: 'Territories',
    });

    if (responseOK(response)) {
      yield put(disponibilidadProductSuccess(response.data));
    } else {
      yield put(disponibilidadProductClean());
    }
  } catch (error) {
    yield put(disponibilidadProductClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetDisponibilidadProductRequest() {
  yield all([takeEvery(DIPONIBILIDADPRODUCT_REQUEST, workGetDisponibilidadProductRequest)]);
}
//politics productos
function* workGetPoliticsProductRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();

  try {
    const response = yield call(getProductsPolicy, {
      cancelToken: source.token,
      name: 'Territories',
    });

    if (responseOK(response)) {
      yield put(politicsProductSuccess(response.data));
    } else {
      yield put(politicsProductClean());
    }
  } catch (error) {
    yield put(politicsProductClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetPoliticProductRequest() {
  yield all([takeEvery(POLITICSPRODUCT_REQUEST, workGetPoliticsProductRequest)]);
}
//SAGAS CLIENTE ///////////////////////////////////////////////////////////////
//client
function* workGetClientRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const params = action?.payload?.params ? action.payload.params : {};
  const form = action?.payload?.form;
  let state;

  try {
    const response = yield call(getClientSoldTo, {
      cancelToken: source.token,
      name: 'Territories',
      params,
    });

    if (responseOK(response)) {
      const result = response.data ? response.data : [];
      const resultList = result.map((item) => {
        return { key: item.soldTo, value: item.name };
      });
      yield put(clientSuccess(resultList));
      if (form) {
        yield call(form.setFieldsValue, { client: resultList.map((item) => item.key) });
      }
    } else {
      yield put(clientClean());
    }

    state = yield select();

    if (state.COMPARATIVE.stateProduct) yield put(comparativeDashboardRequestMainApi());
  } catch (error) {
    yield put(clientClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetClientRequest() {
  yield all([takeEvery(CLIENT_REQUEST, workGetClientRequest)]);
}
//type client request
function* workGetTypeClientRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getFixedCatalogs, {
      cancelToken: source.token,
      name: 'Placements',
    });

    if (responseOK(response)) {
      yield put(typeClientSuccess(response.data));
    } else {
      yield put(typeClientClean());
    }
  } catch (error) {
    yield put(typeClientClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetTypeClientRequest() {
  yield all([takeEvery(TYPE_CLIENT_REQUEST, workGetTypeClientRequest)]);
}

//segmentation request
function* workGetSegmentationRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getFixedCatalogs, {
      cancelToken: source.token,
      name: 'Segmentation',
    });

    if (responseOK(response)) {
      yield put(segmentationSuccess(response.data));
    } else {
      yield put(segmentationClean());
    }
  } catch (error) {
    yield put(segmentationClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetSegmentationRequest() {
  yield all([takeEvery(SEGMENTATION_REQUEST, workGetSegmentationRequest)]);
}

//SAGAS PERIODO ////////////////////////////////////////////////////////
//month
function* workGetMonthRequest({ payload }) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const timelapseDetail = payload;

  try {
    const response = yield call(getTimelapseDetail, {
      cancelToken: source.token,
      timelapseType: timelapseDetail ? timelapseDetail : 1,
    });

    if (responseOK(response)) {
      yield put(monthSuccess(response.data));
    } else {
      yield put(monthClean());
    }
  } catch (error) {
    yield put(monthClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetMonthRequest() {
  yield all([takeEvery(MONTH_REQUEST, workGetMonthRequest)]);
}
//year
function* workGetYearRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getFixedCatalogs, {
      cancelToken: source.token,
      name: 'ProcessYears',
    });

    if (responseOK(response)) {
      yield put(yearSuccess(response.data));
    } else {
      yield put(yearClean());
    }
  } catch (error) {
    yield put(yearClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetYearRequest() {
  yield all([takeEvery(YEAR_REQUEST, workGetYearRequest)]);
}
//type periodo
function* workGetPeriodoRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getFixedCatalogs, {
      cancelToken: source.token,
      name: 'Timelapse',
    });

    if (responseOK(response)) {
      yield put(periodoSuccess(response.data));
    } else {
      yield put(periodoClean());
    }
  } catch (error) {
    yield put(periodoClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetPeriodoRequest() {
  yield all([takeEvery(PERIODO_REQUEST, workGetPeriodoRequest)]);
}
//SAGAS GRAFICO ///////////////////////////////////////////////////////
//type unidades
function* workGetTypeUnidadRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getFixedCatalogs, {
      cancelToken: source.token,
      name: 'UnitTypes',
    });

    if (responseOK(response)) {
      yield put(typeUnidadSuccess(response.data));
    } else {
      yield put(typeUnidadClean());
    }
  } catch (error) {
    yield put(typeUnidadClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetTypeUnidadRequest() {
  yield all([takeEvery(TYPEUNIDAD_REQUEST, workGetTypeUnidadRequest)]);
}
//type information
function* workGetTypeInfoRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    let typeInformacino = [
      { key: 1, value: 'Ventas' },
      { key: 2, value: 'Volumen' },
    ];

    yield put(typeInformationSuccess(typeInformacino));
  } catch (error) {
    yield put(typeInformationClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetTypeInfoRequest() {
  yield all([takeEvery(TYPEINFO_REQUEST, workGetTypeInfoRequest)]);
}

//indicator
function* workGetIndicatorRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getFixedCatalogs, {
      cancelToken: source.token,
      name: 'Zones',
    });

    if (!responseOK(response)) {
      yield put(indicatorClean());
    }
  } catch (error) {
    yield put(indicatorClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetIndicatorRequest() {
  yield all([takeEvery(INDICATOR_REQUEST, workGetIndicatorRequest)]);
}

//zones
function* workGetZonesRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const state = yield select(); //se obtiene el estado de redux
    const { territory } = state.COMPARATIVE.filter[12];

    const params = {};

    if (territory.length > 0) {
      params.territories = territory;
    }

    const response = yield call(getCustomFixedCatalogs, {
      cancelToken: source.token,
      name: 'Zones',
      mode: 'multiple',
      params,
    });

    if (responseOK(response)) {
      yield put(zoneSuccess(response.data));
    } else {
      yield put(zoneClean());
    }
  } catch (error) {
    yield put(zoneClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetZonesRequest() {
  yield all([takeEvery(ZONES_REQUEST, workGetZonesRequest)]);
}
//territories
function* workGetTerritoryRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getCustomFixedCatalogs, {
      cancelToken: source.token,
      name: 'Territories',
    });

    if (responseOK(response)) {
      yield put(territorySuccess(response.data));
    } else {
      yield put(territoryClean());
    }
  } catch (error) {
    yield put(territoryClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetTerritoryRequest() {
  yield all([takeEvery(TERRITORIES_REQUEST, workGetTerritoryRequest)]);
}
//sub region
function* workGetSubRegionRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const state = yield select(); //se obtiene el estado de redux
  const { regions } = state.COMPARATIVE.filter[9];
  const { bussinesline } = state.COMPARATIVE.filter[10];
  const params = {};

  if (regions.length > 0) {
    params.regions = regions;
  }
  if (bussinesline.length > 0) {
    params.businessLines = bussinesline;
  }

  try {
    const response = yield call(getCustomFixedCatalogs, {
      cancelToken: source.token,
      name: 'Subregions',
      mode: 'multiple',
      params,
    });

    if (responseOK(response)) {
      yield put(subRegionSuccess(response.data));
    } else {
      yield put(subRegionClean());
    }
  } catch (error) {
    yield put(subRegionClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetSubRegionRequest() {
  yield all([takeEvery(SUBREGION_REQUEST, workGetSubRegionRequest)]);
}

//busines line
function* workGetBusinessLineRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const state = yield select(); //se obtiene el estado de redux
  const { regions } = state.COMPARATIVE.filter[9];
  const params = {};

  try {
    if (regions.length > 0) {
      params.regions = regions;
    }
    const response = yield call(getCustomFixedCatalogs, {
      cancelToken: source.token,
      name: 'Lines',
      params,
    });

    if (responseOK(response)) {
      yield put(businessLineSuccess(response.data));
    } else {
      yield put(businessLineClean());
    }
  } catch (error) {
    yield put(businessLineClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetBusinessLineRequest() {
  yield all([takeEvery(BUSINESS_LINES_REQUEST, workGetBusinessLineRequest)]);
}

//region request
function* workGetRegionRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getCustomFixedCatalogs, {
      cancelToken: source.token,
      name: 'Regions',
    });

    if (responseOK(response)) {
      yield put(regionSuccess(response.data));
    } else {
      yield put(regionClean());
    }
  } catch (error) {
    yield put(regionClean(error.message));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchGetRegionRequest() {
  yield all([takeEvery(REGION_REQUEST, workGetRegionRequest)]);
}

function* workComparativeSetTypeOfView(action) {
  try {
    yield comparativeSetTypeOfView(action.payload);
  } catch (error) {
    yield comparativeClearTypeOfView();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield comparativeClearTypeOfView();
    }
  }
}

export function* watchComparativeSetTypeOfView() {
  yield all([takeEvery(COMPARATIVE_SET_TYPE_OF_VIEW, workComparativeSetTypeOfView)]);
}

//main api

let containerIndicator = {
  //"structure"
  salesChallenge: 'Desafío de ventas',
  //"salesChallengeVolume"
  agreement: 'Acuerdo Comercial',
  //"agreementVolume"
  sales: 'Ventas históricas',
  //"salesVolume"
  pog: 'POG',
  //"pogVolume"
  salesGoals: 'Metas de venta',
  //"salesGoalsVolume"
};

function* workComparativeDashboardRequestMainApi() {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  let params = {};

  try {
    const state = yield select(); //se obtiene el estado de redux
    const filter = state.COMPARATIVE.filter;
    params = parametersMap(filter);

    if (
      (!params.Regions &&
        !params.Lines &&
        !params.Subregions &&
        !params.Territories &&
        !params.Zones) ||
      (!params.ClientsType &&
        !params.SoldTo &&
        !params.Segmentations &&
        !params.PolicyType &&
        !params.ProductAvailabilityType &&
        !params.Families &&
        !params.Products)
    ) {
      messageError(
        'No se encontraron resultados que coincidan con los criterios de búsqueda ingresados.'
      );

      return;
    }

    if (params.ProductAvailabilityType?.length === 2) {
      delete params.ProductAvailabilityType;
    }

    if (params.PolicyType?.length === 2) {
      delete params.PolicyType;
    }
    const response = yield call(getComparativeData, {
      cancelToken: source.token,
      params,
    });
    if (responseOK(response)) {
      if (response.data.length === 0) {
        messageError(
          'No se encontraron resultados que coincidan con los criterios de búsqueda ingresados.'
        );
      }
      let dataIndicator = Object.keys(response.data[0]).filter((value) => {
        let keysIndicator = Object.keys(containerIndicator);
        return keysIndicator.includes(value);
      });
      dataIndicator = dataIndicator.map((value) => {
        return { key: value, value: containerIndicator[value] };
      });
      yield put(indicatorSuccess(dataIndicator));
      yield put(comparativeDashboardSetMainApi(response.data));
    } else {
      yield put(comparativeDashboardClearMainApi());
    }
  } catch (error) {
    yield comparativeDashboardClearMainApi();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield comparativeDashboardClearMainApi();
      source.cancel('cancelled Request');
    }
  }
}

export function* watchComparativeDashboardRequestMainApi() {
  yield all([
    takeLatest(COMPARATIVE_DASHBOARD_REQUEST_MAIN_API, workComparativeDashboardRequestMainApi),
  ]);
}

function* workComparativeDashboardRequestCatalogsLoad(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const form = action.payload.form;

  try {
    const state = yield select(); //se obtiene el estado de redux
    const clientParams = {
      Placement: state.COMPARATIVE.filter[14].typeclient,
      BusinessLines: state.COMPARATIVE.filter[10].bussinesline,
      //Segmentation: state.COMPARATIVE.filter[17].segmentation,
    };
    yield put(regionRequest());
    yield put(typeClientRequest());
    yield put(segmentationRequest());
    yield put(businessLineRequest());
    yield put(subRegionRequest());
    yield put(territoryRequest());
    yield put(zoneRequest());
    yield put(typeInformationRequest());
    yield put(typeUnidadRequest());
    yield put(periodoRequest());
    yield put(yearRequest());
    yield put(monthRequest());
    yield put(clientRequest({ params: clientParams, form }));
    yield put(politicsProductRequest());
    yield put(disponibilidadProductRequest());
    yield put(familyProductRequest());
    yield put(productRequest());
    yield put(comparativeDashboardRequestMainApi());
    yield put(comparativeDashboardSetCatalogsLoad());
  } catch (error) {
    yield comparativeDashboardClearCatalogsLoad();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield comparativeDashboardClearCatalogsLoad();
      source.cancel('cancelled Request');
    }
  }
}

export function* watchComparativeDashboardRequestCatalogsLoad() {
  yield all([
    takeEvery(
      COMPARATIVE_DASHBOARD_REQUEST_CATALOGS_LOAD,
      workComparativeDashboardRequestCatalogsLoad
    ),
  ]);
}

//get excel
function* workComparativeDashboardRequestExcel(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  let params = {};
  try {
    const state = yield select(); //se obtiene el estado de redux
    const filter = state.COMPARATIVE.filter;

    params = { ...parametersMap(filter), ...excelParameters(filter) };

    const response = yield call(getExcel, {
      cancelToken: source.token,
      params,
    });
    if (responseOK(response)) {
      yield put(comparativeDashboardSetExcel(true));

      if (response?.data) {
        const blob = response.data;

        const link = document.createElement('a');
        link.href = `data:application/pdf;base64,${blob}`;
        link.download = `Dashboard Comparativo ${getDMYDateString()}.xlsx`;
        link.click();
        message001('La descarga de información del dashboard comparativo');
      }
    } else {
      yield put(comparativeDashboardClearExcel());
    }
  } catch (error) {
    yield comparativeDashboardClearExcel();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield comparativeDashboardClearExcel();
      source.cancel('cancelled Request');
    }
  }
}

export function* watchComparativeDashboardRequestExcel() {
  yield all([takeEvery(COMPARATIVE_DASHBOARD_REQUEST_EXCEL, workComparativeDashboardRequestExcel)]);
}

function* workComparativeDashboardRequestInformationExistances(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  let mustRedirect = false;
  let { redirectToDasboardsScreen } = action.payload;
  try {
    const salesResponse = yield call(getCustomFixedCatalogs, {
      cancelToken: source.token,
      name: 'SalesExists',
    });
    //checar POG
    const challengeResponse = yield call(getCustomFixedCatalogs, {
      cancelToken: source.token,
      name: 'ChallengeExists',
    });
    //checar acuerdo comercial
    //checar estructura comercial
    const goalResponse = yield call(getCustomFixedCatalogs, {
      cancelToken: source.token,
      name: 'SalesGoalExists',
    });
    const pogResponse = yield call(getCustomFixedCatalogs, {
      cancelToken: source.token,
      name: 'PogExists',
    });
    const agreementResponse = yield call(getCustomFixedCatalogs, {
      cancelToken: source.token,
      name: 'AgreementExists',
    });

    if (responseOK(salesResponse) && salesResponse?.data === false) {
      setTimeout(function () {
        messageError(
          'Es necesario registrar la información de ventas históricas antes de realizar la consulta del dashboard.'
        );
      }, 1000);

      mustRedirect = true;
    }
    if (responseOK(challengeResponse) && challengeResponse?.data === false) {
      setTimeout(function () {
        messageError(
          'Es necesario registrar la información de desafío de ventas del año de vigencia del proceso antes de realizar la consulta del dashboard.'
        );
      }, 1000);
      mustRedirect = true;
    }
    if (responseOK(goalResponse) && goalResponse?.data === false) {
      setTimeout(function () {
        messageError(
          'Es necesario registrar la información de metas de venta del año de vigencia del proceso antes de realizar la consulta del dashboard.'
        );
        mustRedirect = true;
      }, 1000);
    }
    if (responseOK(pogResponse) && pogResponse?.data === false) {
      setTimeout(function () {
        messageError(
          'Es necesario registrar la información de POG antes de realizar la consulta del dashboard.'
        );
      }, 1000);
      mustRedirect = true;
    }
    if (responseOK(agreementResponse) && agreementResponse?.data === false) {
      setTimeout(function () {
        messageError(
          'Es necesario registrar la información correspondiente a los acuerdos comerciales del año de vigencia del proceso antes de realizar la consulta del dashboard.'
        );
      }, 1000);
      mustRedirect = true;
    }
  } catch (error) {
    yield comparativeDashboardClearInformationExistances();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield comparativeDashboardClearInformationExistances();
      source.cancel('cancelled Request');
    }
    if (mustRedirect) {
      yield comparativeDashboardClearInformationExistances();
      yield call(redirectToDasboardsScreen);
    }
  }
}

export function* watchComparativeDashboardRequestInformationExistances() {
  yield all([
    takeEvery(
      COMPARATIVE_DASHBOARD_REQUEST_INFORMATION_EXISTANCES,
      workComparativeDashboardRequestInformationExistances
    ),
  ]);
}
