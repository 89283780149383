import { takeEvery, put, call, all, cancelled } from 'redux-saga/effects';
import {
  POST_ONE_SEGMENTATION_REQUEST,
  postOneSegmentationClean,
  postOneSegmentationSuccess,
  DELETE_ONE_SEGMENTATION_REQUEST,
  deleteOneSegmentationClean,
  deleteOneSegmentationSuccess,
  PUT_ONE_SEGMENTATION_REQUEST,
  putOneSegmentationClean,
  putOneSegmentationSuccess,
  GET_ACTIVE_CLIENTS_REQUEST,
  getActiveClientsSuccess,
  getActiveClientsClean,
  POST_PRODUCT_CLIENT_REQUEST,
  postProductClientClean,
  POST_PRODUCT_BL_REQUEST,
  postProductBLClean,
  POST_INCENTIVES_RULES_REQUEST,
  postIncentivesRulesClean,
  GET_INCENTIVES_REQUEST,
  getIncentivesClean,
  getIncentivesSuccess,
  GET_OutPolicyBL_REQUEST,
  getOutPolicyBLClean,
  getOutPolicyBLSuccess,
  GET_OutPolicyClient_REQUEST,
  getOutPolicyClientClean,
  getOutPolicyClientSuccess,
  GET_InPoliciyProducts_REQUEST,
  getInPoliciyProductsClean,
  getInPoliciyProductsSuccess,
  GET_OutPoliciyProducts_REQUEST,
  getOutPoliciyProductsClean,
  getOutPoliciyProductsSuccess,
  GET_InAvailability_REQUEST,
  getInAvailabilityClean,
  getInAvailabilitySuccess,
  GET_OutAvailability_REQUEST,
  getOutAvailabilityClean,
  getOutAvailabilitySuccess,
  GET_ClientSingleId_REQUEST,
  getClientSingleIdSuccess,
  getClientSingleIdClean,
  PUT_IncentivesRules_REQUEST,
  putIncentivesRulesClean,
  PUT_OutPolicyProductBL_REQUEST,
  putOutPolicyProductBLClean,
  putOutPolicyProductBLSuccess,
  PUT_OutPolicyProductClient_REQUEST,
  PUT_AvailabilityProducts_REQUEST,
  putAvailabilityProductsClean,
  putAvailabilityProductsSuccess,
  EXISTENCE_INCENTIVES_REQUEST,
  checkExistanceIncentivesSuccess,
  checkExistanceIncentivesClean,
} from '../actions';
import instance from 'services/request';

import {
  postOneSegmentation,
  deleteOneSegmentation,
  putOneSegmentation,
  getActiveClients,
  postProductClient,
  postProductBL,
  postIncentivesRules,
  getIncentives,
  getOutPolicyBL,
  getOutPolicyClient,
  getInPoliciyProducts,
  getOutPoliciyProducts,
  getInAvailability,
  getOutAvailability,
  getClientSingleId,
  putIncentivesRules,
  putOutPolicyProductBL,
  putOutPolicyProductClient,
  putAvailabilityProducts,
  postExistenceIncentives,
} from '../../services/BRregister';
import { responseOK } from 'Domains/SalesChallengeRefactor/utils';
import { openNotification } from 'common';

//Segmentation POST
function* workPostOneSegmentationRequest(action) {
  try {
    const { payload } = action;
    const response = yield call(postOneSegmentation, {
      data: {
        segmentation: payload,
      },
    });
    if (responseOK(response)) {
      yield put(postOneSegmentationSuccess(response.data));
      yield openNotification('success', response.message);
    } else if (response.data) {
      yield openNotification('error', response.message);
    }
  } catch (error) {
    yield put(postOneSegmentationClean());
  }
}
//Segmentation DELETE
function* workDeleteOneSegmentationRequest(action) {
  try {
    const { payload } = action;
    const response = yield call(deleteOneSegmentation, {
      data: {
        id: payload,
      },
    });
    if (responseOK(response)) {
      yield put(deleteOneSegmentationSuccess(response.data));
      yield openNotification('success', response.message);
    } else if (response.data) {
      yield openNotification('error', response.message);
    }
  } catch (error) {
    yield put(deleteOneSegmentationClean());
  }
}
//Segmentation PUT
function* workPutOneSegmentationRequest(action) {
  try {
    const { payload } = action;
    const { id, value } = payload;
    const response = yield call(putOneSegmentation, {
      data: {
        id: id,
        segmentation: value,
      },
    });
    if (responseOK(response)) {
      yield put(putOneSegmentationSuccess(response.data));
      yield openNotification('success', response.message);
    } else if (response.data) {
      yield openNotification('error', response.message);
    }
  } catch (error) {
    yield put(putOneSegmentationClean());
  }
}

///InPoliciyProducts GET CLIENTE
function* workGetActiveClientsRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { BussinessLines } = payload;
    let response = yield call(getActiveClients, { data: { BussinessLines } });
    if (responseOK(response)) {
      yield put(getActiveClientsSuccess(response.data));

      if (response.data === true) {
        yield openNotification('error', response.message);
      }
    } else {
      yield put(getActiveClientsClean());
    }
  } catch (error) {
    yield put(getActiveClientsClean());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}
/// GET ClientSingleId
function* workGetClientSingleIdRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { BussinessLines } = payload;
    let response = yield call(getClientSingleId, { data: { BussinessLines } });
    if (responseOK(response)) {
      yield put(getClientSingleIdSuccess(response.data));

      if (response.data === true) {
        yield openNotification('error', response.message);
      }
    } else {
      yield put(getClientSingleIdClean());
    }
  } catch (error) {
    yield put(getClientSingleIdClean());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}
//PORDUCT CLIENT POST
function* workPostProductClientRequest(action) {
  try {
    const { payload } = action;
    const { products, lineIdClient, clientId, history } = payload;
    const response = yield call(postProductClient, {
      data: {
        products,
        LineId: lineIdClient,
        clientId,
      },
    });
    if (responseOK(response)) {
      yield openNotification('success', response.message);
      setTimeout(() => {
        history.push('/catalog/businessRules');
      }, 300);
    } else if (response.data) {
      yield openNotification('error', response.message);
    }
  } catch (error) {
    yield put(postProductClientClean());
  }
}
//PRODUCT BL POST
function* workPostProductBLRequest(action) {
  try {
    const { payload } = action;
    const { products, lineId, subregionId, history } = payload;
    const response = yield call(postProductBL, {
      data: {
        products,
        lineId,
        subregionId,
      },
    });
    if (responseOK(response)) {
      yield openNotification('success', response.message);
      setTimeout(() => {
        history.push('/catalog/businessRules');
      }, 300);
    } else if (response.data) {
      yield openNotification('error', response.message);
    }
  } catch (error) {
    yield put(postProductBLClean());
  }
}

//INCENTIVES_RULES POST
function* workPostIncentivesRulesRequest(action) {
  try {
    const { payload } = action;
    const { data, history } = payload;
    const response = yield call(postIncentivesRules, {
      data,
    });
    if (responseOK(response)) {
      yield openNotification('success', response.message);
      /*   yield postIncentivesRulesClean(); */

      setTimeout(() => {
        history.push('/catalog/businessRules');
      }, 300);
    } else if (response.data) {
      yield openNotification('error', response.message);
    }
  } catch (error) {
    yield put(postIncentivesRulesClean());
  }
}

//INCENTIVES GET
function* workGetIncentivesRequest(action) {
  try {
    const { payload } = action;
    // const { data, history } = payload;
    const params = payload;
    const response = yield call(getIncentives, {
      params,
    });
    if (responseOK(response)) {
      yield put(getIncentivesSuccess(response));
    } else if (response.data) {
      yield openNotification('error', response.message);
    }
  } catch (error) {
    yield put(getIncentivesClean());
  }
}

// ##################### POLICY ###################

//OutPolicyBL GET
function* workGetOutPolicyBLRequest(action) {
  try {
    const { payload } = action;
    // const { data, history } = payload;
    const params = payload;
    const response = yield call(getOutPolicyBL, {
      params,
    });
    if (responseOK(response)) {
      yield put(getOutPolicyBLSuccess(response));
    } else if (response.data) {
      yield openNotification('error', response.message);
    }
  } catch (error) {
    yield put(getOutPolicyBLClean());
  }
}
//OutPolicyClient GET
function* workGetOutPolicyClientRequest(action) {
  try {
    const { payload } = action;
    const params = payload;
    const response = yield call(getOutPolicyClient, {
      params,
    });
    if (responseOK(response)) {
      yield put(getOutPolicyClientSuccess(response));
    } else if (response.data) {
      yield openNotification('error', response.message);
    }
  } catch (error) {
    yield put(getOutPolicyClientClean());
  }
}
//InPoliciyProducts GET
function* workGetInPoliciyProductsRequest(action) {
  try {
    const { payload } = action;
    // const { data, history } = payload;
    const params = payload;
    const response = yield call(getInPoliciyProducts, {
      params,
    });
    if (responseOK(response)) {
      yield put(getInPoliciyProductsSuccess(response));
    } else if (response.data) {
      yield openNotification('error', response.message);
    }
  } catch (error) {
    yield put(getInPoliciyProductsClean());
  }
}
//OutPoliciyProducts GET
function* workGetOutPoliciyProductsRequest(action) {
  try {
    const { payload } = action;
    // const { data, history } = payload;
    const params = payload;
    const response = yield call(getOutPoliciyProducts, {
      params,
    });
    if (responseOK(response)) {
      yield put(getOutPoliciyProductsSuccess(response));
    } else if (response.data) {
      yield openNotification('error', response.message);
    }
  } catch (error) {
    yield put(getOutPoliciyProductsClean());
  }
}

// ##################### AVAILABILITY ###################
//InAvailability GET
function* workGetInAvailabilityRequest(action) {
  try {
    const { payload } = action;
    // const { data, history } = payload;
    const params = payload;
    const response = yield call(getInAvailability, {
      params,
    });
    if (responseOK(response)) {
      yield put(getInAvailabilitySuccess(response));
    } else if (response.data) {
      yield openNotification('error', response.message);
    }
  } catch (error) {
    yield put(getInAvailabilityClean());
  }
}
//OutAvailability GET
function* workGetOutAvailabilityRequest(action) {
  try {
    const { payload } = action;
    // const { data, history } = payload;
    const params = payload;
    const response = yield call(getOutAvailability, {
      params,
    });
    if (responseOK(response)) {
      yield put(getOutAvailabilitySuccess(response));
    } else if (response.data) {
      yield openNotification('error', response.message);
    }
  } catch (error) {
    yield put(getOutAvailabilityClean());
  }
}
// ##################### PUT Business Rules ###################

//IncentivesRules PUT
function* workPutIncentivesRulesRequest(action) {
  try {
    const { payload } = action;
    const { data, history } = payload;
    const response = yield call(putIncentivesRules, {
      data,
    });
    if (responseOK(response)) {
      yield put(putIncentivesRulesClean());

      yield openNotification('success', response.message);

      setTimeout(() => {
        history.push({
          pathname: '/catalog/businessRules',
          state: { ruleType: 3 },
        });
      }, 300);
    } else if (response.data) {
      yield openNotification('error', response.message);
    }
  } catch (error) {
    yield put(putIncentivesRulesClean());
  }
}

//OutPolicyProductBL PUT
function* workPutOutPolicyProductBLRequest(action) {
  try {
    const { payload } = action;
    const { products, subregionId, lineId, history } = payload;
    const response = yield call(putOutPolicyProductBL, {
      data: {
        products,
        subregionId,
        lineId,
      },
    });
    if (responseOK(response)) {
      yield put(putOutPolicyProductBLSuccess(response.data));
      yield openNotification('success', response.message);
      setTimeout(() => {
        history.push({
          pathname: '/catalog/businessRules',
          state: { ruleType: 2 },
        });
      }, 300);
    } else if (response.data) {
      yield openNotification('error', response.message);
    }
  } catch (error) {
    yield put(putOutPolicyProductBLClean());
  }
}
//OutPolicyProductClient PUT
function* workPutOutPolicyProductClientRequest(action) {
  try {
    const { payload } = action;
    const { products, clientId, lineId, history } = payload;
    const response = yield call(putOutPolicyProductClient, {
      data: {
        products,
        clientId,
        lineId,
      },
    });
    if (responseOK(response)) {
      yield put(putOutPolicyProductBLSuccess(response.data));
      yield openNotification('success', response.message);
      setTimeout(() => {
        history.push({
          pathname: '/catalog/businessRules',
          state: { ruleType: 2 },
        });
      }, 300);
    } else if (response.data) {
      yield openNotification('error', response.message);
    }
  } catch (error) {
    yield put(putOutPolicyProductBLClean());
  }
}
//AvailabilityProducts PUT
function* workPutAvailabilityProductsRequest(action) {
  try {
    const { payload } = action;
    const { products, history } = payload;
    const response = yield call(putAvailabilityProducts, {
      data: {
        products,
      },
    });
    if (responseOK(response)) {
      yield put(putAvailabilityProductsSuccess(response.data));
      yield openNotification('success', response.message);

      setTimeout(() => {
        history.push({
          pathname: '/catalog/businessRules',
          state: { ruleType: 1 },
        });
      }, 300);
    } else if (response.data) {
      yield openNotification('error', response.message);
    }
  } catch (error) {
    yield put(putAvailabilityProductsClean());
  }
}

/// GET EXISTENCE_INCENTIVES
function* workGetExistanceIncentivesRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { data } = payload;

    let response = yield call(postExistenceIncentives, { data });
    if (responseOK(response)) {
      if (response.data === true) {
        yield openNotification(
          'error',
          'La regla de negocio de programa de incentivos ya se encuentra registrada en el sistema'
        );
      }
      yield put(checkExistanceIncentivesSuccess(response.data));
    } else {
      yield put(checkExistanceIncentivesClean());
    }
  } catch (error) {
    yield put(checkExistanceIncentivesClean());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

// ##################### WATCHERS ###################

export function* watchPostOneSegmentationRequest() {
  yield all([takeEvery(POST_ONE_SEGMENTATION_REQUEST, workPostOneSegmentationRequest)]);
}

export function* watchDeleteOneSegmentationRequest() {
  yield all([takeEvery(DELETE_ONE_SEGMENTATION_REQUEST, workDeleteOneSegmentationRequest)]);
}

export function* watchPutOneSegmentationRequest() {
  yield all([takeEvery(PUT_ONE_SEGMENTATION_REQUEST, workPutOneSegmentationRequest)]);
}

export function* watchGetActiveClientsRequest() {
  yield all([takeEvery(GET_ACTIVE_CLIENTS_REQUEST, workGetActiveClientsRequest)]);
}

export function* watchPostProductClientRequest() {
  yield all([takeEvery(POST_PRODUCT_CLIENT_REQUEST, workPostProductClientRequest)]);
}

export function* watchPostProductBLRequest() {
  yield all([takeEvery(POST_PRODUCT_BL_REQUEST, workPostProductBLRequest)]);
}

export function* watchPostIncentivesRulesRequest() {
  yield all([takeEvery(POST_INCENTIVES_RULES_REQUEST, workPostIncentivesRulesRequest)]);
}

export function* watchGetIncentivesRequest() {
  yield all([takeEvery(GET_INCENTIVES_REQUEST, workGetIncentivesRequest)]);
}

// ##################### POLICY ###################

export function* watchGetOutPolicyBLRequest() {
  yield all([takeEvery(GET_OutPolicyBL_REQUEST, workGetOutPolicyBLRequest)]);
}
export function* watchGetOutPolicyClientRequest() {
  yield all([takeEvery(GET_OutPolicyClient_REQUEST, workGetOutPolicyClientRequest)]);
}
export function* watchGetInPoliciyProductsRequest() {
  yield all([takeEvery(GET_InPoliciyProducts_REQUEST, workGetInPoliciyProductsRequest)]);
}
export function* watchGetOutPoliciyProductsRequest() {
  yield all([takeEvery(GET_OutPoliciyProducts_REQUEST, workGetOutPoliciyProductsRequest)]);
}

// ##################### AVAILABILITY ###################

export function* watchGetInAvailabilityRequest() {
  yield all([takeEvery(GET_InAvailability_REQUEST, workGetInAvailabilityRequest)]);
}
export function* watchGetOutAvailabilityRequest() {
  yield all([takeEvery(GET_OutAvailability_REQUEST, workGetOutAvailabilityRequest)]);
}

export function* watchGetClientSingleIdRequest() {
  yield all([takeEvery(GET_ClientSingleId_REQUEST, workGetClientSingleIdRequest)]);
}

// ##################### PUT WATCHERS ###################
export function* watchPutIncentivesRulesRequest() {
  yield all([takeEvery(PUT_IncentivesRules_REQUEST, workPutIncentivesRulesRequest)]);
}
export function* watchPutOutPolicyProductBLRequest() {
  yield all([takeEvery(PUT_OutPolicyProductBL_REQUEST, workPutOutPolicyProductBLRequest)]);
}
export function* watchPutOutPolicyProductClientRequest() {
  yield all([takeEvery(PUT_OutPolicyProductClient_REQUEST, workPutOutPolicyProductClientRequest)]);
}
export function* watchPutAvailabilityProductsRequest() {
  yield all([takeEvery(PUT_AvailabilityProducts_REQUEST, workPutAvailabilityProductsRequest)]);
}

// ##################### EXISTENCE_INCENTIVES ###################
export function* watchExistanceIncentivesRequest() {
  yield all([takeEvery(EXISTENCE_INCENTIVES_REQUEST, workGetExistanceIncentivesRequest)]);
}
