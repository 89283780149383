import { all, fork } from 'redux-saga/effects';
import validationReducer from './redux-saga/reducers';
import * as validationSagas from './redux-saga/sagas';

function* sagas() {
  yield all([...Object.values(validationSagas)].map(fork));
}

export default function getValidationsModule() {
  return {
    id: 'VALIDATIONS',
    reducerMap: {
      VALIDATIONS: validationReducer,
    },
    sagas: [sagas],
  };
}
