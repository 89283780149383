import request from '../../../services/request';
const URL_SALESCHALLENGE = '/Sales/Api/v1/Challenge/';
const URL_CATALOGS = '/Catalogs/Api/v1';
const KAM_CATALOGS = '/Sales/Api/v1/Catalogs/KAM/';

export async function getSalesChallengeCatalogByName({ cancelToken, name, params }) {
  return request(`${URL_SALESCHALLENGE}Catalogs/${name}`, {
    method: 'GET',
    cancelToken,
    params,
  });
}

export async function getSalesChallengeCatalogLines({ cancelToken, params }) {
  return request(`${URL_SALESCHALLENGE}Catalogs/BusinessLines`, {
    method: 'GET',
    cancelToken,
    params,
  });
}

//Controlador BusinessStructure
export async function getBusinessStructureLines({ cancelToken }) {
  return request(`${URL_CATALOGS}/BusinessStructure/Lines`, {
    method: 'GET',
    cancelToken,
  });
}

export async function getBusinessStructureSubregions({ cancelToken }) {
  return request(`${URL_CATALOGS}/BusinessStructure/Subregions`, {
    method: 'GET',
    cancelToken,
  });
}

//Controlador FixedCatalogs
export async function getFixedCatalogsSegmentation({ cancelToken }) {
  return request(`${URL_CATALOGS}/FixedCatalogs/Segmentation`, {
    method: 'GET',
    cancelToken,
  });
}

//
export async function getSalesByName({ cancelToken, name }) {
  return request(`${URL_SALESCHALLENGE}${name}`, {
    method: 'GET',
    cancelToken,
  });
}

//
export async function getKamCatalogByName({ cancelToken, name, params }) {
  return request(`${KAM_CATALOGS}${name}`, {
    method: 'GET',
    cancelToken,
    params,
  });
}

export async function getDaysLeft({ params, cancelToken }) {
  return request(`${URL_CATALOGS}/ProcessDates/DaysLeft/`, {
    method: 'GET',
    params,
    cancelToken,
  });
}

export async function getSalesProductsCatalogsByName({ params, cancelToken, name }) {
  return request(`${URL_SALESCHALLENGE}Products/${name}`, {
    method: 'GET',
    params,
    cancelToken,
  });
}
