import { Button } from 'antd';
import React from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import 'Domains/SalesChallengeRefactor/components/DeleteProductCell/css/styles.css';

const DeleteProductBinIcon = (props) => {
  return (
    <Button className="deleteProduct__btn" {...props} style={{ backgroundColor: 'rgba(0,0,0,0)' }}>
      <FaTrashAlt style={{ color: '#004a96', fontSize: '1.25rem' }} />
    </Button>
  );
};
export default DeleteProductBinIcon;
