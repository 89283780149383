import { takeLatest, all, call, put, cancelled } from 'redux-saga/effects';
import { responseOK } from 'Domains/SalesChallengeRefactor/utils';
import { DrawerEditServices } from '../../../services';
import { DrawerEditActions } from '../../actions';
import instance from 'services/request';
import { messageError } from 'utils/messages';

//Actions
const { PUT_IMAGE_LINE_REQUEST, PUTImageLineClean, PUTImageLineSet } = DrawerEditActions;
//Services
const { PUTImageLine } = DrawerEditServices;

// PUT IMAGE LINE
function* workPUTImageLine(action) {
  const { imageInfo, dataEndpoint, okPUT } = action.payload;
  const { CancelToken } = instance;
  const source = CancelToken.source();
  try {
    const response = yield call(PUTImageLine, {
      cancelToken: source.token,
      data: imageInfo,
      endpoint: dataEndpoint.endpoint,
    });
    if (responseOK(response)) {
      yield put(PUTImageLineSet(response.data));
      okPUT();
    } else {
      yield call(messageError, response.message);

      yield put(PUTImageLineClean());
    }
  } catch (error) {
    yield put(PUTImageLineClean());
    console.error('SAGAS ERROR', error);
  } finally {
    if (yield cancelled()) {
      yield put(PUTImageLineClean());
      source.cancel('cancelled Request');
    }
  }
}

export function* watchPUTImageLine() {
  yield all([takeLatest(PUT_IMAGE_LINE_REQUEST, workPUTImageLine)]);
}
