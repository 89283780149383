import { fork, all } from 'redux-saga/effects';

import * as filters from './filters';
import * as downloadTradeAgreements from './downloadTradeAgreements';
import * as downloadTradeAgreementsSellOut from './downloadTradeAgreementsSellOut';
import * as validationType from './validationType';
import * as validatorList from './validatorList';
import * as addTradeAgreementValidator from './addTradeAgreementValidator';
import * as addTradeAgreementSellOutValidator from './addTradeAgreementSellOutValidator';
import * as deleteTradeAgreementValidator from './deleteTradeAgreementValidator';
import * as deleteTradeAgreementSellOutValidator from './deleteTradeAgreementSellOutValidator';
import * as daysLeft from './daysLeft';
import * as downloadSalesChallenge from './downloadSalesChallenge';
import * as addSalesChallengeValidator from './addSalesChallengeValidator';
import * as deleteSalesChallengeValidator from './deleteSalesChallengeValidator';
import * as salesChallengeValidatorConfirm from './salesChallengeValidatorConfirm';
import * as salesChallengeTerritoryValidator from './salesChallengeTerritoryValidator';

export default function* rootSaga() {
  yield all(
    [
      ...Object.values(filters),
      ...Object.values(downloadTradeAgreements),
      ...Object.values(downloadTradeAgreementsSellOut),
      ...Object.values(validationType),
      ...Object.values(validatorList),
      ...Object.values(addTradeAgreementValidator),
      ...Object.values(addTradeAgreementSellOutValidator),
      ...Object.values(deleteTradeAgreementValidator),
      ...Object.values(deleteTradeAgreementSellOutValidator),
      ...Object.values(daysLeft),
      ...Object.values(downloadSalesChallenge),
      ...Object.values(addSalesChallengeValidator),
      ...Object.values(deleteSalesChallengeValidator),
      ...Object.values(salesChallengeValidatorConfirm),
      ...Object.values(salesChallengeTerritoryValidator),
    ].map(fork)
  );
}
