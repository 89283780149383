import request from 'services/request';
const URL_API = '/Agreements/api/v1/SellIn/Rules/';

/**
 * Creates the base URL for getting trade agreement rules in wich serialized
 * params are required. This params includes only bussinessLinesIds and serializedRegions
 * @param  {[object]} params An object that includes bussinessLinesIds and serializedRegions as arrays
 * @param  {[string]} string The base URL of the string that will be returned as default values
 * @return {[string]} Returns the composed string
 */
const makeConsultRuleSerializedUrl = (params = {}, baseUrl = 'URL') => {
  let composedURL = '';
  let serializedBussinesLines;
  let serializedRegions;
  let serializedSoldTo;

  if (params?.bussinessLinesIds?.length) {
    serializedBussinesLines = serializeArrayParams(params?.bussinessLinesIds, 'bussinessLinesIds');
    delete params.bussinessLinesIds;
  }
  if (params?.subRegionsIds?.length) {
    serializedRegions = serializeArrayParams(params?.subRegionsIds, 'subRegionsIds');
    delete params.subRegionsIds;
  }
  if (params?.soldTo?.length) {
    serializedSoldTo = serializeArrayParams(params?.soldTo, 'soldTo');
    delete params.soldTo;
  }

  if (serializedRegions && serializedBussinesLines) {
    composedURL = `${baseUrl}?${serializedRegions}&${serializedBussinesLines}`;
  } else if (serializedRegions) {
    composedURL = `${baseUrl}?${serializedRegions}`;
  } else if (serializedBussinesLines) {
    composedURL = `${baseUrl}?${serializedBussinesLines}`;
  } else {
    composedURL = `${baseUrl}`;
  }
  if (serializedSoldTo?.length) {
    if (serializedSoldTo && serializedBussinesLines) {
      composedURL = `${baseUrl}?${serializedSoldTo}&${serializedBussinesLines}`;
    } else if (serializedSoldTo) {
      composedURL = `${baseUrl}?${serializedSoldTo}`;
    } else if (serializedBussinesLines) {
      composedURL = `${baseUrl}?${serializedBussinesLines}`;
    } else {
      composedURL = `${baseUrl}`;
    }
  }
  return composedURL;
};

//TODO: Move this to utils
/**
 * Transforms an array into a string useful for queryString.
 * If an array is not provided it will return an empry string
 * @param  {[array]} params An array of  primitive variables
 * @param  {[string]} paramName A name that will be assigned to each elelemt
 * @return {[string]} Returns an useful for queryString
 */
const serializeArrayParams = (params = [], paramName = 'paramNameIds') => {
  let resultantString = '';
  const paramsLength = params?.length ?? 0;
  if (paramsLength > 0) {
    const stringsArray = params.map((parameter, index) => {
      const lastChar = index + 1 === paramsLength ? '' : '&';
      return `${paramName}=${parameter}${lastChar}`;
    });
    resultantString = stringsArray.reduce((accumulatedString, currentValue) => {
      return `${accumulatedString}${currentValue}`;
    });
  }
  return resultantString;
};

export async function getAgreementsOutOfAvality({ cancelToken, params }) {
  return request(`${URL_API}OutAvailability`, {
    method: 'GET',
    params,
    cancelToken,
  });
}

export async function getAgreementsIncentives({ cancelToken, params }) {
  const baseUrl = `${URL_API}Incentives`;
  const composedURL = makeConsultRuleSerializedUrl(params, baseUrl);
  return request(composedURL, {
    params,
    method: 'GET',
    cancelToken,
  });
}

export async function getAgreementsOutPolicy({ cancelToken, params }) {
  const baseUrl = `${URL_API}OutPolicyBL`;
  const composedURL = makeConsultRuleSerializedUrl(params, baseUrl);
  return request(composedURL, {
    params,
    method: 'GET',
    cancelToken,
  });
}

export async function getAgreementsOutPolicyClient({ cancelToken, params }) {
  const baseUrl = `${URL_API}OutPolicyClient`;
  const composedURL = makeConsultRuleSerializedUrl(params, baseUrl);
  return request(composedURL, {
    params,
    method: 'GET',
    cancelToken,
  });
}

// name : MassLoadExcel,  OutPolicy, MassLoadExcel
export async function massLoadExcelByRuleName({ cancelToken, params, name = 'MassLoadExcel' }) {
  return request(`${URL_API}${name}/MassLoadExcel`, {
    method: 'POST',
    data: params,
    cancelToken,
  });
}
