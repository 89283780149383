import React from 'react';
import { modelTable } from '../../../models/tables/registerChallenge';
import EditableTable from 'Domains/TradeAgreements/common/EditableTable';
import { saveVolume } from 'Domains/SalesChallengeRefactor/redux/actions/table';
import { connect } from 'react-redux';

function MainTable(props) {
  const { data, isLoading } = props;

  return (
    <>
      <EditableTable
        columns={modelTable}
        dataSource={data}
        loading={isLoading}
        className="editable-challenge"
      />
    </>
  );
}

function mapStateToProps(state) {
  return {
    data: state?.SALES_CHALLENGE?.table?.data || [],
    isLoading: state?.SALES_CHALLENGE?.registerSales?.challengePog?.isLoading || false,
  };
}

export default connect(mapStateToProps, { saveVolume })(MainTable);
