/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Row, Col, Select, Form } from 'antd';
import { validaForm } from '../helpers';
import { getPopupContainer } from 'Domains/SalesChallengeRefactor/helpers/helperForms';
import PropTypes from 'prop-types';
import { openNotification } from 'common';
import { SubRegion, Clients } from 'RootModule/catalogs';
import { BInput } from 'common/components';

const Client = ({
  getClientData,
  form,
  setIsActiveRegister,
  setNameClient,
  validateClient,
  isNacional,
  crop,
  setCrop,
  getTerritories,
  getLines,
}) => {
  const { Option } = Select;
  const [soldTo, setSoldTo] = useState('');
  const [dataCE, setdataCE] = useState([]);
  const onSuccess = (message) => {
    openNotification('success', message);
  };
  const errorMessage = (message) => {
    openNotification('error', message);
  };
  useEffect(() => {
    let allValues = form.getFieldValue();
    if (allValues.soldTo !== undefined && allValues.lineId !== undefined) {
      validateClient({
        lineId: allValues.lineId,
        soldTo: allValues.soldTo,
        onSuccess,
        errorMessage,
      });
    }
    dataCE.subregionId = undefined;
  }, [crop, soldTo]);

  useEffect(() => {
    let params = {
      SubRegions: dataCE.subregionId,
      BusinessLines: dataCE.lineId,
      IncludeAll: true,
    };
    getTerritories({ params });
  }, [dataCE.subregionId, dataCE.lineId]);

  useEffect(() => {
    let params = {
      ClientSoldTo: soldTo,
    };
    form.setFieldsValue({ lineId: undefined });
    if (soldTo !== '') getLines({ params, onSuccess: autoSelectOnlyOption });
  }, [soldTo]);

  const autoSelectOnlyOption = (options) => {
    if (options.length === 1) {
      form.setFieldsValue({ lineId: options[0].key });
      setCrop(options[0].key);
    }
    validaForm(form, setIsActiveRegister);
  };

  const handleLinkedSelect = (linked, value, options) => {
    let nameClient = options.options.filter((opt) => opt.soldTo === value)[0].name;
    if (typeof value !== 'object') {
      form.setFieldsValue({ [linked]: value });
      setSoldTo(value);
    }

    setNameClient(nameClient);
    form.setFieldsValue({
      territories: undefined,
      subregionId: undefined,
    });
  };
  const handleChange = (e) => {
    let data = form.getFieldValue();
    setdataCE(data);
  };
  const refact = (d) => {
    return (
      <Option key={d.key} value={d.key}>
        {d.value}
      </Option>
    );
  };
  return (
    <div>
      <Row>
        <Col span={6}>
          <Clients
            label="*Sold to"
            optionKey="soldTo"
            valueKey="soldTo"
            labelKey="soldTo"
            placeholder="Seleccionar"
            sourceId="BySoldTo"
            name="soldTo"
            allowClear={false}
            onChange={(value, option) => handleLinkedSelect('name', value, option)}
          />
        </Col>
        <Col span={12}>
          <Clients
            label="*Nombre"
            optionKey="soldTo"
            placeholder="Seleccionar"
            valueKey="soldTo"
            labelKey="name"
            sourceId="BySoldTo"
            name="name"
            allowClear={false}
            onChange={(value, option) => handleLinkedSelect('soldTo', value, option)}
          />
        </Col>
        <Col span={6}>
          <Form.Item name="lineId" label="*Línea de Negocio:" style={{ height: 20 }}>
            <Select
              allowClear={false}
              getPopupContainer={getPopupContainer}
              showSearch
              style={{ width: '100%' }}
              placeholder="Seleccionar"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {getClientData.Line && getClientData.Line.map((d) => refact(d))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <SubRegion
            label="*Subregión"
            name="subregionId"
            placeholder="Seleccionar"
            disabled={crop ? false : true}
            onChange={handleChange}
            requestParams={crop ? { BusinessLines: crop } : null}
          />
        </Col>
        <Col span={6}>
          <Form.Item name="placementId" label="*Presencia:" style={{ height: 20 }}>
            <Select
              getPopupContainer={getPopupContainer}
              showSearch
              style={{ width: '100%' }}
              placeholder="Seleccionar"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {getClientData.placements && getClientData.placements.map((d) => refact(d))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item label="*Territorio" name="territories" style={{ height: 20 }}>
            <Select
              allowClear
              mode={isNacional ? 'multiple' : null}
              getPopupContainer={getPopupContainer}
              showSearch
              maxTagCount={3}
              style={{ width: '100%' }}
              disabled={dataCE.subregionId && dataCE.lineId ? false : true}
              onChange={handleChange}
              placeholder="Seleccionar"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {getClientData.territories && getClientData.territories.map((d) => refact(d))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item name="segmentationId" label="*Segmentación:" style={{ height: 20 }}>
            <Select
              allowClear
              getPopupContainer={getPopupContainer}
              showSearch
              style={{ width: '100%' }}
              placeholder="Seleccionar"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {getClientData.segmentation && getClientData.segmentation.map((d) => refact(d))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            name="periodoVentasId"
            label="*Periodo de incentivo de ventas:"
            style={{ height: 20 }}
          >
            <Select
              getPopupContainer={getPopupContainer}
              showSearch
              style={{ width: '100%' }}
              placeholder="Seleccionar"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {getClientData.incentivePeriod && getClientData.incentivePeriod.map((d) => refact(d))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="periodoPOGId"
            label="*Periodo de incentivo de POG:"
            style={{ height: 20 }}
          >
            <Select
              getPopupContainer={getPopupContainer}
              showSearch
              style={{ width: '100%' }}
              placeholder="Seleccionar"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {getClientData.pogs && getClientData.pogs.map((d) => refact(d))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={6}>
          <BInput
            placeholder="Escribe Moneda"
            maxLength={10}
            name="tradeAgreementCurrency"
            label="*Moneda Acuerdo Comercial:"
          />
        </Col>
      </Row>
    </div>
  );
};
Client.propTypes = {
  getClientData: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  setIsActiveRegister: PropTypes.func.isRequired,
};

export default Client;
