/* eslint-disable no-empty-function */
import request from 'services/request';
const AGREEMENTS_API = '/Agreements/Api/v1/';
const AGREEMENTS_REPORT_SELL_IN = `${AGREEMENTS_API}SellIn/Reports/`;
const AGREEMENTS_REPORT_SELL_OUT = `${AGREEMENTS_API}SellOut/Reports/`;

const SALES_CHALLENGE_API = '/Sales/Api/v1/';
const SALES_CHALLENGE_REPORT = `${SALES_CHALLENGE_API}/Reports/`;
export const getTradeAgreementsValidatorsReport = (cancelToken) => {
  return request(`${AGREEMENTS_REPORT_SELL_IN}HistoryValidation`, {
    method: 'GET',
    cancelToken,
    responseType: 'blob',
    handlerEnabled: false,
  });
};

export const getTradeAgreementsSellOutValidatorsReport = (cancelToken) => {
  // TODO: Change endpoint
  return request(`${AGREEMENTS_REPORT_SELL_OUT}HistoryValidation`, {
    method: 'GET',
    cancelToken,
    responseType: 'blob',
    handlerEnabled: false,
  });
};

export function getSalesChallengeValidatorsReport({ cancelToken }) {
  return request(`${SALES_CHALLENGE_REPORT}HistorySalesChallengeValidation`, {
    method: 'GET',
    responseType: 'blob',
    cancelToken,
    handlerEnabled: false,
  });
}

export const getGenerateFile = (data, cancelToken) => {
  const customBaseURL = `${process.env.REACT_APP_API_REPORTS}Reports/Api/v1/Agreements`;

  return request(``, {
    method: 'POST',
    data,
    customBaseURL,
    cancelToken,
  });
};
