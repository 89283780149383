import {
  CLEAN_FILTERS,
  REGIONS_REQUEST,
  REGIONS_SUCCESS,
  REGIONS_FAIL,
  SUBREGIONS_REQUEST,
  SUBREGIONS_SUCCESS,
  SUBREGIONS_FAIL,
  SEGMENTS_REQUEST,
  SEGMENTS_SUCCESS,
  SEGMENTS_FAIL,
  BUSINESSLINE_REQUEST,
  BUSINESSLINE_SUCCESS,
  BUSINESSLINE_FAIL,
  BUSINESSLINE_REQUEST2,
  BUSINESSLINE_SUCCESS2,
  BUSINESSLINE_FAIL2,
  TERRITORY_REQUEST,
  TERRITORY_SUCCESS,
  TERRITORY_FAIL,
  ZONES_REQUEST,
  ZONES_SUCCESS,
  ZONES_FAIL,
  CLIENTS_REQUEST,
  CLIENTS_SUCCESS,
  CLIENTS_FAIL,
  CLIENTS_UPDATE,
  PRODUCTS_UPDATE,
  PRODUCTS_REQUEST,
  PRODUCTS_SUCCESS,
  PRODUCTS_FAIL,
  FAMILY_REQUEST,
  FAMILY_SUCCESS,
  FAMILY_FAIL,
  BUSINESSRULES_REQUEST,
  BUSINESSRULES_SUCCESS,
  BUSINESSRULES_FAIL,
  SEGMENT_BUSINESSRULES_REQUEST,
  SEGMENT_BUSINESSRULES_SUCCESS,
  SEGMENT_BUSINESSRULES_FAIL,
  YEARS_REQUEST,
  YEARS_SUCCESS,
  YEARS_FAIL,
  GET_PRODUCTS_ADD_REQUEST,
  GET_PRODUCTS_ADD_SUCCESS,
  GET_PRODUCTS_ADD_FAIL,
  GET_PRODUCTS_ADD_CLEAN,
  PRODUCTS_UPDATE_SUCCESS,
  POG_REQUEST,
  POG_SUCCESS,
  POG_FAIL,
  ROLES__REQUEST,
  ROLES__SUCCESS,
  ROLES_FAIL,
  AREAS_REQUEST,
  AREAS_SUCCESS,
  AREAS_FAIL,
  COUNTRIES_REQUEST,
  COUNTRIES_SUCCESS,
  COUNTRIES_FAIL,
  COMPANIES_REQUEST,
  COMPANIES_SUCCESS,
  COMPANIES_FAIL,
  CURRENCY_REQUEST,
  CURRENCY_SUCCESS,
  CURRENCY_FAIL,
} from '../actions/catalogs';

const catalogs = (state = {}, action) => {
  switch (action.type) {
    case CLEAN_FILTERS:
      return state;

    case AREAS_REQUEST:
      return {
        ...state,
        areas: {
          isLoading: true,
          data: [],
        },
      };

    case AREAS_SUCCESS:
      return {
        ...state,
        areas: {
          isLoading: false,
          data: action.payload,
        },
      };

    case AREAS_FAIL:
      return {
        ...state,
        areas: {
          isLoading: false,
          data: [],
        },
      };

    case POG_REQUEST:
      return {
        ...state,
        pogs: {
          isLoading: true,
          data: [],
        },
      };

    case POG_SUCCESS:
      return {
        ...state,
        pogs: {
          isLoading: false,
          data: action.payload,
        },
      };

    case POG_FAIL:
      return {
        ...state,
        pogs: {
          isLoading: false,
          data: [],
        },
      };

    case REGIONS_REQUEST:
      return {
        ...state,
        regions: {
          isLoading: true,
          data: [],
        },
      };

    case REGIONS_SUCCESS:
      return {
        ...state,
        regions: {
          isLoading: false,
          data: action.payload,
        },
      };

    case REGIONS_FAIL:
      return {
        ...state,
        regions: {
          isLoading: false,
          data: [],
        },
      };

    //BUSINESS LINE 1
    case BUSINESSLINE_REQUEST:
      return {
        ...state,
        businessLines: {
          isLoading: true,
          data: [],
        },
      };
    case BUSINESSLINE_SUCCESS:
      return {
        ...state,
        businessLines: {
          isLoading: false,
          data: action.payload,
        },
      };
    case BUSINESSLINE_FAIL:
      return {
        ...state,
        businessLines: {
          isLoading: false,
          data: [],
        },
      };

    //BUSINESS LINE 2
    case BUSINESSLINE_REQUEST2:
      return {
        ...state,
        businessLines2: {
          isLoading: true,
          data: [],
        },
      };
    case BUSINESSLINE_SUCCESS2:
      return {
        ...state,
        businessLines2: {
          isLoading: false,
          data: action.payload,
        },
      };
    case BUSINESSLINE_FAIL2:
      return {
        ...state,
        businessLines2: {
          isLoading: false,
          data: [],
        },
      };

    case SEGMENTS_REQUEST:
      return {
        ...state,
        segmentation: {
          isLoading: true,
          data: [],
        },
      };

    case SEGMENTS_SUCCESS:
      return {
        ...state,
        segmentation: {
          isLoading: false,
          data: action.payload,
        },
      };

    case SEGMENTS_FAIL:
      return {
        ...state,
        segmentation: {
          isLoading: false,
          data: [],
        },
      };

    //
    case SUBREGIONS_REQUEST:
      return {
        ...state,
        subregions: {
          isLoading: true,
          data: [],
        },
      };

    case SUBREGIONS_SUCCESS:
      return {
        ...state,
        subregions: {
          isLoading: false,
          data: action.payload,
        },
      };

    case SUBREGIONS_FAIL:
      return {
        ...state,
        subregions: {
          isLoading: false,
          data: [],
        },
      };

    case COUNTRIES_REQUEST:
      return {
        ...state,
        countries: {
          isLoading: true,
          data: [],
        },
      };

    case COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: {
          isLoading: false,
          data: action.payload,
        },
      };

    case COUNTRIES_FAIL:
      return {
        ...state,
        countries: {
          isLoading: false,
          data: [],
        },
      };

    case TERRITORY_REQUEST: {
      return {
        ...state,
        territory: {
          isLoading: true,
          data: [],
        },
      };
    }

    case TERRITORY_SUCCESS: {
      return {
        ...state,
        territory: {
          isLoading: false,
          data: action.payload,
        },
      };
    }

    case TERRITORY_FAIL: {
      return {
        ...state,
        territory: {
          isLoading: false,
          data: [],
        },
      };
    }

    case ZONES_REQUEST: {
      return {
        ...state,
        zones: {
          isLoading: true,
          data: [],
        },
      };
    }

    case ZONES_SUCCESS: {
      return {
        ...state,
        zones: {
          isLoading: false,
          data: action.payload,
        },
      };
    }

    case ZONES_FAIL: {
      return {
        ...state,
        zones: {
          isLoading: false,
          data: [],
        },
      };
    }

    case CLIENTS_REQUEST: {
      return {
        ...state,
        clients: {
          isLoading: true,
          data: [],
        },
      };
    }

    case CLIENTS_UPDATE:
    case CLIENTS_SUCCESS: {
      return {
        ...state,
        clients: {
          isLoading: false,
          data: action.payload,
        },
      };
    }

    case CLIENTS_FAIL: {
      return {
        ...state,
        clients: {
          isLoading: false,
          data: [],
        },
      };
    }

    case PRODUCTS_REQUEST: {
      return {
        ...state,
        products: {
          isLoading: true,
          data: [],
        },
      };
    }
    case PRODUCTS_UPDATE:
    case PRODUCTS_SUCCESS: {
      return {
        ...state,
        products: {
          isLoading: false,
          data: action.payload,
        },
      };
    }

    case PRODUCTS_FAIL: {
      return {
        ...state,
        products: {
          isLoading: false,
          data: [],
        },
      };
    }

    case FAMILY_REQUEST: {
      return {
        ...state,
        families: {
          isLoading: true,
          data: [],
        },
      };
    }

    case FAMILY_SUCCESS: {
      return {
        ...state,
        families: {
          isLoading: false,
          data: action.payload,
        },
      };
    }

    case FAMILY_FAIL: {
      return {
        ...state,
        families: {
          isLoading: false,
          data: [],
        },
      };
    }

    case BUSINESSRULES_REQUEST: {
      return {
        ...state,
        businessRules: {
          isLoading: true,
          data: [],
        },
      };
    }

    case BUSINESSRULES_SUCCESS: {
      return {
        ...state,
        businessRules: {
          isLoading: false,
          data: action.payload,
        },
      };
    }

    case BUSINESSRULES_FAIL: {
      return {
        ...state,
        businessRules: {
          isLoading: false,
          data: [],
        },
      };
    }

    case SEGMENT_BUSINESSRULES_REQUEST: {
      return {
        ...state,
        segmentBusinessRules: {
          isLoading: true,
          data: [],
        },
      };
    }

    case SEGMENT_BUSINESSRULES_SUCCESS: {
      return {
        ...state,
        segmentBusinessRules: {
          isLoading: false,
          data: action.payload,
        },
      };
    }

    case SEGMENT_BUSINESSRULES_FAIL: {
      return {
        ...state,
        segmentBusinessRules: {
          isLoading: false,
          data: [],
        },
      };
    }

    case YEARS_REQUEST: {
      return {
        ...state,
        years: {
          isLoading: true,
          data: [],
        },
      };
    }

    case YEARS_SUCCESS: {
      return {
        ...state,
        years: {
          isLoading: false,
          data: action.payload,
        },
      };
    }

    case YEARS_FAIL: {
      return {
        ...state,
        years: {
          isLoading: false,
          data: [],
        },
      };
    }

    case ROLES__REQUEST: {
      return {
        ...state,
        roles: {
          isLoading: true,
          data: [],
        },
      };
    }

    case ROLES__SUCCESS: {
      return {
        ...state,
        roles: {
          isLoading: false,
          data: action.payload,
        },
      };
    }

    case ROLES_FAIL: {
      return {
        ...state,
        roles: {
          isLoading: false,
          data: [],
        },
      };
    }

    case GET_PRODUCTS_ADD_REQUEST: {
      return {
        ...state,
        addProducts: {
          ...state?.addProducts,
          [action?.payload?.id]: {
            isLoading: true,
          },
        },
      };
    }

    case GET_PRODUCTS_ADD_SUCCESS: {
      return {
        ...state,
        addProducts: {
          ...state?.addProducts,
          [action?.payload?.id]: {
            isLoading: false,
            data: action.payload?.data,
          },
        },
      };
    }

    case GET_PRODUCTS_ADD_FAIL: {
      return {
        ...state,
        addProducts: {
          ...state?.addProducts,
          [action?.payload?.id]: {
            isLoading: false,
          },
        },
      };
    }

    case COMPANIES_REQUEST:
      return {
        ...state,
        companies: {
          isLoading: true,
          data: [],
        },
      };

    case COMPANIES_SUCCESS:
      return {
        ...state,
        companies: {
          isLoading: false,
          data: action.payload,
        },
      };

    case COMPANIES_FAIL:
      return {
        ...state,
        companies: {
          isLoading: false,
          data: [],
        },
      };

    // case CLEAN_ADD_PRODUCTS:
    case GET_PRODUCTS_ADD_CLEAN: {
      return {
        ...state,
        addProducts: {},
      };
    }

    case PRODUCTS_UPDATE_SUCCESS:
      return {
        ...state,
        addProducts: action.payload,
      };

    case CURRENCY_REQUEST:
      return {
        ...state,
        currency: {
          isLoading: true,
          data: [],
        },
      };

    case CURRENCY_SUCCESS:
      return {
        ...state,
        currency: {
          isLoading: false,
          data: action.payload,
        },
      };

    case CURRENCY_FAIL:
      return {
        ...state,
        currency: {
          isLoading: false,
          data: [],
        },
      };

    default:
      return state;
  }
};

export default catalogs;
