import React from 'react';
import { BButton } from 'common/components';
import { Row, Col } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { MSG_036 } from 'Domains/SalesChallengeRefactor/utils/messages';
import { openNotification } from 'common';
import { HistoryAction } from '..';
import { SendToValidate } from '..';
import {
  cleanTopFilters,
  queryClean,
  saveAgreenmentRequest,
} from 'Domains/TradeAgreementsSellOut/redux/actions';

const FooterActions = (props) => {
  const {
    subscriptions,
    productLabel,
    clientLabel,
    itCanSave,
    saveAgreenmentRequest: saveAgreenmentReq,
    cleanTopFilters: cleanFilters,
    queryClean: cleanQuery,
    productId,
    pageType,
  } = props;
  const history = useHistory();

  const redirectToConsult = () => {
    cleanFilters();
    cleanQuery({ state: 'idle', showBruteValue: true });
    history.push('/trade-agreements-sellout');
  };

  const handleOnSave = () => {
    const message = MSG_036({
      zone: subscriptions?.zoneLabel,
      client: clientLabel,
      product: productLabel,
    });
    saveAgreenmentReq({
      data: { whoSave: 'SAVE_BUTTON' },
      onSuccess: onSuccessValidate,
      message,
    });
  };

  const onSuccessValidate = (msg) => {
    openNotification('success', msg);
    setTimeout(() => {
      redirectToConsult();
    }, 300);
  };

  const handleCancelButton = () => {
    redirectToConsult();
  };

  const disableButtons = () => {
    if (productId) {
      return !itCanSave;
    }
    return true;
  };

  return (
    <Row gutter={6} justify="end" style={{ padding: 5 }}>
      {pageType === 3 && (
        <Col span={2}>
          <HistoryAction />
        </Col>
      )}

      <Col span={2}>
        <BButton
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 40,
            margin: '0 auto',
          }}
          //loading={isSending}
          icon={<SaveOutlined style={{ color: itCanSave ? 'white' : '#00000040' }} />}
          block
          disabled={disableButtons()}
          onClick={handleOnSave}
        />
      </Col>

      <Col span={10}>
        <BButton block label="Cancelar" onClick={handleCancelButton} style={{ height: 40 }} />
      </Col>

      <Col span={10}>
        <SendToValidate disabled={disableButtons()} onSuccessValidate={onSuccessValidate} />
      </Col>
    </Row>
  );
};

function mapStateToProps(state) {
  return {
    itCanSave: state?.TRADE_AGREEMENTS_SELLOUT?.registerTrade?.volumesValidsToSave ?? false,
    clientLabel: state?.TRADE_AGREEMENTS_SELLOUT?.messages?.clientLabel,
    productLabel: state?.TRADE_AGREEMENTS_SELLOUT?.messages?.productLabel,
    subscriptions: state?.TRADE_AGREEMENTS_SELLOUT?.subscriptions?.register,
    productId: state?.TRADE_AGREEMENTS_SELLOUT?.query?.productId,
    agreementId:
      state?.TRADE_AGREEMENTS_SELLOUT?.registerTrade?.currentAgreement?.data?.agreementId ?? false,
  };
}

export default connect(mapStateToProps, {
  saveAgreenmentRequest,
  cleanTopFilters,
  queryClean,
})(FooterActions);
