import { takeLatest, put, select } from 'redux-saga/effects';
import {
  REQUEST_VALIDATORS_DISABLED_DEPENDING,
  setValidatorsDisabledDepending,
} from '../actions/filters';
import {
  validatorsFiltersSalesChallengeOrder,
  validatorsFiltersTradeAgreementOrder,
} from 'Domains/Validations/consts';
import { getDisabledFiltersAndDependingFilter } from 'Domains/Validations/utils/filters';

function* workRequestValidatorsDisabledDepending(action) {
  const query = yield select((state) => state?.VALIDATIONS?.filters?.query);
  const validationType = yield select(
    (state) => state?.VALIDATIONS?.validationFieldsReducer?.validationFields
  );
  let validatorsDisabledDepending = {};
  let mainFiltersOrder =
    validationType === 'salesChallenge'
      ? validatorsFiltersSalesChallengeOrder
      : validatorsFiltersTradeAgreementOrder;
  let filtersOrder = {
    ...mainFiltersOrder,
  };
  const actionType = action?.payload;

  if (actionType === 'tradeAgreement') {
    delete filtersOrder.zoneId;
  }

  if (query) {
    validatorsDisabledDepending = getDisabledFiltersAndDependingFilter(
      query,
      filtersOrder,
      actionType
    );
  }

  yield put(setValidatorsDisabledDepending(validatorsDisabledDepending));
}

export function* watchRequestValidatorsDisabledDepending() {
  yield takeLatest(REQUEST_VALIDATORS_DISABLED_DEPENDING, workRequestValidatorsDisabledDepending);
}
