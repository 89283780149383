import { takeLatest, put, call, cancelled, select } from 'redux-saga/effects';
import instance from 'services/request';
import { CHANGE_CELL, saveTable } from 'Domains/TradeAgreements/redux/actions/table';
import {
  areVolumesValids,
  updateCurrentAgreement,
  updateReach,
  POST_AGREEMENT_REQUEST,
  postAgreementSuccess,
  postAgreementRequest,
  postAgreemenFail,
} from 'Domains/TradeAgreements/redux/actions/registerTrade';

import {
  cleanValuesToSum,
  checkIfVolumesAreValids,
} from 'Domains/SalesChallengeRefactor/redux/sagas/table';
import { orderArrayObjects } from 'Domains/SalesChallengeRefactor/utils';
import { postAgreements } from '../../services/registerTrade';
import { responseOK } from 'Domains/TradeAgreements/utils';
import { validateTableRequest as validateTableMandatory } from 'Domains/TradeAgreements/redux/actions/table';

export const processData = (currentTable, data, productInfo, showBruteValue) => {
  //menor que 9 es cuándo editamos una celda

  if (currentTable && currentTable.length > 0) {
    let updateRow = {};

    let currentVolume = currentTable.filter((row) => row.rowKey === 1);

    if (typeof data !== 'undefined' && data.key) {
      updateRow = { ...currentVolume[0], [data.key]: data.value };
    } else {
      updateRow = { ...currentVolume[0] };
    }

    let newData = [...currentTable];

    try {
      if (productInfo && typeof productInfo?.netPrice === 'number') {
        let totals = cleanValuesToSum(
          newData,
          updateRow,
          productInfo.netPrice,
          productInfo.moq,
          {},
          showBruteValue ? 3 : 2,
          productInfo
        );

        if (totals && Object.keys(totals).length > 0) {
          return totals.allData;
        }
      } else {
        console.warn('OJO: No se recibio el precio como número');
      }
    } catch (error) {
      alert('Ocurrio un error en el calculo ');
    }
  }
};

function* workSaveTableRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();

  try {
    const { payload } = action;
    const { data } = payload;
    const currentTable = yield select((state) => state?.TRADE_AGREEMENTS?.table?.data);
    const query = yield select((state) => state?.TRADE_AGREEMENTS?.query);
    const productInfo = yield select(
      (state) => state?.TRADE_AGREEMENTS?.registerTrade?.productInfo?.data ?? false
    );

    if (productInfo) {
      let sum = false;
      //tomar el precio según showBrutePrice

      sum = yield processData(currentTable, data, productInfo, query?.showBruteValue);

      if (sum) {
        let rowVolumes = sum.find((row) => row.rowKey === 1);
        if (rowVolumes) {
          let orderTable = sum.sort(orderArrayObjects('rowKey'));

          yield put(saveTable(orderTable));
          let totalValids = checkIfVolumesAreValids(rowVolumes, productInfo.moq);
          let allAreValids = totalValids.length === 12;
          yield put(areVolumesValids(allAreValids));
          if (data.whoSaveChallenge) {
            yield put(validateTableMandatory({ state: allAreValids, wasSend: false }));
          }

          if (data.isValid) {
            const territoryId = yield select(
              (state) => state?.TRADE_AGREEMENTS?.infoForAgreement?.territoryInfo?.territoryId
            );
            const { clientId, productId } = query;
            let dataToSave = {
              clientId,
              territoryId,
              productId,
              products: totalValids,
            };
            yield put(postAgreementRequest({ dataToSave, query }));
          }
        } else {
          //yield put(saveVolumeSuccess(sum));
        }
      }
    }
  } catch (_e) {
    console.error(_e);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* watchSaveVolumeRequest() {
  yield takeLatest(CHANGE_CELL, workSaveTableRequest);
}

function* workerPostAgreements(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();

  try {
    const { payload } = action;
    const { dataToSave } = payload;

    const response = yield call(postAgreements, {
      cancelToken: source.token,
      data: dataToSave,
    });

    if (responseOK(response)) {
      yield put(postAgreementSuccess());

      let update = {
        clientNetAmount: response.data.clientNetAmount,
        clientBruteAmount: response.data.clientBruteAmount,
      };

      update = { ...update, bruteQuarters: response.data.quartersBrute };

      update = { ...update, netQuarters: response.data.quartersNet };

      yield put(updateCurrentAgreement(update));
      yield put(updateReach(response.data.semaphore));
    } else {
      yield put(postAgreemenFail());
    }
  } catch (_e) {
    console.error(_e);
    yield put(postAgreemenFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* watchPostAgreements() {
  yield takeLatest(POST_AGREEMENT_REQUEST, workerPostAgreements);
}
