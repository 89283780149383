import request from '../../../services/request';
const URL_SALESCHALLENGE = '/Sales/Api/v1/Challenge/';

export async function rejectSalesChallengeByAdmin({ params, cancelToken }) {
  return request(`${URL_SALESCHALLENGE}RejectSalesChallengeByAdmin`, {
    method: 'PUT',
    data: params,
    cancelToken,
  });
}
