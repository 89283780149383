export const setAnOption = (
  options,
  optionKey,
  filterName,
  form,
  keyClient,
  clientId,
  handleOnFormValuesChange
) => {
  if (options?.length > 0) {
    const optionIndex = options.findIndex((option) => option?.key === optionKey);
    form.setFieldsValue({
      [filterName]: options[optionIndex]?.key ?? undefined,
    });
    if (keyClient || clientId) {
      form.setFieldsValue({
        clientId: keyClient ?? clientId,
      });
    }
    const allValues = form.getFieldsValue();
    const changedValues = { [filterName]: options[optionIndex]?.key };

    handleOnFormValuesChange({ changedValues, allValues });
  }
};
