import { takeLatest, put, call, cancelled, select } from 'redux-saga/effects';
import instance from 'services/request';
import { getTradeAgreementsValidatorsCatalog } from '../../services/tradeAgreementsValidators';
import { responseOK } from 'Domains/TradeAgreements/utils';
import {
  TRADE_AGREEMENTS_VALIDATORS_CATALOG_REQUEST,
  tradeAgreementsValidatorsCatalogSuccess,
  tradeAgreementsValidatorsCatalogFail,
} from '../actions/tradeAgreementsValidators';
import { messageError } from 'utils/messages';

function* workTradeAgreementsValidatorsRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const { params } = payload;
  const query = yield select((state) => state?.VALIDATIONS?.filters?.query);
  const { clientId } = query;
  try {
    let requestConfig = {
      cancelToken: source.token,
      name: 'Clients',
      params: params,
    };
    requestConfig = { ...requestConfig, name: 'Clients' };
    const response = yield call(getTradeAgreementsValidatorsCatalog, requestConfig);
    if (responseOK(response)) {
      const filteredData = response.data.filter((item) => {
        for (let index = 0; index < clientId.length; index++) {
          let foundIndex = item.clients.findIndex((el) => {
            return el.clientId === clientId[index];
          });

          if (foundIndex === -1) {
            return true;
          }
        }
        return false;
      });
      const hasError = response.data.length === 0;
      yield put(tradeAgreementsValidatorsCatalogSuccess(filteredData));
      if (hasError) messageError(response.message);
    } else {
      yield put(tradeAgreementsValidatorsCatalogFail());
    }
  } catch (e) {
    yield put(tradeAgreementsValidatorsCatalogFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request ');
    }
  }
}

export function* watchValidatorsRequest() {
  yield takeLatest(
    TRADE_AGREEMENTS_VALIDATORS_CATALOG_REQUEST,
    workTradeAgreementsValidatorsRequest
  );
}
