import SalesChallenge from 'Domains/SalesChallengeRefactor';
import SalesChallengeMassiveUpload from 'Domains/SalesChallengeRefactor/MassiveUpload';
import SalesChallengeRegister from 'Domains/SalesChallengeRefactor/pages/SalesChallengeRegister';

export const salesChallengeModule = {
  'sales-challenge': SalesChallenge,
  'sales-challenge_massive-upload': SalesChallengeMassiveUpload,
  'register_sales-challenge': SalesChallengeRegister,
  'adjusted_sales-challenge': SalesChallengeRegister,
  'update_sales-challenge': SalesChallengeRegister,
};
