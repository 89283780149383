import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal } from 'antd';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { openNotification } from 'common';
import getRootModule from 'RootModule';
import getValidationsModule from 'Domains/Validations/getValidationsModule';
import { downloadSalesChallengeReportRequest } from '../redux-saga/actions/downloadSalesChallengeReport';
import { downloadTradeAgreementsReportRequest } from '../redux-saga/actions/downloadTradeAgreement';
import { downloadTradeAgreementsSellOutReportRequest } from '../redux-saga/actions/downloadTradeAgreementSellOut';

const DownloadReport = () => {
  const [isVisibleModal, setIsVisibleModal] = useState(false);
  const dispatch = useDispatch();
  const generateReport = () => {
    setIsVisibleModal(!isVisibleModal);
  };

  const handleGenerateReport = async (type) => {
    if (type === 'salesChallenge') {
      dispatch(
        downloadSalesChallengeReportRequest({
          onSuccess: () =>
            openNotification(
              'success',
              'La descarga del archivo de reporte de validadores de desafío de ventas se generó exitosamente'
            ),
        })
      );
      return;
    }
    if (type === 'tradeAgreement') {
      dispatch(
        downloadTradeAgreementsReportRequest({
          onSuccess: () =>
            openNotification(
              'success',
              'La descarga del archivo de reporte de validadores de acuerdo comercial sell in se generó exitosamente'
            ),
        })
      );
      return;
    }
    dispatch(
      downloadTradeAgreementsSellOutReportRequest({
        onSuccess: () =>
          openNotification(
            'success',
            'La descarga del archivo de reporte de validadores de acuerdo comercial sell out se generó exitosamente'
          ),
      })
    );
  };

  return (
    <DynamicModuleLoader modules={[getRootModule(), getValidationsModule()]}>
      <Button className="reportBtn" onClick={generateReport}>
        Generar Reporte
      </Button>

      <Modal
        className={'reportModal'}
        onOk={generateReport}
        onCancel={generateReport}
        visible={isVisibleModal}
        footer={null}
        width={'min-content'}
      >
        <div className="reportModal__wrapper">
          <h4> Descargar Reporte validadores </h4>

          <p> ¿Qué reporte desea descargar? </p>
          <section className="reportModal__wrapper__buttons">
            <Button
              style={{ width: 'min-content' }}
              onClick={() => {
                handleGenerateReport('salesChallenge');
              }}
            >
              Desafío de ventas
            </Button>
            <Button
              style={{ width: 'min-content' }}
              onClick={() => {
                handleGenerateReport('tradeAgreement');
              }}
            >
              Acuerdo comercial Sell In
            </Button>
            <Button
              style={{ width: 'min-content' }}
              onClick={() => {
                handleGenerateReport('tradeAgreementSellOut');
              }}
            >
              Acuerdo comercial Sell Out
            </Button>
          </section>
        </div>
      </Modal>
    </DynamicModuleLoader>
  );
};

export default DownloadReport;
