import { takeEvery, all, call, put, cancelled } from 'redux-saga/effects';

import {
  PRICES_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
  PRICES_MASSIVE_UPLOAD_REQUEST_VERIFY,
  PRICES_MASSIVE_UPLOAD_REQUEST_UPLOAD,
  pricesMassiveUploadSetAllowUpload,
  pricesMassiveUploadClearAllowUpload,
  pricesMassiveUploadSetVerify,
  pricesMassiveUploadClearVerify,
  pricesMassiveUploadClearUpload,
} from '../actions';

import instance from 'services/request';

import { responseOK } from 'Domains/SalesChallengeRefactor/utils';

import { pricesMassiveUpload } from '../../services';
import {
  fileTo64String,
  validateTypeAndSize,
  summaryModal,
  summaryEndModal,
} from 'common/MassiveUploads/utils';
import { messageError } from 'utils/messages';

function* workPricesMassiveUploadSetAllowUpload(action) {
  try {
    yield pricesMassiveUploadSetAllowUpload(action.payload);
  } catch (error) {
    yield pricesMassiveUploadClearAllowUpload();
  } finally {
    if (yield cancelled()) {
      yield pricesMassiveUploadClearAllowUpload();
    }
  }
}

function* workPricesMassiveUploadRequestVerify({ payload }) {
  const { file, setFileString, selectSubRegion } = payload;

  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const isValidFile = validateTypeAndSize(file);
    if (!isValidFile) {
      messageError(
        'El formato del archivo de carga masiva de lista de precios debe ser .xlsx y tener un tamaño máximo de 20 MB.'
      );

      return;
    }

    const file64Str = yield call(fileTo64String, file);
    yield call(setFileString, file64Str);
    const response = yield call(pricesMassiveUpload, {
      cancelToken: source.token,
      subregion: selectSubRegion,
      data: {
        content: file64Str,
        overwrite: false,
        save: false,
      },
    });
    if (responseOK(response)) {
      let { data, message, allCorrectMessage } = response;
      let allCorrect = data.correct > 0 && data.totalErrors === 0;

      if (allCorrect) {
        data.allCorrectMessage = allCorrectMessage;
      }

      yield put(pricesMassiveUploadSetVerify(data));
      yield call(summaryModal, message, false);
    } else {
      yield put(pricesMassiveUploadClearVerify());
    }
  } catch (error) {
    yield pricesMassiveUploadClearVerify();
  } finally {
    if (yield cancelled()) {
      yield pricesMassiveUploadClearVerify();
      source.cancel('cancelled Request');
    }
  }
}

function* workPricesMassiveUploadRequestUpload({ payload }) {
  const { history, fileString, overwrite, selectSubRegion } = payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(pricesMassiveUpload, {
      cancelToken: source.token,
      subregion: selectSubRegion,
      data: {
        content: fileString,
        save: true,
        overwrite,
      },
    });
    if (responseOK(response)) {
      yield call(summaryEndModal, response.data, history, '/catalog/trade-agreement-info');
    } else {
      yield put(pricesMassiveUploadClearUpload());
    }
  } catch (error) {
    yield pricesMassiveUploadClearUpload();
  } finally {
    if (yield cancelled()) {
      yield pricesMassiveUploadClearUpload();
      source.cancel('cancelled Request');
    }
  }
}

export function* watchPricesMassiveUploadSetAllowUpload() {
  yield all([
    takeEvery(PRICES_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD, workPricesMassiveUploadSetAllowUpload),
  ]);
}

export function* watchPricesMassiveUploadRequestVerify() {
  yield all([
    takeEvery(PRICES_MASSIVE_UPLOAD_REQUEST_VERIFY, workPricesMassiveUploadRequestVerify),
  ]);
}

export function* watchPricesMassiveUploadRequestUpload() {
  yield all([
    takeEvery(PRICES_MASSIVE_UPLOAD_REQUEST_UPLOAD, workPricesMassiveUploadRequestUpload),
  ]);
}
