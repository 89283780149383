export const DELETE_TRADE_AGREEMENT_SELL_OUT_VALIDATOR_REQUEST =
  'DELETE_TRADE_AGREEMENT_SELL_OUT_VALIDATOR_REQUEST';
export const DELETE_TRADE_AGREEMENT_SELL_OUT_VALIDATOR_SUCCESS =
  'DELETE_TRADE_AGREEMENT_SELL_OUT_VALIDATOR_SUCCESS';
export const DELETE_TRADE_AGREEMENT_SELL_OUT_VALIDATOR_FAIL =
  'DELETE_TRADE_AGREEMENT_SELL_OUT_VALIDATOR_FAIL';

export function deleteTradeAgreementSellOutValidatorRequest(payload) {
  return {
    type: DELETE_TRADE_AGREEMENT_SELL_OUT_VALIDATOR_REQUEST,
    payload,
  };
}

export function deleteTradeAgreementSellOutValidatorSuccess(payload) {
  return { type: DELETE_TRADE_AGREEMENT_SELL_OUT_VALIDATOR_SUCCESS, payload };
}

export function deleteTradeAgreementSellOutValidatorFail(payload) {
  return { type: DELETE_TRADE_AGREEMENT_SELL_OUT_VALIDATOR_FAIL, payload };
}
