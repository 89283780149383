import request from 'services/request';

export async function addTradeAgreementValidator(params, cancelToken) {
  //console.log('params', params);
  return request('/Agreements/Api/v1/SellIn/Validators/Save', {
    method: 'POST',
    cancelToken,
    data: params,
  });
}
