export const VALIDATORS_LIST_SALES_CHALLENGE_REQUEST = 'VALIDATORS_LIST_SALES_CHALLENGE_REQUEST';
export const VALIDATORS_LIST_SALES_CHALLENGE_SUCCESS = 'VALIDATORS_LIST_SALES_CHALLENGE_SUCCESS';
export const VALIDATORS_LIST_SALES_CHALLENGE_FAILURE = 'VALIDATORS_LIST_SALES_CHALLENGE_FAILURE';

export const validatorsListSalesChallengeRequest = (payload) => ({
  type: VALIDATORS_LIST_SALES_CHALLENGE_REQUEST,
  payload: payload,
});

export const validatorsListSalesChallengeSuccess = (payload) => ({
  type: VALIDATORS_LIST_SALES_CHALLENGE_SUCCESS,
  payload: payload,
});

export const validatorsListSalesChallengeFailure = () => ({
  type: VALIDATORS_LIST_SALES_CHALLENGE_FAILURE,
});

export const VALIDATORS_LIST_TRADE_AGREEMENT_REQUEST = 'VALIDATORS_LIST_TRADE_AGREEMENT_REQUEST';
export const VALIDATORS_LIST_TRADE_AGREEMENT_SUCCESS = 'VALIDATORS_LIST_TRADE_AGREEMENT_SUCCESS';
export const VALIDATORS_LIST_TRADE_AGREEMENT_FAILURE = 'VALIDATORS_LIST_TRADE_AGREEMENT_FAILURE';

export const validatorsListTradeAgreementRequest = (payload) => ({
  type: VALIDATORS_LIST_TRADE_AGREEMENT_REQUEST,
  payload: payload,
});

export const validatorsListTradeAgreementSuccess = (payload) => ({
  type: VALIDATORS_LIST_TRADE_AGREEMENT_SUCCESS,
  payload: payload,
});

export const validatorsListTradeAgreementFailure = () => ({
  type: VALIDATORS_LIST_TRADE_AGREEMENT_FAILURE,
});

export const VALIDATORS_LIST_TRADE_AGREEMENT_SELL_OUT_REQUEST =
  'VALIDATORS_LIST_TRADE_AGREEMENT_SELL_OUT_REQUEST';
export const VALIDATORS_LIST_TRADE_AGREEMENT_SELL_OUT_SUCCESS =
  'VALIDATORS_LIST_TRADE_AGREEMENT_SELL_OUT_SUCCESS';
export const VALIDATORS_LIST_TRADE_AGREEMENT_SELL_OUT_FAILURE =
  'VALIDATORS_LIST_TRADE_AGREEMENT_SELL_OUT_FAILURE';

export const validatorsListTradeAgreementSellOutRequest = (payload) => ({
  type: VALIDATORS_LIST_TRADE_AGREEMENT_SELL_OUT_REQUEST,
  payload: payload,
});

export const validatorsListTradeAgreementSellOutSuccess = (payload) => ({
  type: VALIDATORS_LIST_TRADE_AGREEMENT_SELL_OUT_SUCCESS,
  payload: payload,
});

export const validatorsListTradeAgreementSellOutFailure = () => ({
  type: VALIDATORS_LIST_TRADE_AGREEMENT_SELL_OUT_FAILURE,
});
