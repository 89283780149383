import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { layoutContext } from '../../../../context';
import getTradeAgreementsModule from '../../tradeModule';
import getRootModule from 'RootModule';
import { useHistory } from 'react-router-dom';
import LayoutTradeAgreementsRegister from '../../components/LayoutTradeAgreementsRegister';
import { queryClean } from '../../redux/actions/query';
import { cleanRegisterTrade } from '../../redux/actions/registerTrade';
import { cleanTopFilters } from '../../redux/actions/filters';
import { getDaysLeftRequest } from '../../redux/actions/registerTrade';
import { getPageType } from '../../utils';

import '../TradeAgreements/style.css';
function TradeAgreementRegister(props) {
  const {
    cleanTopFilters: cleanFilters,
    cleanRegisterTrade: cleanTrade,
    queryClean: cleanQuery,
    getDaysLeftRequest: getDaysLeft,
  } = props;
  const history = useHistory();
  const { onChangeActiveComponent } = useContext(layoutContext);

  let pageType = getPageType(history);
  if (typeof pageType === 'undefined') {
    history.goBack();
  }

  useEffect(() => {
    cleanFilters();
    cleanTrade();
    cleanQuery({ state: 'idle', showBruteValue: true });
    getDaysLeft({ params: { processId: 7 }, setDaysLeft: true }); //7 días restantes
    getDaysLeft({ params: { processId: 10 } }); //fecha de proceso
    getDaysLeft({ params: { processId: 9 } }); //fecha de proceso
    try {
      localStorage.removeItem('query');
    } catch (error) {
      console.error(error);
    }
  }, [cleanQuery, cleanTrade, cleanFilters, getDaysLeft]);

  return (
    <LayoutTradeAgreementsRegister
      onChangeActiveComponent={onChangeActiveComponent}
      pageType={pageType.key}
    />
  );
}

const ConnectedTradeAgreementRegister = connect(null, {
  cleanRegisterTrade,
  queryClean,
  cleanTopFilters,
  getDaysLeftRequest,
})(TradeAgreementRegister);

const DynamicModule = () => (
  <DynamicModuleLoader modules={[getRootModule(), getTradeAgreementsModule()]}>
    <ConnectedTradeAgreementRegister />
  </DynamicModuleLoader>
);

export default DynamicModule;
