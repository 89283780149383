import React from 'react';
import LayoutTradeAgreementsGenerate from 'Domains/TradeAgreements/components/LayoutTradeAgreementsGenerate';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import getTradeAgreementsModule from '../../tradeModule';
import getRootModule from 'RootModule';
import './style.css';

function TradeAgreementsGenerate() {
  return <LayoutTradeAgreementsGenerate />;
}

const DynamicModule = () => (
  <DynamicModuleLoader modules={[getRootModule(), getTradeAgreementsModule()]}>
    <TradeAgreementsGenerate />
  </DynamicModuleLoader>
);

export default DynamicModule;
