import {
  APPROVE_TRADE_AGREEMENT_REQUEST,
  APPROVE_TRADE_AGREEMENT_SUCCESS,
  APPROVE_TRADE_AGREEMENT_FAIL,
  REJECT_TRADE_AGREEMENT_REQUEST,
  REJECT_TRADE_AGREEMENT_SUCCESS,
  REJECT_TRADE_AGREEMENT_FAIL,
  APPROVE_TRADE_AGREEMENT_KAM_REQUEST,
  APPROVE_TRADE_AGREEMENT_KAM_SUCCESS,
  APPROVE_TRADE_AGREEMENT_KAM_FAIL,
  REJECT_TRADE_AGREEMENT_KAM_REQUEST,
  REJECT_TRADE_AGREEMENT_KAM_SUCCESS,
  REJECT_TRADE_AGREEMENT_KAM_FAIL,
  GENERATE_TRADE_AGREEMENT_REQUEST,
  GENERATE_TRADE_AGREEMENT_SUCCESS,
  GENERATE_TRADE_AGREEMENT_FAIL,
} from '../actions/agreement';

const agreement = (state = {}, action) => {
  switch (action.type) {
    case APPROVE_TRADE_AGREEMENT_KAM_REQUEST:
    case APPROVE_TRADE_AGREEMENT_REQUEST:
    case GENERATE_TRADE_AGREEMENT_REQUEST:
    case REJECT_TRADE_AGREEMENT_KAM_REQUEST:
    case REJECT_TRADE_AGREEMENT_REQUEST: {
      return {
        ...state,
        validateStatus: {
          isLoading: true,
          data: [],
        },
      };
    }

    case APPROVE_TRADE_AGREEMENT_KAM_SUCCESS:
    case APPROVE_TRADE_AGREEMENT_SUCCESS:
    case GENERATE_TRADE_AGREEMENT_SUCCESS:
    case REJECT_TRADE_AGREEMENT_KAM_SUCCESS:
    case REJECT_TRADE_AGREEMENT_SUCCESS: {
      return {
        ...state,
        validateStatus: {
          isLoading: false,
          data: action.payload,
        },
      };
    }

    case APPROVE_TRADE_AGREEMENT_FAIL:
    case APPROVE_TRADE_AGREEMENT_KAM_FAIL:
    case GENERATE_TRADE_AGREEMENT_FAIL:
    case REJECT_TRADE_AGREEMENT_FAIL:
    case REJECT_TRADE_AGREEMENT_KAM_FAIL: {
      return {
        ...state,
        validateStatus: {
          isLoading: false,
          data: [],
        },
      };
    }

    default:
      return state;
  }
};
export default agreement;
