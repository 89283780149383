import React, { forwardRef } from 'react';
import { Form, Select } from 'antd';
import { setRuleRequired } from 'utils/validations';
import PropTypes from 'prop-types';
import { getPopupContainer as helperGetPopupContainer } from 'Domains/SalesChallengeRefactor/helpers/helperForms';

const BGroupSelect = forwardRef((props, ref) => {
  const {
    form,
    initialValue,
    name,
    isLoading,
    isRequired,
    label,
    options,
    placeholder,
    getPopupContainer,
    filterOption,
    allowClear,
    disabled,
    optionsProps,
    ...other
  } = props;

  const requiredRule = isRequired ? [setRuleRequired(label)] : [];

  const loadingSelect = {
    hasFeedback: true,
    validateStatus: 'validating',
  };

  const loading = isLoading ? loadingSelect : null;
  const initial = initialValue === null ? null : { initialValue: initialValue };
  const parentValue = props.value ? { value: props.value } : {};
  return options ? (
    <Form.Item
      label={label}
      {...loading}
      name={name}
      rules={[...requiredRule]}
      {...initial}
      {...other}
    >
      <Select
        showSearch
        data-testid="idtest"
        id={name}
        ref={ref}
        {...other}
        {...parentValue}
        size={'middle'}
        getPopupContainer={getPopupContainer}
        placeholder={placeholder}
        filterOption={filterOption}
        allowClear={allowClear}
        disabled={disabled || isLoading}
        options={options}
      />
    </Form.Item>
  ) : null;
});

BGroupSelect.propTypes = {
  allowClear: PropTypes.bool,
  filterOption: PropTypes.func,
  getPopupContainer: PropTypes.func,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLoading: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  optionsProps: PropTypes.object,
};

BGroupSelect.defaultProps = {
  allowClear: true,
  initialValue: null,
  isLoading: false,
  isRequired: false,
  label: 'Label',
  name: 'select',
  options: [],
  placeholder: 'Seleccione una opción',
  disabled: false,
  optionsProps: {},
  getPopupContainer: helperGetPopupContainer,
  filterOption: (input, option) => {
    if (isNaN(option.children))
      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    else return option.children.toString().indexOf(input.toLowerCase()) >= 0;
  },
};

BGroupSelect.displayName = 'BGroupSelect';

export default BGroupSelect;
