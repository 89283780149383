import { takeLatest, put, call, select } from 'redux-saga/effects';
import instance from 'services/request';
import {
  DELETE_TRADE_AGREEMENT_VALIDATOR_REQUEST,
  deleteTradeAgreementValidatorSuccess,
  deleteTradeAgreementValidatorFail,
} from '../actions/deleteTradeAgreementValidator';
//import { mockAddTradeAgreementValidator } from '../../services/addTradeAgreementValidator';
import { openNotification } from 'common/misc/openNotification';
import { responseOK } from 'utils';
import { deleteTradeAgreementValidator } from 'Domains/Validations/services/deleteTradeAgreementValidator';
import { validatorsListTradeAgreementRequest } from '../actions/validatorList';
import { tradeAgreementsValidatorsCatalogRequest } from 'RootModule/redux/actions/tradeAgreementsValidators';
import { clientsRequest } from 'RootModule/redux/actions/catalogs';

function* workDeleteTradeAgreementValidator(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { params } = action.payload;
    const response = yield call(deleteTradeAgreementValidator, params, source.token);
    const query = yield select((state) => state?.VALIDATIONS?.filters?.query);
    const paramsToGetValidators = { Territories: query.territoryId, Clients: query.clientId };
    const requestParams = {
      lineId: query.lineId,
      subRegionId: query.subRegionId,
      territoryId: query.territoryId,
    };
    const sourceId = 'Validators';

    if (responseOK(response)) {
      yield put(deleteTradeAgreementValidatorSuccess());
      openNotification(
        'success',
        response.message
          ? response.message
          : 'La eliminación del usuario validador se realizó correctamente.'
      );
      yield put(
        clientsRequest({
          requestParams,
          sourceId,
          mode: 'multiple',
        })
      );
      yield put(validatorsListTradeAgreementRequest({ params: paramsToGetValidators }));
      yield put(tradeAgreementsValidatorsCatalogRequest({ params: paramsToGetValidators }));
    } else {
      openNotification(
        'error',
        response.message ? response.message : 'No se pudo realizar la operación'
      );
    }
  } catch (error) {
    yield put(deleteTradeAgreementValidatorFail());
    console.error(error);
  }
}

export function* watchDeleteTradeAgreementValidator() {
  yield takeLatest(DELETE_TRADE_AGREEMENT_VALIDATOR_REQUEST, workDeleteTradeAgreementValidator);
}
