import request from '../../services/request';
import { paramsSerializer } from 'RootModule/services/catalogs';

const URL_API = `/Catalogs/Api/v1/Validators/UserSellOutByTerritory`;

export async function getTradeAgreementsSellOutValidatorsCatalog({ cancelToken, params }) {
  return request(`${URL_API}`, {
    method: 'GET',
    cancelToken,
    paramsSerializer,
    params,
  });
}
