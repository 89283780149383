import { takeEvery, all, call, put, cancelled } from 'redux-saga/effects';

import {
  InfoPOG_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
  InfoPOG_MASSIVE_UPLOAD_REQUEST_VERIFY,
  InfoPOG_MASSIVE_UPLOAD_REQUEST_UPLOAD,
  InfoPOGMassiveUploadSetAllowUpload,
  InfoPOGMassiveUploadClearAllowUpload,
  InfoPOGMassiveUploadSetVerify,
  InfoPOGMassiveUploadClearVerify,
  InfoPOGMassiveUploadClearUpload,
  EXIST_STATEMENTS,
} from '../actions';

import instance from 'services/request';

import { responseOK } from 'Domains/SalesChallengeRefactor/utils';

import { InfoPOGMassiveUpload, ExistsStatements } from '../../services';
import {
  fileTo64String,
  validateTypeAndSize,
  summaryModal,
  summaryEndModal,
} from 'common/MassiveUploads/utils';
import { messageError } from 'utils/messages';

function* workInfoPOGMassiveUploadSetAllowUpload(action) {
  try {
    yield InfoPOGMassiveUploadSetAllowUpload(action.payload);
  } catch (error) {
    yield InfoPOGMassiveUploadClearAllowUpload();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield InfoPOGMassiveUploadClearAllowUpload();
    }
  }
}

function* workInfoPOGMassiveUploadRequestVerify({ payload }) {
  const { file, setFileString } = payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const isValidFile = validateTypeAndSize(file);
    if (!isValidFile) {
      messageError(
        'El formato del archivo de carga masiva de POG debe ser .xlsx y tener un tamaño máximo de 20 MB.'
      );

      return;
    }

    const file64Str = yield call(fileTo64String, file);
    yield call(setFileString, file64Str);
    const response = yield call(InfoPOGMassiveUpload, {
      cancelToken: source.token,
      data: {
        content: file64Str,
        overwrite: false,
        save: false,
      },
    });
    if (responseOK(response)) {
      let { data, message, allCorrectMessage } = response;
      let allCorrect = data.correct > 0 && data.totalErrors === 0;

      if (allCorrect) {
        data.allCorrectMessage = allCorrectMessage;
      }

      yield put(InfoPOGMassiveUploadSetVerify(data));
      yield call(summaryModal, message, false);
    } else {
      yield put(InfoPOGMassiveUploadClearVerify());
    }
  } catch (error) {
    yield InfoPOGMassiveUploadClearVerify();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield InfoPOGMassiveUploadClearVerify();
      source.cancel('cancelled Request');
    }
  }
}

function* workInfoPOGMassiveUploadRequestUpload({ payload }) {
  const { history, fileString, overwrite } = payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(InfoPOGMassiveUpload, {
      cancelToken: source.token,
      data: {
        content: fileString,
        save: true,
        overwrite,
      },
    });
    if (responseOK(response)) {
      yield call(
        summaryEndModal,
        response.data,
        history,
        '/account-status/base-information/POG-acount/consult'
      );
    } else {
      yield put(InfoPOGMassiveUploadClearUpload());
    }
  } catch (error) {
    yield InfoPOGMassiveUploadClearUpload();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield InfoPOGMassiveUploadClearUpload();
      source.cancel('cancelled Request');
    }
  }
}
function* workExistsStatements({ payload }) {
  const { history } = payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(ExistsStatements, {
      cancelToken: source.token,
    });

    if (responseOK(response)) {
      if (response.data === false) {
        messageError(
          'Es necesario registrar la información correspondiente a elementos dinámicos en el sistema, antes de realizar el registro de POG. Realice el registro de elementos dinámicos de estado de cuenta para continuar con la operación.'
        );
        setTimeout(() => {
          history.push(`/account-status/base-information`);
        }, 100);
      }
    }
  } catch (error) {
    console.error(error);
  }
}
export function* watchInfoPOGMassiveUploadSetAllowUpload() {
  yield all([
    takeEvery(InfoPOG_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD, workInfoPOGMassiveUploadSetAllowUpload),
  ]);
}

export function* watchInfoPOGMassiveUploadRequestVerify() {
  yield all([
    takeEvery(InfoPOG_MASSIVE_UPLOAD_REQUEST_VERIFY, workInfoPOGMassiveUploadRequestVerify),
  ]);
}

export function* watchInfoPOGMassiveUploadRequestUpload() {
  yield all([
    takeEvery(InfoPOG_MASSIVE_UPLOAD_REQUEST_UPLOAD, workInfoPOGMassiveUploadRequestUpload),
  ]);
}

export function* watchExistsStatements() {
  yield all([takeEvery(EXIST_STATEMENTS, workExistsStatements)]);
}
