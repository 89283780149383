import request from 'services/request';
const AGREEMENTS_API = '/Agreements/Api/v1/SellIn/';
const AGREEMENTS_INFO = `${AGREEMENTS_API}Info/`;

export async function getInfo(indicatorName, params, cancelToken) {
  return request(`${AGREEMENTS_INFO}${indicatorName}`, {
    method: 'GET',
    params,
    cancelToken,
  });
}
