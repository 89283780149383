import React, { forwardRef } from 'react';
import { Switch, Form } from 'antd';
import PropTypes from 'prop-types';
import './style.css';

export const BSwitch = forwardRef((props, _ref) => {
  const {
    disabled,
    form,
    inputKey,
    initialValue,
    isRequired,
    className,
    label,
    checkedChildren,
    unCheckedChildren,
    onChange,
    wrapperClassName,
    name,
    isLoading,
    title,
    ...other
  } = props;

  const requiredRule = isRequired ? [{ required: true, message: 'Este campo es obligatorio' }] : [];

  return (
    <div className={`basf-switch-control  ${wrapperClassName}`}>
      <Form.Item
        label={label}
        name={name}
        rules={[...requiredRule]}
        {...other}
        valuePropName="checked"
      >
        <Switch
          data-testid="mySuitch"
          disabled={disabled}
          className={className}
          checkedChildren={checkedChildren}
          unCheckedChildren={unCheckedChildren}
          onChange={(checked) => {
            onChange(checked);
          }}
          {...other}
        />
      </Form.Item>
    </div>
  );
});

BSwitch.propTypes = {
  className: PropTypes.string,
  checkedChildren: PropTypes.string,
  unCheckedChildren: PropTypes.string,
  //onChange: PropTypes.func,
  inputKey: PropTypes.string,
  isRequired: PropTypes.bool,
  other: PropTypes.object,
  initialValue: PropTypes.bool,
  wrapperClassName: PropTypes.string,
};

BSwitch.defaultProps = {
  inputKey: 'BSwitch',
  className: '',
  checkedChildren: '',
  unCheckedChildren: '',
  //onChange: () => {},
  other: null,
  isRequired: true,
  initialValue: false,
  wrapperClassName: 'myBSwitch',
};

BSwitch.displayName = 'BSwitch';
