import { createStore } from 'redux-dynamic-modules';

import initialState from './initialState';
import { getSagaExtension } from 'redux-dynamic-modules-saga';
const enhancers = [];

const isDevelopment = process.env.REACT_APP_REDUX === 'true';
if (isDevelopment && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__) {
  enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__());
}
const store = createStore(
  {
    initialState,
    enhancements: enhancers,
    extensions: [getSagaExtension()],
  },
  [],
  []
);

export default store;
