import { takeLatest, put, cancelled, call } from 'redux-saga/effects';
import instance from 'services/request';
import { responseOK } from 'Domains/SalesChallengeRefactor/utils';
import { validateElementsActions } from '../../actions';
import { requestElementsExistence } from '../../../services';
import { openNotification } from 'common';

const {
  validateElementsSet,
  validateElementsFail,
  VALIDATE_ELEMENTS_REQUEST,
  GET_VALIDATE_ELEMENTS,
  VALIDATE_ELEMENTS_FAIL,
} = validateElementsActions;

function* reqValidateElements(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const { history } = payload;
  try {
    const response = yield call(requestElementsExistence, { cancelToken: source.token, payload });
    if (responseOK(response)) {
      yield put(validateElementsSet(response.data));
      if (response.data === false) {
        setTimeout(() => {
          openNotification(
            'error',
            response.message
              ? response.message
              : 'Es necesario registrar la información correspondiente a elementos dinámicos en el sistema, antes de realizar la Administración de Elementos Dinámicos de Estado de cuenta. Realice el registro de elementos dinámicos de estado de cuenta para continuar con la operació'
          );
          history.push('/account-status');
        }, 2000);
      }
    } else {
      yield put(validateElementsFail('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(validateElementsFail(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* SagaRequestSectionData() {
  yield takeLatest(VALIDATE_ELEMENTS_REQUEST, reqValidateElements);
}

export function* SagaGetSectionData() {
  yield takeLatest(GET_VALIDATE_ELEMENTS, validateElementsSet);
}

export function* SagaSectionDataFail() {
  yield takeLatest(VALIDATE_ELEMENTS_FAIL, validateElementsFail);
}
