import { policyFormatter } from './policyFormatter';

export const handleFilters = (
  currentChange,
  form,
  setDisabledFilters,
  setRequestParamsSubscription,
  requestParamsSubscription,
  setAllowRequests,
  formValues,
  hasTerritoryId,
  getProductInfo
) => {
  const actions = {
    territoryId: () => {
      form.setFieldsValue({
        clientId: undefined,
        businessRule: undefined,
        family: undefined,
        productId: undefined,
      });
      setDisabledFilters({
        lineId: false,
        territoryId: null,
        clientId: false,
        businessRule: true,
        family: true,
        productId: true,
      });
      setRequestParamsSubscription({
        ...requestParamsSubscription,
        clientId: {
          ...requestParamsSubscription.clientId,
          territoryId: formValues.territoryId,
          year: formValues.year,
        },
        family: { ...requestParamsSubscription.family, territoryId: formValues.territoryId },
        productId: {
          ...requestParamsSubscription.productId,
          territoryId: formValues.territoryId,
        },
      });
      setAllowRequests({
        lineId: false,
        territoryId: null,
        clientId: true,
        businessRule: false,
        family: false,
        productId: false,
      });
    },
    clientId: () => {
      form.setFieldsValue({
        businessRule: undefined,
        family: undefined,
        productId: undefined,
      });
      setDisabledFilters({
        lineId: false,
        territoryId: null,
        clientId: false,
        businessRule: false,
        family: true,
        productId: true,
      });
      setRequestParamsSubscription({
        ...requestParamsSubscription,
        family: {
          ...requestParamsSubscription.family,
          clientId: formValues.clientId,
          territoryId: formValues.territoryId,
          year: formValues.year,
        },
        productId: {
          ...requestParamsSubscription.productId,
          clientId: formValues.clientId,
          year: formValues.year,
        },
      });
      setAllowRequests({
        lineId: false,
        territoryId: null,
        clientId: false,
        businessRule: true,
        family: false,
        productId: false,
      });
    },
    businessRule: () => {
      form.setFieldsValue({
        family: undefined,
        productId: undefined,
      });
      const { PolicyTypeId, Portafolio } = policyFormatter(formValues.businessRule);
      setDisabledFilters({
        lineId: false,
        territoryId: null,
        clientId: false,
        businessRule: false,
        family: false,
        productId: true,
      });
      setRequestParamsSubscription({
        ...requestParamsSubscription,
        family: {
          ...requestParamsSubscription.family,
          PolicyTypeId,
          Portafolio,
          ...hasTerritoryId(),
        },
        productId: {
          ...requestParamsSubscription.productId,
          PolicyTypeId,
          Portafolio,
        },
      });
      setAllowRequests({
        lineId: false,
        territoryId: null,
        clientId: false,
        businessRule: false,
        family: true,
        productId: false,
      });
    },
    family: () => {
      form.setFieldsValue({
        productId: undefined,
      });
      setDisabledFilters({
        year: false,
        regionId: false,
        lineId: false,
        subRegionId: false,
        territoryId: null,
        clientId: false,
        businessRule: false,
        family: false,
        productId: false,
      });
      setRequestParamsSubscription({
        ...requestParamsSubscription,
        productId: {
          ...requestParamsSubscription.productId,
          family: formValues.family,
          ...hasTerritoryId(),
        },
      });
      setAllowRequests({
        year: false,
        regionId: false,
        lineId: false,
        subRegionId: false,
        territoryId: null,
        clientId: false,
        businessRule: false,
        family: false,
        productId: true,
      });
    },
    productId: () => {
      const { PolicyTypeId, Portafolio } = policyFormatter(formValues.businessRule);
      getProductInfo({
        params: {
          ...hasTerritoryId(),
          clientId: formValues.clientId,
          productId: formValues.productId,
          year: formValues.year,
          PolicyTypeId,
          Portafolio,
        },
      });
    },
    default: () => {
      return;
    },
  };

  const handleAction = () => {
    (actions[currentChange] || actions.default)();
  };

  handleAction();
};
