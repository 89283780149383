import { takeLatest, put, cancelled, call } from 'redux-saga/effects';
import instance from 'services/request';
import { responseOK } from 'Domains/SalesChallengeRefactor/utils';
import { IncentiveCreditNotesActions } from '../../actions';
import { requestSectionData } from '../../../services';

const {
  getIcnClients,
  icnClientsFail,
  cleanIcnClients,
  ICN_CLIENTS_REQUEST,
  GET_ICN_CLIENTS,
  ICN_CLIENTS_FAIL,
  CLEAN_ICN_CLIENTS,
  INC_SUBREGIONS_REQUEST,
  incSubregionsSet,
  incSubregionsClean,
} = IncentiveCreditNotesActions;

function* reqIcnClients(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  try {
    const response = yield call(requestSectionData, { cancelToken: source.token, payload });
    if (responseOK(response)) {
      yield put(getIcnClients(response.data ?? []));
    } else {
      yield put(icnClientsFail('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(icnClientsFail(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}
function* reqIcnSubregions(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  try {
    const response = yield call(requestSectionData, { cancelToken: source.token, payload });
    if (responseOK(response)) {
      yield put(incSubregionsSet(response.data ?? []));
    } else {
      yield put(incSubregionsClean('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(incSubregionsClean(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* SagaIncSubregionsData() {
  yield takeLatest(INC_SUBREGIONS_REQUEST, reqIcnSubregions);
}

export function* SagaRequestSectionData() {
  yield takeLatest(ICN_CLIENTS_REQUEST, reqIcnClients);
}

export function* SagaGetSectionData() {
  yield takeLatest(GET_ICN_CLIENTS, getIcnClients);
}

export function* SagaSectionDataFail() {
  yield takeLatest(ICN_CLIENTS_FAIL, icnClientsFail);
}

export function* SagaSectionDataClean() {
  yield takeLatest(CLEAN_ICN_CLIENTS, cleanIcnClients);
}
