/* eslint-disable no-console */
import { takeEvery, put, call, all, cancelled } from 'redux-saga/effects';
import { READ_ONE_CLIENT_REQUEST, readOneClientSuccess, readOneClientClean } from '../actions';
import { getOneClient } from '../../services/index';
import instance from 'services/request';
import { responseOK } from 'Domains/SalesChallengeRefactor/utils';
/* import ErrorBoundary from 'antd/lib/alert/ErrorBoundary'; */

function* workReadOneClientRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { clientId } = payload;

    const response = yield call(getOneClient, {
      clientId,
    });

    if (responseOK(response)) {
      const dataToSave = {
        ...response.data,
        clientManagers: response.data.clientManagers?.map((manager, index) => {
          return { ...manager, id: index };
        }),
        clientSigners: response.data.clientSigners.map((signer, index) => {
          return { ...signer, id: index };
        }),
      };
      yield put(readOneClientSuccess(dataToSave));
    } else {
      yield put(readOneClientClean());
    }
  } catch (error) {
    yield put(readOneClientClean(error.message));
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

export function* watchReadOneClientRequest() {
  yield all([takeEvery(READ_ONE_CLIENT_REQUEST, workReadOneClientRequest)]);
}
