export const setAnOptionGroup = (
  options,
  optionKey,
  filterName,
  form,
  keyClient,
  clientId,
  handleOnFormValuesChange
) => {
  if (options?.length > 0) {
    const optionIndex = options.findIndex((option) => option?.value === optionKey);
    form.setFieldsValue({
      [filterName]: options[optionIndex]?.value ?? undefined,
    });
    if (keyClient || clientId) {
      form.setFieldsValue({
        clientId: keyClient ?? clientId,
      });
    }
    const allValues = form.getFieldsValue();
    const changedValues = { [filterName]: options[optionIndex]?.value };

    handleOnFormValuesChange({ changedValues, allValues });
  }
};
