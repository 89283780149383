import React from 'react';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import getRootModule from 'RootModule';

import getTradeAgreementsSellOutModule from 'Domains/TradeAgreementsSellOut/tradeSellOutModule';
import './style.css';
import { LayoutTradeAgreementsGenerate } from 'Domains/TradeAgreementsSellOut/components/LayoutTradeAgreementsGenerate';

function TradeSellOutGenerate() {
  return <LayoutTradeAgreementsGenerate />;
}

const DynamicModule = () => (
  <DynamicModuleLoader modules={[getRootModule(), getTradeAgreementsSellOutModule()]}>
    <TradeSellOutGenerate />
  </DynamicModuleLoader>
);

export default DynamicModule;
