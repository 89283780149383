import request from 'services/request';
const DELETE_VALIDATOR_API = '/Sales/Api/v1/Validators/Delete';

export async function deleteSalesChallengeValidator(params, cancelToken) {
  return request(`${DELETE_VALIDATOR_API}`, {
    method: 'DELETE',
    data: params,
    cancelToken,
  });
}
