import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { filtersEnum } from 'RootModule/models/enums/filtersEnum';
import { segmentBusinessRulesRequest } from 'RootModule/redux/actions/catalogs';
import BGroupSelect from 'common/components/BGroupSelect';

function SegmentBusinessRules(props) {
  const {
    businessRules,
    segmentBusinessRulesRequest: getBusinessRules,
    onOptionsChange,
    sourceId,
    requestParams,
    askFromService,
    onSuccess,
    ...other
  } = props;

  useEffect(() => {
    if (askFromService) {
      getBusinessRules({ requestParams, sourceId, onSuccess });
    }
  }, [getBusinessRules, requestParams, askFromService, sourceId, onSuccess]);

  useEffect(() => {
    if (onOptionsChange) {
      onOptionsChange(getBusinessRules.data ?? []);
    }
  }, [getBusinessRules, onOptionsChange]);
  return (
    <BGroupSelect
      isLoading={businessRules?.isLoading}
      disabled={businessRules?.isLoading}
      options={businessRules?.data}
      {...other}
    />
  );
}

SegmentBusinessRules.propTypes = {
  segmentBusinessRulesRequest: PropTypes.func,
  initialValue: PropTypes.any,
  requestParams: PropTypes.object,
  sourceId: PropTypes.oneOf(['Agreements', 'Sales', 'Agreements-SellOut']),
  askFromService: PropTypes.bool,
  onOptionsChange: PropTypes.func,
};

SegmentBusinessRules.defaultProps = {
  segmentBusinessRulesRequest: null,
  label: filtersEnum.businessRules.label,
  name: filtersEnum.businessRules.name,
  initialValue: undefined,
  requestParams: {},
  sourceId: 'Sales',
  askFromService: true,
  onOptionsChange: null,
};

function stateProps(state) {
  return {
    businessRules: state.APP?.catalogs?.segmentBusinessRules,
  };
}

export default connect(stateProps, {
  segmentBusinessRulesRequest,
})(SegmentBusinessRules);
