export const useValidatorsParams = () => {
  const getAddTradeAgreementsParams = ({ id, clientId, territoryId }) => {
    return {
      clientId: clientId,
      userId: id,
      territoryId: territoryId,
    };
  };

  const getDeleteTradeAgreementsParams = ({ validatorsToDisable, selectedUser, territoryId }) => {
    return {
      clientId: validatorsToDisable,
      userId: [selectedUser],
      territoryId: territoryId,
    };
  };

  const getAddSalesChallengeAddParams = ({ id, territoryId }) => {
    return {
      users: id,
      territories: territoryId,
    };
  };

  const getDeleteSalesChallengeParams = ({ validatorsToDisable, selectedUser }) => {
    return {
      users: [selectedUser],
      territories: validatorsToDisable,
    };
  };

  return {
    getAddTradeAgreementsParams,
    getDeleteTradeAgreementsParams,
    getAddSalesChallengeAddParams,
    getDeleteSalesChallengeParams,
  };
};
