// /Catalogs/Api/v1/Validators/ValidatorsChallenge
// /Catalogs/Api/v1/Validators/ValidatorsAgreement
import request from 'services/request';
import { paramsSerializer } from 'RootModule/services/catalogs';

const VALIDATORS_CATALOGS_API = '/Catalogs/Api/v1/Validators/';

export async function getValidatorsChallenge(params, cancelToken) {
  return request(`${VALIDATORS_CATALOGS_API}ValidatorsChallenge`, {
    method: 'GET',
    params,
    cancelToken,
    paramsSerializer,
  });
}

export async function getValidatorsAgreement(params, cancelToken) {
  return request(`${VALIDATORS_CATALOGS_API}SellIn`, {
    method: 'GET',
    params,
    cancelToken,
    paramsSerializer,
  });
}

export async function getValidatorsSellOutAgreement(params, cancelToken) {
  return request(`${VALIDATORS_CATALOGS_API}SellOut`, {
    method: 'GET',
    params,
    cancelToken,
    paramsSerializer,
  });
}
