import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import BSelect from 'common/components/BSelect';
import { connect } from 'react-redux';
import { zonesRequest } from 'RootModule/redux/actions/catalogs';
import { filtersEnum } from 'RootModule/models/enums/filtersEnum';
import { handleOptionsProps } from 'Domains/SalesChallengeRefactor/helpers/helperForms';

function Zones(props) {
  const {
    zones,
    zonesRequest: getZones,
    askFromService,
    requestParams,
    sourceId,
    onSuccess,
    mode,
    onChange,
    ruleToClassName,
    optionsProps,
    ...other
  } = props;

  useEffect(() => {
    if (askFromService) getZones({ requestParams, sourceId, onSuccess, mode });
  }, [getZones, askFromService, requestParams, sourceId, onSuccess, mode]);

  const handleOnChange = (value) => {
    if (onChange) onChange(value, { options: zones?.data, name: props.name });
  };

  return (
    <BSelect
      disabled={zones.isLoading}
      isLoading={zones.isLoading}
      options={zones.data}
      optionsProps={handleOptionsProps(zones, optionsProps, ruleToClassName)}
      getPopupContainer={(trigger) => trigger.parentNode}
      mode={mode}
      onChange={handleOnChange}
      {...other}
    />
  );
}

Zones.propTypes = {
  askFromService: PropTypes.bool,
  initialValue: PropTypes.any,
  label: PropTypes.string,
  name: PropTypes.string,
  requestParams: PropTypes.object,
  ruleToClassName: PropTypes.func,
  optionsProps: PropTypes.object,
  sourceId: PropTypes.oneOf(['Agreements', 'Agreements-SellOut', 'Sales', 'Fixed', 'Validators']),
  zones: PropTypes.object,
  zonesRequest: PropTypes.func,
};

Zones.defaultProps = {
  askFromService: true,
  initialValue: undefined,
  label: filtersEnum.zones.label,
  name: filtersEnum.zones.name,
  requestParams: {},
  ruleToClassName: () => {
    return;
  },
  optionsProps: {},
  sourceId: 'Sales',
  zones: { data: [], isLoading: false },
  zonesRequest: null,
};

function mapStateToProps(state) {
  return {
    zones: state.APP?.catalogs?.zones,
  };
}

export default connect(mapStateToProps, { zonesRequest })(Zones);
