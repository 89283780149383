import { takeLatest, put, call, cancelled, select } from 'redux-saga/effects';
import instance from 'services/request';
import { getValidators } from '../../services/salesChallengeValidators';
import { responseOK } from 'Domains/TradeAgreements/utils';
import {
  SALES_CHALLENGE_VALIDATORS_CATALOG_REQUEST,
  salesChallengeValidatorsCatalogSuccess,
  salesChallengeValidatorsCatalogFail,
} from '../actions/salesChallengeValidators';
import { messageError } from 'utils/messages';

function* workValidatorsRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const { params } = payload;
  try {
    let requestConfig = {
      cancelToken: source.token,
      params: params,
    };
    const query = yield select((state) => state?.VALIDATIONS?.filters?.query);
    const { territoryId } = query;
    const response = yield call(getValidators, requestConfig);

    if (responseOK(response)) {
      const filteredData = response.data.filter((item) => {
        for (let index = 0; index < territoryId.length; index++) {
          let foundIndex = item.territories.findIndex(
            (el) => el.territoryId === territoryId[index]
          );
          if (foundIndex === -1) {
            return true;
          }
        }
        return false;
      });
      const hasError = response.data.length === 0;
      yield put(salesChallengeValidatorsCatalogSuccess(filteredData));
      if (hasError) messageError(response.message);
    } else {
      yield put(salesChallengeValidatorsCatalogFail());
    }
  } catch (e) {
    yield put(salesChallengeValidatorsCatalogFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request ');
    }
  }
}

export function* watchValidatorsRequest() {
  yield takeLatest(SALES_CHALLENGE_VALIDATORS_CATALOG_REQUEST, workValidatorsRequest);
}
