import { getPopupContainer } from 'Domains/SalesChallengeRefactor/helpers/helperForms';
import React, { useEffect, useState, useContext } from 'react';
import { ProgramaSelect, ProductosSelect } from '../components';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import BRregisterModule from '../BRregisterModule';
import { layoutContext } from '../../../context';
import { useHistory } from 'react-router-dom';
import { Form, Select, Row, Col } from 'antd';
import getRootModule from 'RootModule';
import { ActionButtons } from 'common';
import { connect } from 'react-redux';
import {
  postOneSegmentationRequest,
  deleteOneSegmentationRequest,
  putOneSegmentationRequest,
  getInPoliciyProductsRequest,
  postProductClientRequest,
  postProductBLRequest,
  postIncentivesRulesRequest,
  checkExistanceIncentivesRequest,
  getClientSingleIdRequest,
} from '../redux-saga/actions';
import segmentationFilter from '../utils/segmentationFilter';

const BusinessRulesForm = (props) => {
  const {
    postOneSegmentationRequest: postOneSegmentation,
    deleteOneSegmentationRequest: deleteSegment,
    putOneSegmentationRequest: putSegment,
    getInPoliciyProductsRequest: getIPPR,
    postProductClientRequest: postProductClient,
    postProductBLRequest: postProductBL,
    postIncentivesRulesRequest: postIncentivesRules,
    checkExistanceIncentivesRequest: checkExistanceIncentives,
    // getClientSingleIdRequest: getClientSingleId,
  } = props;

  const history = useHistory();
  const { onChangeActiveComponent } = useContext(layoutContext);
  const { Option } = Select;
  const [form] = Form.useForm();
  const [btnTF, setbtnTF] = useState(true);
  const [Switch1, setSwitch1] = useState({
    checked: false,
    disabled: false,
  });
  const [Switch2, setSwitch2] = useState({
    checked: false,
    disabled: false,
  });
  const [Switch3, setSwitch3] = useState({
    checked: false,
    disabled: false,
  });
  const [selectRuleB, setselectRuleB] = useState('programa');
  const [selectSubTipo, setselectSubTipo] = useState('estructura');
  const [productsPolicy, setproductsPolicy] = useState([]);

  //Cambiar en Selects Regla de negocio
  function onChangeRuleB(value) {
    setselectRuleB(value);
  }

  //Cambiar en Selects Subtipo
  function onChangeSubTipo(value) {
    setselectSubTipo(value);
  }

  //Mensaje preventivo salida
  useEffect(() => {
    onChangeActiveComponent('BussinessRules');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Detectar momentos de cambio de Btn Submit
  useEffect(() => {
    setBtnPOST();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectRuleB, selectSubTipo, productsPolicy, Switch1, Switch2, Switch3]);

  //Detectar momentos de cambio de Btn Submit con Form
  const OnValuesChange = (value) => {
    let formData = form.getFieldsValue();
    let { lineId, subregionId, segmentationId } = formData;
    const currentChange = Object.keys(value)[0];
    switch (currentChange) {
      case 'lineId':
      case 'subregionId':
      case 'segmentationId':
        if (lineId !== undefined && subregionId !== undefined && segmentationId !== undefined) {
          //CONSULTA
          let data = {
            LineId: lineId,
            SubRegionId: subregionId,
            SegmentationId: segmentationId,
          };
          checkExistanceIncentives({ data });
        }
        break;

      default:
        break;
    }
    setBtnPOST();
  };

  //Boton Activar Desactivar Submit
  const setBtnPOST = () => {
    let formData = form.getFieldsValue();

    let { lineId, subregionId, baseDiscount, objective1, segmentationId, nameOA, nameOQ, namePOG } =
      formData;

    //valida registro existente
    if (selectRuleB === 'productos' && selectSubTipo === 'estructura') {
      //Activar Btn en ESTRUCTURA
      if (productsPolicy.length > 0) {
        setbtnTF(false);
      } else {
        setbtnTF(true);
      }
    }
    //Activar Btn en CLIENTE
    if (selectRuleB === 'productos' && selectSubTipo === 'cliente') {
      if (productsPolicy.length > 0) {
        setbtnTF(false);
      } else {
        setbtnTF(true);
      }
    }
    //Activar Btn en PROGRAMA
    if (selectRuleB === 'programa') {
      if (
        lineId &&
        subregionId &&
        segmentationId &&
        baseDiscount !== '' &&
        baseDiscount !== null &&
        baseDiscount !== undefined
      ) {
        if (Switch1.checked === false || Switch2.checked === false || Switch3.checked === false) {
          if (Switch1.checked === false && Switch2.checked === false && Switch3.checked === false) {
            if (
              nameOA &&
              objective1 !== '' &&
              objective1 !== undefined &&
              objective1 !== null &&
              nameOQ &&
              namePOG
            ) {
              setbtnTF(false);
            } else {
              setbtnTF(true);
            }
          }
          if (Switch1.checked === false && Switch2.checked === false && Switch3.checked === true) {
            if (
              nameOA &&
              objective1 !== '' &&
              objective1 !== undefined &&
              objective1 !== null &&
              nameOQ
            ) {
              setbtnTF(false);
            } else {
              setbtnTF(true);
            }
          }

          if (Switch1.checked === false && Switch2.checked === true && Switch3.checked === false) {
            if (
              nameOA &&
              objective1 !== '' &&
              objective1 !== undefined &&
              objective1 !== null &&
              namePOG
            ) {
              setbtnTF(false);
            } else {
              setbtnTF(true);
            }
          }
          if (Switch1.checked === false && Switch2.checked === true && Switch3.checked === true) {
            if (nameOA && objective1 !== '' && objective1 !== undefined && objective1 !== null) {
              setbtnTF(false);
            } else {
              setbtnTF(true);
            }
          }

          if (Switch1.checked === true && Switch2.checked === false && Switch3.checked === false) {
            if (nameOQ && namePOG) {
              setbtnTF(false);
            } else {
              setbtnTF(true);
            }
          }
          if (Switch1.checked === true && Switch2.checked === false && Switch3.checked === true) {
            if (nameOQ) {
              setbtnTF(false);
            } else {
              setbtnTF(true);
            }
          }

          if (Switch1.checked === true && Switch2.checked === true && Switch3.checked === false) {
            if (namePOG) {
              setbtnTF(false);
            } else {
              setbtnTF(true);
            }
          }

          if (Switch1.checked === true && Switch2.checked === true && Switch3.checked === true) {
            setbtnTF(false);
          }
        } else {
          setbtnTF(false);
        }
      } else {
        setbtnTF(true);
      }
    }
  };

  //Btn Submit
  const onSubmit = () => {
    let formData = form.getFieldsValue();
    let {
      lineId,
      lineIdClient,
      clientId,
      subregionId,
      OQdiscount1,
      OQdiscount2,
      OQdiscount3,
      OQdiscount4,
      POGdiscount1,
      POGdiscount2,
      POGdiscount3,
      POGdiscount4,
      baseDiscount,
      nameOA,
      nameOQ,
      namePOG,
      objective1,
      objective2,
      objective3,
      segmentationId,
    } = formData;

    //Registro productos Cliente
    if (selectRuleB === 'productos' && selectSubTipo === 'cliente') {
      postProductClient({
        history,
        products: productsPolicy,
        clientId,
        lineIdClient,
      });
    }

    //Registro productos Estructura
    if (selectRuleB === 'productos' && selectSubTipo === 'estructura') {
      postProductBL({
        history,
        products: productsPolicy,
        subregionId,
        lineId,
      });
    }

    //Registro de Incentivos
    if (selectRuleB === 'programa') {
      postIncentivesRules({
        history,
        data: {
          lineId: lineId,
          subRegionId: subregionId,
          segmentationId: segmentationId,
          baseDiscount: baseDiscount,
          objectiveAnual: {
            name: nameOA,
            active: !Switch1.checked,
            objective1: objective1,
            objective2: objective2,
            objective3: objective3,
          },
          objectiveQuarterly: {
            name: nameOQ,
            active: !Switch2.checked,
            discount1: OQdiscount1,
            discount2: OQdiscount2,
            discount3: OQdiscount3,
            discount4: OQdiscount4,
          },
          discountsPOG: {
            name: namePOG,
            active: !Switch3.checked,
            discount1: POGdiscount1,
            discount2: POGdiscount2,
            discount3: POGdiscount3,
            discount4: POGdiscount4,
          },
        },
      });
    }
  };

  //Btn Cancelar
  const onCancel = () => {
    history.push('/catalog');
  };
  return (
    <>
      <div className={'module-card'}>
        <Form form={form} onValuesChange={OnValuesChange}>
          <Row>
            {/* REGLA DE NEGOCIO */}
            <Col span={8}>
              <Form.Item name="ruleB" label="*Regla de negocio:">
                <Select
                  defaultValue="programa"
                  placeholder="Selccionar"
                  optionFilterProp="children"
                  onChange={onChangeRuleB}
                  getPopupContainer={getPopupContainer}
                >
                  <Option value="programa">Programa de incentivos</Option>
                  <Option value="productos">Productos fuera de política</Option>
                </Select>
              </Form.Item>
            </Col>
            {selectRuleB === 'productos' ? (
              /* SUBTIPO */
              <Col span={8}>
                <Form.Item name="subTipo" label="*Subtipo:">
                  <Select
                    defaultValue="estructura"
                    placeholder="Selccionar"
                    optionFilterProp="children"
                    getPopupContainer={getPopupContainer}
                    onChange={onChangeSubTipo}
                  >
                    <Option value="cliente">Cliente</Option>
                    <Option value="estructura">Estructura comercial</Option>
                  </Select>
                </Form.Item>
              </Col>
            ) : (
              ''
            )}
          </Row>

          {selectRuleB === 'programa' ? (
            /* Select PROGRAMA */
            <ProgramaSelect
              Switch1={Switch1}
              setSwitch1={setSwitch1}
              Switch2={Switch2}
              setSwitch2={setSwitch2}
              Switch3={Switch3}
              setSwitch3={setSwitch3}
              form={form}
              postOneSegmentation={postOneSegmentation}
              deleteSegment={deleteSegment}
              putSegment={putSegment}
              segmentationFilter={segmentationFilter}
            />
          ) : (
            /* Select PRODUCTOS   */
            <ProductosSelect
              selectSubTipo={selectSubTipo}
              form={form}
              getIPPR={getIPPR}
              setproductsPolicy={setproductsPolicy}
            />
          )}
        </Form>
      </div>
      <ActionButtons
        onCancel={onCancel}
        onSubmit={onSubmit}
        disabled={btnTF}
        buttons={['cancel', 'submit']}
      />
    </>
  );
};
function mapStateToProps(state) {
  return { registerBussinesRulesData: state?.REGISTER_BUSSINES_RULES?.registerBussinesRules?.data };
}
const ConnectedBusinessRules = connect(mapStateToProps, {
  postOneSegmentationRequest,
  deleteOneSegmentationRequest,
  putOneSegmentationRequest,
  getInPoliciyProductsRequest,
  postProductClientRequest,
  postProductBLRequest,
  postIncentivesRulesRequest,
  checkExistanceIncentivesRequest,
  getClientSingleIdRequest,
})(BusinessRulesForm);
const BusinessRules = () => (
  <DynamicModuleLoader modules={[getRootModule(), BRregisterModule()]}>
    <ConnectedBusinessRules />
  </DynamicModuleLoader>
);

export default BusinessRules;
