import { put, call, cancelled, takeEvery } from 'redux-saga/effects';
import instance from 'services/request';
import {
  SALES_CHALLENGE_VALIDATOR_CONFIRM_REQUEST,
  salesChallengeValidatorConfirmSuccess,
  salesChallengeValidatorConfirmFail,
} from '../actions/salesChallengeValidatorConfirm';
import { SalesChallengeValidatorConfirm } from '../../services/salesChallengeValidatorConfirm';
import { openNotification } from 'common/misc/openNotification';
import { responseOK } from 'utils';

function* workSalesChallengeValidatorConfirm(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { zoneId, setIsValidator } = payload;
    const response = yield call(SalesChallengeValidatorConfirm, zoneId, source.token);
    if (responseOK(response)) {
      if (typeof setIsValidator === 'function') setIsValidator(response.data);
      yield put(salesChallengeValidatorConfirmSuccess(response.data));
      //obtener validadores
    } else {
      openNotification(
        'error',
        response.message ? response.message : 'No se pudo realizar la operación'
      );
    }
  } catch (error) {
    console.error('[Redux-Saga-Basf]', error);
    yield put(salesChallengeValidatorConfirmFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* watchSalesChallengeValidatorConfirm() {
  yield takeEvery(SALES_CHALLENGE_VALIDATOR_CONFIRM_REQUEST, workSalesChallengeValidatorConfirm);
}
