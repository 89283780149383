export const navItems = [
  {
    label: 'Inicio',
    icon: 'HomeOutlined',
    link: '/dashboard',
    module: 'Dashboard',
  },
  {
    label: 'Usuario',
    icon: 'UserOutlined',
    module: 'Users',
    children: [
      {
        label: 'Añadir',
        icon: 'UserAddOutlined',
        link: '/users/add',
        component: 'add_users',
      },
      {
        label: 'Consultar',
        icon: 'ReadOutlined',
        link: '/users',
        component: 'Users',
      },
      {
        label: 'Carga Masiva',
        icon: 'UserAddOutlined',
        link: '/users/upload',
        component: 'upload_users',
      },
    ],
  },
  {
    label: 'Catálogos',
    icon: 'BookOutlined',
    link: '/catalog',
    module: 'Catalog',
  },
  {
    label: 'Información histórica',
    icon: 'ClockCircleOutlined',
    link: '/historic-information',
    module: 'historic-information',
  },
  {
    label: 'Desafío de ventas',
    icon: 'BiTargetLock',
    link: '/sales-challenge',
    module: 'sales-challenge',
  },
  {
    label: 'Acuerdo comercial',
    icon: 'FaHandshake',
    module: 'trade-agreements',
    children: [
      {
        label: 'Sell In',
        icon: 'FaHandshake',
        link: '/trade-agreements',
        component: 'trade-agreements',
      },
      {
        label: 'Sell Out',
        icon: 'FaRegHandshake',
        link: '/trade-agreements-sellout',
        component: 'trade-agreements-sellout',
      },
    ],
  },
  {
    label: 'Estado de cuenta',
    icon: 'AiOutlineDollarCircle',
    link: '/account-status',
    module: 'account-status',
  },
  {
    label: 'Consultar bitácora',
    icon: 'FaRegStickyNote',
    link: '/binnacle',
    module: 'binnacle',
  },
  {
    label: 'Dashboards',
    icon: 'PieChartOutlined',
    link: '/dashboards',
    module: 'dashboards',
  },
  {
    label: 'Validadores',
    icon: 'ProfileOutlined',
    link: '/validators',
    module: 'validators',
  },
];
