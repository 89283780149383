import { takeLatest, put, cancelled, call, takeEvery, all } from 'redux-saga/effects';
import instance from 'services/request';
import { openNotification } from 'common';
import {
  requestSubRegions,
  requestBusinessLines,
  requestMonth,
  requestFrequency,
  requestPeriod,
  requestStatus,
  requestRegister,
  requestClient,
  requestChageStatus,
  requestDeletePeriod,
  requestStatusDynamicsElement,
} from '../../services';
import { responseOK } from 'Domains/SalesChallengeRefactor/utils';
import {
  BUSINESS_LINES_REQUEST,
  SUBREGIONS_REQUEST,
  MONTH_REQUEST,
  PERIOD_REQUEST,
  CLIENT_REQUEST,
  STATUSDATA_REQUEST,
  REGISTER_REQUEST,
  registerSet,
  registerClean,
  statusDataSet,
  statusDataClean,
  clientSet,
  clientClean,
  periodSet,
  periodClean,
  monthSet,
  monthClean,
  businessLinesSet,
  businessLinesClean,
  subRegionsSet,
  subRegionsClean,
  FREQUENCY_REQUEST,
  frequencySet,
  frequencyClean,
  CHANGE_STATUS_REQUEST,
  changeStatusClean,
  changeStatusSet,
  DELETE_PERIOD_REQUEST,
  deletePeriodSet,
  deletePeriodClean,
  statusDynamicsElementsSet,
  statusDynamicsElementsClean,
  STATUS_REQUEST,
} from '../actions';

function* ReqSubRegions(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  try {
    const response = yield call(requestSubRegions, {
      cancelToken: source.token,
      payload,
    });
    if (responseOK(response)) {
      yield put(subRegionsSet(response.data));
    } else {
      yield put(subRegionsClean('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(subRegionsClean(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}
function* ReqBusinessLines(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  try {
    const response = yield call(requestBusinessLines, { cancelToken: source.token, payload });
    if (responseOK(response)) {
      yield put(businessLinesSet(response?.data ?? []));
    } else {
      yield put(businessLinesClean('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(businessLinesClean(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

function* ReqMonth(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  try {
    const response = yield call(requestMonth, { cancelToken: source.token, payload });
    if (responseOK(response)) {
      yield put(monthSet(response.data));
    } else {
      yield put(monthClean('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(monthClean(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

function* ReqPeriod(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  try {
    const response = yield call(requestPeriod, { cancelToken: source.token, payload });
    if (responseOK(response)) {
      yield put(periodSet(response.data));
    } else {
      yield put(periodClean('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(periodClean(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

function* ReqClient(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;

  try {
    const response = yield call(requestClient, {
      cancelToken: source.token,
      params: payload.params,
    });

    if (responseOK(response)) {
      let data = response.data.map((d, index) => {
        return { ...d, key: index };
      });
      yield put(clientSet(data));
    } else {
      yield put(clientClean('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(clientClean(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

function* ReqStatus(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;

  try {
    const response = yield call(requestStatus, { cancelToken: source.token, payload });
    if (responseOK(response)) {
      yield put(statusDataSet(response.data));
    } else {
      yield put(statusDataClean('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(statusDataClean(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

function* ReqRegister(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const { history, params } = payload;
  try {
    const response = yield call(requestRegister, {
      cancelToken: source.token,
      params,
    });
    if (responseOK(response)) {
      if (response.data === true) {
        openNotification('success', response.message);
        setTimeout(() => {
          history.push('/account-status');
        }, 300);
      }
      yield put(registerSet('registrado'));
    } else {
      yield put(registerClean('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(registerClean(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

function* ReqFrequency(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;

  try {
    const response = yield call(requestFrequency, { cancelToken: source.token, payload });
    if (responseOK(response)) {
      yield put(frequencySet(response.data));
    } else {
      yield put(frequencyClean('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(frequencyClean(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}
function* ReqChangeStatus(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;

  try {
    const response = yield call(requestChageStatus, {
      cancelToken: source.token,
      params: payload.params,
    });
    if (responseOK(response)) {
      if (response.data === true) {
        openNotification('success', response.message);
      } else {
        openNotification('error', response.message);
      }
      yield put(changeStatusSet(response.data));
    } else {
      yield put(changeStatusClean('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(changeStatusClean(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

function* ReqDeletePeriod(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;

  try {
    const response = yield call(requestDeletePeriod, {
      cancelToken: source.token,
      params: payload.params,
    });
    if (responseOK(response)) {
      if (response.data === true) {
        openNotification('success', response.message);
        const res = yield call(requestStatus, { cancelToken: source.token });
        if (responseOK(res)) {
          yield put(statusDataSet(res.data));
        } else {
          yield put(statusDataClean('error al cargar la respuesta'));
        }
      }
      yield put(deletePeriodSet(response.data));
    } else {
      yield put(deletePeriodClean('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(deletePeriodClean(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}
export function* SagaIcnRequestDeletePeriod() {
  yield takeLatest(DELETE_PERIOD_REQUEST, ReqDeletePeriod);
}

function* ReqStatusDynamicsElements(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { history } = action.payload;

  try {
    const response = yield call(requestStatusDynamicsElement, {
      cancelToken: source.token,
    });
    if (responseOK(response)) {
      if (response.data === false) {
        openNotification('error', response.message);
        setTimeout(() => {
          history.push('/account-status');
        }, 200);
      }
      yield put(statusDynamicsElementsSet(response.data));
    } else {
      yield put(statusDynamicsElementsClean('error al cargar la respuesta'));
    }
  } catch (e) {
    yield put(statusDynamicsElementsClean(e));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}
export function* SagaStatusDynamicsElement() {
  yield takeLatest(STATUS_REQUEST, ReqStatusDynamicsElements);
}
export function* SagaIcnRequestChangeStatus() {
  yield takeLatest(CHANGE_STATUS_REQUEST, ReqChangeStatus);
}

export function* SagaIcnRequestFrequency() {
  yield takeLatest(FREQUENCY_REQUEST, ReqFrequency);
}

export function* SagaIcnRequestRegister() {
  yield takeLatest(REGISTER_REQUEST, ReqRegister);
}

export function* SagaIcnRequestStatus() {
  yield takeLatest(STATUSDATA_REQUEST, ReqStatus);
}

export function* SagaIcnRequestClient() {
  yield takeLatest(CLIENT_REQUEST, ReqClient);
}

export function* SagaIcnRequestPeriod() {
  yield takeLatest(PERIOD_REQUEST, ReqPeriod);
}

export function* SagaIcnRequestMonth() {
  yield takeLatest(MONTH_REQUEST, ReqMonth);
}

export function* SagaIcnRequestSubRegions() {
  yield takeLatest(SUBREGIONS_REQUEST, ReqSubRegions);
}
export function* SagaIcnRequesBusinessLines() {
  yield all([takeEvery(BUSINESS_LINES_REQUEST, ReqBusinessLines)]);
}
