import React from 'react';

export const ValidatorsScopeWrapper = ({ data }) => {
  return (
    <>
      {data.map(({ name }) => (
        <React.Fragment key={name}>
          <span className="validator-scope-element">{name}</span>
          &nbsp;
        </React.Fragment>
      ))}
    </>
  );
};
