import { takeEvery, all, call, put, cancelled } from 'redux-saga/effects';

import {
  SALES_CHALLENGE_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
  SALES_CHALLENGE_MASSIVE_UPLOAD_REQUEST_VERIFY,
  SALES_CHALLENGE_MASSIVE_UPLOAD_REQUEST_UPLOAD,
  salesChallengeMassiveUploadSetAllowUpload,
  salesChallengeMassiveUploadClearAllowUpload,
  salesChallengeMassiveUploadSetVerify,
  salesChallengeMassiveUploadClearVerify,
  salesChallengeMassiveUploadClearUpload,
} from '../actions';

import instance from 'services/request';

import { responseOK } from 'Domains/SalesChallengeRefactor/utils';

import { salesChallengeMassiveUpload } from '../../services';
import {
  fileTo64String,
  validateTypeAndSize,
  summaryModal,
  summaryEndModal,
  triggerModalForDuplicates,
} from 'common/MassiveUploads/utils';
import { messageError } from 'utils/messages';

function* workSalesChallengeMassiveUploadSetAllowUpload(action) {
  try {
    yield salesChallengeMassiveUploadSetAllowUpload(action.payload);
  } catch (error) {
    yield salesChallengeMassiveUploadClearAllowUpload();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield salesChallengeMassiveUploadClearAllowUpload();
    }
  }
}

function* workSalesChallengeMassiveUploadRequestVerify({ payload }) {
  const { file, setFileString } = payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const isValidFile = validateTypeAndSize(file);
    if (!isValidFile) {
      messageError(
        'El formato del archivo de carga masiva de Tipos de Cambio debe ser .xlsx y tener un tamaño máximo de 20 MB.'
      );

      return;
    }

    const file64Str = yield call(fileTo64String, file);
    yield call(setFileString, file64Str);
    const response = yield call(salesChallengeMassiveUpload, {
      cancelToken: source.token,
      data: {
        content: file64Str,
        overwrite: false,
        save: false,
        verify: false,
      },
    });
    if (responseOK(response)) {
      let { data, message, allCorrectMessage } = response;
      let allCorrect = data.correct > 0 && data.totalErrors === 0;

      if (allCorrect) {
        data.allCorrectMessage = allCorrectMessage;
      }

      yield put(salesChallengeMassiveUploadSetVerify(data));
      yield call(summaryModal, message);
    } else {
      yield put(salesChallengeMassiveUploadClearVerify());
    }
  } catch (error) {
    yield salesChallengeMassiveUploadClearVerify();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield salesChallengeMassiveUploadClearVerify();
      source.cancel('cancelled Request');
    }
  }
}

function* workSalesChallengeMassiveUploadRequestUpload({ payload }) {
  const { history, fileString, requestKeep, requestReplace, openModal, replace, keep } = payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const regularParams = {
      cancelToken: source.token,
      data: {
        content: fileString,
        save: false,
        overwrite: false,
        verify: true,
      },
    };

    const replaceParams = {
      cancelToken: source.token,
      data: {
        content: fileString,
        save: true,
        overwrite: true,
        verify: true,
      },
    };

    const keepParams = {
      cancelToken: source.token,
      data: {
        content: fileString,
        save: true,
        overwrite: false,
        verify: true,
      },
    };

    let response;
    let paramsToSubmit = regularParams;

    if (keep) {
      paramsToSubmit = keepParams;
    }

    if (replace) {
      paramsToSubmit = replaceParams;
    }

    response = yield call(salesChallengeMassiveUpload, paramsToSubmit);
    if (responseOK(response)) {
      let { data, message } = response;
      let { duplicatedSystem } = data;

      //yield put(salesChallengeMassiveUploadSetVerify(response.data));
      if (duplicatedSystem.length > 0 && openModal) {
        triggerModalForDuplicates(
          duplicatedSystem.length,
          requestReplace,
          requestKeep,
          'desafíos de venta'
        );
      } else {
        yield call(summaryEndModal, response.data, history, '/sales-challenge', message);
      }
    } else {
      yield put(salesChallengeMassiveUploadClearUpload());
    }
  } catch (error) {
    yield salesChallengeMassiveUploadClearUpload();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield salesChallengeMassiveUploadClearUpload();
      source.cancel('cancelled Request');
    }
  }
}

export function* watchSalesChallengeMassiveUploadSetAllowUpload() {
  yield all([
    takeEvery(
      SALES_CHALLENGE_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
      workSalesChallengeMassiveUploadSetAllowUpload
    ),
  ]);
}

export function* watchSalesChallengeMassiveUploadRequestVerify() {
  yield all([
    takeEvery(
      SALES_CHALLENGE_MASSIVE_UPLOAD_REQUEST_VERIFY,
      workSalesChallengeMassiveUploadRequestVerify
    ),
  ]);
}

export function* watchSalesChallengeMassiveUploadRequestUpload() {
  yield all([
    takeEvery(
      SALES_CHALLENGE_MASSIVE_UPLOAD_REQUEST_UPLOAD,
      workSalesChallengeMassiveUploadRequestUpload
    ),
  ]);
}
