/* eslint-disable no-empty-function */
import { Col } from 'antd';
import { ReviewActions } from 'common';

import React from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import { aproveClientModal, rejectClientModal } from '../ReviewActionsContainer/aproveRejectModals';
import {
  approveTradeAgreementRequest,
  rejectTradeAgreementRequest,
  approveTradeAgreementRequestByKAM,
  rejectTradeAgreementRequestByKAM,
} from 'Domains/TradeAgreements/redux/actions/agreement';

const SecondLevelValidation = (props) => {
  const {
    approveTradeAgreementRequest: approveRequest,
    rejectTradeAgreementRequest: rejectRequest,
    approveTradeAgreementRequestByKAM: approveByKAM,
    rejectTradeAgreementRequestByKAM: rejectByKAM,
    daysLeft,
    year,
    clientId,
    territoryId,
    typeOfValidator,
    currentView,
    //
    clientStatusId,
    isValidSecondValidator,
  } = props;

  const canValidateAgreement =
    daysLeft > 0 && currentView === 'clientId' && clientStatusId === 6 && isValidSecondValidator;

  const defaultParams = { clientId, year, territoryId };

  const onValidate = () => {
    const validationService = typeOfValidator === 'default' ? approveRequest : approveByKAM;

    validationService({ data: defaultParams });
  };

  const onReject = (comment) => {
    const rejectService = typeOfValidator === 'default' ? rejectRequest : rejectByKAM;

    rejectService({ data: { ...defaultParams, comment } });
  };

  return (
    canValidateAgreement && (
      <Col style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <ReviewActions
          className={'sales-challenge '}
          templateLiteralsAprove={aproveClientModal}
          templateLiteralsReject={rejectClientModal}
          onApprove={() => onValidate()}
          onRejected={(comment) => onReject(comment)}
          actions={['approve', 'rejected']}
          showLabels={true}
        />
      </Col>
    )
  );
};

const mapStateToProps = (state) => {
  return {
    year: state?.TRADE_AGREEMENTS?.query?.year ?? null,
    clientId: state?.TRADE_AGREEMENTS?.query?.clientId,
    territoryId: state?.TRADE_AGREEMENTS?.query?.territoryId,
    daysLeft:
      state?.TRADE_AGREEMENTS?.registerTrade?.daysLeft?.data?.VALIDITY_PROCESS?.daysLeft ?? 0,
    // TRADE_AGREEMENTS.views.current
    currentView: state?.TRADE_AGREEMENTS?.views?.current ?? '',
    //TRADE_AGREEMENTS.infoForAgreement.clientInfo.statusId
    clientStatusId: state?.TRADE_AGREEMENTS?.infoForAgreement?.clientInfo?.statusId ?? 0,
    //TRADE_AGREEMENTS.secondValidation.valid
    isValidSecondValidator: state?.TRADE_AGREEMENTS?.secondValidation?.valid ?? false,
  };
};

export default connect(mapStateToProps, {
  approveTradeAgreementRequest,
  rejectTradeAgreementRequest,
  approveTradeAgreementRequestByKAM,
  rejectTradeAgreementRequestByKAM,
})(SecondLevelValidation);

SecondLevelValidation.propTypes = {
  // onValidate: PropTypes.func,
  // onReject: PropTypes.func,
  typeOfValidator: PropTypes.oneOf(['default', 'kam']),
};

SecondLevelValidation.defaultProps = {
  // onValidate: () => {},
  // onReject: () => {},
  typeOfValidator: 'default',
};
