import { Modal, Select } from 'antd';
import React from 'react';

export const DeleteValidatorModal = ({
  deletionModal,
  validatorsToDisable,
  moduleTitle,
  modalOptions,
  onCancel,
  onOk,
  onChange,
}) => {
  const { Option } = Select;
  return (
    <Modal
      visible={deletionModal.visible}
      onCancel={onCancel}
      onOk={onOk}
      okButtonProps={{ disabled: validatorsToDisable.length === 0 }}
    >
      {' '}
      <h1 className="modal-title">Eliminar Validadores de {moduleTitle}</h1>
      Seleccione los permisos de validador que desea eliminar para el usuario{' '}
      <b>{deletionModal.user.userName}</b> <br />
      <Select
        style={{ width: '80%', margin: '1em auto' }}
        mode="multiple"
        maxTagCount={2}
        placeholder="Seleccione los permisos de validación"
        allowClear
        value={validatorsToDisable}
        onChange={onChange}
      >
        {modalOptions?.map((item) => (
          <Option key={item.name} value={item.id}>
            {item.name}
          </Option>
        ))}
      </Select>
    </Modal>
  );
};
