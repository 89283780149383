import request from 'services/request';
const URL_SALESCHALLENGE = '/Agreements/Api/v1/SellOut/';

export async function approveTradeAgreement({ data, cancelToken }) {
  return request(`${URL_SALESCHALLENGE}ApproveSellOut`, {
    method: 'PUT',
    cancelToken,
    data,
  });
}

export async function rejectTradeAgreement({ data, cancelToken }) {
  return request(`${URL_SALESCHALLENGE}RejectSellOut`, {
    method: 'PUT',
    cancelToken,
    data,
  });
}

export async function approveTradeAgreementByKam({ data, cancelToken }) {
  return request(`${URL_SALESCHALLENGE}ApproveSellOutByKam`, {
    method: 'PUT',
    cancelToken,
    data,
  });
}

export async function rejectTradeAgreementByKam({ data, cancelToken }) {
  return request(`${URL_SALESCHALLENGE}RejectSellOutByKam`, {
    method: 'PUT',
    cancelToken,
    data,
  });
}

export async function generateAgreement({ payload, cancelToken }) {
  return request(`${URL_SALESCHALLENGE}Generate`, {
    method: 'POST',
    cancelToken,
    data: payload,
  });
}

export async function rejectTradeAgreementByAdmin({ params, cancelToken }) {
  return request(`${URL_SALESCHALLENGE}RejectSellOutByAdmin`, {
    method: 'PUT',
    cancelToken,
    data: params,
  });
}
