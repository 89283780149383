import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { tradeAgreementsFilters } from 'Domains/TradeAgreements/models/enumFilters';

import BLoading from 'common/components/BLoading';
import { ErrorBoundary } from 'Domains/TradeAgreements/common';

const ViewsManagement = (props) => {
  // View from state
  const { view } = props;

  const excludesViews = ['year', 'businessRule'];
  // Loads view except when view is year or bussinessRule
  const loadView = () => {
    if (!excludesViews.includes(view)) {
      let enumKeys = Object.keys(tradeAgreementsFilters);
      let a = enumKeys
        .map((key) => {
          if (tradeAgreementsFilters[key].name === view) {
            return tradeAgreementsFilters[key];
          }
          return undefined;
        })
        .filter((el) => typeof el !== 'undefined');
      if (a[0]) {
        let viewName = a[0]?.view;
        const Component = lazy(() => import(`./views/${viewName}`));
        return <Component />;
      }
    }
    return null;
  };

  if (typeof view === 'undefined') return null;

  if (view !== '') {
    return (
      <Suspense fallback={<BLoading />}>
        <ErrorBoundary>{loadView()}</ErrorBoundary>
      </Suspense>
    );
  }
};

function mapStateToProps(state) {
  return {
    view: state?.TRADE_AGREEMENTS_SELLOUT?.views?.current || undefined,
  };
}

export default connect(mapStateToProps, {})(ViewsManagement);
