import request from '../../../services/request';

const URL_SALESCHALLENGE = '/sales/api/v1/Reports/';
const SALESCHALLENGE_REPORT = `${URL_SALESCHALLENGE}SalesChallenge?`;

export async function getReport(filterName, params, cancelToken) {
  // ${filterName}
  return request(`${SALESCHALLENGE_REPORT}`, {
    method: 'GET',
    responseType: 'blob',
    params,
    cancelToken,
    handlerEnabled: { requestEnable: true },
  });
}
