import { takeEvery, put, call, all, cancelled } from 'redux-saga/effects';
import {
  POST_ONE_REGISTERPRICES_REQUEST,
  postOneRegisterPricesClean,
  GET_CHECK_EXISTENCE_REQUEST,
  getCheckExistenceSuccess,
  getCheckExistenceClean,
} from '../actions';
import { postOneRegisterPrices, getCheckExistence } from '../../services/registerPrices';
import { responseOK } from 'Domains/SalesChallengeRefactor/utils';
import instance from 'services/request';
import { openNotification } from 'common';

function* workPostOneRegisterPricesRequest(action) {
  try {
    const { payload } = action;
    const { values, history } = payload;
    //Destructurin
    const {
      zoneId,
      lineId,
      subregionId,
      countryId,
      productNumber,
      soldTo,
      clientLineId,
      companyId,
      ap,
      list,
      net,
      brute,
      suggested,
      taxes,
    } = values;

    const response1 = yield call(getCheckExistence, {
      params: {
        lineId,
        subregionId,
        countryId,
        companyId,
        productNumber,
        soldTo,
        clientLineId,
        zoneId,
      },
    });

    if (responseOK(response1)) {
      yield put(getCheckExistenceSuccess(response1.data));
      if (response1.data === false) {
        /*  yield put(getCheckExistenceClean()); */
        const response2 = yield call(postOneRegisterPrices, {
          data: {
            zoneId,
            lineId,
            subregionId,
            countryId,
            productNumber,
            soldTo,
            clientLineId,
            companyId,
            ap,
            list,
            net,
            brute,
            suggested,
            taxes,
          },
        });
        if (responseOK(response2)) {
          yield openNotification('success', response2.message);
          setTimeout(() => {
            if (history) history.push('/catalog/prices');
          }, 300);
        } else {
          console.error('[BASF[REDUX-SAGA]##');
        }
      } else if (response1.data === true) {
        yield openNotification('error', response1.message);
      }
    } else {
      yield put(postOneRegisterPricesClean());
    }
  } catch (error) {
    yield put(postOneRegisterPricesClean());

    console.error('[BASF[REDUX-SAGA]', error);
  }
}

function* workGetCheckExistenceRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const {
      lineId,
      subregionId,
      countryId,
      productNumber,
      soldTo,
      clientLineId,
      zoneId,
      companyId,
    } = payload;

    const response = yield call(getCheckExistence, {
      params: {
        lineId,
        subregionId,
        countryId,
        productNumber,
        soldTo,
        clientLineId,
        zoneId,
        companyId,
      },
    });
    if (responseOK(response)) {
      if (response.data === true) {
        yield openNotification('error', response.message);
      }
    } else {
      yield put(getCheckExistenceClean());
    }
  } catch (error) {
    yield put(getCheckExistenceClean());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}
export function* watchPostOneRegisterPricesRequest() {
  yield all([takeEvery(POST_ONE_REGISTERPRICES_REQUEST, workPostOneRegisterPricesRequest)]);
}
export function* watchCreateOneCaseRequest() {
  yield all([takeEvery(GET_CHECK_EXISTENCE_REQUEST, workGetCheckExistenceRequest)]);
}
