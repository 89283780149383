export const setReportParams = (query, current, reportName) => {
  let date = new Date().toISOString().split('T')[0];
  const {
    regionId,
    lineId,
    subRegionId,
    family,
    clientId,
    productId,
    territoryId,
    businessRule: policyTypeId,
    year,
  } = query;
  let params = { year, name: `ACUERDO ${date}`, lineId };

  switch (current) {
    case 'regionId':
      reportName = 'region';
      params = { ...params, regionId };
      break;
    case 'lineId':
      reportName = 'businessLine';
      params = { ...params, regionId, lineId };
      break;
    case 'subRegionId':
      reportName = 'subRegion';
      params = { ...params, regionId, clientId, subRegionId };
      break;
    case 'territoryId':
      reportName = 'territory';
      params = { ...params, regionId, territoryId };
      break;
    case 'family':
      reportName = 'family';
      params = { ...params, clientId, family, territoryId, policyTypeId };
      break;
    case 'clientId':
      reportName = 'client';
      params = { ...params, clientId, territoryId };
      break;
    case 'productId':
      reportName = 'product';
      params = { ...params, clientId, productId, territoryId, policyTypeId };
      break;

    default:
      break;
  }
  return { params, reportName };
};
