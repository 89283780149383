export const fnActionType = (
  clientInfo,
  clientData2,
  yearSelected,
  existClient,
  updatableNational,
  updatableNational2,
  ON_PROCESS,
  REJECTED,
  APPROVED,
  user,
  isNational,
  statusClientId,
  actionTradeEnum,
  territoryId,
  roleIdEnum,
  process
) => {
  if (!clientInfo && !clientData2) return false;

  const sameYear = parseInt(yearSelected) === process?.VALIDITY_PROCESS?.year;

  if (!existClient || !sameYear) return false;

  const { statusId, updatable, challenged, allApproved } = clientInfo ?? clientData2;

  if (
    statusId === APPROVED &&
    allApproved === true &&
    (user?.role?.roleId === roleIdEnum.ADMINISTRATOR ||
      (user?.role?.roleId === roleIdEnum.AREA_ADMINISTRATOR && user?.area === 'Acceso a mercado'))
  ) {
    return actionTradeEnum.GENERATE_FILE;
  }

  const daysLeftRegister = process?.GENERATE_TRADE_AGREEMENT?.daysLeft;
  const daysLeftAdjusted = process?.TRADE_AGREEMENT_ADJUST?.daysLeft;

  if (!updatable && !updatableNational && !updatableNational2) return false;

  const canGenerate = (status, daysLeft, isChallenged, territory) =>
    status === null && daysLeft >= 0 && isChallenged && territory !== null;

  const canUpdate = (status, daysLeft, territory) =>
    status === ON_PROCESS && daysLeft >= 0 && territory !== null;

  const canAdjust = (status, daysLeft, territory, role) =>
    status === REJECTED &&
    daysLeft >= 0 &&
    territory !== null &&
    (!role || user?.role?.roleId === role);

  const canRegister = (isChallenged, status, daysLeft, territory) =>
    !isChallenged && status === null && daysLeft >= 0 && territory !== null;

  if (isNational) {
    if (canGenerate(statusClientId, daysLeftRegister, challenged, territoryId)) {
      return actionTradeEnum.GENERATE;
    } else if (canUpdate(statusClientId, daysLeftRegister, territoryId)) {
      return actionTradeEnum.UPDATE;
    } else if (
      canAdjust(statusClientId, daysLeftAdjusted, territoryId) ||
      canAdjust(statusClientId, daysLeftAdjusted, territoryId, roleIdEnum.SALES_AGENT)
    ) {
      return actionTradeEnum.ADJUSTED;
    } else if (canRegister(challenged, statusClientId, daysLeftRegister, territoryId)) {
      return actionTradeEnum.REGISTER;
    }
  } else {
    if (canGenerate(statusId, daysLeftRegister, challenged)) {
      return actionTradeEnum.GENERATE;
    } else if (canUpdate(statusId, daysLeftRegister)) {
      return actionTradeEnum.UPDATE;
    } else if (canAdjust(statusId, daysLeftAdjusted)) {
      return actionTradeEnum.ADJUSTED;
    } else if (canRegister(challenged, statusId, daysLeftRegister)) {
      return actionTradeEnum.REGISTER;
    }
  }
};
