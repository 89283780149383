import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import BSelect from 'common/components/BSelect';
import { connect } from 'react-redux';
import { productsRequest } from 'RootModule/redux/actions/catalogs';

import { handleOptionsProps } from 'Domains/SalesChallengeRefactor/helpers/helperForms';
import { filtersEnum } from 'RootModule/models/enums/filtersEnum';

export const agreementStyle = {
  paddingLeft: '0.22em',
};
export const hasMontlyVolumeStyle = {
  borderLeft: 'solid green .22em',
  ...agreementStyle,
};
function Products(props) {
  const {
    products,
    productsRequest: getProducts,
    askFromService,
    requestParams,
    sourceId,
    optionsProps,
    ruleToClassName,
    onSuccess,
    showHasYearVolume,
    hasVolumeParams,
    ...other
  } = props;

  useEffect(() => {
    if (askFromService) {
      getProducts({ requestParams, sourceId, onSuccess, showHasYearVolume, hasVolumeParams });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProducts, askFromService, requestParams, sourceId, onSuccess, showHasYearVolume]);

  function getOptions() {
    let { data } = products;
    return data ?? [];
  }

  return (
    <BSelect
      isLoading={products.isLoading}
      disabled={products.isLoading}
      optionsProps={handleOptionsProps(products, optionsProps, ruleToClassName)}
      options={getOptions()}
      getPopupContainer={(trigger) => trigger.parentNode}
      {...other}
    />
  );
}

Products.propTypes = {
  productsRequest: PropTypes.func,
  products: PropTypes.object,
  askFromService: PropTypes.bool,
  requestParams: PropTypes.object,
  sourceId: PropTypes.oneOf(['Sales', 'Agreements', 'Agreements-SellOut', 'Products']),
  optionsProps: PropTypes.object,
  ruleToClassName: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  showHasYearVolume: PropTypes.bool,
  hasVolumeParams: PropTypes.object,
};

Products.defaultProps = {
  productsRequest: null,
  products: { data: [], isLoading: false },
  askFromService: true,
  requestParams: {},
  sourceId: 'Sales',
  optionsProps: {},
  ruleToClassName: () => {
    return;
  },
  label: filtersEnum.products.label,
  name: filtersEnum.products.name,
  showHasYearVolume: false,
  hasVolumeParams: {},
};

function mapStateToProps(state) {
  return {
    products: state.APP?.catalogs?.products,
  };
}

export default connect(mapStateToProps, { productsRequest })(Products);
