import { takeLatest, put, call, cancelled } from 'redux-saga/effects';
import instance from 'services/request';
import {
  DOWNLOAD_SALES_CHALLENGE_REPORT_REQUEST,
  downloadSalesChallengeReportSuccess,
  downloadSalesChallengeReportFail,
} from '../actions/downloadSalesChallengeReport';
import { getSalesChallengeValidatorsReport } from '../../services/reports';
import { openNotification } from 'common/misc/openNotification';

function* workDownloadSalesChallengeReportRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  let date = new Date().toISOString().split('T')[0];
  try {
    const { payload } = action;
    const { onSuccess, fileConfig } = payload;
    const response = yield call(getSalesChallengeValidatorsReport, source.token);
    if (response.Blob === null) {
      openNotification(
        'error',
        response.message
          ? response.message
          : 'El sistema no pudo realizar la operación, inténtelo de nuevo mas tarde'
      );
    }
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(response);
    link.download = `REPORTE VALIDADORES DESAFIO_${date}.${
      fileConfig ? fileConfig.extension : 'xlsx'
    }`;
    link.click();
    onSuccess();
    yield put(downloadSalesChallengeReportSuccess(response));
  } catch (error) {
    console.error('[Redux-Saga-Basf]', error);
    yield put(downloadSalesChallengeReportFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* watchReportRequest() {
  yield takeLatest(
    DOWNLOAD_SALES_CHALLENGE_REPORT_REQUEST,
    workDownloadSalesChallengeReportRequest
  );
}
