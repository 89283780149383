//REJECT TRADE AGREEMENT BY ADMINISTRATOR

import { Button, Col } from 'antd';
import React, { useCallback, useState } from 'react';
import { CloseOutlined } from '@ant-design/icons';
import RejectionModal from 'Domains/TradeAgreements/common/RejectionModal';
import { connect } from 'react-redux';
import { userIs } from 'utils';
import { rejectTradeAgreementRequest } from 'Domains/TradeAgreements/redux/actions/rejectTradeAgreement';
import { salesChallengeStatus } from 'Domains/TradeAgreements/models/enums';

export const buttonStyle = {
  backgroundColor: 'rgba(0,0,0,0)',
  border: 'none',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-end',
  height: '100%',
};
const circleSize = '32px';
export const circleStyle = {
  borderRadius: '50%',
  height: circleSize,
  width: circleSize,
  minHeight: circleSize,
  minWidth: circleSize,
  backgroundColor: '#E6001D',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  fontSize: '16px',
};

const RejectTradeAgreement = (props) => {
  const {
    rejectTradeAgreementRequest: rejectTradeAgreement,
    clientId,
    territoryId,
    year,
    validityProcessYear,
    validationStatus,
    currentView,
    isLoadingView,
    isLoadingTradeAgreementInfo,
  } = props;
  const [showRejectionModal, setShowRejectionModal] = useState(false);

  const [commentText, setCommentText] = useState('');

  const canRejectTradeAgreements = useCallback(() => {
    const viewIsValid = currentView === 'clientId';
    let userIsValid = userIs('Administrador', 'Administrador de Acceso al Mercado');

    const yearIsValid = year === validityProcessYear;

    const validationStatusCheck =
      validationStatus === salesChallengeStatus.APPROVED &&
      typeof validationStatus !== 'undefined' &&
      validationStatus !== null;

    return (
      validationStatusCheck &&
      !isLoadingView &&
      clientId &&
      viewIsValid &&
      userIsValid &&
      !isLoadingTradeAgreementInfo &&
      yearIsValid
    );
  }, [
    clientId,
    isLoadingView,
    isLoadingTradeAgreementInfo,
    validationStatus,
    currentView,
    year,
    validityProcessYear,
  ]);

  const rejectionModalHandleOk = useCallback(() => {
    const params = {
      comment: commentText.trim(),
      clientId,
      territoryId,
      year,
    };
    rejectTradeAgreement({ params });
    setShowRejectionModal(false);
  }, [setShowRejectionModal, rejectTradeAgreement, clientId, commentText, territoryId, year]);

  const rejectionModalHandleCancel = useCallback(
    () => setShowRejectionModal(false),
    [setShowRejectionModal]
  );

  return (
    <>
      {canRejectTradeAgreements() && (
        <Col style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={() => setShowRejectionModal(true)} style={buttonStyle}>
            <h1 style={{ color: '#E6001D' }}>Rechazar</h1>
            <div style={circleStyle}>
              <CloseOutlined />
            </div>
          </Button>
          <RejectionModal
            visible={showRejectionModal}
            onOk={rejectionModalHandleOk}
            onCancel={rejectionModalHandleCancel}
            commentText={commentText}
            setCommentText={setCommentText}
            okIsDisabled={!commentText.trim()}
          />
        </Col>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return {
    validityProcess: state.TRADE_AGREEMENTS?.registerTrade?.daysLeft?.data?.VALIDITY_PROCESS,
    clientId: state?.TRADE_AGREEMENTS?.query?.clientId ?? false,

    territoryId: state?.TRADE_AGREEMENTS?.query?.territoryId ?? false,
    year: state?.TRADE_AGREEMENTS?.query?.year && parseInt(state?.TRADE_AGREEMENTS?.query?.year),
    validityProcessYear:
      state?.TRADE_AGREEMENTS?.registerTrade?.daysLeft?.data?.VALIDITY_PROCESS?.year,
    validationStatus: state.TRADE_AGREEMENTS?.views?.indicators?.data?.statusClientId,
    isLoadingTradeAgreementInfo: state.TRADE_AGREEMENTS?.views?.indicators?.isLoading,
    currentView: state.TRADE_AGREEMENTS?.views?.current,
    isLoadingView: state.TRADE_AGREEMENTS?.views?.indicators?.isLoading,
  };
}

export default connect(mapStateToProps, { rejectTradeAgreementRequest })(RejectTradeAgreement);
