import React from 'react';
import '../css/style.css';
import { getDaysInMonth } from '../utils/helpers';
import { Row, Col, Form, Select, Button, TimePicker, Modal } from 'antd';
import { PlusOutlined, DeleteFilled } from '@ant-design/icons';
import { getPopupContainer } from 'Domains/SalesChallengeRefactor/helpers/helperForms';
const format = 'HH:mm';

const SendScheduleInfoSection = ({
  form,
  formValues,
  monthOptions,
  periodOptions,
  frequencyData,
  SetValue,
  value,
  deletePeriod,
  statusData,
  setperiodDays,
  periodDays,
  setIsActiveRegister,
  validateTimeout,
  validateRemoveDate,
}) => {
  const { confirm } = Modal;
  const { Option } = Select;
  const onChangeAdd = () => {
    let newArray = [...value];
    let finalValue = 1;
    if (newArray.length > 0) {
      finalValue = newArray[newArray.length - 1];
      newArray.push(finalValue + 1);
    } else newArray.push(1);
    SetValue(newArray);
    setIsActiveRegister(true);
  };
  const onChangeDelete = (v) => {
    if (value.length === 1) {
      SetValue([0]);
      let respoRV = {};
      respoRV[`month${v}`] = undefined;
      respoRV[`frecuency${v}`] = undefined;
      respoRV[`day${v}`] = undefined;
      respoRV[`period${v}`] = undefined;
      respoRV[`time${v}`] = undefined;
      form.setFieldsValue(respoRV);
      setIsActiveRegister(true);
    } else {
      let newArray = value.filter((val) => val !== v);
      SetValue(newArray);
      let respoRV = {};
      respoRV[`month${v}`] = undefined;
      respoRV[`frecuency${v}`] = undefined;
      respoRV[`day${v}`] = undefined;
      respoRV[`period${v}`] = undefined;
      respoRV[`time${v}`] = undefined;
      form.setFieldsValue(respoRV);
      validateRemoveDate(newArray);
    }
  };

  const onChangeFrecuency = (period, day) => {
    form.setFieldsValue({ [period]: undefined, [day]: undefined });
  };

  const showConfirm = (val) => {
    let { autoPeriodDelivery } = statusData;
    let periodData = autoPeriodDelivery.sort((a, b) => a.month - b.month);
    confirm({
      title: 'Eliminar',
      content:
        '¿Está seguro que desea eliminar la programación de envío automático de estado de cuenta para el mes seleccionado?',
      onOk() {
        onChangeDelete(val);

        if (periodData[val]?.deliveryScheduleId)
          deletePeriod({ params: { scheduleId: periodData[val].deliveryScheduleId } });
      },
      onCancel() {
        return;
      },
    });
  };

  const onChangeMonth = (val, e, other) => {
    let month = other.children.split('-')[1];
    let dayArray = [...periodDays];
    dayArray[val] = getDaysInMonth(parseInt(e.split('-')[0]), month);
    setperiodDays(dayArray);
  };
  return (
    <>
      {value.length > 0 &&
        value.map((val, index) => {
          return (
            <>
              <br />
              <Row>
                <Col span={5}>
                  <Form.Item name={`month${val}`} label="*Mes:" style={{ height: 20 }}>
                    <Select
                      placeholder="Seleccionar"
                      getPopupContainer={getPopupContainer}
                      onChange={(e, other) => onChangeMonth(val, e, other)}
                    >
                      {monthOptions?.map((d) => {
                        return (
                          <Option key={d.key} value={d.key}>
                            {d.text}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item name={`frecuency${val}`} label="*Frecuencia:" style={{ height: 20 }}>
                    <Select
                      onChange={(e) => onChangeFrecuency(`period${val}`, `day${val}`)}
                      placeholder="Seleccionar"
                      getPopupContainer={getPopupContainer}
                    >
                      {frequencyData?.map((d) => {
                        return (
                          <Option key={d.key} value={d.key}>
                            {d.text}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={5}>
                  {formValues[`frecuency${val}`] === 2 ||
                  formValues[`frecuency${val}`] === undefined ? (
                    <Form.Item name={`period${val}`} label="*Periodo:" style={{ height: 20 }}>
                      <Select placeholder="Seleccionar" getPopupContainer={getPopupContainer}>
                        {periodOptions?.map((d) => (
                          <Option key={d.key} value={d.key}>
                            {' '}
                            {d.text}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  ) : (
                    <Form.Item name={`day${val}`} label="*Día:" style={{ height: 20 }}>
                      <Select placeholder="Seleccionar" getPopupContainer={getPopupContainer}>
                        {periodDays[index]?.map((d) => (
                          <Option key={d.key} value={d.value}>
                            {d.value}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </Col>
                <Col span={5}>
                  <Form.Item name={`time${val}`} label="*Horario:" style={{ height: 20 }}>
                    <TimePicker
                      minuteStep={10}
                      popupClassName="timepickerhours"
                      inputReadOnly={true}
                      showNow={false}
                      onSelect={(v) => {
                        form.setFieldsValue({ [`time${val}`]: v });
                        validateTimeout();
                      }}
                      format={format}
                      style={{ width: '100%' }}
                      popupStyle={{ width: '200px', padding: 20 }}
                      getPopupContainer={getPopupContainer}
                    />
                  </Form.Item>
                </Col>
                <Col span={4}>
                  {index === value.length - 1 ? (
                    <>
                      <Button
                        type="text"
                        shape="circle"
                        style={{ marginTop: '36px', marginLeft: '30px' }}
                        onClick={() => showConfirm(val)}
                        icon={<DeleteFilled style={{ color: '#004a96', fontSize: '23px' }} />}
                        size="large"
                      />
                      <Button
                        type="primary"
                        shape="circle"
                        style={{ marginTop: '36px', marginLeft: '30px' }}
                        onClick={onChangeAdd}
                        icon={<PlusOutlined />}
                        size="middle"
                      />
                    </>
                  ) : (
                    <Button
                      type="text"
                      shape="circle"
                      style={{ marginTop: '36px', marginLeft: '30px' }}
                      onClick={() => showConfirm(val)}
                      icon={<DeleteFilled style={{ color: '#004a96', fontSize: '23px' }} />}
                      size="large"
                    />
                  )}
                </Col>
              </Row>
            </>
          );
        })}
      <br />
    </>
  );
};

export default SendScheduleInfoSection;
