import React, { useState, useCallback } from 'react';
import Styles from './edit-table.module.css';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Modal } from 'antd';
import './style.css';
import { FullscreenOutlined } from '@ant-design/icons';

import BButton from 'common/components/BButton';
import { BTable } from '../BTable';
import { createTable, updateTableInputs } from 'Domains/TradeAgreementsSellOut/redux/actions';
import { modelTableSellOut } from 'Domains/TradeAgreementsSellOut/components/common/EditableTable/registerChallengeSellOut';

function EditableTable(props) {
  const { dataSource, columns, loading, className } = props;

  const [fullTable, setFullTable] = useState(false);

  const getTable = useCallback(() => {
    return (
      <BTable
        bordered
        hovered={false}
        loading={loading}
        dataSource={dataSource}
        columns={columns ? columns : modelTableSellOut}
        className={`editable ${Styles['container-table']}  ${className}  ${
          dataSource.length > 0 ? 'editable-has-data' : ''
        }`}
        pagination={false}
      />
    );
  }, [dataSource, columns, loading, className]);

  return (
    <>
      {dataSource && dataSource.length > 0 && !fullTable && (
        <BButton
          icon={<FullscreenOutlined />}
          title="Extender tabla"
          className="button-full-table"
          onClick={() => setFullTable(!fullTable)}
        />
      )}
      <Modal
        mask={true}
        maskStyle={{ backgroundColor: 'rgba(0,0,0,0.7)' }}
        width={window.screen.width - 100}
        className="container-full-table"
        visible={fullTable}
        onCancel={() => setFullTable(!fullTable)}
        onOk={() => setFullTable(!fullTable)}
      >
        {getTable()}
      </Modal>
      {!fullTable && getTable()}
    </>
  );
}

EditableTable.propTypes = {
  dataSource: PropTypes.oneOfType([PropTypes.array]),
  loading: PropTypes.bool,
};

EditableTable.defaultProps = {
  initialValues: { ene: null, feb: null, mar: null },
  isBruteValue: true,
  dataSource: [],
  loading: false,
};

function mapStateToProps(state) {
  return {
    showBruteValue: state?.TRADE_AGREEMENTS_SELLOUT?.query?.showBruteValue,
    display: state?.TRADE_AGREEMENTS_SELLOUT?.registerTrade?.table?.display,
  };
}

export default connect(mapStateToProps, { updateTableInputs, createTable })(EditableTable);
