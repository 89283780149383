import React from 'react';
import { DatePicker, Form } from 'antd';
import PropTypes from 'prop-types';
import { setRuleRequired } from 'utils/validations';
import { getPopupContainer as helperGetPopupContainer } from 'Domains/SalesChallengeRefactor/helpers/helperForms';
import { ConfigProvider } from 'antd';
import 'moment/locale/es-mx';

import esES from 'antd/lib/locale/es_ES';
const { RangePicker } = DatePicker;

function BRangePicker(props) {
  const { name, isRequired, label, getPopupContainer, ...other } = props;
  const requiredRule = isRequired ? [setRuleRequired(label)] : [];

  return (
    <ConfigProvider locale={esES}>
      <Form.Item name={name} {...other} rules={[...requiredRule]}>
        <RangePicker name={name} getPopupContainer={getPopupContainer} format={'DD-MM-YYYY'} />
      </Form.Item>
    </ConfigProvider>
  );
}

export default BRangePicker;

BRangePicker.propTypes = {
  name: PropTypes.string,
  getPopupContainer: PropTypes.func,
};

BRangePicker.defaultProps = {
  name: 'rangePicker',
  getPopupContainer: helperGetPopupContainer,
};
