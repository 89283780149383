import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BSelect } from 'common/components';
import { filtersEnum } from 'RootModule/models/enums/filtersEnum';
import { rolesRequest } from 'RootModule/redux/actions/catalogs';

function Roles(props) {
  const {
    roles,
    rolesRequest: getRoles,
    requestParams,
    sourceId,
    askFromService,
    onOptionsChange,
    onSuccess,
    ...other
  } = props;

  useEffect(() => {
    if (askFromService) {
      getRoles({ requestParams, sourceId, onSuccess });
    }
  }, [getRoles, requestParams, askFromService, sourceId, onSuccess]);

  useEffect(() => {
    if (onOptionsChange) {
      onOptionsChange(roles.data ?? []);
    }
  }, [roles, onOptionsChange]);

  return (
    <BSelect
      isLoading={roles?.isLoading}
      disabled={roles?.isLoading}
      options={roles?.data}
      getPopupContainer={(trigger) => trigger.parentNode}
      {...other}
    />
  );
}

Roles.propTypes = {
  initialValue: PropTypes.any,
  rolesRequest: PropTypes.func,
  roles: PropTypes.object,
  requestParams: PropTypes.object,
  sourceId: PropTypes.oneOf(['Agreements', 'Agreements-SellOut', 'Sales']),
  askFromService: PropTypes.bool,
  onOptionsChange: PropTypes.func,
};

Roles.defaultProps = {
  initialValue: undefined,
  rolesRequest: null,
  roles: { data: [], isLoading: false },
  label: filtersEnum.businessLine.label,
  name: filtersEnum.businessLine.name,
  requestParams: {},
  sourceId: 'Sales',
  askFromService: true,
  onOptionsChange: null,
};

function mapStateToProps(state) {
  return {
    roles: state.APP?.catalogs?.roles,
  };
}

export default connect(mapStateToProps, { rolesRequest })(Roles);
