import { useState, useEffect, useRef } from 'react';
import useUser from 'hooks/useUser';

const initialState = {
  orchestrateFiltersChange: null,
  orchestrateFiltersReset: null,
  initialDisabledFilters: null,
  initialAllowRequest: null,
  initialRequestParamsSubscription: null,
};
function useFilters() {
  const [stateModel, setStateModel] = useState(initialState);
  const wasRendered = useRef(false);
  let user = useUser();

  const importModelFrom = (modelName) => {
    import(`Domains/SalesChallengeRefactor/components/TopFiltersGroup/${modelName}`).then(
      (file) => {
        setStateModel(file);
      }
    );
  };

  useEffect(() => {
    const getRolFilters = async () => {
      try {
        if (Object.keys(user).length > 0 && user.role) {
          const allSubReginoAccess = JSON.parse(localStorage.getItem('allSubregionsAccess'));
          const { roleId } = user?.role;
          switch (roleId) {
            case 1:
            case 2:
              importModelFrom('adminSettings');
              break;

            case 4:
              if (allSubReginoAccess === true) {
                importModelFrom('GTVSubRegionSettings');
              } else {
                importModelFrom('GTVSettings');
              }
              break;

            case 5:
              importModelFrom('rtevSettings');
              break;

            case 6:
              importModelFrom('apLeaderSettings');
              break;

            case 7:
              importModelFrom('consultantSettings');
              break;

            case 8:
              importModelFrom('kamSettings');
              break;
            case 9:
              if (allSubReginoAccess === true) {
                importModelFrom('GTVSubRegionSettings');
              } else {
                importModelFrom('GTVSettings');
              }
              break;

            default:
              break;
          }
        }
      } catch (error) {
        if (wasRendered.current) setStateModel(initialState);
      }
    };
    if (!wasRendered.current) getRolFilters();
    return () => {
      wasRendered.current = false;
    };
  }, [user]);
  return stateModel;
}

useFilters.propTypes = {};

useFilters.defaultProps = {};

export default useFilters;
