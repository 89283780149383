import React, { useEffect, useCallback } from 'react';
import { Row, Col } from 'antd';
import { connect } from 'react-redux';

import OnlyBattery from 'Domains/SalesChallengeRefactor/components/indicators/OnlyBattery';

import { formatToPrice } from '../../../utils';
import { toggleTypeValue } from '../../../redux/actions/query';
import { infoRequest } from 'Domains/TradeAgreementsSellOut/redux/actions/info';
import useWasRendered from 'hooks/useWasRendered';
import { BCards } from '../../common/BCards';
import { BTable } from '../../common/BTable';
import { BSwitch } from '../../common/BSwitch';

import './style.css';
import { quartersInfo } from './quartersInfo';

const FirstRowRight = (props) => {
  const {
    quartySales,
    netQuarters,
    toggleTypeValue: setTypeValue,
    showBruteValue,
    totalSales,
    infoRequest: getInfo,
    productId,
    year,
    clientId,
    territoryId,
    PolicyTypeId,
    Portafolio,
    reach,
    currentAgreement,
    isSaving,
    bruteQuarters,
  } = props;
  const { wasRendered, setWasRendered } = useWasRendered();
  // Effect to get agreement info
  useEffect(() => {
    if (!wasRendered.current) {
      getInfo({
        params: {
          productId,
          year,
          clientId,
          territoryId,
          PolicyTypeId,
          Portafolio,
        },
        name: 'Product',
      });
      setWasRendered(true);
    }
  }, [
    setWasRendered,
    wasRendered,
    getInfo,
    productId,
    year,
    clientId,
    territoryId,
    PolicyTypeId,
    Portafolio,
  ]);
  // Toggles showBruteValue
  const handleToggle = (checked) => {
    setTypeValue(checked);
  };
  // Handles whether to show client net amount or brute amount
  const handleTotalClient = useCallback(() => {
    if (currentAgreement) {
      if (showBruteValue) {
        return formatToPrice(currentAgreement?.data?.clientBruteAmount);
      } else {
        return formatToPrice(currentAgreement?.data?.clientNetAmount);
      }
    } else {
      return '';
    }
  }, [showBruteValue, currentAgreement]);
  // Handles brute n net quarters
  const prepareQuarters = useCallback(() => {
    let aux = {};
    if (netQuarters && bruteQuarters) {
      if (showBruteValue) {
        if (bruteQuarters.length > 0) {
          bruteQuarters.map((quarty, index) => {
            aux = { ...aux, [`q${index + 1}`]: quarty };
            return null;
          });
        }
      } else {
        if (netQuarters.length > 0) {
          netQuarters.map((quarty, index) => {
            aux = { ...aux, [`q${index + 1}`]: quarty };
            return null;
          });
        }
      }
    }
    return [aux];
  }, [bruteQuarters, netQuarters, showBruteValue]);

  return (
    <Row className="first-row-agreements">
      <Col {...{ lg: 9, md: 12, sm: 12, xs: 24 }}>
        <BCards
          align="center"
          headerType="info"
          loading={quartySales?.isLoading || isSaving}
          title={showBruteValue ? 'Venta trimestral bruta (USD)' : 'Venta trimestral neta (USD)'}
          className="bquarty-sales"
        >
          <BTable
            bordered={true}
            loading={currentAgreement?.isLoading || isSaving}
            pagination={false}
            noDataFeedback={false}
            columns={quartersInfo}
            dataSource={prepareQuarters()}
          />
        </BCards>
      </Col>
      <Col {...{ lg: 6, md: 12, sm: 12, xs: 24 }}>
        <BCards
          align="center"
          headerType="info"
          loading={totalSales?.isLoading || isSaving}
          title={showBruteValue ? 'Total cliente bruto (USD)' : 'Total cliente neto (USD)'}
          className="card-total-sale"
        >
          <h4 style={{ paddingTop: '15px' }}>{handleTotalClient()}</h4>
        </BCards>
      </Col>
      <Col {...{ lg: 4, md: 12, sm: 12, xs: 24 }}>
        <BCards align="center" headerType="info" title="Tipo de valor" className="card-type-value">
          <span>
            Bruto
            <BSwitch
              onChange={handleToggle}
              style={{ diplay: 'inline-block' }}
              name="typeValue"
            />{' '}
            Neto
          </span>
        </BCards>
      </Col>
      <Col {...{ lg: 5, md: 12, sm: 12, xs: 24 }}>
        <BCards
          align="center"
          headerType="info"
          title="Acuerdo vs Desafío"
          className="card-battery"
          loading={reach?.isLoading || isSaving}
        >
          <OnlyBattery value={reach?.data} style={{ marginTop: reach?.data ? '19px' : '35px' }} />
        </BCards>
      </Col>
    </Row>
  );
};
function mapStateToProps(state) {
  return {
    showBruteValue: state?.TRADE_AGREEMENTS_SELLOUT?.query?.showBruteValue,
    quartySales: state?.TRADE_AGREEMENTS_SELLOUT?.registerTrade?.currentAgreement?.data?.quarters,
    netQuarters:
      state?.TRADE_AGREEMENTS_SELLOUT?.registerTrade?.currentAgreement?.data?.netQuarters,
    bruteQuarters:
      state?.TRADE_AGREEMENTS_SELLOUT?.registerTrade?.currentAgreement?.data?.bruteQuarters,
    totalSales: state?.TRADE_AGREEMENTS_SELLOUT?.registerTrade?.currentAgreement?.data,
    batteryInfo: state?.TRADE_AGREEMENTS_SELLOUT?.views?.indicators?.batteryInfo,
    productId: state?.TRADE_AGREEMENTS_SELLOUT?.query?.productId ?? null,
    year: state?.TRADE_AGREEMENTS_SELLOUT?.query?.year ?? null,
    clientId: state?.TRADE_AGREEMENTS_SELLOUT?.query?.clientId ?? null,
    territoryId:
      state?.TRADE_AGREEMENTS_SELLOUT?.infoForAgreement?.territoryInfo?.territoryId ?? null,
    PolicyTypeId: state?.TRADE_AGREEMENTS_SELLOUT?.query?.PolicyTypeId ?? null,
    Portafolio: state?.TRADE_AGREEMENTS_SELLOUT?.query?.Portafolio ?? null,
    isSaving: state?.TRADE_AGREEMENTS_SELLOUT?.registerTrade?.postSaveAgreement?.isLoading ?? false,
  };
}

export default connect(mapStateToProps, { toggleTypeValue, infoRequest })(FirstRowRight);
