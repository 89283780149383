import React, { useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { layoutContext } from 'context';
import { useHistory } from 'react-router-dom';
import { DynamicModuleLoader } from 'redux-dynamic-modules';

import getRootModule from 'RootModule';
import { getPageType } from '../utils';

import getTradeAgreementsSellOutModule from 'Domains/TradeAgreementsSellOut/tradeSellOutModule';
import './TradeAgreementsSellOut/style.css';
import {
  cleanRegisterTrade,
  cleanTopFilters,
  getDaysLeftRequest,
  queryClean,
} from '../redux/actions';
import { LayoutTradeAgreementsRegister } from '../components/LayoutTradeAgreementsRegister';

function TradeSellOutRegister() {
  const dispatch = useDispatch();
  const history = useHistory();
  // Using React's useContext hook to access the layout context
  const { onChangeActiveComponent } = useContext(layoutContext);
  // Getting the page type using the utility function
  let pageType = getPageType(history);
  // If page type is undefined, go back to the previous page
  if (typeof pageType === 'undefined') {
    history.goBack();
  }

  useEffect(() => {
    dispatch(cleanTopFilters());
    dispatch(cleanRegisterTrade());
    dispatch(queryClean({ state: 'idle', showBruteValue: true }));
    // Funtions to dispatch actions to get days left with specific process IDs
    const fetchDaysLeft = () => {
      dispatch(getDaysLeftRequest({ params: { processId: 7 }, setDaysLeft: true })); // 7 días restantes
      dispatch(getDaysLeftRequest({ params: { processId: 10 } })); // fecha de proceso
      dispatch(getDaysLeftRequest({ params: { processId: 9 } })); // fecha de proceso
    };
    fetchDaysLeft();
    try {
      localStorage.removeItem('query');
    } catch (error) {
      console.error(error);
    }
  }, [dispatch]);

  return (
    <>
      <LayoutTradeAgreementsRegister
        onChangeActiveComponent={onChangeActiveComponent}
        pageType={pageType.key}
      />
    </>
  );
}
// Dynamic module loader to load necessary Redux modules
const DynamicModule = () => (
  <DynamicModuleLoader modules={[getRootModule(), getTradeAgreementsSellOutModule()]}>
    <TradeSellOutRegister />
  </DynamicModuleLoader>
);

export default DynamicModule;
