import { takeLatest, put, call, cancelled, takeEvery } from 'redux-saga/effects';
import {
  INDICATORS_REQUEST,
  indicatorsFail,
  indicatorsSuccess,
  KAM_INDICATORS_REQUEST,
  kamIndicatorsSuccess,
  kamIndicatorsFail,
  DAYS_LEFT_REQUEST,
  daysLeftSuccess,
  daysLeftFail,
  DAYS_LEFT_TYPES_REQUEST,
  daysLeftTypesSuccess,
  daysLeftTypesFail,
  territoryData,
  zoneData,
  GET_CLIENT_SUMMARY_REQUEST,
  getClientSummarySuccess,
  getClientSummaryFail,
} from '../actions/indicators';

import instance from 'services/request';
import { getIndicator, getClientSummary, getKAMIndicators } from '../../services/indicators';
import { getDaysLeft } from 'Domains/SalesChallengeRefactor/services/catalogs';
import { responseOK } from '../../utils';

function* workerIndicatorRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { params, name, sourceId } = payload;
    let response;
    if (sourceId === 'KAM') {
      response = yield call(getKAMIndicators, name, params, source.token);
    } else {
      response = yield call(getIndicator, name, params, source.token);
    }
    if (responseOK(response)) {
      yield put(indicatorsSuccess(response.data));
      if (name === 'territory' || name === 'Territory') {
        yield put(territoryData(response.data));
      }
      if (name === 'zone' || name === 'Zone') {
        yield put(zoneData(response.data));
      }
    } else {
      yield put(indicatorsFail());
    }
  } catch (e) {
    yield put(indicatorsFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('Cancelled request');
    }
  }
}

function* workerKAMIndicatorRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { params } = payload;

    const response = yield call(getKAMIndicators, params, source.token);
    if (responseOK(response)) {
      yield put(kamIndicatorsSuccess(response.data));
    } else {
      yield put(kamIndicatorsFail());
    }
  } catch (e) {
    yield put(kamIndicatorsFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('Cancelled request');
    }
  }
}

function* workDaysLeftRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { params } = payload;

    const response = yield call(getDaysLeft, { cancelToken: source.token, params: params });

    if (responseOK(response)) {
      yield put(daysLeftSuccess(response?.data));
    } else {
      yield put(daysLeftFail());
    }
  } catch (e) {
    yield put(daysLeftFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('Cancelled request');
    }
  }
}

function* workDaysLeftTypesRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { params, type } = payload;

    const response = yield call(getDaysLeft, { cancelToken: source.token, params: params });

    if (responseOK(response)) {
      yield put(daysLeftTypesSuccess({ ...response?.data, type }));
    } else {
      yield put(daysLeftTypesFail());
    }
  } catch (e) {
    yield put(daysLeftTypesFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('Cancelled request');
    }
  }
}

export function* watchDaysLeftTypesRequest() {
  yield takeEvery(DAYS_LEFT_TYPES_REQUEST, workDaysLeftTypesRequest);
}

export function* watchDaysLeftRequest() {
  yield takeLatest(DAYS_LEFT_REQUEST, workDaysLeftRequest);
}

export function* watchIndicatorRequest() {
  yield takeLatest(INDICATORS_REQUEST, workerIndicatorRequest);
}

export function* watchKAMIndicatorRequest() {
  yield takeLatest(KAM_INDICATORS_REQUEST, workerKAMIndicatorRequest);
}

function* workerGetClientSummary(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { params, onSuccess } = payload;

    const response = yield call(getClientSummary, { params, cancelToken: source.token });

    if (responseOK(response)) {
      if (onSuccess) {
        yield onSuccess(response.data);
      }
      yield put(getClientSummarySuccess(response.data));
    } else {
      yield put(getClientSummaryFail());
    }
  } catch (e) {
    console.error(e);
    yield put(getClientSummaryFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchGetClientSummary() {
  yield takeLatest(GET_CLIENT_SUMMARY_REQUEST, workerGetClientSummary);
}
