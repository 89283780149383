import { takeLatest, put, call, select } from 'redux-saga/effects';
import instance from 'services/request';
import {
  ADD_TRADE_AGREEMENT_SELL_OUT_VALIDATOR_REQUEST,
  addTradeAgreementSellOutValidatorSuccess,
  addTradeAgreementSellOutValidatorFail,
} from '../actions/addTradeAgreementSellOutValidator';
import { openNotification } from 'common/misc/openNotification';
import { responseOK } from 'utils';
import { validatorsListTradeAgreementSellOutRequest } from '../actions/validatorList';
import { clientsRequest } from 'RootModule/redux/actions/catalogs';
import { addTradeAgreementSellOutValidator } from 'Domains/Validations/services/addTradeAgreementSellOutValidator';
import { tradeAgreementsSellOutValidatorsCatalogRequest } from 'RootModule/redux/actions/tradeAgreementsSellOutValidators';

function* workAddTradeAgreementSellOutValidator(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();

  try {
    const { params } = action?.payload;
    const paramsToGetValidators = { Territories: params.territoryId, Clients: params.clientId };

    //const paramsToGetCatalog = { territoryId: params.territoryId };
    const response = yield call(addTradeAgreementSellOutValidator, params, source.token);
    const query = yield select((state) => state?.VALIDATIONS?.filters?.query);
    const requestParams = {
      lineId: query.lineId,
      subRegionId: query.subRegionId,
      territoryId: query.territoryId,
    };
    const sourceId = 'Validators';

    if (responseOK(response)) {
      yield put(addTradeAgreementSellOutValidatorSuccess());
      openNotification('success', response.message);
      yield put(validatorsListTradeAgreementSellOutRequest({ params: paramsToGetValidators }));

      yield put(
        clientsRequest({
          requestParams,
          sourceId,
          mode: 'multiple',
        })
      );
      yield put(tradeAgreementsSellOutValidatorsCatalogRequest({ params: paramsToGetValidators }));
    } else {
      yield put(addTradeAgreementSellOutValidatorFail());
      openNotification('error', response.message);
    }
  } catch (error) {
    yield put(addTradeAgreementSellOutValidatorFail());
    console.error('[BASF[REDUX-SAGA]', error);
  }
}

export function* watchAddTradeAgreementSellOutValidator() {
  yield takeLatest(
    ADD_TRADE_AGREEMENT_SELL_OUT_VALIDATOR_REQUEST,
    workAddTradeAgreementSellOutValidator
  );
}
