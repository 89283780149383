import React from 'react';
import getNotificationsModule from '../index';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { connect } from 'react-redux';
import NotificationContainer from 'Layout/Components/Notifications/containers/NotificationContainer';
import {
  markAsReadRequest,
  markAllAsReadRequest,
} from 'Layout/Components/Notifications/redux/actions/notifications';
import './styles.css';

function NotificationsPage(props) {
  return (
    <div className="notifications-page">
      <NotificationContainer />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    notifications: state?.NOTIFICATIONS?.notifications?.current ?? [],
    loading: state?.NOTIFICATIONS?.notifications?.isLoading ?? true,
  };
}
const NotificationsWrapped = connect(mapStateToProps, { markAsReadRequest, markAllAsReadRequest })(
  NotificationsPage
);

const Notification = () => (
  <DynamicModuleLoader modules={[getNotificationsModule()]}>
    <NotificationsWrapped />
  </DynamicModuleLoader>
);

export default Notification;
