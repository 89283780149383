import { policyFormatter } from './policyFormatter';

export const initialDisabledFilters = {
  year: false,
  regionId: false,
  lineId: true,
  subRegionId: true,
  territoryId: true,
  clientId: true,
  businessRule: true,
  family: true,
  productId: true,
};
export const initialAllowRequest = {
  year: true,
  regionId: true,
  lineId: false,
  subRegionId: false,
  territoryId: false,
  clientId: false,
  businessRule: false,
  family: false,
  productId: false,
};

export const initialRequestParamsSubscription = {
  year: {},
  regionId: {},
  lineId: {},
  subRegionId: {},
  territoryId: {},
  clientId: { owner: true },
  businessRule: {},
  family: {},
  productId: {},
};

export function orchestrateFiltersChange(params) {
  const {
    changedValues,
    currentChange,
    form,
    formValues,
    requestParamsSubscription,
    setAllowRequests,
    setClientSelected,
    setDisabledFilters,
    setRequestParamsSubscription,
    setTerritorySelected,
  } = params;

  switch (currentChange) {
    case 'year':
      form.setFieldsValue({
        regionId: undefined,
        lineId: undefined,
        subRegionId: undefined,
        territoryId: undefined,
        clientId: undefined,
        businessRule: undefined,
        family: undefined,
        productId: undefined,
      });
      setDisabledFilters({
        year: false,
        regionId: false,
        lineId: true,
        subRegionId: true,
        territoryId: true,
        clientId: true,
        businessRule: true,
        family: true,
        productId: true,
      });
      setAllowRequests({
        year: false,
        regionId: true,
        lineId: false,
        subRegionId: false,
        territoryId: false,
        clientId: false,
        businessRule: false,
        family: false,
        productId: false,
      });
      break;

    case 'regionId':
      form.setFieldsValue({
        lineId: undefined,
        subRegionId: undefined,
        territoryId: undefined,
        clientId: undefined,
        businessRule: undefined,
        family: undefined,
        productId: undefined,
      });
      setDisabledFilters({
        year: false,
        regionId: false,
        lineId: false,
        subRegionId: true,
        territoryId: true,
        clientId: true,
        businessRule: true,
        family: true,
        productId: true,
      });
      setRequestParamsSubscription({
        ...requestParamsSubscription,
        lineId: { ...requestParamsSubscription.lineId, regionId: formValues.regionId },
        subRegionId: {
          ...requestParamsSubscription.subRegionId,
          regionId: formValues.regionId,
        },
      });
      setAllowRequests({
        year: false,
        regionId: false,
        lineId: true,
        subRegionId: false,
        territoryId: false,
        clientId: false,
        businessRule: false,
        family: false,
        productId: false,
      });
      break;

    case 'lineId':
      form.setFieldsValue({
        subRegionId: undefined,
        territoryId: undefined,
        clientId: undefined,
        businessRule: undefined,
        family: undefined,
        productId: undefined,
      });
      setDisabledFilters({
        year: false,
        regionId: false,
        lineId: false,
        subRegionId: false,
        territoryId: true,
        clientId: true,
        businessRule: true,
        family: true,
        productId: true,
      });
      setRequestParamsSubscription({
        ...requestParamsSubscription,
        subRegionId: { ...requestParamsSubscription.subRegionId, lineId: formValues.lineId },
        territoryId: { ...requestParamsSubscription.territoryId, lineId: formValues.lineId },
      });
      setAllowRequests({
        year: false,
        regionId: false,
        lineId: false,
        subRegionId: true,
        territoryId: false,
        clientId: false,
        businessRule: false,
        family: false,
        productId: false,
      });
      break;

    case 'subRegionId':
      form.setFieldsValue({
        territoryId: undefined,
        clientId: undefined,
        businessRule: undefined,
        family: undefined,
        productId: undefined,
      });
      setDisabledFilters({
        year: false,
        regionId: false,
        lineId: false,
        subRegionId: false,
        territoryId: false,
        clientId: true,
        businessRule: true,
        family: true,
        productId: true,
      });
      setRequestParamsSubscription({
        ...requestParamsSubscription,
        territoryId: {
          ...requestParamsSubscription.territoryId,
          subRegionId: formValues.subRegionId,
          regionId: formValues.regionId,
        },
      });
      setAllowRequests({
        year: false,
        regionId: false,
        lineId: false,
        subRegionId: false,
        territoryId: true,
        clientId: false,
        businessRule: false,
        family: false,
        productId: false,
      });
      break;

    case 'territoryId':
      setTerritorySelected(changedValues);
      form.setFieldsValue({
        clientId: undefined,
        businessRule: undefined,
        family: undefined,
        productId: undefined,
      });
      setDisabledFilters({
        year: false,
        regionId: false,
        lineId: false,
        subRegionId: false,
        territoryId: false,
        clientId: false,
        businessRule: true,
        family: true,
        productId: true,
      });
      setRequestParamsSubscription({
        ...requestParamsSubscription,
        clientId: {
          ...requestParamsSubscription.clientId,
          territoryId: formValues.territoryId,
          year: formValues.year,
          lineId: formValues.lineId,
        },
        family: { ...requestParamsSubscription.family, territoryId: formValues.territoryId },
        productId: {
          ...requestParamsSubscription.productId,
          territoryId: formValues.territoryId,
        },
      });
      setAllowRequests({
        year: false,
        regionId: false,
        lineId: false,
        subRegionId: false,
        territoryId: false,
        clientId: true,
        businessRule: false,
        family: false,
        productId: false,
      });
      break;

    case 'clientId':
      setClientSelected(changedValues);
      form.setFieldsValue({
        businessRule: undefined,
        family: undefined,
        productId: undefined,
      });
      setDisabledFilters({
        year: false,
        regionId: false,
        lineId: false,
        subRegionId: false,
        territoryId: false,
        clientId: false,
        businessRule: false,
        family: true,
        productId: true,
      });
      setRequestParamsSubscription({
        ...requestParamsSubscription,
        family: {
          ...requestParamsSubscription.family,
          clientId: formValues.clientId,
          year: formValues.year,
        },
        productId: {
          ...requestParamsSubscription.productId,
          clientId: formValues.clientId,
        },
      });
      setAllowRequests({
        year: false,
        regionId: false,
        lineId: false,
        subRegionId: false,
        territoryId: false,
        clientId: false,
        businessRule: true,
        family: false,
        productId: false,
      });

      break;

    case 'businessRule': {
      form.setFieldsValue({
        family: undefined,
        productId: undefined,
      });
      setDisabledFilters({
        year: false,
        regionId: false,
        lineId: false,
        subRegionId: false,
        territoryId: false,
        clientId: false,
        businessRule: false,
        family: false,
        productId: true,
      });
      const { PolicyTypeId, Portafolio } = policyFormatter(formValues.businessRule);
      setRequestParamsSubscription({
        ...requestParamsSubscription,
        family: {
          ...requestParamsSubscription.family,
          PolicyTypeId,
          Portafolio,
          year: formValues.year,
        },
        productId: {
          ...requestParamsSubscription.productId,
          PolicyTypeId,
          Portafolio,
        },
      });

      setAllowRequests({
        year: false,
        regionId: false,
        lineId: false,
        subRegionId: false,
        territoryId: false,
        clientId: false,
        businessRule: false,
        family: true,
        productId: false,
      });
      break;
    }

    case 'family':
      form.setFieldsValue({
        productId: undefined,
      });
      setDisabledFilters({
        year: false,
        regionId: false,
        lineId: false,
        subRegionId: false,
        territoryId: false,
        clientId: false,
        businessRule: false,
        family: false,
        productId: false,
      });
      setRequestParamsSubscription({
        ...requestParamsSubscription,
        productId: {
          ...requestParamsSubscription.productId,
          family: formValues.family,
          year: formValues.year,
        },
      });
      setAllowRequests({
        year: false,
        regionId: false,
        lineId: false,
        subRegionId: false,
        territoryId: false,
        clientId: false,
        businessRule: false,
        family: false,
        productId: true,
      });
      break;

    default:
      break;
  }
}

export function orchestrateFiltersReset(params) {
  const { setDisabledFilters, setRequestParamsSubscription, setAllowRequests } = params;
  setDisabledFilters({
    ...initialDisabledFilters,
  });
  setRequestParamsSubscription({
    ...initialRequestParamsSubscription,
  });
  setAllowRequests({
    ...initialAllowRequest,
  });
}
