import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BSelect } from 'common/components';
import { connect } from 'react-redux';
import { getActiveClientsRequest } from '../../redux-saga/actions';
import { handleOptionsProps } from 'Domains/SalesChallengeRefactor/helpers/helperForms';
import { filtersEnum } from 'RootModule/models/enums/filtersEnum';

import './style.css';

function Clients(props) {
  const {
    clients,
    getActiveClientsRequest: getClients,
    askFromService,
    name,
    requestParams,
    sourceId,
    optionsProps,
    ruleToClassName,
    onSuccess,
    onChange,
    mode,
    ...other
  } = props;

  const [clientsData, setClientsData] = useState([]);

  useEffect(() => {
    if (askFromService) {
      getClients({
        requestParams,
        sourceId,
        onSuccess,
        mode,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getClients /*  askFromService, requestParams, sourceId, onSuccess, mode */]);

  useEffect(() => {
    if (clients?.data) {
      setClientsData(clients.data);
    }
  }, [clients]);

  const handleOnChange = (value) => {
    if (onChange) onChange(value, { options: clients?.data, name: props.name });
  };

  return (
    <BSelect
      mode={mode}
      onChange={handleOnChange}
      isLoading={clients.isLoading}
      disabled={clients.isLoading}
      name={name}
      options={clientsData}
      optionsProps={handleOptionsProps(clients, optionsProps, ruleToClassName)}
      getPopupContainer={(trigger) => trigger.parentNode}
      {...other}
    />
  );
}

Clients.propTypes = {
  clientsRequest: PropTypes.func,
  clients: PropTypes.object,
  askFromService: PropTypes.bool,
  requestParams: PropTypes.object,
  sourceId: PropTypes.oneOf(['Agreements', 'Agreements-SellOut', 'Sales', 'KAM', 'TradeReport']),
  optionsProps: PropTypes.object,
  ruleToClassName: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
};

Clients.defaultProps = {
  clientsRequest: null,
  clients: { data: [], isLoading: false },
  askFromService: true,
  requestParams: {},
  sourceId: 'Sales',
  optionsProps: {},
  ruleToClassName: () => {
    return;
  },
  label: filtersEnum.clients.label,
  name: filtersEnum.clients.name,
};

function mapStateToProps(state) {
  return {
    clients: state.REGISTER_BUSSINES_RULES?.registerBussinesRules,
  };
}

export default connect(mapStateToProps, { getActiveClientsRequest })(Clients);
