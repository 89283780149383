import { takeLatest, put, cancelled, delay, select, call } from 'redux-saga/effects';
import {
  //   MARK_ALL_AS_READ,
  MARK_AS_READ_REQUEST,
  markAsReadSuccess,
  markAsReadFail,
  handlePageRequest,
  handlePageTrueRequest,
  GET_NOTIFICATION_REQUEST,
  getNotificationSuccess,
  getNotificationSuccessRead,
  getNotificationFailRead,
  getNotificationFail,
  MARK_ALL_AS_READ_REQUEST,
  markAllAsReadFail,
  GET_NOTIFICATION_PAGE,
  GET_NOTIFICATION_TRUE,
} from 'Layout/Components/Notifications/redux/actions/notifications';
import instance from 'services/request';
import { getFromStore } from 'Domains/TradeAgreements/redux/sagas/selectors';
import {
  getOneNotifications,
  putAllExchangeRead,
  getOneNotificationsFalse,
  getOneNotificationsTrue,
} from '../../services/notifications';
import { responseOK } from 'Domains/SalesChallengeRefactor/utils';
// GET
function* getNotificationWorker(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();

  try {
    const response = yield call(getOneNotifications, {
      cancelToken: source.token,
    });

    if (responseOK(response)) {
      let total = response.data.total;

      let data = response.data.items.map((element) => {
        var date = new Date(element.registered);
        let formatted_date =
          date.getDate().toString().padStart(2, '0') +
          '/' +
          (date.getMonth() + 1).toString().padStart(2, '0') +
          '/' +
          date.getFullYear().toString().substr(-2);
        let formatted_date_complete =
          date.getDate().toString().padStart(2, '0') +
          '/' +
          (date.getMonth() + 1).toString().padStart(2, '0') +
          '/' +
          date.getFullYear();
        let formatted_hour =
          date.getHours().toString().padStart(2, '0') +
          ':' +
          date.getMinutes().toString().padStart(2, '0') +
          ':' +
          date.getSeconds().toString().padStart(2, '0');
        return {
          id: element.id,
          message: element.message,
          registered: formatted_date,
          registredComplete: formatted_date_complete,
          registredHour: formatted_hour,
          read: element.read,
          link: element.link,
        };
      });

      yield put(getNotificationSuccess(data, total));
    } else {
      yield put(getNotificationFail());
    }
  } catch (e) {
    yield put(getNotificationFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* notificationRequest() {
  yield takeLatest(GET_NOTIFICATION_REQUEST, getNotificationWorker);
}

/// GET FALSE
function* getNotificationPage(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  let page = payload;
  try {
    const response = yield call(getOneNotificationsFalse, {
      cancelToken: source.token,
      page,
    });

    let total = response.data.total;

    let data = response.data.items.map((element) => {
      var date = new Date(element.registered);
      let formatted_date =
        date.getDate().toString().padStart(2, '0') +
        '/' +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        '/' +
        date.getFullYear();
      let formatted_date_complete =
        date.getDate().toString().padStart(2, '0') +
        '/' +
        (date.getMonth() + 1).toString().padStart(2, '0') +
        '/' +
        date.getFullYear();
      let formatted_hour =
        date.getHours().toString().padStart(2, '0') +
        ':' +
        date.getMinutes().toString().padStart(2, '0') +
        ':' +
        date.getSeconds().toString().padStart(2, '0');
      return {
        id: element.id,
        message: element.message,
        registered: formatted_date,
        registredComplete: formatted_date_complete,
        registredHour: formatted_hour,
        read: element.read,
        link: element.link,
      };
    });

    yield put(getNotificationSuccess(data, total));
  } catch (e) {
    yield put(getNotificationFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* notificationRequestPage() {
  yield takeLatest(GET_NOTIFICATION_PAGE, getNotificationPage);
}

// GET TRUE
function* getNotificationPageRead(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  let page = payload;

  try {
    const response = yield call(getOneNotificationsTrue, {
      cancelToken: source.token,
      page,
    });

    if (responseOK(response)) {
      let total = response.data.total;

      let data = response.data.items.map((element) => {
        var date = new Date(element.registered);
        let formatted_date =
          date.getDate().toString().padStart(2, '0') +
          '/' +
          (date.getMonth() + 1).toString().padStart(2, '0') +
          '/' +
          date.getFullYear();
        let formatted_hour =
          date.getHours().toString().padStart(2, '0') +
          ':' +
          date.getMinutes().toString().padStart(2, '0') +
          ':' +
          date.getSeconds().toString().padStart(2, '0');
        return {
          id: element.id,
          message: element.message,
          registered: formatted_date,
          registredHour: formatted_hour,
          read: element.read,
          link: element.link,
        };
      });

      yield put(getNotificationSuccessRead(data, total));
    } else {
      yield put(getNotificationFailRead());
    }
  } catch (e) {
    yield put(getNotificationFailRead());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* notificationRequestPageRead() {
  yield takeLatest(GET_NOTIFICATION_TRUE, getNotificationPageRead);
}

//PUT

function* markAsReadWorker(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const { key } = payload;

  try {
    const data = {
      notificationsIds: [key],
    };

    let currentNotifications = yield select((state) =>
      getFromStore(state, 'NOTIFICATIONS', 'notifications', 'notificationsFalse', 'current')
    );

    if (currentNotifications.current.length > 0) {
      let filtered = currentNotifications.current.filter((n) => n.id !== key);

      yield delay(400);
      let total = currentNotifications.total;

      yield put(markAsReadSuccess(filtered, total));
    }

    yield call(putAllExchangeRead, {
      data,
    });

    yield put(handlePageTrueRequest(1));
    yield put(handlePageRequest(1));
  } catch (e) {
    yield put(markAsReadFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

export function* maskAsReadWatcher() {
  yield takeLatest(MARK_AS_READ_REQUEST, markAsReadWorker);
}

function* markAllAsReadWorker() {
  try {
    let currentNotifications = yield select((state) =>
      getFromStore(state, 'NOTIFICATIONS', 'notifications', 'notificationsFalse', 'current')
    );

    let keys = currentNotifications.current.map((element) => {
      return element.id;
    });

    const data = {
      notificationsIds: keys,
    };

    yield call(putAllExchangeRead, {
      data,
    });

    if (currentNotifications.current.length > 0) {
      yield delay(1000);

      yield put(handlePageRequest(1));
      yield put(handlePageTrueRequest(1));
    }
  } catch (e) {
    yield put(markAllAsReadFail());
  }
}

export function* maskAllAsReadWatcher() {
  yield takeLatest(MARK_ALL_AS_READ_REQUEST, markAllAsReadWorker);
}
