import React from 'react';
import { Modal, Spin } from 'antd';
import { connect } from 'react-redux';
import { IconsMap } from '../../../../Layout/utils';
import 'common/components/DeleteProductModal/DeleteProductModal.css';
import {
  deleteProductShowModalSet,
  deleteProductCloseModal,
} from 'Domains/SalesChallengeRefactor/redux/actions/deleteProduct';
import { deleteProductRequest } from 'Domains/SalesChallengeRefactor/redux/actions/deleteProduct';
import './css/DeleteProductModal.css';
const ExclamationIcon = IconsMap.ExclamationCircleOutlined;
const DeleteProductModal = ({
  title,
  showDeleteProductModal,
  modalIcon,
  productId,
  zoneId,
  familyId,
  clientId,
  year,
  deleteProductShowModalSet: setModalVisibility,
  deleteProductCloseModal: closeModal,
  deleteProductRequest: deleteProduct,
  validProducts,
  familyInfoLoading,
}) => {
  const onCancel = () => {
    closeModal();
  };

  const handleDeleteAgree = () => {
    const params = { productId, zoneId, clientId, year };

    deleteProduct({ params });
    closeModal();
  };

  const label = 'desafío de ventas';
  const message =
    validProducts > 1 ? (
      <p>¿Está seguro que desea eliminar el producto del {label}? </p>
    ) : (
      <p>
        {' '}
        ¿Está seguro que desea eliminar el producto del {label}?
        <br /> <br />
        <b>
          {' '}
          Considere que si este producto es el único asociado al {label}, el registro del desafío de
          ventas se eliminará también del sistema.{' '}
        </b>
      </p>
    );
  const modalContent =
    familyInfoLoading === true ? (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin />
      </div>
    ) : (
      <p> {message} </p>
    );

  return (
    <Modal
      title={title}
      onOk={handleDeleteAgree}
      onCancel={onCancel}
      visible={showDeleteProductModal}
      icon={modalIcon}
      className={'deleteModal'}
    >
      <div className="header-modal">
        <ExclamationIcon style={{ fontSize: '1.5rem', alignSelf: 'center' }} />
        <p>Eliminar producto de {label}</p>
      </div>

      <div className="body-modal">{modalContent}</div>
    </Modal>
  );
};

const ConnectedSaleChallenge = connect(
  (state) => {
    return {
      showDeleteProductModal: state?.SALES_CHALLENGE?.deleteProduct?.deleteProduct?.showModal,
      zoneId: state?.SALES_CHALLENGE?.query?.zoneId ?? false,
      // clientId: state?.SALES_CHALLENGE?.query?.clientId ?? false,
      clientId:
        state?.SALES_CHALLENGE?.query?.client ?? state?.SALES_CHALLENGE?.query?.clientId ?? null,
      familyId: state?.SALES_CHALLENGE?.query?.family ?? false,
      year: state?.SALES_CHALLENGE?.query?.year ?? false,
      productId: state?.SALES_CHALLENGE?.views?.indicators?.data?.productId ?? null,
      validProducts: state?.SALES_CHALLENGE?.getFamilyInformation?.validProducts ?? 0,
      familyInfoLoading: state?.SALES_CHALLENGE?.getFamilyInformation?.loading ?? false,
    };
  },
  { deleteProductShowModalSet, deleteProductRequest, deleteProductCloseModal }
)(DeleteProductModal);

export default ConnectedSaleChallenge;
