export const ADD_TRADE_AGREEMENT_SELL_OUT_VALIDATOR_REQUEST =
  'ADD_TRADE_AGREEMENT_SELL_OUT_VALIDATOR_REQUEST';
export const ADD_TRADE_AGREEMENT_SELL_OUT_VALIDATOR_SUCCESS =
  'ADD_TRADE_AGREEMENT_SELL_OUT_VALIDATOR_SUCCESS';
export const ADD_TRADE_AGREEMENT_SELL_OUT_VALIDATOR_FAIL =
  'ADD_TRADE_AGREEMENT_SELL_OUT_VALIDATOR_FAIL';

export function addTradeAgreementSellOutValidatorRequest(payload) {
  return {
    type: ADD_TRADE_AGREEMENT_SELL_OUT_VALIDATOR_REQUEST,
    payload,
  };
}

export function addTradeAgreementSellOutValidatorSuccess(payload) {
  return { type: ADD_TRADE_AGREEMENT_SELL_OUT_VALIDATOR_SUCCESS, payload };
}

export function addTradeAgreementSellOutValidatorFail(payload) {
  return { type: ADD_TRADE_AGREEMENT_SELL_OUT_VALIDATOR_FAIL, payload };
}
