import { getPopupContainer } from 'Domains/SalesChallengeRefactor/helpers/helperForms';
import React, { useEffect, useState, useContext } from 'react';
import { ProgramaSelect, Politica, Disponibilidad } from '../components';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import BRupdateModule from '../BRupdateModule';
import { layoutContext } from 'context';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { Form, Select, Row, Col } from 'antd';
import getRootModule from 'RootModule';
import { ActionButtons } from 'common';
import { connect } from 'react-redux';
import {
  postOneSegmentationRequest,
  deleteOneSegmentationRequest,
  putOneSegmentationRequest,
  getIncentivesRequest,
  getOutPolicyBLRequest,
  getOutPolicyClientRequest,
  getInPoliciyProductsRequest,
  getOutPoliciyProductsRequest,
  getOutAvailabilityRequest,
  getInAvailabilityRequest,
  getClientSingleIdRequest,
  putIncentivesRulesRequest,
  putOutPolicyProductBLRequest,
  putOutPolicyProductClientRequest,
  putAvailabilityProductsRequest,
  checkExistanceIncentivesRequest,
} from '../redux-saga/actions';
import segmentationFilter from '../utils/segmentationFilter';

const BusinessRulesForm = (props) => {
  //Props
  const {
    postOneSegmentationRequest: postOneSegmentation,
    deleteOneSegmentationRequest: deleteSegment,
    putOneSegmentationRequest: putSegment,
    getIncentivesRequest: getIncentives,
    getOutPolicyBLRequest: getOutPolicyBL,
    getInPoliciyProductsRequest: getInPoliciyProducts,
    getOutPoliciyProductsRequest: getOutPoliciyProducts,
    getOutAvailabilityRequest: getOutAvailability,
    getInAvailabilityRequest: getInAvailability,
    getClientSingleIdRequest: getClientSingleId,
    putIncentivesRulesRequest: putIncentivesRules,
    putOutPolicyProductBLRequest: putOutPolicyProductBL,
    putOutPolicyProductClientRequest: putOutPolicyProductClient,
    putAvailabilityProductsRequest: putAvailabilityProducts,
    checkExistanceIncentivesRequest: checkExistanceIncentives,
    BRData,
  } = props;
  const history = useHistory();
  const { onChangeActiveComponent } = useContext(layoutContext);
  const { Option } = Select;
  const [form] = Form.useForm();
  const params = useParams();
  const BussinessLinesIds = params.id;
  const [btnTF, setbtnTF] = useState(true);
  const [Switch1, setSwitch1] = useState({
    checked: true,
    disabled: false,
  });
  const [Switch2, setSwitch2] = useState({
    checked: true,
    disabled: false,
  });
  const [Switch3, setSwitch3] = useState({
    checked: true,
    disabled: false,
  });
  const [selectRuleB, setselectRuleB] = useState();
  const [selectSubTipo, setselectSubTipo] = useState('estructura');
  const [productsPolicy, setproductsPolicy] = useState([]);
  const [changeForm, setchangeForm] = useState(true);

  //Renderizar datos de Subregion y Cliente a travez de props
  useEffect(() => {
    if (props?.location?.state?.subRegionId > 0) {
      form.setFieldsValue({
        lineId: props?.location?.state?.lineId,
        subregionId: props?.location?.state?.subRegionId,
      });
    } else if (props?.location?.state?.clientId) {
      setselectSubTipo('cliente');
      form.setFieldsValue({
        clientId: props?.location?.state?.client,
        lineIdClient: props?.location?.state?.businessLine,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);
  const segmentationReload = () => {
    const { INCENTIVES } = BRData;
    if (INCENTIVES) {
      const { data } = INCENTIVES;
      form.setFieldsValue({
        segmentationId: data?.segmentationId,
      });
    }
  };

  //Renderizar Programa de Incentivos
  useEffect(() => {
    const { INCENTIVES } = BRData;
    if (INCENTIVES) {
      if (changeForm) {
        setchangeForm(false);
        const { data } = INCENTIVES;
        form.setFieldsValue({
          lineId: data?.lineId,
          subregionId: data?.subRegionId,
          segmentationId: data?.segmentationId,
          nameOA: data?.yearTableName,
          nameOQ: data?.quarterTableName,
          namePOG: data?.pogTableName,
          objective1: data?.annualObjetiveData.annualObjetive1,
          objective2: data?.annualObjetiveData.annualObjetive2,
          objective3: data?.annualObjetiveData.annualObjetive3,
          OQdiscount1: data?.quarterlyObjetiveData.quarterlyDiscount1,
          OQdiscount2: data?.quarterlyObjetiveData.quarterlyDiscount2,
          OQdiscount3: data?.quarterlyObjetiveData.quarterlyDiscount3,
          OQdiscount4: data?.quarterlyObjetiveData.quarterlyDiscount4,
          POGdiscount1: data?.pogDiscountData.pogDiscount1,
          POGdiscount2: data?.pogDiscountData.pogDiscount2,
          POGdiscount3: data?.pogDiscountData.pogDiscount3,
          POGdiscount4: data?.pogDiscountData.pogDiscount4,
          baseDiscount: data?.baseDiscount,
        });
        setSwitch1({
          checked: !data?.yearTableActive,
          disabled: false,
        });
        setSwitch2({
          checked: !data?.quarterTableActive,
          disabled: false,
        });
        setSwitch3({
          checked: !data?.pogTableActive,
          disabled: false,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [BRData]);

  //Obtener Productos InPoliciy && OutPoliciy
  useEffect(() => {
    const formData = form.getFieldValue();
    if (formData.subregionId > 0) {
      form.setFieldsValue({
        subTipo: 'estructura',
      });
      getInPoliciyProducts({
        lineId: props?.location?.state?.lineId,
        subregionId: props?.location?.state?.subRegionId,
      });
      getOutPoliciyProducts({
        lineId: props?.location?.state?.lineId,
        subregionId: props?.location?.state?.subRegionId,
      });
    } else if (formData.clientId) {
      form.setFieldsValue({
        subTipo: 'cliente',
      });
      getInPoliciyProducts({
        lineId: props?.location?.state?.lineId,
        clientId: props?.location?.state?.clientId,
      });
      getOutPoliciyProducts({
        lineId: props?.location?.state?.lineId,
        clientId: props?.location?.state?.clientId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form, selectRuleB, selectSubTipo]);

  //Detectar Regla de negocio
  useEffect(() => {
    if (BussinessLinesIds === 'policy') {
      onChangeRuleB('politica');
    } else if (BussinessLinesIds === 'availability') {
      getInAvailability({ opcional: 'opcional getInAvailability' });
      getOutAvailability({ opcional: 'opcional getOutAvailability' });
      onChangeRuleB('disponibilidad');
    } else {
      reloadIncentiveProgram();
    }

    onChangeActiveComponent('BussinessRules');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const reloadIncentiveProgram = () => {
    onChangeRuleB('programa');
    getIncentives({ IncentiveId: BussinessLinesIds });
  };
  //Cambiar en Selects Regla de negocio
  function onChangeRuleB(value) {
    setselectRuleB(value);
    form.setFieldsValue({ ruleB: value });
  }

  //Cambiar en Selects Subtipo
  function onChangeSubTipo(value) {
    setselectSubTipo(value);
  }

  //Detectar momentos de cambio de Btn Submit
  useEffect(() => {
    setBtnPUT();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectRuleB, selectSubTipo, productsPolicy, Switch1, Switch2, Switch3, BRData]);

  //Detectar momentos de cambio de Btn Submit con Form
  const OnValuesChange = (value) => {
    let formData = form.getFieldsValue();
    let { lineId, subregionId, segmentationId } = formData;
    const currentChange = Object.keys(value)[0];
    switch (currentChange) {
      case 'lineId':
        if (lineId !== undefined && subregionId !== undefined && segmentationId !== undefined) {
          //CONSULTA
          let data = {
            LineId: lineId,
            SubRegionId: subregionId,
            SegmentationId: segmentationId,
          };
          checkExistanceIncentives({ data });
        }
        break;
      case 'subregionId':
        if (lineId !== undefined && subregionId !== undefined && segmentationId !== undefined) {
          //CONSULTA
          let data = {
            LineId: lineId,
            SubRegionId: subregionId,
            SegmentationId: segmentationId,
          };
          checkExistanceIncentives({ data });
        }
        break;
      case 'segmentationId':
        if (lineId !== undefined && subregionId !== undefined && segmentationId !== undefined) {
          //CONSULTA
          let data = {
            LineId: lineId,
            SubRegionId: subregionId,
            SegmentationId: segmentationId,
          };
          checkExistanceIncentives({ data });
        }
        break;
      default:
        break;
    }
    setBtnPUT();
  };

  //Boton Activar Desactivar Submit
  const setBtnPUT = () => {
    let formData = form.getFieldsValue();

    let { lineId, subregionId, baseDiscount, objective1, segmentationId, nameOA, nameOQ, namePOG } =
      formData;

    //Activar Btn en Fuera de Diponibilidad
    if (selectRuleB === 'disponibilidad') {
      //Activar Btn en disponibilidad
      setbtnTF(false);
    }
    //Activar Btn en SUBREGION
    if (selectRuleB === 'politica' && selectSubTipo === 'estructura') {
      //Activar Btn en ESTRUCTURA
      setbtnTF(false);
    }
    //Activar Btn en CLIENTE
    if (selectRuleB === 'politica' && selectSubTipo === 'cliente') {
      setbtnTF(false);
    }
    //Activar Btn en PROGRAMA
    if (selectRuleB === 'programa') {
      if (
        lineId &&
        subregionId &&
        segmentationId &&
        baseDiscount !== '' &&
        baseDiscount !== null &&
        baseDiscount !== undefined
      ) {
        if (Switch1.checked === false || Switch2.checked === false || Switch3.checked === false) {
          if (Switch1.checked === false && Switch2.checked === false && Switch3.checked === false) {
            if (
              nameOA &&
              objective1 !== '' &&
              objective1 !== undefined &&
              objective1 !== null &&
              nameOQ &&
              namePOG
            ) {
              setbtnTF(false);
            } else {
              setbtnTF(true);
            }
          }
          if (Switch1.checked === false && Switch2.checked === false && Switch3.checked === true) {
            if (
              nameOA &&
              objective1 !== '' &&
              objective1 !== undefined &&
              objective1 !== null &&
              nameOQ
            ) {
              setbtnTF(false);
            } else {
              setbtnTF(true);
            }
          }

          if (Switch1.checked === false && Switch2.checked === true && Switch3.checked === false) {
            if (
              nameOA &&
              objective1 !== '' &&
              objective1 !== undefined &&
              objective1 !== null &&
              namePOG
            ) {
              setbtnTF(false);
            } else {
              setbtnTF(true);
            }
          }
          if (Switch1.checked === false && Switch2.checked === true && Switch3.checked === true) {
            if (nameOA && objective1 !== '' && objective1 !== undefined && objective1 !== null) {
              setbtnTF(false);
            } else {
              setbtnTF(true);
            }
          }

          if (Switch1.checked === true && Switch2.checked === false && Switch3.checked === false) {
            if (nameOQ && namePOG) {
              setbtnTF(false);
            } else {
              setbtnTF(true);
            }
          }
          if (Switch1.checked === true && Switch2.checked === false && Switch3.checked === true) {
            if (nameOQ) {
              setbtnTF(false);
            } else {
              setbtnTF(true);
            }
          }

          if (Switch1.checked === true && Switch2.checked === true && Switch3.checked === false) {
            if (namePOG) {
              setbtnTF(false);
            } else {
              setbtnTF(true);
            }
          }

          if (Switch1.checked === true && Switch2.checked === true && Switch3.checked === true) {
            setbtnTF(false);
          }
        } else {
          setbtnTF(false);
        }
      } else {
        setbtnTF(true);
      }
    }
  };

  //Btn Submit
  const onSubmit = () => {
    let formData = form.getFieldsValue();
    let {
      lineId,
      subregionId,
      OQdiscount1,
      OQdiscount2,
      OQdiscount3,
      OQdiscount4,
      POGdiscount1,
      POGdiscount2,
      POGdiscount3,
      POGdiscount4,
      baseDiscount,
      nameOA,
      nameOQ,
      namePOG,
      objective1,
      objective2,
      objective3,
      segmentationId,
    } = formData;

    // productos disponibilidad
    if (selectRuleB === 'disponibilidad') {
      putAvailabilityProducts({
        products: productsPolicy,
        history,
      });
    }
    //editar productos Cliente
    if (selectRuleB === 'politica' && selectSubTipo === 'cliente') {
      putOutPolicyProductClient({
        history,
        products: productsPolicy,
        lineId: props?.location?.state?.lineId,
        clientId: props?.location?.state?.clientId,
      });
    }

    //Editar productos Estructura
    if (selectRuleB === 'politica' && selectSubTipo === 'estructura') {
      putOutPolicyProductBL({
        history,
        products: productsPolicy,
        subregionId,
        lineId,
      });
    }

    //Registro de Incentivos
    if (selectRuleB === 'programa') {
      putIncentivesRules({
        history,
        data: {
          incentiveId: BussinessLinesIds,
          lineId: lineId,
          subRegionId: subregionId,
          segmentationId: segmentationId,
          baseDiscount: baseDiscount,
          objectiveAnual: {
            name: nameOA,
            active: !Switch1.checked,
            objective1: objective1,
            objective2: objective2,
            objective3: objective3,
          },
          objectiveQuarterly: {
            name: nameOQ,
            active: !Switch2.checked,
            discount1: OQdiscount1,
            discount2: OQdiscount2,
            discount3: OQdiscount3,
            discount4: OQdiscount4,
          },
          discountsPOG: {
            name: namePOG,
            active: !Switch3.checked,
            discount1: POGdiscount1,
            discount2: POGdiscount2,
            discount3: POGdiscount3,
            discount4: POGdiscount4,
          },
        },
      });
    }
  };

  //Btn Cancelar
  const onCancel = () => {
    // <Option value="programa">Programa de incentivos</Option>
    // <Option value="politica">Productos fuera de política</Option>
    // <Option value="disponibilidad">Productos fuera de disponibilidad</Option>
    if (selectRuleB === 'programa') {
      history.push({
        pathname: '/catalog/businessRules',
        state: { ruleType: 3 },
      });
    } else if (selectRuleB === 'politica') {
      history.push({
        pathname: '/catalog/businessRules',
        state: { ruleType: 2 },
      });
    } else if (selectRuleB === 'disponibilidad') {
      history.push({
        pathname: '/catalog/businessRules',
        state: { ruleType: 1 },
      });
    }
  };
  return (
    <>
      <div className={'module-card'}>
        <Form form={form} onValuesChange={OnValuesChange}>
          <Row>
            {/* REGLA DE NEGOCIO */}
            <Col span={8}>
              <Form.Item name="ruleB" label="*Regla de negocio:">
                <Select
                  disabled
                  getPopupContainer={getPopupContainer}
                  defaultValue={selectRuleB}
                  placeholder="Selccionar"
                  optionFilterProp="children"
                  onChange={onChangeRuleB}
                >
                  <Option value="programa">Programa de incentivos 2</Option>
                  <Option value="politica">Productos fuera de política</Option>
                  <Option value="disponibilidad">Productos fuera de disponibilidad</Option>
                </Select>
              </Form.Item>
            </Col>

            {selectRuleB === 'politica' ? (
              /* SUBTIPO */
              <Col span={8}>
                <Form.Item name="subTipo" label="*Subtipo:">
                  <Select
                    disabled
                    defaultValue={selectSubTipo}
                    placeholder="Selccionar"
                    optionFilterProp="children"
                    getPopupContainer={getPopupContainer}
                    onChange={onChangeSubTipo}
                  >
                    <Option value="cliente">Cliente</Option>
                    <Option value="estructura">Estructura comercial</Option>
                  </Select>
                </Form.Item>
              </Col>
            ) : (
              ''
            )}
          </Row>
          {selectRuleB === 'programa' ? (
            /* Select PROGRAMA */
            <ProgramaSelect
              Switch1={Switch1}
              setSwitch1={setSwitch1}
              Switch2={Switch2}
              setSwitch2={setSwitch2}
              Switch3={Switch3}
              setSwitch3={setSwitch3}
              form={form}
              postOneSegmentation={postOneSegmentation}
              deleteSegment={deleteSegment}
              putSegment={putSegment}
              segmentationFilter={segmentationFilter}
              segmentationReload={segmentationReload}
            />
          ) : null}
          {/* Select POLITICA   */}
          {selectRuleB === 'politica' ? (
            <Politica
              selectSubTipo={selectSubTipo}
              form={form}
              setproductsPolicy={setproductsPolicy}
              getOutPolicyBL={getOutPolicyBL}
              getClientSingleId={getClientSingleId}
              BRData={BRData}
            />
          ) : null}
          {/*  Select DISPONIBILIDAD  */}
          {selectRuleB === 'disponibilidad' ? (
            <Disponibilidad
              selectSubTipo={selectSubTipo}
              form={form}
              getInPoliciyProducts={getInPoliciyProducts}
              setproductsPolicy={setproductsPolicy}
              BRData={BRData}
            />
          ) : null}
        </Form>
      </div>
      <ActionButtons
        onCancel={onCancel}
        onSubmit={onSubmit}
        disabled={btnTF}
        buttons={['cancel', 'submit']}
      />
    </>
  );
};
function mapStateToProps(state) {
  return {
    BRData: state?.UPDATE_BUSSINES_RULES?.updateBussinesRules,
  };
}
const ConnectedBusinessRules = withRouter(
  connect(mapStateToProps, {
    postOneSegmentationRequest,
    deleteOneSegmentationRequest,
    putOneSegmentationRequest,
    getIncentivesRequest,
    getOutPolicyBLRequest,
    getOutPolicyClientRequest,
    getInPoliciyProductsRequest,
    getOutPoliciyProductsRequest,
    getOutAvailabilityRequest,
    getInAvailabilityRequest,
    getClientSingleIdRequest,
    putIncentivesRulesRequest,
    putOutPolicyProductBLRequest,
    putOutPolicyProductClientRequest,
    putAvailabilityProductsRequest,
    checkExistanceIncentivesRequest,
  })(BusinessRulesForm)
);
const BusinessRules = () => (
  <DynamicModuleLoader modules={[getRootModule(), BRupdateModule()]}>
    <ConnectedBusinessRules />
  </DynamicModuleLoader>
);

export default BusinessRules;
