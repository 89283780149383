import React from 'react';
import { Button, Select } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

export const ValidatorsListView = ({
  moduleTitle,
  canModify,
  handleSelect,
  selectedOption,
  options,
  dropdownLength,
  handleAddValidator,
  selected,
  setSelectedOption,
  handleSelectedValidatorToDisable,
  reducedList,
  hasAproved,
  setSelectedUser,
  setDeletionModal,
  validatorScopeWrapper,
  delValidatorModal,
}) => {
  const { Option } = Select;

  return (
    <article className="validatorItem">
      <h5> Agregar validador {moduleTitle} </h5>
      <div className="validatorItem__wrap">
        <div className="validator-add-selecter">
          <p> Validador </p>
          <Select
            disabled={!canModify}
            mode="multiple"
            onChange={handleSelect}
            maxTagCount={1}
            allowClear
            value={selectedOption}
          >
            {options.map((item) => (
              <Option key={item.userId} value={JSON.stringify(item)}>
                {item.userName} <br />
                <b>{item.rol}</b>
              </Option>
            ))}
          </Select>
        </div>
        <Button
          disabled={!canModify || dropdownLength < 1 || selectedOption.length === 0}
          onClick={() => {
            handleAddValidator(selected.current);
            setSelectedOption([]);
          }}
        >
          + Agregar validador{' '}
        </Button>
      </div>
      <div className="already-added-wrapper">
        {reducedList.map((item) => {
          const { userId, userName, rol } = item;
          return (
            <div key={userId} className="validators-list-wrapper">
              <div className="validator-name-and-position-label">
                {userName}{' '}
                {canModify && hasAproved(item) && (
                  <CloseOutlined
                    className="validators-delete"
                    onClick={() => {
                      setSelectedUser(userId);
                      setDeletionModal({ visible: true, user: item });
                      handleSelectedValidatorToDisable(item);
                    }}
                  />
                )}{' '}
                <br /> <b>{rol}</b> <br />
                <div className="validator-scope-wrapper">{validatorScopeWrapper(item)}</div>
              </div>
            </div>
          );
        })}
      </div>
      {delValidatorModal()}
    </article>
  );
};
