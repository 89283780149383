import { takeEvery, all, call, put, cancelled } from 'redux-saga/effects';

import {
  ProductMaster_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
  ProductMaster_MASSIVE_UPLOAD_REQUEST_VERIFY,
  ProductMaster_MASSIVE_UPLOAD_REQUEST_UPLOAD,
  ProductMasterMassiveUploadSetAllowUpload,
  ProductMasterMassiveUploadClearAllowUpload,
  ProductMasterMassiveUploadSetVerify,
  ProductMasterMassiveUploadClearVerify,
  ProductMasterMassiveUploadClearUpload,
} from '../actions';

import instance from 'services/request';

import { responseOK } from 'Domains/SalesChallengeRefactor/utils';

import { ProductMasterMassiveUpload } from '../../services';
import {
  fileTo64String,
  validateTypeAndSize,
  summaryModal,
  summaryEndModal,
} from 'common/MassiveUploads/utils';
import { messageError } from 'utils/messages';

function* workProductMasterMassiveUploadSetAllowUpload(action) {
  try {
    yield ProductMasterMassiveUploadSetAllowUpload(action.payload);
  } catch (error) {
    yield ProductMasterMassiveUploadClearAllowUpload();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield ProductMasterMassiveUploadClearAllowUpload();
    }
  }
}

function* workProductMasterMassiveUploadRequestVerify({ payload }) {
  const { file, setFileString, subregion, line, urlEndpoint } = payload;

  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const isValidFile = validateTypeAndSize(file);
    if (!isValidFile) {
      messageError(
        'El formato del archivo de carga masiva de maestro de productos debe ser .xlsx y tener un tamaño máximo de 20 MB.'
      );

      return;
    }

    const file64Str = yield call(fileTo64String, file);
    yield call(setFileString, file64Str);
    const response = yield call(ProductMasterMassiveUpload, {
      urlEndpoint,
      cancelToken: source.token,
      data: {
        content: file64Str,
        overwrite: false,
        save: false,
        subregion: subregion,
        line: line,
      },
    });
    if (responseOK(response)) {
      let { data, message, allCorrectMessage } = response;
      let allCorrect = data.correct > 0 && data.totalErrors === 0;

      if (allCorrect) {
        data.allCorrectMessage = allCorrectMessage;
      }

      yield put(ProductMasterMassiveUploadSetVerify(data));
      yield call(summaryModal, message, false);
    } else {
      yield put(ProductMasterMassiveUploadClearVerify());
    }
  } catch (error) {
    yield ProductMasterMassiveUploadClearVerify();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield ProductMasterMassiveUploadClearVerify();
      source.cancel('cancelled Request');
    }
  }
}

function* workProductMasterMassiveUploadRequestUpload({ payload }) {
  const { history, fileString, overwrite, subregion, line, urlEndpoint } = payload;

  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(ProductMasterMassiveUpload, {
      urlEndpoint,
      cancelToken: source.token,
      data: {
        content: fileString,
        save: true,
        overwrite,
        subregion: subregion,
        line: line,
      },
    });
    if (responseOK(response)) {
      yield call(summaryEndModal, response.data, history, '/catalog/trade-agreement-info');
    } else {
      yield put(ProductMasterMassiveUploadClearUpload());
    }
  } catch (error) {
    yield ProductMasterMassiveUploadClearUpload();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield ProductMasterMassiveUploadClearUpload();
      source.cancel('cancelled Request');
    }
  }
}

export function* watchProductMasterMassiveUploadSetAllowUpload() {
  yield all([
    takeEvery(
      ProductMaster_MASSIVE_UPLOAD_SET_ALLOW_UPLOAD,
      workProductMasterMassiveUploadSetAllowUpload
    ),
  ]);
}

export function* watchProductMasterMassiveUploadRequestVerify() {
  yield all([
    takeEvery(
      ProductMaster_MASSIVE_UPLOAD_REQUEST_VERIFY,
      workProductMasterMassiveUploadRequestVerify
    ),
  ]);
}

export function* watchProductMasterMassiveUploadRequestUpload() {
  yield all([
    takeEvery(
      ProductMaster_MASSIVE_UPLOAD_REQUEST_UPLOAD,
      workProductMasterMassiveUploadRequestUpload
    ),
  ]);
}
