import React, { useState, useEffect } from 'react';
import { Row, Col, Select, Form } from 'antd';
import { getPopupContainer } from 'Domains/SalesChallengeRefactor/helpers/helperForms';
import PropTypes from 'prop-types';
import { SubRegion, BusinessLine2, Clients } from 'RootModule/catalogs';
import { BInput } from 'common/components';

const Client = ({
  getClientData,
  form,
  isNacional,
  crop,
  setCrop,
  getTerritories,
  statusSwitch,
}) => {
  const { Option } = Select;
  const [dataCE, setdataCE] = useState([]);
  const [requestParams, setRequestParams] = useState({
    zoneId: {},
    productNumber: {},
    lineId: {},
  });
  useEffect(() => {
    let params = {
      SubRegions: dataCE.subregionId,
      BusinessLines: dataCE.lineId,
      IncludeAll: true,
    };
    getTerritories({ params });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCE.subregionId]);

  const autoSelectOnlyOption = (options) => {
    if (options.length === 1) {
      form.setFieldsValue({ lineId: options[0].key });
      setCrop(options[0].key);
    }
  };

  const handleLinkedSelect = (linked, value, options) => {
    const optionsArray = options?.options ?? [];
    if (optionsArray.length > 0) {
      if (typeof value !== 'object') {
        form.setFieldsValue({ [linked]: value });
      }
      let dataBL = form.getFieldValue().soldTo;
      setRequestParams({ soldTo: dataBL });
    }
  };
  const handleChange = (e) => {
    let data = form.getFieldValue();
    setdataCE(data);
  };
  const refact = (d) => {
    return (
      <Option key={d.key} value={d.key}>
        {d.value}
      </Option>
    );
  };
  return (
    <div>
      <Row>
        <Col span={6}>
          <Clients
            label="*Sold to"
            optionKey="soldTo"
            valueKey="soldTo"
            labelKey="soldTo"
            placeholder="Seleccionar"
            sourceId="BySoldTo"
            name="soldTo"
            allowClear={false}
            onChange={(value, option) => handleLinkedSelect('name', value, option)}
            disabled={true}
          />
        </Col>
        <Col span={12}>
          <Clients
            label="*Nombre"
            optionKey="name"
            placeholder="Seleccionar"
            valueKey="soldTo"
            labelKey="name"
            sourceId="BySoldTo"
            name="name"
            allowClear={false}
            onChange={(value, option) => handleLinkedSelect('soldTo', value, option)}
            disabled={true}
          />
        </Col>
        <Col span={6}>
          <BusinessLine2
            label="*Linea de Negocio:"
            sourceId="Fixed"
            placeholder="Seleccionar"
            allowClear={false}
            requestParams={{ clientSoldTo: requestParams.soldTo }}
            onSuccess={autoSelectOnlyOption}
            name="lineId"
            askFromService={requestParams.soldTo}
            disabled={!requestParams.soldTo}
          />
        </Col>
        <Col span={6}>
          <SubRegion
            label="*Subregión"
            name="subregionId"
            placeholder="Seleccionar"
            disabled={!statusSwitch && crop ? false : true}
            onChange={handleChange}
            requestParams={crop ? { BusinessLines: crop } : null}
          />
        </Col>
        <Col span={6}>
          <Form.Item name="placementId" label="*Presencia:" style={{ height: 20 }}>
            <Select
              disabled={statusSwitch}
              getPopupContainer={getPopupContainer}
              showSearch
              style={{ width: '100%' }}
              placeholder="Seleccionar"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {getClientData.placements &&
                getClientData.placements.map((d) => {
                  return refact(d);
                })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item label="*Territorio" name="territories" style={{ height: 20 }}>
            <Select
              disabled={statusSwitch}
              allowClear
              mode={isNacional ? 'multiple' : null}
              getPopupContainer={getPopupContainer}
              showSearch
              maxTagCount={3}
              style={{ width: '100%' }}
              onChange={handleChange}
              placeholder="Seleccionar"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {getClientData.territories &&
                getClientData.territories.map((d) => {
                  return refact(d);
                })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item name="segmentationId" label="*Segmentación:" style={{ height: 20 }}>
            <Select
              disabled={statusSwitch}
              allowClear
              getPopupContainer={getPopupContainer}
              showSearch
              style={{ width: '100%' }}
              placeholder="Seleccionar"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {getClientData.segmentation &&
                getClientData.segmentation.map((d) => {
                  return (
                    <Option key={d.key} value={d.key}>
                      {d.value}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            name="periodoVentasId"
            label="*Periodo de incentivo de ventas:"
            style={{ height: 20 }}
          >
            <Select
              disabled={statusSwitch}
              getPopupContainer={getPopupContainer}
              showSearch
              style={{ width: '100%' }}
              placeholder="Seleccionar"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {getClientData.incentivePeriod &&
                getClientData.incentivePeriod.map((d) => {
                  return refact(d);
                })}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name="periodoPOGId"
            label="*Periodo de incentivo de POG:"
            style={{ height: 20 }}
          >
            <Select
              disabled={statusSwitch}
              getPopupContainer={getPopupContainer}
              showSearch
              style={{ width: '100%' }}
              placeholder="Seleccionar"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {getClientData.pogs &&
                getClientData.pogs.map((d) => {
                  return refact(d);
                })}
            </Select>
          </Form.Item>
        </Col>

        <Col span={6}>
          <BInput
            disabled={statusSwitch}
            placeholder="Escribe Moneda"
            maxLength={10}
            name="tradeAgreementCurrency"
            label="*Moneda Acuerdo Comercial:"
          />
        </Col>
      </Row>
    </div>
  );
};
Client.propTypes = {
  getClientData: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};

export default Client;
