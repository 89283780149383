import { actionTradeEnum } from '../models/enums';

function getPageType(history) {
  let pageType = history?.location?.pathname.split('/')?.[2];
  pageType = pageType.toUpperCase();
  pageType = actionTradeEnum[pageType];
  return pageType;
}

export default getPageType;
