import { takeLatest, call, put, cancelled } from 'redux-saga/effects';

import {
  /* FAMILY */
  CONSULT_FILTERS_FAMILY_REQUEST_OPTIONS,
  consultFiltersFamilySetOptions,
  consultFiltersFamilyClearOptions,
  /* business line*/
  CONSULT_FILTERS_BUSINESS_LINE_REQUEST_OPTIONS,
  consultFiltersBusinessLineSetOptions,
  consultFiltersBusinessLineClearOptions,
  /* subregion */
  CONSULT_FILTERS_SUBREGION_REQUEST_OPTIONS,
  consultFiltersSubregionSetOptions,
  consultFiltersSubregionClearOptions,
  /* segmentation */
  CONSULT_FILTERS_SEGMENTATION_REQUEST_OPTIONS,
  consultFiltersSegmentationSetOptions,
  consultFiltersSegmentationClearOptions,
  /* placement */
  CONSULT_FILTERS_PLACEMENT_REQUEST_OPTIONS,
  consultFiltersPlacementSetOptions,
  consultFiltersPlacementClearOptions,
  /* Year  */
  CONSULT_FILTERS_YEAR_REQUEST_OPTIONS,
  consultFiltersYearSetOptions,
  consultFiltersYearClearOptions,
  /* pog Year  */
  CONSULT_FILTERS_POGYEAR_REQUEST_OPTIONS,
  consultFiltersPogYearSetOptions,
  consultFiltersPogYearClearOptions,
  /*  Pog Periodicity */
  CONSULT_FILTERS_POG_PERIODICITY_REQUEST_OPTIONS,
  consultFiltersPogPeriodicitySetOptions,
  consultFiltersPogPeriodicityClearOptions,
  /*  Sales Periodicity */
  CONSULT_FILTERS_SALES_PERIODICITY_REQUEST_OPTIONS,
  consultFiltersSalesPeriodicitySetOptions,
  consultFiltersSalesPeriodicityClearOptions,
  /*  Territories */
  CONSULT_FILTERS_TERRITORIES_REQUEST_OPTIONS,
  consultFiltersTerritoriesSetOptions,
  consultFiltersTerritoriesClearOptions,
  /*  Months */
  CONSULT_FILTERS_MONTHS_REQUEST_OPTIONS,
  consultFiltersMonthsSetOptions,
  consultFiltersMonthsClearOptions,
} from '../actions/consultFilters';

import instance from 'services/request';

import { responseOK } from 'Domains/SalesChallengeRefactor/utils';
import {
  getBusinessLine,
  getPlacement,
  getSegmentation,
  getSubregion,
  getYear,
  getPogYear,
  getPogPeriodicity,
  getSalesPeriodicity,
  getTerritories,
  getFamily,
  getPurchasesYear,
  getObjectivesIncentivesYears,
} from 'RootModule/services/consultFilters';

/*FAMILY*/
function* workConsultFiltersFamilyRequestOptions(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getFamily, {
      cancelToken: source.token,
      params: action.payload,
    });
    if (responseOK(response)) {
      yield put(consultFiltersFamilySetOptions(response.data));
    } else {
      yield put(consultFiltersFamilyClearOptions());
    }
  } catch (error) {
    yield consultFiltersFamilyClearOptions();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield consultFiltersFamilyClearOptions();
      source.cancel('cancelled Request');
    }
  }
}

export function* watchConsultFiltersFamilyRequestOptions() {
  yield takeLatest(CONSULT_FILTERS_FAMILY_REQUEST_OPTIONS, workConsultFiltersFamilyRequestOptions);
}

/* business line*/
function* workConsultFiltersBusinessLineRequestOptions(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getBusinessLine, {
      cancelToken: source.token,
      params: action.payload,
      data: action.payload,
    });
    if (responseOK(response)) {
      yield put(consultFiltersBusinessLineSetOptions(response.data));
    } else {
      yield put(consultFiltersBusinessLineClearOptions());
    }
  } catch (error) {
    yield consultFiltersBusinessLineClearOptions();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield consultFiltersBusinessLineClearOptions();
      source.cancel('cancelled Request');
    }
  }
}

export function* watchConsultFiltersBusinessLineRequestOptions() {
  yield takeLatest(
    CONSULT_FILTERS_BUSINESS_LINE_REQUEST_OPTIONS,
    workConsultFiltersBusinessLineRequestOptions
  );
}

/*subregion*/
function* workConsultFiltersSubregionRequestOptions(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getSubregion, {
      cancelToken: source.token,
      params: action.payload,
    });
    if (responseOK(response)) {
      yield put(consultFiltersSubregionSetOptions(response.data));
    } else {
      yield put(consultFiltersSubregionClearOptions());
    }
  } catch (error) {
    yield consultFiltersSubregionClearOptions();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield consultFiltersSubregionClearOptions();
      source.cancel('cancelled Request');
    }
  }
}

export function* watchConsultFiltersSubregionRequestOptions() {
  yield takeLatest(
    CONSULT_FILTERS_SUBREGION_REQUEST_OPTIONS,
    workConsultFiltersSubregionRequestOptions
  );
}

/* segmentation */

function* workConsultFiltersSegmentationRequestOptions(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getSegmentation, {
      cancelToken: source.token,
      params: action.payload,
      data: action.payload,
    });
    if (responseOK(response)) {
      yield put(consultFiltersSegmentationSetOptions(response.data));
    } else {
      yield put(consultFiltersSegmentationClearOptions());
    }
  } catch (error) {
    yield consultFiltersSegmentationClearOptions();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield consultFiltersSegmentationClearOptions();
      source.cancel('cancelled Request');
    }
  }
}

export function* watchConsultFiltersSegmentationRequestOptions() {
  yield takeLatest(
    CONSULT_FILTERS_SEGMENTATION_REQUEST_OPTIONS,
    workConsultFiltersSegmentationRequestOptions
  );
}

/* placement */

function* workConsultFiltersPlacementRequestOptions(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getPlacement, {
      cancelToken: source.token,
      params: action.payload,
      data: action.payload,
    });
    if (responseOK(response)) {
      yield put(consultFiltersPlacementSetOptions(response.data));
    } else {
      yield put(consultFiltersPlacementClearOptions());
    }
  } catch (error) {
    yield consultFiltersPlacementClearOptions();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield consultFiltersPlacementClearOptions();
      source.cancel('cancelled Request');
    }
  }
}

export function* watchConsultFiltersPlacementRequestOptions() {
  yield takeLatest(
    CONSULT_FILTERS_PLACEMENT_REQUEST_OPTIONS,
    workConsultFiltersPlacementRequestOptions
  );
}

/*  Year */

function* workConsultFiltersYearRequestOptions(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { params, serviceName } = action.payload;

  let service;

  switch (serviceName) {
    case 'ObjectivesIncentivesYears':
      service = getObjectivesIncentivesYears;
      break;
    case 'PurchasesYears':
      service = getPurchasesYear;
      break;
    case 'ProcessYears':
    default:
      service = getYear;
      break;
  }

  try {
    const response = yield call(service, {
      cancelToken: source.token,
      params,
    });
    if (responseOK(response)) {
      yield put(consultFiltersYearSetOptions(response.data));
    } else {
      yield put(consultFiltersYearClearOptions());
    }
  } catch (error) {
    yield consultFiltersYearClearOptions();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield consultFiltersYearClearOptions();
      source.cancel('cancelled Request');
    }
  }
}

export function* watchConsultFiltersYearRequestOptions() {
  yield takeLatest(CONSULT_FILTERS_YEAR_REQUEST_OPTIONS, workConsultFiltersYearRequestOptions);
}

/* pog Year */

function* workConsultFiltersPogYearRequestOptions(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getPogYear, {
      cancelToken: source.token,
      params: action.payload,
      data: action.payload,
    });
    if (responseOK(response)) {
      yield put(consultFiltersPogYearSetOptions(response.data));
    } else {
      yield put(consultFiltersPogYearClearOptions());
    }
  } catch (error) {
    yield consultFiltersPogYearClearOptions();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield consultFiltersYearClearOptions();
      source.cancel('cancelled Request');
    }
  }
}

export function* watchConsultFiltersPogYearRequestOptions() {
  yield takeLatest(
    CONSULT_FILTERS_POGYEAR_REQUEST_OPTIONS,
    workConsultFiltersPogYearRequestOptions
  );
}

/*  Pog Periodicity */

function* workConsultFiltersPogPeriodicityRequestOptions(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getPogPeriodicity, {
      cancelToken: source.token,
      params: action.payload,
      data: action.payload,
    });
    if (responseOK(response)) {
      yield put(consultFiltersPogPeriodicitySetOptions(response.data));
    } else {
      yield put(consultFiltersPogPeriodicityClearOptions());
    }
  } catch (error) {
    yield consultFiltersPogPeriodicityClearOptions();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield consultFiltersPogPeriodicityClearOptions();
      source.cancel('cancelled Request');
    }
  }
}

export function* watchConsultFiltersPogPeriodicityRequestOptions() {
  yield takeLatest(
    CONSULT_FILTERS_POG_PERIODICITY_REQUEST_OPTIONS,
    workConsultFiltersPogPeriodicityRequestOptions
  );
}

/*  Sales Periodicity */

function* workConsultFiltersSalesPeriodicityRequestOptions(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getSalesPeriodicity, {
      cancelToken: source.token,
      params: action.payload,
      data: action.payload,
    });
    if (responseOK(response)) {
      yield put(consultFiltersSalesPeriodicitySetOptions(response.data));
    } else {
      yield put(consultFiltersSalesPeriodicityClearOptions());
    }
  } catch (error) {
    yield consultFiltersSalesPeriodicityClearOptions();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield consultFiltersSalesPeriodicityClearOptions();
      source.cancel('cancelled Request');
    }
  }
}

export function* watchConsultFiltersSalesPeriodicityRequestOptions() {
  yield takeLatest(
    CONSULT_FILTERS_SALES_PERIODICITY_REQUEST_OPTIONS,
    workConsultFiltersSalesPeriodicityRequestOptions
  );
}

/*  Territories */

function* workConsultFiltersTerritoriesRequestOptions(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const response = yield call(getTerritories, {
      cancelToken: source.token,
      params: action.payload,
      data: action.payload,
    });
    if (responseOK(response)) {
      yield put(consultFiltersTerritoriesSetOptions(response.data));
    } else {
      yield put(consultFiltersTerritoriesClearOptions());
    }
  } catch (error) {
    yield consultFiltersTerritoriesClearOptions();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield consultFiltersTerritoriesClearOptions();
      source.cancel('cancelled Request');
    }
  }
}

export function* watchConsultFiltersTerritoriesRequestOptions() {
  yield takeLatest(
    CONSULT_FILTERS_TERRITORIES_REQUEST_OPTIONS,
    workConsultFiltersTerritoriesRequestOptions
  );
}

/*  Months */

function* workConsultFiltersMonthsRequestOptions() {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    let months = [
      { key: 1, value: 'Enero' },
      { key: 2, value: 'Febrero' },
      { key: 3, value: 'Marzo' },
      { key: 4, value: 'Abril' },
      { key: 5, value: 'Mayo' },
      { key: 6, value: 'Junio' },
      { key: 7, value: 'Julio' },
      { key: 8, value: 'Agosto' },
      { key: 9, value: 'Septiembre' },
      { key: 10, value: 'Octubre' },
      { key: 11, value: 'Noviembre' },
      { key: 12, value: 'Diciembre' },
    ];
    yield put(consultFiltersMonthsSetOptions(months));
  } catch (error) {
    yield consultFiltersMonthsClearOptions();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield consultFiltersMonthsClearOptions();
      source.cancel('cancelled Request');
    }
  }
}

export function* watchConsultFiltersMonthsRequestOptions() {
  yield takeLatest(CONSULT_FILTERS_MONTHS_REQUEST_OPTIONS, workConsultFiltersMonthsRequestOptions);
}
