import { takeLatest, call, put, cancelled } from 'redux-saga/effects';

import {
  TABLE_COMPONENTS_CHANGING_SWITCH_REQUEST_SET_STATUS,
  tableComponentsChangingSwitchSetSetStatus,
  tableComponentsChangingSwitchClearSetStatus,
} from '../actions/tableComponents';

import instance from 'services/request';

import { responseOK } from 'Domains/SalesChallengeRefactor/utils';
import { accountStatementSetEnabledClient } from 'RootModule/services/tableComponents';
import { messageError, messageSuccess } from 'utils/messages';
// accountStatementSetEnabledClient({ cancelToken, clientId, status })

function* workTableComponentsChangingSwitchRequestSetStatus(action) {
  const CancelToken = instance.CancelToken;
  const { enableLoading, disableLoading, setStatus, clientId, status, extraParams } =
    action.payload;

  const source = CancelToken.source();
  try {
    enableLoading();

    const response = yield call(accountStatementSetEnabledClient, {
      cancelToken: source.token,
      clientId,
      status,
      extraParams,
    });
    if (responseOK(response)) {
      setStatus(status);
      yield put(tableComponentsChangingSwitchSetSetStatus(action.payload));
      yield call(messageSuccess, response.message);
    } else {
      yield call(messageError, response.message);
      yield put(tableComponentsChangingSwitchClearSetStatus());
    }
    yield call(disableLoading);
  } catch (error) {
    yield call(disableLoading);
    yield tableComponentsChangingSwitchClearSetStatus();
    console.error(error);
  } finally {
    if (yield cancelled()) {
      yield tableComponentsChangingSwitchClearSetStatus();
      source.cancel('cancelled Request');
    }
  }
}

export function* watchTableComponentsChangingSwitchRequestSetStatus() {
  yield takeLatest(
    TABLE_COMPONENTS_CHANGING_SWITCH_REQUEST_SET_STATUS,
    workTableComponentsChangingSwitchRequestSetStatus
  );
}
