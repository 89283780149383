import React, { forwardRef } from 'react';
import { Form, Select } from 'antd';
import { setRuleRequired } from 'utils/validations';
import PropTypes from 'prop-types';
import { getPopupContainer as helperGetPopupContainer } from 'Domains/SalesChallengeRefactor/helpers/helperForms';

const { Option } = Select;

const BSelect = forwardRef((props, ref) => {
  const {
    form,
    initialValue,
    name,
    isLoading,
    isRequired,
    label,
    labelKey,
    optionKey,
    options,
    placeholder,
    valueKey,
    getPopupContainer,
    filterOption,
    allowClear,
    disabled,
    optionsProps,
    selectAll,
    maxTagCount,
    ...other
  } = props;

  const requiredRule = isRequired ? [setRuleRequired(label)] : [];

  const createOptionsList = () => {
    let optionsList = [];
    try {
      optionsList = [...options];
    } catch (error) {
      optionsList = [];
    }

    if (selectAll) {
      optionsList = [
        { [valueKey]: 'ALL', [optionKey]: 'ALL', [labelKey]: 'Todos' },
        ...optionsList,
      ];
    }
    if (typeof options !== 'undefined' && options.length > 0) {
      return optionsList?.map((option) => {
        return (
          <Option
            key={option[optionKey]}
            value={option[valueKey]}
            {...optionsProps[option[valueKey]]}
          >
            {option[labelKey]}
          </Option>
        );
      });
    }
  };

  const loadingSelect = {
    hasFeedback: true,
    validateStatus: 'validating',
  };

  const loading = isLoading ? loadingSelect : null;
  const initial = initialValue === null ? null : { initialValue: initialValue };
  const parentValue = props.value ? { value: props.value } : {};
  return options ? (
    <Form.Item
      label={label}
      {...loading}
      name={name}
      rules={[...requiredRule]}
      {...initial}
      {...other}
    >
      <Select
        showSearch
        data-testid="idtest"
        id={name}
        ref={ref}
        {...other}
        {...parentValue}
        size={'middle'}
        getPopupContainer={getPopupContainer}
        placeholder={placeholder}
        filterOption={filterOption}
        allowClear={allowClear}
        disabled={disabled || isLoading}
        maxTagCount={maxTagCount}
      >
        {createOptionsList()}
      </Select>
    </Form.Item>
  ) : null;
});

BSelect.propTypes = {
  allowClear: PropTypes.bool,
  filterOption: PropTypes.func,
  getPopupContainer: PropTypes.func,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isLoading: PropTypes.bool,
  isRequired: PropTypes.bool,
  label: PropTypes.string,
  labelKey: PropTypes.string,
  name: PropTypes.string,
  optionKey: PropTypes.string.isRequired,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  valueKey: PropTypes.string,
  disabled: PropTypes.bool,
  optionsProps: PropTypes.object,
};

BSelect.defaultProps = {
  allowClear: true,
  initialValue: null,
  isLoading: false,
  isRequired: false,
  label: 'Label',
  labelKey: 'value',
  name: 'select',
  optionKey: 'key',
  options: [],
  placeholder: 'Seleccione una opción',
  valueKey: 'key',
  disabled: false,
  optionsProps: {},
  getPopupContainer: helperGetPopupContainer,
  filterOption: (input, option) => {
    if (isNaN(option.children))
      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    else return option.children.toString().indexOf(input.toLowerCase()) >= 0;
  },
};

BSelect.displayName = 'BSelect';

export default BSelect;
