import rootReducer from './redux/reducers';
import rootSagas from './redux/sagas';

export default function getTradeAgreementsSellOutModule() {
  return {
    id: 'trade-agreements-sellout',
    reducerMap: {
      TRADE_AGREEMENTS_SELLOUT: rootReducer,
    },
    sagas: [rootSagas],
  };
}
