export const QUERY_SAVE = 'TRADE-AGREEMENTS/QUERY_SAVE';
export const QUERY_CLEAN = 'TRADE-AGREEMENTS/QUERY_CLEAN';

export function queryClean(payload) {
  return {
    type: QUERY_CLEAN,
    payload,
  };
}

export function querySave(payload) {
  let PolicyTypeId = payload.businessRule || payload.PolicyTypeId;
  let Portafolio = payload.Portafolio;
  if (payload.businessRule?.includes('_')) {
    let array = payload.businessRule.split('_');
    PolicyTypeId = array[0];
    Portafolio = array[1];
  }
  return {
    type: QUERY_SAVE,
    payload: { ...payload, PolicyTypeId, Portafolio, businessRule: undefined },
  };
}

export const TOGGLE_TYPE_VALUE = 'TRADE-AGREEMENTS/TOGGLE_TYPE_VALUE';

export function toggleTypeValue(payload) {
  return {
    type: TOGGLE_TYPE_VALUE,
    payload: payload,
  };
}
