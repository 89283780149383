import { takeLatest, put, call, cancelled, select, takeEvery } from 'redux-saga/effects';
import {
  REGIONS_REQUEST,
  regionSuccess,
  regionFail,
  SEGMENTS_REQUEST,
  segmentsSuccess,
  segmentsFail,
  BUSINESSLINE_REQUEST,
  businessLineSuccess,
  businessLineFail,
  BUSINESSLINE_REQUEST2,
  businessLineSuccess2,
  businessLineFail2,
  SUBREGIONS_REQUEST,
  subregionsSuccess,
  subregionsFail,
  TERRITORY_REQUEST,
  territoryFail,
  territorySuccess,
  ZONES_REQUEST,
  zonesSuccess,
  zonesFail,
  CLIENTS_REQUEST,
  clientsSuccess,
  clientsFail,
  PRODUCTS_REQUEST,
  productsSuccess,
  productsFail,
  FAMILY_REQUEST,
  familySuccess,
  familyFail,
  BUSINESSRULES_REQUEST,
  businessRulesSuccess,
  businessRulesFail,
  YEARS_REQUEST,
  yearsSuccess,
  yearsFail,
  GET_PRODUCTS_ADD_REQUEST,
  getProductsAddSuccess,
  getProductsAddFail,
  PRODUCTS_UPDATE_REQUEST,
  updateProductRequest,
  updateProductUpdate,
  POG_REQUEST,
  pogSuccess,
  pogFail,
  rolesSuccess,
  rolesFail,
  ROLES__REQUEST,
  AREAS_REQUEST,
  areasSuccess,
  areasFail,
  COUNTRIES_REQUEST,
  countriesSuccess,
  countriesFail,
  COMPANIES_REQUEST,
  companiesSuccess,
  companiesFail,
  CURRENCY_REQUEST,
  currencySuccess,
  currencyFail,
  segmentBusinessRulesSuccess,
  segmentBusinessRulesFail,
  SEGMENT_BUSINESSRULES_REQUEST,
} from 'RootModule/redux/actions/catalogs';
import {
  CLIENT_SELECTED,
  saveClient,
  TERRITORY_SELECTED,
} from 'Domains/TradeAgreements/redux/actions/infoForAgreement';
import { getFromStore } from 'Domains/TradeAgreements/redux/sagas/selectors';
import instance from 'services/request';
import {
  getSalesChallengeCatalogByName,
  getBusinessStructureLines,
  getBusinessStructureSubregions,
  getFixedCatalogsSegmentation,
  getAgreementCatalogByCatalogName,
  getAgreementProductById,
  getSalesChallengeCatalogLines,
  getFixedCatalogsBusienssLines,
  getAgreementByName,
  getDaysLeft,
  getProductsToAdd,
} from 'Domains/TradeAgreements/services/catalogs';
import {
  getAgreementByName as getAgreeSellOutByName,
  getAgreementCatalogByCatalogName as getAgreeSellOUtCatalogByName,
  getSalesChallengeCatalogByName as getSalesChallengeCatByName,
  getAgreementProductById as getAgreeProductById,
} from 'Domains/TradeAgreementsSellOut/services/catalogs';

import {
  getSalesByName,
  getKamCatalogByName,
  getSalesProductsCatalogsByName,
} from 'Domains/SalesChallengeRefactor/services/catalogs';
import {
  getFixedCatalogByName,
  getCatalogByName,
  getFixedCatalogProcessYears,
  getFixedCatalogRegions,
  getClientsForValidators,
  getZonesForValidators,
  getTerritoriesForValidators,
  getPoliciesCatalog,
} from 'RootModule/services/catalogs';
import { getCatalogUser } from 'User/services/users';
import { getClientsApprovedList } from 'Domains/TradeAgreements/services/reports';
import { responseOK } from 'Domains/TradeAgreements/utils';
import { orderArrayObjects } from 'Domains/SalesChallengeRefactor/utils';

function* workRegionsRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const { onSuccess, sourceId } = payload;
  let service;
  try {
    switch (sourceId) {
      case 'Fixed':
        service = getFixedCatalogRegions;
        break;
      case 'Sales':
      case 'Agreements-SellOut':
        service = getSalesChallengeCatByName;
        break;
      default:
        service = getSalesChallengeCatalogByName;
        break;
    }

    const response = yield call(service, {
      cancelToken: source.token,
      name: 'Regions',
    });

    if (responseOK(response)) {
      yield put(regionSuccess(response.data));
      if (onSuccess) onSuccess(response.data);
    } else {
      yield put(regionFail());
    }
  } catch (e) {
    yield put(regionFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled RQUEST');
    }
  }
}

function* workSegmentsRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const { onSuccess } = payload;
  try {
    const response = yield call(getFixedCatalogsSegmentation, source.token);
    if (responseOK(response)) {
      yield put(segmentsSuccess(response.data));
      if (onSuccess) onSuccess(response.data);
    } else {
      yield put(segmentsFail());
    }
  } catch (e) {
    yield put(regionFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request ');
    }
  }
}

function* workSubregionsRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const { sourceId, requestParams, onSuccess, mode } = payload;
  let service;
  let name;
  switch (sourceId) {
    case 'Fixed':
      name = 'SubRegions';
      service = getFixedCatalogByName;
      break;
    case 'Catalogs':
      service = getBusinessStructureSubregions;
      break;

    case 'Sales':
      name = 'Subregions';
      service = getSalesChallengeCatalogByName;
      break;

    default:
      service = getBusinessStructureSubregions;
      break;
  }
  try {
    const response = yield call(service, {
      cancelToken: source.token,
      name: name,
      params: requestParams,
      catalogName: name,
      mode,
    });

    if (responseOK(response)) {
      yield put(subregionsSuccess(response.data));
      if (onSuccess) onSuccess(response.data);
    } else {
      yield put(subregionsFail());
    }
  } catch (e) {
    yield put(subregionsFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request ');
    }
  }
}

function* workBusinesslineRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const { sourceId, requestParams, onSuccess } = payload;
  let service;
  let catalogName;
  switch (sourceId) {
    case 'Fixed':
      catalogName = 'BusinessLines';
      service = getFixedCatalogByName;
      break;
    case 'Catalogs':
      service = getBusinessStructureLines;
      break;

    case 'Sales':
      service = getSalesChallengeCatalogLines;
      break;

    default:
      service = getBusinessStructureLines;
      break;
  }
  try {
    const response = yield call(service, {
      cancelToken: source.token,
      params: requestParams,
      catalogName,
    });
    if (responseOK(response)) {
      yield put(businessLineSuccess(response.data));
      if (onSuccess) onSuccess(response.data);
    } else {
      yield put(businessLineFail());
    }
  } catch (error) {
    console.error(error);
    yield put(businessLineFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request ');
    }
  }
}

function* workBusinesslineRequest2(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const { sourceId, requestParams, onSuccess } = payload;
  let service;
  let catalogName;
  switch (sourceId) {
    case 'Fixed':
      catalogName = 'BusinessLines';
      service = getFixedCatalogByName;
      break;
    case 'Catalogs':
      service = getBusinessStructureLines;
      break;

    case 'Sales':
      service = getSalesChallengeCatalogLines;
      break;
    case 'soldTo':
      service = getFixedCatalogsBusienssLines;
      break;

    default:
      service = getBusinessStructureLines;
      break;
  }
  try {
    const response = yield call(service, {
      cancelToken: source.token,
      params: requestParams,
      catalogName,
    });
    if (responseOK(response)) {
      yield put(businessLineSuccess2(response.data));
      if (onSuccess) onSuccess(response.data);
    } else {
      yield put(businessLineFail2());
    }
  } catch (error) {
    console.error(error);
    yield put(businessLineFail2());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request ');
    }
  }
}

function* workTerritoryRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const { requestParams, onSuccess, sourceId, mode } = payload;

  let service;
  let catalogName;
  switch (sourceId) {
    case 'Validators':
      service = getTerritoriesForValidators;
      break;

    case 'Fixed':
      service = getFixedCatalogByName;
      catalogName = 'Territories';
      break;
    case 'Agreements':
      service = getAgreementCatalogByCatalogName;
      break;
    case 'Agreements-SellOut':
      service = getAgreeSellOUtCatalogByName;
      break;
    case 'Sales':
      service = getSalesChallengeCatalogByName;
      break;

    case 'KAM':
      service = getKamCatalogByName;
      break;

    default:
      service = getSalesChallengeCatalogByName;
      break;
  }
  try {
    const response = yield call(service, {
      cancelToken: source.token,
      name: 'Territories',
      params: requestParams,
      catalogName,
      mode,
    });
    if (responseOK(response)) {
      yield put(territorySuccess(response.data));
      if (onSuccess) onSuccess(response.data);
    } else {
      yield put(territoryFail());
    }
  } catch (error) {
    console.error(error);
    yield put(territoryFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request ');
    }
  }
}

function* workZonesRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  let { requestParams, onSuccess, sourceId, mode } = payload;
  let service;
  let catalogName;
  switch (sourceId) {
    case 'Fixed':
      service = getFixedCatalogByName;
      catalogName = 'Zones';

      break;
    case 'Validators':
      service = getZonesForValidators;

      break;

    case 'Sales':
      service = getSalesChallengeCatalogByName;
      break;

    case 'KAM':
      service = getKamCatalogByName;
      break;

    default:
      service = getSalesChallengeCatalogByName;
      break;
  }
  try {
    const response = yield call(service, {
      cancelToken: source.token,
      name: 'Zones',
      params: requestParams,
      mode,
      catalogName,
    });
    if (responseOK(response)) {
      yield put(zonesSuccess(response.data));
      if (onSuccess) onSuccess(response.data);
    } else {
      yield put(zonesFail());
    }
  } catch (error) {
    console.error(error);
    yield put(zonesFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request ');
    }
  }
}

function* workClientsRequest(action) {
  const { payload } = action;
  const { requestParams, onSuccess, sourceId, mode } = payload;
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  let service;

  let requestConfig = {
    cancelToken: source.token,
    name: 'Clients',
    params: requestParams,
  };

  switch (sourceId) {
    case 'Validators':
      service = getClientsForValidators;
      break;
    case 'Agreements':
      service = getAgreementCatalogByCatalogName;
      break;
    case 'Agreements-SellOut':
      service = getAgreeSellOUtCatalogByName;
      break;
    case 'Sales':
      service = getSalesChallengeCatalogByName;
      break;
    case 'KAM':
      service = getKamCatalogByName;
      break;

    case 'TradeReport':
      service = getClientsApprovedList;
      delete requestConfig.params;
      delete requestConfig.name;
      requestConfig = { ...requestConfig, clientId: requestParams.clientId };
      break;

    case 'Placement':
      service = getCatalogUser;
      requestConfig = { ...requestConfig, mode };
      break;

    case 'BySoldTo':
      service = getCatalogByName;
      requestConfig = { ...requestConfig, catalogName: 'Clients/BySoldTo', mode };
      break;

    case 'ByPlacement':
      service = getCatalogByName;
      requestConfig = { ...requestConfig, catalogName: 'Clients/ByPlacement', mode };
      break;

    case 'All':
      service = getCatalogByName;
      requestConfig = { ...requestConfig, catalogName: 'Clients', mode };
      break;

    default:
      service = getAgreementCatalogByCatalogName;
  }
  try {
    const response = yield call(service, {
      ...requestConfig,
    });
    if (responseOK(response)) {
      if (sourceId === 'Validators') {
        response.data = response.data.map((client) => ({
          ...client,
          key: client.clientId,
          value: client.clientName,
        }));
      }
      yield put(clientsSuccess(response.data));
      if (onSuccess) {
        yield onSuccess(response.data);
      }
    } else {
      yield put(clientsFail());
    }
  } catch (error) {
    console.error(error);
    yield put(clientsFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request ');
    }
  }
}

function* workProductsRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const { requestParams, sourceId, onSuccess, showHasYearVolume, hasVolumeParams } = payload;
  let service;
  let sellOutFlag = false;
  let requestConfig = {};

  switch (sourceId) {
    case 'ByProductNumber':
      service = getCatalogByName;
      requestConfig = { catalogName: 'Products/ByProductNumber', params: requestParams };
      break;
    case 'Sales':
      service = getSalesChallengeCatalogByName;
      requestConfig = { name: 'Products', params: requestParams };
      break;

    case 'Products':
      service = getSalesProductsCatalogsByName;
      requestConfig = { name: 'List', params: requestParams };
      break;
    case 'Agreements-SellOut':
      service = getAgreeSellOUtCatalogByName;
      requestConfig = { name: 'Products', params: requestParams };
      sellOutFlag = true;
      break;
    default:
      service = getAgreementCatalogByCatalogName;
      requestConfig = { name: 'Products', params: requestParams };
      break;
  }
  try {
    const response = yield call(service, {
      cancelToken: source.token,
      ...requestConfig,
    });
    if (responseOK(response)) {
      const { data } = response;
      if (!showHasYearVolume) {
        yield put(productsSuccess(data));
      } else {
        const productsWithYearVolume = [];
        const totalProducts = data.length;

        for (let x = 0; x < totalProducts; x++) {
          let productData = { ...data[x] };
          let ProductId = data[x].key;
          let responseProduct = {};
          if (sellOutFlag) {
            responseProduct = yield call(getAgreeProductById, {
              params: { ...hasVolumeParams, ProductId },
              cancelToken: source.token,
            });
          } else {
            responseProduct = yield call(getAgreementProductById, {
              params: { ...hasVolumeParams, ProductId },
              cancelToken: source.token,
            });
          }

          if (responseOK(responseProduct)) {
            const { montlyVolume } = responseProduct.data;
            const nullVolumes = montlyVolume.reduce(
              (current, volume) => current + (volume === null ? 1 : 0),
              0
            );
            /* se cambia de < 13 a === 0 por el ticket 5705 */
            productData.hasMontlyVolume = nullVolumes === 0;
          }
          productsWithYearVolume.push(productData);
        }
        yield put(productsSuccess(productsWithYearVolume));
        sellOutFlag = false;
      }
      if (onSuccess) {
        yield onSuccess(data);
      }
    } else {
      yield put(productsFail());
    }
  } catch (error) {
    console.error(error);
    yield put(productsFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request ');
    }
  }
}

function* workFamilyRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const { requestParams, sourceId, onSuccess } = payload;
  let service;
  let requestConfig = {};

  switch (sourceId) {
    case 'Sales':
      service = getSalesChallengeCatalogByName;
      requestConfig = { name: 'Families', params: requestParams };
      break;

    case 'Products':
      service = getSalesProductsCatalogsByName;
      requestConfig = { name: 'FamiliesList', params: requestParams };
      break;

    case 'Agreements-SellOut':
      service = getAgreeSellOUtCatalogByName;
      requestConfig = { name: 'Families', params: requestParams };
      break;
    default:
      service = getAgreementCatalogByCatalogName;
      requestConfig = { name: 'Families', params: requestParams };
      break;
  }

  try {
    const response = yield call(service, {
      cancelToken: source.token,
      ...requestConfig,
    });
    if (responseOK(response)) {
      let data = response.data;
      yield put(familySuccess(data));
      if (onSuccess) onSuccess(data);
    } else {
      yield put(familyFail());
    }
  } catch (error) {
    console.error(error);
    yield put(familyFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request ');
    }
  }
}

function* workBusinessrulesRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const { onSuccess } = payload;
  try {
    const response = {
      data: [
        { value: 'Dentro de política', key: 1 },
        { value: 'Fuera de política', key: 2 },
        { value: 'Todos', key: 3 },
      ],
    };
    if (responseOK(response)) {
      yield put(businessRulesSuccess(response.data));
      if (onSuccess) onSuccess(response.data);
    } else {
      yield put(businessRulesFail());
    }
  } catch (error) {
    console.error(error);
    yield put(businessRulesFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request ');
    }
  }
}

function* workSegmentBusinessRulesRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const { onSuccess } = payload;
  try {
    const response = yield call(getPoliciesCatalog, { cancelToken: source.token });
    if (responseOK(response)) {
      const allowedItems = [];
      response.data.forEach((item) => {
        if (item.portafolios.length > 0) {
          const allowOptions = [];
          item.portafolios.forEach((option) => {
            allowOptions.push({
              label: option.name,
              value: `${item.policyType_Id}_${option.portafolioType_Id}`,
            });
          });
          allowedItems.push({ label: item.name, options: allowOptions });
        } else {
          allowedItems.push({ label: item.name, value: `${item.policyType_Id}` });
        }
      });
      yield put(segmentBusinessRulesSuccess(allowedItems));
      if (onSuccess) onSuccess(allowedItems);
    } else {
      yield put(segmentBusinessRulesFail());
    }
  } catch (error) {
    console.error(error);
    yield put(segmentBusinessRulesFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request ');
    }
  }
}

function* workYearsRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const { sourceId, onSuccess } = payload;
  let service;
  let params = {};

  switch (sourceId) {
    case 'DaysLeft':
      service = getDaysLeft;
      params = { processId: 10 };
      break;

    case 'Sales':
      service = getSalesByName;
      params = { name: 'Years' };
      break;
    case 'Agreements':
      service = getAgreementByName;
      params = { name: 'Years' };
      break;
    case 'Agreements-SellOut':
      service = getAgreeSellOutByName;
      params = { name: 'Years' };
      break;
    case 'Fixed':
      service = getFixedCatalogProcessYears;
      params = {};
      break;
    default:
      console.warn(
        `sourceId: ${sourceId} is not controlled by switch, Try Years, DaysLeft or add case to sagas`
      );
      break;
  }
  try {
    const response = yield call(service, {
      cancelToken: source.token,
      params: params,
      name: params?.name ? params.name : '',
    });

    if (responseOK(response)) {
      if (sourceId === 'Agreements') {
        response.data.sort(function (a, b) {
          return b.value - a.value;
        });
      }
      yield put(yearsSuccess(response.data));
      if (onSuccess) onSuccess(response.data);
    } else {
      yield put(yearsFail());
    }
  } catch (error) {
    console.error(error);
    yield put(yearsFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request ');
    }
  }
}

function* workerClientSelected(action) {
  const currentClients = yield select((state) =>
    getFromStore(state, 'TRADE_AGREEMENTS', 'filters', 'clients')
  );

  try {
    const { payload } = action;
    const { clientId } = payload;
    if (currentClients) {
      const getClientInfo = currentClients.data.find((client) => client.key === clientId);

      if (getClientInfo && Object.keys(getClientInfo).length > 0) {
        yield put(saveClient(getClientInfo));
      } else {
        console.warn('Por algún motivo extraño no encontramos el cliente');
      }
    } else {
      console.warn("'No viene de la consulta de desafío");
    }
  } catch (error) {
    console.error(error);
  }
}

function* workerTerritorySelected(action) {
  const currentTerritory = yield select((state) =>
    getFromStore(state, 'TRADE_AGREEMENTS', 'filters', 'territory')
  );

  try {
    const { payload } = action;
    const { territoryId } = payload;
    if (currentTerritory) {
      if (currentTerritory?.data) {
        const getTerritoryInfo = currentTerritory?.data.find(
          (territory) => territory.key === territoryId
        );

        if (getTerritoryInfo && Object.keys(getTerritoryInfo).length > 0) {
          yield put(saveClient(getTerritoryInfo));
        } else {
          console.warn('Por algún motivo extraño no encontramos el territorio');
        }
      } else {
        console.warn('No viene de la consulta de desafío');
      }
    } else {
      console.warn('No viene de la consulta de desafío');
    }
  } catch (error) {
    console.error(error);
  }
}

function* workGetProductsAddRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const { onSuccess, id, requestParams, sourceId } = payload;
  let service;
  let requestConfig;

  switch (sourceId) {
    case 'Products/Agreements':
      service = getProductsToAdd;
      requestConfig = { name: 'List', params: requestParams, cancelToken: source.token };
      break;

    case 'Sales':
    default:
      service = getSalesProductsCatalogsByName;
      requestConfig = { name: 'List', params: requestParams, cancelToken: source.token };
      break;
  }
  try {
    const response = yield call(service, {
      ...requestConfig,
    });
    if (responseOK(response)) {
      yield put(getProductsAddSuccess({ data: response.data, id }));
      yield put(updateProductRequest());

      //TODO Cambiar esta petición hasta que se seleccione el botón aceptar
      // const getReach = yield call(getChallengeReach, requestParams, source.token);
      // yield put(reachSuccess(getReach.data));
      if (onSuccess) {
        onSuccess({ data: response.data, id });
      }
    } else {
      yield put(getProductsAddFail({ data: response.data, id }));
    }
  } catch (error) {
    console.error(error);
    yield put(getProductsAddFail({ id }));
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request ');
    }
  }
}

function* workerUpdateProducts() {
  try {
    let currentProducts = yield select((state) =>
      getFromStore(state, 'APP', 'catalogs', 'addProducts')
    );

    let idsToRemove = yield select((state) =>
      getFromStore(state, 'SALES_CHALLENGE', 'addProduct', 'selected')
    );

    if (!idsToRemove) {
      idsToRemove = yield select((state) =>
        getFromStore(state, 'TRADE_AGREEMENTS', 'addProduct', 'selected')
      );
    }

    let rowProducts = Object.keys(currentProducts);

    let removeFrom = currentProducts[rowProducts.length - 1];

    if (removeFrom?.data?.length > 0 && idsToRemove) {
      let clean = removeFrom.data.filter((product) => !idsToRemove.includes(product.key));
      removeFrom = { ...removeFrom, data: [...clean] };

      if (rowProducts.length - 1 > 0) {
        let newProducts = {
          ...currentProducts,
          [rowProducts.length - 1]: removeFrom,
        };
        let products = [];
        if (newProducts?.[0]?.place) {
          products = newProducts.sort(orderArrayObjects('place'));
        } else {
          products = newProducts;
        }

        yield put(updateProductUpdate(products));
      }
    }
  } catch (error) {
    console.error(error);
  }
}

function* workRolesRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { onSuccess } = payload;
    const response = yield call(getFixedCatalogByName, {
      cancelToken: source.token,
      catalogName: 'Roles',
    });

    if (responseOK(response)) {
      yield put(rolesSuccess(response.data));
      if (onSuccess) onSuccess(response.data);
    } else {
      yield put(rolesFail());
    }
  } catch (error) {
    yield put(rolesFail());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

function* workPogRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { onSuccess } = payload;
    const response = yield call(getFixedCatalogByName, {
      cancelToken: source.token,
      catalogName: 'Pogs',
    });

    if (responseOK(response)) {
      yield put(pogSuccess(response.data));
      if (onSuccess) onSuccess(response.data);
    } else {
      yield put(pogFail());
    }
  } catch (error) {
    yield put(pogFail());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

function* workAreasRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { onSuccess } = payload;
    const response = yield call(getFixedCatalogByName, {
      cancelToken: source.token,
      catalogName: 'Areas',
    });

    if (responseOK(response)) {
      yield put(areasSuccess(response.data));
      if (onSuccess) onSuccess(response.data);
    } else {
      yield put(areasFail());
    }
  } catch (error) {
    yield put(areasFail());
    console.error('[BASF[REDUX-SAGA]', error);
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request');
    }
  }
}

function* workCountriesRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const {
    sourceId,
    requestParams,
    onSuccess,
    mode,

    setPreviousValue,
  } = payload;
  let service;
  let name;
  switch (sourceId) {
    case 'Zones':
      name = 'Countries';
      service = getFixedCatalogByName;
      break;

    case 'Subregion':
      name = 'CountriesBySubregion';
      service = getFixedCatalogByName;
      break;

    default:
      name = 'Countries';
      service = getFixedCatalogByName;
      break;
  }
  try {
    const response = yield call(service, {
      cancelToken: source.token,
      params: requestParams,
      catalogName: name,
      mode,
    });

    if (responseOK(response)) {
      yield put(countriesSuccess(response.data));
      if (onSuccess) onSuccess(response.data);

      if (setPreviousValue && typeof setPreviousValue === 'function') {
        yield call(setPreviousValue, [99999, ...response.data.map(({ key }) => key)]);
      }
    } else {
      yield put(countriesFail());
    }
  } catch (e) {
    yield put(countriesFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request ');
    }
  }
}

function* workCompaniesRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const { requestParams, onSuccess, mode } = payload;
  let service = getFixedCatalogByName;
  let name = 'Companies';

  try {
    const response = yield call(service, {
      cancelToken: source.token,
      params: requestParams,
      catalogName: name,
      mode,
    });

    if (responseOK(response)) {
      yield put(companiesSuccess(response.data));
      if (onSuccess) onSuccess(response.data);
    } else {
      yield put(companiesFail());
    }
  } catch (e) {
    yield put(companiesFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request ');
    }
  }
}

/** */
function* workCurrencyRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  const { payload } = action;
  const { onSuccess, mode } = payload;
  let service = getFixedCatalogByName;
  let name = 'Currencies';
  try {
    const response = yield call(service, {
      cancelToken: source.token,
      catalogName: name,
      mode,
    });

    if (responseOK(response)) {
      yield put(currencySuccess(response.data));
      if (onSuccess) onSuccess(response.data);
    } else {
      yield put(currencyFail());
    }
  } catch (e) {
    yield put(currencyFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled Request ');
    }
  }
}
/** */

// WATCHERS

export function* watchFamilyRequest() {
  yield takeLatest(FAMILY_REQUEST, workFamilyRequest);
}

export function* watchProductsRequest() {
  yield takeLatest(PRODUCTS_REQUEST, workProductsRequest);
}

export function* watchClientsRequest() {
  yield takeLatest(CLIENTS_REQUEST, workClientsRequest);
}

export function* watchZonesRequest() {
  yield takeLatest(ZONES_REQUEST, workZonesRequest);
}

export function* watchTerritoryRequest() {
  yield takeLatest(TERRITORY_REQUEST, workTerritoryRequest);
}

export function* watchBusinessrulesRequest() {
  yield takeLatest(BUSINESSRULES_REQUEST, workBusinessrulesRequest);
}

export function* watchSegmentBusinessRulesRequest() {
  yield takeLatest(SEGMENT_BUSINESSRULES_REQUEST, workSegmentBusinessRulesRequest);
}

export function* watchRegionRequest() {
  yield takeLatest(REGIONS_REQUEST, workRegionsRequest);
}

export function* watchSegmentsRequest() {
  yield takeLatest(SEGMENTS_REQUEST, workSegmentsRequest);
}

export function* watchSubregionsRequest() {
  yield takeLatest(SUBREGIONS_REQUEST, workSubregionsRequest);
}

export function* businesslineRequest() {
  yield takeLatest(BUSINESSLINE_REQUEST, workBusinesslineRequest);
}

export function* businesslineRequest2() {
  yield takeLatest(BUSINESSLINE_REQUEST2, workBusinesslineRequest2);
}

export function* watchYearsRequest() {
  yield takeLatest(YEARS_REQUEST, workYearsRequest);
}

export function* watchClientSelected() {
  yield takeLatest(CLIENT_SELECTED, workerClientSelected);
}

export function* watchTerritorySelected() {
  yield takeLatest(TERRITORY_SELECTED, workerTerritorySelected);
}

export function* watchGetProductsAddRequest() {
  yield takeEvery(GET_PRODUCTS_ADD_REQUEST, workGetProductsAddRequest);
}

export function* watchUpdateProducts() {
  yield takeEvery(PRODUCTS_UPDATE_REQUEST, workerUpdateProducts);
}

export function* watchPogRequest() {
  yield takeEvery(POG_REQUEST, workPogRequest);
}

export function* watchRolesRequest() {
  yield takeEvery(ROLES__REQUEST, workRolesRequest);
}

export function* watchAreasRequest() {
  yield takeEvery(AREAS_REQUEST, workAreasRequest);
}

export function* watchCountriesRequest() {
  yield takeEvery(COUNTRIES_REQUEST, workCountriesRequest);
}

export function* watchCompaniesRequest() {
  yield takeEvery(COMPANIES_REQUEST, workCompaniesRequest);
}

export function* watchCurrencyRequest() {
  yield takeEvery(CURRENCY_REQUEST, workCurrencyRequest);
}
