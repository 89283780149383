import { takeLatest, put, call, cancelled } from 'redux-saga/effects';
import {
  INFO_REQUEST,
  infoFail,
  infoSuccess,
  KAM_INFO_REQUEST,
  kamInfoSuccess,
  kamInfoFail,
} from '../actions/info';
import instance from 'services/request';
import { getInfo, getKAMInfo } from '../../services/info';
import { responseOK } from '../../utils';

function* workerInfoRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { params, name, onSuccess, sourceId } = payload;
    let response;
    if (sourceId === 'KAM') {
      response = yield call(getKAMInfo, params, source.token);
    } else {
      response = yield call(getInfo, name, params, source.token);
    }

    if (responseOK(response)) {
      if (onSuccess) {
        yield onSuccess(response.data);
      }
      yield put(infoSuccess(response.data));
    } else {
      yield put(infoFail());
    }
  } catch (e) {
    yield put(infoFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchIndicatorRequest() {
  yield takeLatest(INFO_REQUEST, workerInfoRequest);
}

function* workerKAMInfoRequest(action) {
  const CancelToken = instance.CancelToken;
  const source = CancelToken.source();
  try {
    const { payload } = action;
    const { params, onSuccess } = payload;
    const response = yield call(getKAMInfo, params, source.token);

    if (responseOK(response)) {
      if (onSuccess) {
        yield onSuccess(response.data);
      }
      yield put(kamInfoSuccess(response.data));
    } else {
      yield put(kamInfoFail());
    }
  } catch (e) {
    yield put(kamInfoFail());
  } finally {
    if (yield cancelled()) {
      source.cancel('cancelled REQUEST');
    }
  }
}

export function* watchKAMIndicatorRequest() {
  yield takeLatest(KAM_INFO_REQUEST, workerKAMInfoRequest);
}
