import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col } from 'antd';
import { ReviewActions } from 'common';

import {
  approveTradeAgreementRequest,
  approveTradeAgreementRequestByKAM,
  rejectTradeAgreementRequest,
  rejectTradeAgreementRequestByKAM,
} from '../redux/actions';
import { aproveClientModal, rejectClientModal } from '../utils/aproveRejectModals';

const SecondLevelValidation = (props) => {
  const {
    approveTradeAgreementRequest: approveRequest,
    rejectTradeAgreementRequest: rejectRequest,
    approveTradeAgreementRequestByKAM: approveByKAM,
    rejectTradeAgreementRequestByKAM: rejectByKAM,
    daysLeft,
    year,
    clientId,
    territoryId,
    typeOfValidator,
    currentView,
    clientStatusId,
    isValidSecondValidator,
  } = props;

  const canValidateAgreement =
    daysLeft > 0 && currentView === 'clientId' && clientStatusId === 6 && isValidSecondValidator;

  const defaultParams = { clientId, year, territoryId };

  const onValidate = () => {
    const validationService = typeOfValidator === 'default' ? approveRequest : approveByKAM;

    validationService({ data: defaultParams });
  };

  const onReject = (comment) => {
    const rejectService = typeOfValidator === 'default' ? rejectRequest : rejectByKAM;

    rejectService({ data: { ...defaultParams, comment } });
  };
  return (
    canValidateAgreement && (
      <Col style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <ReviewActions
          className={'sales-challenge '}
          templateLiteralsAprove={aproveClientModal}
          templateLiteralsReject={rejectClientModal}
          onApprove={() => onValidate()}
          onRejected={(comment) => onReject(comment)}
          actions={['approve', 'rejected']}
          showLabels={true}
        />
      </Col>
    )
  );
};

const mapStateToProps = (state) => {
  return {
    year: state?.TRADE_AGREEMENTS_SELLOUT?.query?.year ?? null,
    clientId: state?.TRADE_AGREEMENTS_SELLOUT?.query?.clientId,
    territoryId: state?.TRADE_AGREEMENTS_SELLOUT?.query?.territoryId,
    daysLeft:
      state?.TRADE_AGREEMENTS_SELLOUT?.registerTrade?.daysLeft?.data?.VALIDITY_PROCESS?.daysLeft ??
      0,
    currentView: state?.TRADE_AGREEMENTS_SELLOUT?.views?.current ?? '',
    clientStatusId: state?.TRADE_AGREEMENTS_SELLOUT?.infoForAgreement?.clientInfo?.statusId ?? 0,
    isValidSecondValidator: state?.TRADE_AGREEMENTS_SELLOUT?.secondValidation?.valid ?? false,
  };
};

export default connect(mapStateToProps, {
  approveTradeAgreementRequest,
  rejectTradeAgreementRequest,
  approveTradeAgreementRequestByKAM,
  rejectTradeAgreementRequestByKAM,
})(SecondLevelValidation);

SecondLevelValidation.propTypes = {
  typeOfValidator: PropTypes.oneOf(['default', 'kam']),
};

SecondLevelValidation.defaultProps = {
  typeOfValidator: 'default',
};
