import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules-react';
import getRootModule from 'RootModule';
import getTradeAgreementsRateMassiveUploadModule from '../getTradeAgreementsRateMassiveUploadModule';
import { layoutContext } from 'context';
import { useHistory } from 'react-router-dom';
import { Form } from 'antd';
import {
  MassiveUploadTable,
  UploadExcelButton,
  ActionButtonsExcelUpload,
} from 'common/MassiveUploads/components';
import {
  tradeAgreementsMassiveUploadSetAllowUpload,
  tradeAgreementsMassiveUploadRequestVerify,
  tradeAgreementsMassiveUploadRequestUpload,
  tradeAgreementsMassiveUploadClearUpload,
  tradeAgreementsMassiveUploadClearVerify,
  tradeAgreementsMassiveUploadClearAllowUpload,
} from '../redux-sagas/actions';
import tableColumns from '../schemas/massiveUploadTableColumns'; //cambiar columna
const TradeSellOutMassiveUpload = (props) => {
  const {
    tradeAgreementsMassiveUploadSetAllowUpload: setAllowUpload,
    tradeAgreementsMassiveUploadRequestVerify: requestVerify,
    tradeAgreementsMassiveUploadRequestUpload: requestUpload,
    tradeAgreementsMassiveUploadClearUpload: clearUpload,
    tradeAgreementsMassiveUploadClearVerify: clearVerify,
    tradeAgreementsMassiveUploadClearAllowUpload: clearAllowUpload,
    allowUpload,
    verify,
  } = props;

  const history = useHistory();
  const [form] = Form.useForm();
  const { onChangeActiveComponent } = useContext(layoutContext);
  const [fileString, setFileString] = useState('');

  const correct = verify?.correct;
  const entriesList = verify?.listErrors;
  const allCorrectMessage = verify?.allCorrectMessage;
  // Effect to dispatch massiveUpload actions
  useEffect(() => {
    clearAllowUpload();

    return () => {
      clearAllowUpload();
      clearUpload();
      clearVerify();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // Effet to setActiveComponent n setAllowUpload
  useEffect(() => {
    onChangeActiveComponent('activo');
    if (correct > 0) {
      setAllowUpload(true);
    } else {
      setAllowUpload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [correct]);
  // Fn to verify file with service
  function customRequest({ file }) {
    requestVerify({ file, setFileString });
  }
  // Next fn's are explained based on their name
  function requestReplace() {
    requestUpload({ history, fileString, overwrite: false, save: true, replace: true });
  }

  function requestKeep() {
    requestUpload({ history, fileString, overwrite: false, save: false, keep: true });
  }

  function requestOverwrite() {
    requestUpload({
      history,
      fileString,
      overwrite: false,
      save: true,
      requestReplace,
      requestKeep,
      openModal: true,
    });
  }

  function onSubmit() {
    requestOverwrite();
  }
  function onCancel() {
    history.push(`/trade-agreements-sellout`);
  }

  return (
    <>
      <Form form={form}>
        <UploadExcelButton customRequest={customRequest} />
        <MassiveUploadTable
          data={entriesList}
          columns={tableColumns}
          allCorrectMessage={allCorrectMessage}
        />
        <ActionButtonsExcelUpload
          onSubmit={onSubmit}
          onCancel={onCancel}
          disabled={!allowUpload}
          buttons={['cancel', 'submit']}
        />
      </Form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    allowUpload: state?.TRADE_AGREEMENTS_SELLOUT_MASSIVE_UPLOAD?.allowUpload,
    verify: state?.TRADE_AGREEMENTS_SELLOUT_MASSIVE_UPLOAD?.verify,
  };
};

const ConnectedUsersMassiveUpload = connect(mapStateToProps, {
  tradeAgreementsMassiveUploadSetAllowUpload,
  tradeAgreementsMassiveUploadRequestVerify,
  tradeAgreementsMassiveUploadRequestUpload,
  tradeAgreementsMassiveUploadClearUpload,
  tradeAgreementsMassiveUploadClearVerify,
  tradeAgreementsMassiveUploadClearAllowUpload,
})(TradeSellOutMassiveUpload);

const UsersMassiveUploadWithModules = () => {
  return (
    <DynamicModuleLoader modules={[getRootModule(), getTradeAgreementsRateMassiveUploadModule()]}>
      <ConnectedUsersMassiveUpload />
    </DynamicModuleLoader>
  );
};
export default UsersMassiveUploadWithModules;
