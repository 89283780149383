export const DOWNLOAD_TRADE_AGREEMENTS_SELL_OUT_REPORT_REQUEST =
  'DOWNLOAD_TRADE_AGREEMENTS_SELL_OUT_REPORT_REQUEST';
export const DOWNLOAD_TRADE_AGREEMENTS_SELL_OUT_REPORT_SUCCESS =
  'DOWNLOAD_TRADE_AGREEMENTS_SELL_OUT_REPORT_SUCCESS';
export const DOWNLOAD_TRADE_AGREEMENTS_SELL_OUT_REPORT_FAIL =
  'DOWNLOAD_TRADE_AGREEMENTS_SELL_OUT_REPORT_FAIL';

export function downloadTradeAgreementsSellOutReportRequest(payload) {
  return {
    type: DOWNLOAD_TRADE_AGREEMENTS_SELL_OUT_REPORT_REQUEST,
    payload: {
      ...payload,
      fileConfig: {
        extension: 'xlsx',
        contentType:
          'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    },
  };
}

export function downloadTradeAgreementsSellOutReportSuccess(payload) {
  return { type: DOWNLOAD_TRADE_AGREEMENTS_SELL_OUT_REPORT_SUCCESS, payload };
}

export function downloadTradeAgreementsSellOutReportFail(payload) {
  return { type: DOWNLOAD_TRADE_AGREEMENTS_SELL_OUT_REPORT_FAIL, payload };
}
