import React, { useEffect, useCallback } from 'react';
import { Col, Row } from 'antd';
import TopFilterToRegister from '../TopFiltersToRegister';
import { twoColumns } from 'models/layout';
import ColumnLeft from './ColumnLeft';
import FirstRowRight from './FirstRowRight';
import SecondRowRight from './SecondRowRight';
import FooterActions from 'Domains/TradeAgreements/components/LayoutTradeAgreementsRegister/FooterActions';
import {
  getAgreementRequest,
  getReachRequest,
} from 'Domains/TradeAgreements/redux/actions/registerTrade';

import { connect } from 'react-redux';
import { DeleteTradeAgreementProductModal } from '../DeleteTradeAgreementProductModal';
const { leftColumn, rightColumn } = twoColumns;
function LayoutTradeAgreementsRegister(props) {
  const {
    pageType,
    onChangeActiveComponent,
    clientId,
    productId,
    year,
    territoryId,
    showBruteValue,
    getAgreementRequest: getAgreement,
    getReachRequest: getReach,
    currentAgreement,
    table,
    reach,
  } = props;

  useEffect(() => {
    if (clientId && productId) {
      getAgreement({
        params: { year, territoryId, clientId, productId },
        origin: 'filter',
      });
      getReach({
        params: {
          year,
          territoryId,
          clientId,
          policyTypeId: 1,
        },
      });
    }
  }, [getAgreement, year, territoryId, clientId, productId, getReach]);

  const prepareDataTable = useCallback(() => {
    if (table && table.length > 0) {
      if (showBruteValue) {
        return table.filter((row) => row.rowKey !== 2);
      } else {
        return table.filter((row) => row.rowKey !== 3);
      }
    }
    return [];
  }, [showBruteValue, table]);

  return (
    <>
      <Row gutter={{ xs: 8, sm: 10, md: 16, lg: 18 }}>
        <Col span={22}>
          <TopFilterToRegister onChangeActiveComponent={onChangeActiveComponent} />
        </Col>
      </Row>

      {clientId && productId && (
        <>
          <Row gutter={{ xs: 8, sm: 10, md: 16, lg: 18 }}>
            <Col {...{ ...leftColumn, lg: 3 }}>
              <ColumnLeft showBruteValue={showBruteValue} pageType={pageType} />
            </Col>
            <Col {...{ ...rightColumn, lg: 21 }}>
              <FirstRowRight currentAgreement={currentAgreement} reach={reach} />
              <SecondRowRight
                showBruteValue={showBruteValue}
                currentAgreement={currentAgreement}
                dataSource={prepareDataTable()}
              />
            </Col>
          </Row>
        </>
      )}
      <Row justify="end">
        <Col {...{ md: 10, sm: 12, xs: 24 }}>
          <FooterActions pageType={pageType} />
        </Col>
      </Row>
      <DeleteTradeAgreementProductModal />
    </>
  );
}

function mapStateToProps(state) {
  return {
    clientId: state?.TRADE_AGREEMENTS?.query.clientId || false,
    productId: state?.TRADE_AGREEMENTS?.query?.productId || false,
    showBruteValue: state?.TRADE_AGREEMENTS?.query?.showBruteValue,
    year: state?.TRADE_AGREEMENTS?.query?.year,
    territoryId: state?.TRADE_AGREEMENTS?.infoForAgreement?.territoryInfo?.territoryId,
    currentAgreement: state?.TRADE_AGREEMENTS?.registerTrade?.currentAgreement ?? false,
    table: state?.TRADE_AGREEMENTS?.table?.data ?? [],
    reach: state?.TRADE_AGREEMENTS?.registerTrade?.reach ?? false,
  };
}

export default connect(mapStateToProps, { getAgreementRequest, getReachRequest })(
  LayoutTradeAgreementsRegister
);
