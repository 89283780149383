import React from 'react';
import { formatPriceWithDecimals, orderArrayObjects } from 'Domains/SalesChallengeRefactor/utils';

export const dataSource = {
  priceId: 'a16e2655-eb6c-412e-8ca6-246e09cc9c7f',
  pogYear1: ['', '', '', '', '', '', '', '', '', '', '', '', ''],
  volumeYear1: ['', '', '', '', '', '', '', '', '', '', '', '', ''],
  netSaleYear1: ['', '', '', '', '', '', '', '', '', '', '', '', ''],
  pogYear2: ['', '', '', '', '', '', '', '', '', '', '', '', ''],
  volumeYear2: ['', '', '', '', '', '', '', '', '', '', '', '', ''],
  netSaleYear2: ['', '', '', '', '', '', '', '', '', '', '', '', ''],
  recomendation: [
    '400.25',
    '19,321.20',
    '19,321',
    '19,321',
    '19,321',
    '19,321',
    '19,321',
    '19,321',
    '19,321',
    '19,321',
    '800.20',
    '1,000.37',
    '376,478',
  ],
};

export const months = {
  0: { month: 'ene', key: 0 },
  1: { month: 'feb', key: 1 },
  2: { month: 'mar', key: 2 },
  3: { month: 'apr', key: 3 },
  4: { month: 'may', key: 4 },
  5: { month: 'jun', key: 5 },
  6: { month: 'jul', key: 6 },
  7: { month: 'ago', key: 7 },
  8: { month: 'sep', key: 8 },
  9: { month: 'oct', key: 9 },
  10: { month: 'nov', key: 10 },
  11: { month: 'dic', key: 11 },
  12: { month: 'total', key: 12 },
};

export const generateRowsSalesChallenge = (params) => {
  const { year, data: processData, moq, price, isEditable } = params;

  let { bruteQuarters, netQuarters, ...data } = processData;

  let rows = [];
  let unitVolume = '(Kg/L)';
  let unitMoney = '(USD)';

  if (data && year) {
    let keys = Object.keys(data);

    if (keys.length > 0) {
      keys.map((k) => {
        let row = { moq, price, year };

        if (typeof data[k] !== 'string' && data[k] && data[k].length > 0) {
          data[k].map((value, index) => {
            if (typeof months[index] !== 'undefined') {
              if (k === 'netSale') {
                row = {
                  ...row,
                  [months[index].month]: formatPriceWithDecimals(value),
                  isEditable: false,
                };
              } else {
                row = {
                  ...row,
                  [months[index].month]: value,
                  isEditable: false,
                };
              }

              //año 2 es el más viejo
              switch (k) {
                case 'pogYear2':
                  row = {
                    ...row,
                    title: (
                      <>
                        POG {year - 2} <br /> {unitVolume}
                      </>
                    ),
                    rowKey: 0,
                  };
                  break;
                case 'volumeYear2':
                  row = {
                    ...row,
                    title: (
                      <>
                        Volumen {year - 2} <br />
                        {unitVolume}
                      </>
                    ),
                    rowKey: 1,
                  };
                  break;
                case 'netSaleYear2':
                  row = {
                    ...row,
                    title: (
                      <>
                        Venta neta {year - 2} <br />
                        {unitMoney}
                      </>
                    ),
                    rowKey: 2,
                  };
                  break;

                case 'pogYear1':
                  row = {
                    ...row,
                    title: (
                      <>
                        POG {year - 1} <br />
                        {unitVolume}
                      </>
                    ),
                    rowKey: 3,
                  };
                  break;
                case 'volumeYear1':
                  row = {
                    ...row,
                    title: (
                      <>
                        Volumen {year - 1} <br />
                        {unitVolume}
                      </>
                    ),
                    rowKey: 4,
                  };
                  break;
                case 'netSaleYear1':
                  row = {
                    ...row,
                    title: (
                      <>
                        Venta neta {year - 1} <br />
                        {unitMoney}
                      </>
                    ),
                    rowKey: 5,
                  };
                  break;

                case 'recomendation':
                  row = {
                    ...row,
                    title: (
                      <>
                        Recomendación <br />
                        {unitVolume}
                      </>
                    ),
                    rowKey: 6,
                  };
                  break;
                case 'volumes':
                  row = {
                    ...row,
                    title: `Volumen  ${year} ${unitVolume}`,

                    isEditable,
                    currentPeriod: true,
                    rowKey: 7,
                    moq,
                    price,
                    year,
                  };

                  break;
                case 'netSale':
                  row = {
                    ...row,
                    title: `Venta neta ${year} ${unitMoney}`,
                    isEditable: false,
                    rowKey: 8,
                    currentPeriod: true,
                    moq,
                    price,
                    year,
                  };

                  break;
                //for trade-agreements
                case 'montlyVolume':
                  row = {
                    ...row,
                    title: `Volumen  ${year} ${unitVolume}`,
                    isEditable: true,
                    currentPeriod: true,
                    pageType: 'TradeAgreements',
                    rowKey: 1,
                    moq,
                    price,
                    year,
                  };
                  break;
                case 'netAmount':
                  row = {
                    ...row,
                    title: `Venta neta  ${year} ${unitMoney}`,
                    isEditable: false,
                    currentPeriod: true,
                    pageType: 'TradeAgreements',
                    rowKey: 2,
                    moq,
                    price,
                    year,
                  };

                  break;
                case 'bruteAmount':
                  row = {
                    ...row,
                    title: `Venta bruta  ${year} ${unitMoney}`,
                    isEditable: false,
                    currentPeriod: true,
                    pageType: 'TradeAgreements',
                    rowKey: 3,
                    moq,
                    price,
                    year,
                  };

                  break;

                default:
                  break;
              }
            }
            return null;
          });

          rows = [...rows, row];
        }
        return null;
      });

      rows = [...rows];

      let aux = [];
      rows.map((r, index) => {
        aux = [...aux, { ...r }];
        return null;
      });

      aux = aux.sort(orderArrayObjects('rowKey'));

      return aux;
    }

    return [];
  }
};

export const calculateMoq = (value, moq) => {
  let valueBy = value * 100;
  valueBy = parseInt(valueBy.toFixed());
  let moqBy = moq * 100;
  let onlyInteger = parseInt(moqBy.toFixed());
  return valueBy % onlyInteger === 0;
};
